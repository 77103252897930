import { EventExecutionStatus, RemoteEvent } from "../types/types";

// export class EventExecutionStatus {
//   public eventLocalId: string;
//   public instructionId: string;
//   public executionStarted: boolean;
//   public executionCompleted: boolean;
//   public ok: undefined | boolean;

//   constructor(event: RemoteEvent) {
//     this.eventLocalId = event.localId;
//     this.instructionId = event.instructionId;
//     this.executionStarted = false;
//     this.executionCompleted = false;
//   };

//   public get executing() {
//     return this.executionStarted && !this.executionCompleted;
//   };

//   public setExecutionStarted() {
//     this.executionStarted = true;
//   };

//   public setExecutionCompleted() {
//     this.executionCompleted = true;
//   };

//   public setOk(ok: boolean) {
//     this.ok = ok;
//   };
// };

export const eventExecutionStatusHelpers = {
  create: (event: RemoteEvent): EventExecutionStatus => {
    return {
      eventLocalId: event.localId,
      instructionId: event.instructionId,
      executionStarted: false,
      executionCompleted: false,
      ok: undefined
    };
  },
  executing: (executionStatus: EventExecutionStatus) => {
    return executionStatus.executionStarted && !executionStatus.executionCompleted;
  },
  setExecutionStarted: (executionStatus: EventExecutionStatus) => {
    executionStatus.executionStarted = true;
  },
  setExecutionCompleted: (executionStatus: EventExecutionStatus) => {
    executionStatus.executionCompleted = true;
  },
  setOk: (executionStatus: EventExecutionStatus, ok: boolean) => {
    executionStatus.ok = ok;
  }
};