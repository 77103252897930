import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    width: '55vw',
    height: '60vh',
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    // justifyContent: 'space-between',
    // width: 200,
    // height: 200,
    backgroundColor: theme.colors.globalBg,
    overflow: 'scroll'
  },
  infoLine: {
    display: 'flex',
    width: '100%',
  }
});