import React, { useMemo } from 'react';
import { Switch as MuiSwitch, SwitchProps, Theme, alpha, createStyles, withStyles } from '@material-ui/core';
import { useThemeContext } from '~/theme';

const SwitchColored: React.FC<SwitchProps> = (props) => {
  const { theme: { colors } } = useThemeContext();

  const Switch = useMemo(() => withStyles((theme: Theme) =>
    createStyles({
      switchBase: {
        color: colors.white,
        "&.Mui-checked": {
          color: colors.switchCheckedColor,
          '&:hover': {
            backgroundColor: alpha(colors.switchTrackCheckedColor, theme.palette.action.hoverOpacity),
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: colors.switchTrackCheckedColor
        },
      },
    })
  )(MuiSwitch), [colors]);

  return (
    <Switch {...props}/>
  );
};

SwitchColored.defaultProps={}

export { SwitchColored };