import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle cx={12} cy={12} r={10} stroke={props.color} strokeWidth={1.5} />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M12 17v-6"
    />
    <circle
      cx={1}
      cy={1}
      r={1}
      fill={props.color}
      transform="matrix(1 0 0 -1 11 9)"
    />
  </svg>
)
export { SvgComponent as InfoIcon }
