import React, { useState } from 'react';
import { useStyles } from './ExportScenarioModalStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../CustomButton';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { TextInput } from '../TextInput';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { prepareExportableScenario } from '~/helpers/draggableHelpers';
import { exportDataAsJsonFile } from '~/helpers/jsonHelpers';
import { handleOpenLink } from '~/helpers/windowHelpers';
import { CONFIG } from '~/config';
import { Checkbox } from '../Checkbox';
import { capitalizeString } from '~/helpers/strings';
import { useScenarioCollection } from '~/hooks/useScenarioCollection';
import cn from 'classnames';

export type ExportScenarioModalProps = CustomModalProps & {};

const ExportScenarioModal: React.FC<ExportScenarioModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    draggableInstuctionsState,
    connectedClientProjectId
  } = useSelector(userSelector);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [addToCollection, setAddToCollection] = useState(true);
  const [download, setDownload] = useState(false);

  const { saveScenarioToScenarioCollection } = useScenarioCollection();

  const handleExport = () => {
    const scenarioTitle = title || `${CONFIG.PRODUCT_NAME} ${t("Other.savedScenario")}`;
    const scenarioDescription = description || t("Common.noDescriptionProvided");

    const exportableScenario = prepareExportableScenario(draggableInstuctionsState, scenarioTitle, scenarioDescription);

    if (addToCollection)
      saveScenarioToScenarioCollection(exportableScenario);

    if (download)
      exportDataAsJsonFile({
        data: exportableScenario,
        dataFallbackName: "scenario",
        fileName: scenarioTitle,
        successCallback: restProps.onClose
      });

    restProps.onClose();
  }

  return (
    <CustomModal
      {...restProps}
      classname={cn(s.exportScenarioModal)}
      title={t("Other.saveOrExportScenario")}
      enableBlur={true}
    >
      <>
        <Box className={cn(s.mainBlock)}>
          <Box className={cn(s.inputCont)}>
            <TextInput 
              label={`Title:`}
              labelColor={theme.colors.textMain}
              value={title}
              onChangeText={(text) => setTitle(text)}
              placeholder={t("Other.awesomeScenario")}
            />
          </Box>
          <Box className={cn(s.inputCont)}>
            <TextInput 
              label={capitalizeString(`${t("Common.description")}:`)}
              labelColor={theme.colors.textMain}
              value={description}
              onChangeText={(text) => setDescription(text)}
              placeholder={t("Common.usefulDescription")}
            />
          </Box>

          <Box className={cn(s.checkboxesCont)}>
            <Box 
              className={cn(s.checkboxRow)} 
              title={`${t("Other.thisOptionAvailableIfSetUpProjectId")} - ${CONFIG.LINKS.SETUP_CODEBUD_PROJECT}`}
              onClick={() => !connectedClientProjectId && handleOpenLink(CONFIG.LINKS.SETUP_CODEBUD_PROJECT)}
            >
              <Checkbox 
                mark={addToCollection}
                onPress={() => setAddToCollection((v) => !v)}
                marginRight={theme.metrics.x2}
                disabled={!connectedClientProjectId}
              />
              <p className={cn(s.label)}>
                {t("Other.addToScenarioCollection")}
              </p>
            </Box>
            <Box className={cn(s.checkboxRow)} title={t("Other.downloadScenarioJSON")}>
              <Checkbox 
                mark={download}
                onPress={() => setDownload((v) => !v)}
                marginRight={theme.metrics.x2}
              />
              <p className={cn(s.label)}>
                {t("Common.downloadAsJSON")}
              </p>
            </Box>
          </Box>
        </Box>

        <Box className={cn(s.bottomButton)} title={t("Other.importScenario")}>
          <CustomButton 
            title={t("Common.ok")}
            bgColorActive={theme.colors.black}
            colorActive={theme.colors.white}
            onPress={handleExport}
          />
        </Box>
      </>
    </CustomModal>
  );
};

ExportScenarioModal.defaultProps={}

export { ExportScenarioModal };