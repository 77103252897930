import React, { memo, forwardRef } from 'react';
import { useStyles } from './CustomButtonStyles';
import { CircularProgress } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { theme } from '~/theme/default/theme';
import classNames from 'classnames';

export type CustomButtonProps = {
  ref?: any;
  height?: number;
  fontSize?: number;
  title?: string;
  disabled?: boolean;
  bgColorActive?: string;
  bgColorUnactive?: string;
  colorActive?: string;
  colorUnactive?: string;
  spinner?: boolean;
  alt?: string;
  icon?: React.ReactNode;
  borderRadius?: number;
  borderWidth?: number;
  borderColorActive?: string;
  borderColorUnactive?: string;
  onPress: () => void;
};

const CustomButton: React.FC<CustomButtonProps> = forwardRef(({
  height,
  fontSize,
  title,
  disabled,
  bgColorActive,
  bgColorUnactive,
  colorActive,
  colorUnactive,
  spinner,
  alt,
  icon,
  borderRadius,
  borderWidth,
  borderColorActive,
  borderColorUnactive,
  onPress
}, ref) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  
  return (
    <div
      ref={ref as any}
      tabIndex={0}
      className={classNames(s.container)}
      style={{
        backgroundColor: disabled? (bgColorUnactive ?? theme.colors.buttonBgUnactive): (bgColorActive ?? theme.colors.buttonBgActive), 
        fontSize, 
        height, 
        borderRadius,
        border: `${borderWidth}px solid ${disabled ? borderColorUnactive : borderColorActive}`,
        cursor: (disabled || spinner) ? undefined : "pointer"
      }}
      onClick={() => (!disabled && !spinner) && onPress()}
      title={alt}
    >
      {spinner?
        <CircularProgress size={20} />
      :
        icon ??
        <p 
          className={classNames(s.titleText)}
          style={{
            color: disabled? (colorUnactive ?? theme.colors.buttonTextUnactive): (colorActive ?? theme.colors.buttonTextActive),
            fontSize: (title ?? "").length > 30 ? 14 : undefined
          }}
        >
          {title}
        </p>
      }
    </div>
  );
});

CustomButton.defaultProps={
  title: '',
  height: 40,
  fontSize: 20,
  alt: undefined,
  spinner: false,
  disabled: false,
  borderRadius: theme.metrics.x2,
  borderWidth: 0
}

const MemorizedComponent = memo(CustomButton);
export { MemorizedComponent as CustomButton };