import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.bgForm,
    borderRadius: 12,
    overflow: 'scroll',
  },
  whiteText: {
    margin: 0,
    color: theme.colors.textMain,
  }
});