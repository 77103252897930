import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const CONSTRUCTOR_MENU_HEIGHT = 36;

export const useStyles = (theme: Theme) => makeStyles({
  leftSide: {
    display: 'flex',
    // flex: 3,
    width: '30%',
    padding: 12,
    flexDirection: 'column',
    // backgroundColor: 'blue',
    justifyContent: 'space-between'
  },
  rightSide: {
    display: 'flex',
    width: '70%',
    // flex: 7,
    flexDirection: 'column',
    padding: 12,
    // backgroundColor: 'blue'
  },
  kiosk: {
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    background: theme.colors.bgQuaternary,
    overflow: "scroll",
    padding: theme.metrics.x2,
    borderRadius: theme.metrics.x2,
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  dashedContainer: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: theme.metrics.x,
    width: '100%',
    height: '100%',
    minHeight: '100%',
    overflowX: "hidden",
    overflowY: "scroll",
    transitionDuration: "0.4s"
  },
  cloneContainer: {
    "& ~div": {
      transform: "none !important"
    },
    transform: "none !important"
  },
  part: {
    display: 'flex',
    flexDirection: 'column'
  },
  availableInstructionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderRadius: theme.metrics.x2,
    backgroundColor: theme.colors.bgQuaternary
  },
  marginBottom: {
    marginBottom: 8
  },
  settingsBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '10%',
    // backgroundColor: 'red'
  },
  rowEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  settingsPanel: {
    display: 'flex',
    // flex: 2,
    height: '10%',
    width: '100%',
    justifyContent: 'flex-end',
    // backgroundColor: 'red'
  },
  constructorContainer: {
    display: 'flex',
    position: 'relative',
    // flex: 13,
    height: '60%',
    width: '100%',
    paddingTop: theme.metrics.x3,
    paddingBottom: theme.metrics.x3,
    // backgroundColor: 'blue'
  },
  constructorMenu: {
    display: 'flex',
    height: CONSTRUCTOR_MENU_HEIGHT,
    flexDirection: 'row',
    borderRadius: theme.metrics.x2,
    // borderTopLeftRadius: theme.metrics.x2,
    // borderTopRightRadius: theme.metrics.x2,
    paddingLeft: theme.metrics.x05,
    paddingRight: theme.metrics.x05,
    backgroundColor: theme.colors.bgQuaternary,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  constructorMenuButton: {
    transform: "scale(0.8)"
  },
  clearConstructorIcon: {
    height: 28,
    width: 28
  },
  constructorModeCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30
  },
  whiteText: {
    color: theme.colors.textMain,
    margin: 0,
    fontFamily: theme.fonts.f400.fontFamily
  },
  whiteTextBolder: {
    color: theme.colors.textMain,
    margin: 0,
    fontFamily: theme.fonts.f700.fontFamily
  },
  grayText: {
    color: theme.colors.textTetriary,
    margin: 0,
    fontFamily: theme.fonts.f400.fontFamily
  },
  block: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  searchInputContainer: {
    width: '100%',
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    marginBottom: theme.metrics.x2,
  },
  sortButton: {
    
  },
  droppableConstructorPlaceholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8
  },
  sortNetworkLogsButton: {
    width: 18,
    height: 18,
    transform: "scale(1.8)",
    marginLeft: theme.metrics.x1_5,
    marginRight: theme.metrics.x1_5,
  },
  toolsPanelCont: {
    display: 'flex',
    height: '30%',
    width: '100%'
  },
});