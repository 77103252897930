import React, { createContext, useRef, useState } from 'react';
import { ReactElement } from 'react';
import { Rnd as ResizeableView } from "react-rnd";
import { FLOATING_VIEW_SIZINGS, FLOATING_VIEW_ANIMATIONS_DURATIONS } from './constants';
import { FloatingViewContent } from './types';
import { useDebounce } from '~/hooks';

export type FloatingViewContextT = {
  resizeableViewRef: React.MutableRefObject<ResizeableView | null>;
  floatingViewVisible: boolean;
  floatingViewMinimized: boolean;
  floatingViewMinimizedWithWindowAnimationDelay: boolean;
  currentFloatingViewContent: FloatingViewContent | undefined;
  closeFloatingWindow: () => void;
  openFloatingViewFor: (content: FloatingViewContent) => void;
  minimizeFloatingWindow: () => void;
  maximizeFloatingWindow: () => void;
  defaultWidth: number;
  defaultHeight: number;
};

export const FloatingViewContext = createContext<FloatingViewContextT>(null as any);

export type FloatingViewContextProviderProps = {
  children: ReactElement;
};

export const FloatingViewProvider: React.FC<FloatingViewContextProviderProps> = ({children}) => {
  const resizeableViewRef = useRef<ResizeableView | null>(null);
  const [visible, setVisible] = useState(false);
  const [minimized, setMinimized] = useState(false);
  const [currentContent, setCurrentContent] = useState<FloatingViewContent | undefined>();
  const [defaultWidth, setDefaultWidth] = useState(FLOATING_VIEW_SIZINGS.WIDTH.NetworkTimeline);
  const [defaultHeight, setDefaultHeight] = useState(FLOATING_VIEW_SIZINGS.HEIGHT.NetworkTimeline);

  const minimizedWithWindowAnimationDelay = useDebounce(
    minimized,
    (m) => m ? FLOATING_VIEW_ANIMATIONS_DURATIONS.VIEW_MINIMIZATION.JS : 0
  );

  const closeFloatingWindow = () => {
    setCurrentContent(undefined);
    setVisible(false);
    setMinimized(false);
  };

  const openFor = (content: FloatingViewContent) => {
    setCurrentContent(content);
    setDefaultWidth(FLOATING_VIEW_SIZINGS.WIDTH[content]);
    setDefaultHeight(FLOATING_VIEW_SIZINGS.HEIGHT[content]);
    setVisible(true);
    setMinimized(false);
  }

  const minimizeFloatingWindow = () => {
    setMinimized(true);
  }

  const maximizeFloatingWindow = () => {
    setMinimized(false);
  }

  return (
    <FloatingViewContext.Provider 
      value={{
        resizeableViewRef,
        floatingViewVisible: visible,
        floatingViewMinimized: minimized,
        floatingViewMinimizedWithWindowAnimationDelay: minimizedWithWindowAnimationDelay,
        currentFloatingViewContent: currentContent,
        closeFloatingWindow,
        openFloatingViewFor: openFor,
        minimizeFloatingWindow,
        maximizeFloatingWindow,
        defaultWidth,
        defaultHeight
      }}
    >
      {children}
    </FloatingViewContext.Provider>
  );
};
