import React, { memo } from 'react';
import { useStyles } from './ViewEmailStyles';
import { Box, Typography } from '@material-ui/core';
import { Mail } from '~/helpers/draggableHelpers';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
// import { prepareHtml } from '~/helpers/strings';
import parse from "html-react-parser";
import classNames from 'classnames';

export type ViewEmailProps = {
  mail: Mail | null;
};

const ViewEmail: React.FC<ViewEmailProps> = ({
  mail
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const html = mail?.html;

  return (
    <Box className={classNames(s.container)}>
      {html?
        parse(html)
      :
        <Typography variant="h5" paragraph className={classNames(s.whiteText)}>
          {t("Other.htmlParseError")}
        </Typography>
      }
    </Box>
  );
};

ViewEmail.defaultProps={}

const MemorizedComponent = memo(ViewEmail);
export { MemorizedComponent as ViewEmail };