import { balticSeaPaletteColors, gradients } from './colors';
import { emoji } from './emoji';
import { fonts } from './fonts';
import { iconsDarkTheme } from './icons';
import { metrics } from './metrics';
import { metaData } from './metaData';

const hitSlop = { top: 10, left: 10, bottom: 10, right: 10 } as const;

const balticSeaTheme = {
  fonts,
  colors: balticSeaPaletteColors,
  gradients,
  metrics,
  icons: iconsDarkTheme,
  hitSlop,
  emoji,
  isDark: true,
  metaData
} as const;

export { balticSeaTheme };