import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="_x32_"
    width="32"
    height="32"
    viewBox="0 0 512 512"
    fill={props.color}
  >
    <path
      d="M464 0H48C21.492 0 0 21.492 0 48v416c0 26.508 21.492 48 48 48h416c26.507 0 48-21.492 48-48V48c0-26.508-21.493-48-48-48zm-19.336 35c10.492 0 19 8.508 19 19s-8.508 19-19 19c-10.493 0-19-8.508-19-19s8.507-19 19-19zm-70.5 0c10.492 0 19 8.508 19 19s-8.508 19-19 19c-10.493 0-19-8.508-19-19s8.507-19 19-19zm-70.5 0c10.492 0 19 8.508 19 19s-8.508 19-19 19c-10.493 0-19-8.508-19-19s8.507-19 19-19zM472 464c0 4.406-3.586 8-8 8H48c-4.414 0-8-3.594-8-8V104h432v360z"
      className="st0"
    />
    <path
      d="M97.484 328.656h16.031c.977 0 1.625-.633 1.93-1.562l13.774-44h.336l13.43 44c.328.93.985 1.562 1.946 1.562h16.187c.985 0 1.633-.633 1.938-1.562l25.117-72.656c.313-.93-.187-1.562-1.304-1.562h-19.914c-1.149 0-1.789.484-2.102 1.562l-12.313 45.094h-.312l-13.766-45.094c-.336-1.078-.977-1.562-2.11-1.562H122.75c-1.141 0-1.953.484-2.258 1.562l-13.454 45.094h-.328l-12.945-45.094c-.329-1.078-.954-1.562-2.266-1.562H71.594c-.992 0-1.454.633-1.157 1.562l25.118 72.656c.319.929.968 1.562 1.929 1.562zM224.086 328.656h16.054c.962 0 1.618-.633 1.93-1.562l13.766-44h.328l13.43 44c.336.93.984 1.562 1.953 1.562h16.18c.984 0 1.625-.633 1.953-1.562l25.102-72.656c.313-.93-.172-1.562-1.297-1.562h-19.906c-1.156 0-1.805.484-2.117 1.562l-12.313 45.094h-.32l-13.758-45.094c-.336-1.078-.977-1.562-2.102-1.562h-13.61c-1.133 0-1.946.484-2.258 1.562l-13.446 45.094h-.32l-12.962-45.094c-.312-1.078-.969-1.562-2.258-1.562h-19.914c-.993 0-1.469.633-1.133 1.562l25.094 72.656c.322.929.971 1.562 1.924 1.562zM350.882 328.656h16.047c.953 0 1.602-.633 1.922-1.562l13.766-44h.336l13.43 44c.328.93.969 1.562 1.938 1.562h16.195c.977 0 1.625-.633 1.946-1.562l25.117-72.656c.297-.93-.187-1.562-1.297-1.562h-19.938c-1.133 0-1.781.484-2.094 1.562l-12.305 45.094h-.32l-13.766-45.094c-.336-1.078-.977-1.562-2.102-1.562H376.14c-1.133 0-1.937.484-2.25 1.562l-13.461 45.094h-.305l-12.954-45.094c-.335-1.078-.961-1.562-2.266-1.562h-19.922c-.969 0-1.453.633-1.141 1.562l25.118 72.656c.307.929.955 1.562 1.923 1.562z"
      className="st0"
    />
  </svg>
)

export { SvgComponent as BrowserIcon };