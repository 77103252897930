import { RemoteEvent } from "~/types/types";

export class Scenario {
  private static _currentId = 0;
  public id: number;
  public events: RemoteEvent[];

  constructor(events: RemoteEvent[]) {
    this.id = Scenario._currentId++;
    this.events = events;
  }
}