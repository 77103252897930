import React, { useState } from 'react';
import { useStyles } from './TanStackQueryTabStyles';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { useTanStackQueryTabAnalytics } from './useTanStackQueryTabAnalytics';
import { Box, Typography } from '@material-ui/core';
import { StateInspectorHeader } from '~/components/StateInspectorHeader';
import { PinnedPathsContainer } from '~/components/PinnedPathsContainer';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourTanStackQueryInspectorSelector } from '~/logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { TanStackIcon } from '~/assets/icons';
import { OurTanStackQueryInspectorActions } from '~/logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSlice';
import cn from 'classnames';

export type TanStackQueryTabProps = {};

const TanStackQueryTab: React.FC<TanStackQueryTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey
  } = useSelector(userSelector);

  const {
    tanStackQueriesDataCopy,
    linesWithMarkup,
    tanStackQueriesDataCopyNumOfLines,
    tanStackQueriesDataCopyTimestamp,
    pinnedPaths
  } = useSelector(ourTanStackQueryInspectorSelector);

  const {
    trackUserChangedViewMode
  } = useTanStackQueryTabAnalytics();

  const [pinnedMode, setPinnedMode] = useState(false);

  const onPressUnpin = (path: string) => {
    dispatch(OurTanStackQueryInspectorActions.unpinPath({path, apiKey}));
  }

  return (
    <Box className={cn(s.container)}>
      {tanStackQueriesDataCopy ?
        <Box className={s?.syntaxContainer}>
          <StateInspectorHeader 
            pinnedMode={pinnedMode}
            stateCopyNumOfLines={tanStackQueriesDataCopyNumOfLines}
            stateCopyTimestamp={tanStackQueriesDataCopyTimestamp}
            stateManagerName={"tanstack"}
            dataName={"queries data"}
            onPress={() => {
              trackUserChangedViewMode(pinnedMode ? "default" : "pinned");
              setPinnedMode((v) => !v);
            }}
            onPressClear={() => dispatch(OurTanStackQueryInspectorActions.clearTanStackQueryInspector())}
          />
          <Box className={cn(s.dataContainer)}>
            {pinnedMode ?
              <PinnedPathsContainer 
                stateCopy={tanStackQueriesDataCopy}
                pinnedPaths={pinnedPaths}
                onPressUnpin={onPressUnpin}
                icon={<TanStackIcon fill={theme.colors.textBright} />}
              />
            :
              <SyntaxHighlighter 
                code={JSON.stringify(tanStackQueriesDataCopy, null, 2)}
                linesWithMarkup={linesWithMarkup}
                customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
                enableFolding={true}
                virtualize={true}
                withPins={true}
                pinnedPaths={pinnedPaths}
                borderRadius={0}
                onInspectorFoldChange={(lineIndex, newOpened) => dispatch(OurTanStackQueryInspectorActions.handleInspectorFoldChange({lineIndex, newOpened}))}
                onPinPath={(path) => dispatch(OurTanStackQueryInspectorActions.pinPath({path, apiKey}))}
                onUnpinPath={(path) => dispatch(OurTanStackQueryInspectorActions.unpinPath({path, apiKey}))}
              />
            }
          </Box>
        </Box>
      :
        <Box className={s?.syntaxContainer}>
          <Box className={ds.fullSpaceCenteredCol}>
            <Box className={cn(s.tanStackQueryIcon)}>
              <TanStackIcon fill={theme.colors.textBright}/>
            </Box>
            <Typography variant="h5" className={cn(s.whiteText)}>
              {`TanStack queries data ${t("MainPage.StateManagerTab.willBeDisplayedHere")}`}
            </Typography>
          </Box>
        </Box>
      }
    </Box>
  );
};

TanStackQueryTab.defaultProps={}

export { TanStackQueryTab };