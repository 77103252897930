import React, { useContext } from 'react';
import { useStyles } from './AvailableInstructionsStyles';
import { Box } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { OpenInFloatingViewButton } from '~/components/FloatingView';
import { DroppableInstructions } from '~/Pages/Main/Tabs/MainTab/DroppableInstructions';
import { useThemeContext } from '~/theme';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { useSendEvent } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { foundСoincidences } from '~/helpers/strings';
import { AvailableInstructionsContext } from '~/providers';
import cn from 'classnames';

export type AvailableInstructionsProps = {};

const AvailableInstructions: React.FC<AvailableInstructionsProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    navigationTab,
    appSettings,
    allInstructions,
    bothSidesConnected
  } = useSelector(userSelector);

  const {
    searchInstructionsValue,
    searchInstructionsInnerValue,
    setSearchInstructionsInnerValue
  } = useContext(AvailableInstructionsContext);

  const { onPressSendEvent } = useSendEvent("NETWORK_TAB");

  return (
    <Box className={cn(ds.fullSpaceCol, s.availableInstructionsContainer)}>
      {appSettings.showInstructionsSearch.value &&
        <Box className={cn(s.searchInputContainer)}>
          <TextInput 
            value={searchInstructionsInnerValue}
            onChangeText={(text) => setSearchInstructionsInnerValue(text)}
            onClear={() => setSearchInstructionsInnerValue("")}
            labelColor={theme.colors.black}
            color={theme.colors.inputTextSecondary}
            placeholder={t("Common.searchForInstructions")}
            validationOk={true}
            height={32}
            fontSize={16}
            paddingLeft={8}
            paddingRight={28}
            borderRadius={theme.metrics.x2}
            borderUnfocused={`solid 1px ${theme.colors.inputBorderColorUnactiveSecondary}`}
            bgColor={theme.colors.inputBgSecondary}
          />

          <OpenInFloatingViewButton content={"NetworkAvailableInstructions"} />
        </Box>
      }

      <DroppableInstructions 
        bothSidesConnected={bothSidesConnected}
        instructions={allInstructions.filter((instruction) => foundСoincidences(instruction.sQuery, searchInstructionsValue))}
        showSpecialInstructions={false}
        draggingDisabled={true}
        onPressSendEvent={onPressSendEvent}
        withEventExecutionProgressBar={navigationTab !== "MAIN_TAB"}
      />
    </Box>
  );
};

AvailableInstructions.defaultProps={}

export { AvailableInstructions };