import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useOtpPageAnalytics = () => {
  const trackOtpResendTriggered = () => {
    segmentAnalytics.track("OTP Code resend triggered");
  };

  useEffect(() => {
    segmentAnalytics.page("OtpPage", "OtpPage");
  }, []);

  return {
    trackOtpResendTriggered
  };
};