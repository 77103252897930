import { useSelector } from "react-redux";
import { useLayoutDependentState } from "~/hooks";
import { userSelector } from "../logic/user/UserSelectors";


export const useRndSizeState = (rndId: string, containerAxisSize: number, defaultFactor: number) => {
  const {
    rndDefaultSizeSettings
  } = useSelector(userSelector);

  return useLayoutDependentState<number>(() => {
    if (rndDefaultSizeSettings[rndId] && rndDefaultSizeSettings[rndId] <= 1)
      return containerAxisSize * rndDefaultSizeSettings[rndId];

    return containerAxisSize * defaultFactor;
  }, [containerAxisSize, rndDefaultSizeSettings, rndId]);
}