import React, { memo } from 'react';
import { useStyles } from './NavigationBarStyles';
import { Box, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourReduxInspectorSelector } from '~/logic/ourReduxInspector/OurReduxInspectorSelectors';
import { ourZustandInspectorSelector } from '~/logic/ourZustandInspector/OurZustandInspectorSelectors';
import { ourTanStackQueryInspectorSelector } from '~/logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSelectors';
import { ourContextsInspectorSelector } from '~/logic/ourContextsInspector/OurContextsInspectorSelectors';
import { ourMobxInspectorSelector } from '~/logic/ourMobxInspector/OurMobxInspectorSelectors';
import { networkSelector } from '~/logic/network/NetworkSelectors';
import { UserActions } from '~/logic/user/UserSlice';
import { DraggableNavigationBarTabs } from '~/components/DraggableNavigationBarTabs';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from "~/assets/icons";
import { NavigationTab } from '~/types/types';
import { REQUIRED_NAVIGATION_BAR_TABS } from './constants';
import cn from 'classnames';
import 'animate.css';

export type NavigationBarProps = {};

const NavigationBar: React.FC<NavigationBarProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    navigationTab,
    bothSidesConnected,
    editingNavBarTabs,
    chosenNavBarTabs,
    gotSomethingNewIndicator: mainIndicator,
    emailTabGotSomethingNewIndicator: mailIndicator
  } = useSelector(userSelector);

  const {
    gotSomethingNewIndicator: reduxIndicator
  } = useSelector(ourReduxInspectorSelector);

  const {
    gotSomethingNewIndicator: zustandIndicator
  } = useSelector(ourZustandInspectorSelector);

  const {
    gotSomethingNewIndicator: tanStackQueryIndicator
  } = useSelector(ourTanStackQueryInspectorSelector);

  const {
    gotSomethingNewIndicator: contextsIndicator
  } = useSelector(ourContextsInspectorSelector);

  const {
    gotSomethingNewIndicator: mobxIndicator
  } = useSelector(ourMobxInspectorSelector);

  const {
    gotSomethingNewIndicator: networkIndicator
  } = useSelector(networkSelector);

  const shouldDisplayNewIndicator = (tabId: NavigationTab) => {
    if (navigationTab === tabId)
      return false;

    switch (tabId) {
      case "MAIN_TAB":
        return mainIndicator;
      case "MAIL_TAB":
        return mailIndicator;
      case "REDUX_INSPECTOR_TAB":
        return reduxIndicator;
      case "ZUSTAND_INSPECTOR_TAB":
        return zustandIndicator;
      case "TANSTACK_QUERY_INSPECTOR_TAB":
        return tanStackQueryIndicator;
      case "CONTEXTS_INSPECTOR_TAB":
        return contextsIndicator;
      case "MOBX_INSPECTOR_TAB":
        return mobxIndicator;
      case "NETWORK_TAB":
        return networkIndicator;
      default:
        return false;
    }
  }

  const onPressTab = (tabId: NavigationTab) => {
    if (tabId !== navigationTab)
      dispatch(UserActions.setNavigationTabWithProcessing(tabId));
  }

  return (
    <Box className={cn(s.container, "animate__animated animate__fadeInLeft")}>
      <Box className={cn(s.logoCont)}>
        <img src={theme.icons.CODEBUD_LOGO} className={ds.fullSpaceImg}/>
      </Box>

      <Box className={cn(s.innerContainer)}>
        <DraggableNavigationBarTabs 
          droppableId={"dnd_navbar_tabs_bar_itself"}
          tabs={chosenNavBarTabs}
          flexDirection={"column"}
          isDragDisabled={!editingNavBarTabs}
          isDropDisabled={!editingNavBarTabs}
          isDragDisabledForTab={(tabId) => REQUIRED_NAVIGATION_BAR_TABS.has(tabId)}
          onPressTab={(tabId) => onPressTab(tabId)}
          shouldDisplayNewIndicator={(tabId) => shouldDisplayNewIndicator(tabId)}
        />
      </Box>

      <Box className={cn(s.bottomCont)}>
        {bothSidesConnected &&
          <IconButton 
            onClick={() => dispatch(UserActions.setConnectedClientInfoModalVisible(true))}
            size='small' 
            title={t("Other.viewConnectionDetails")}
            style={{marginBottom: theme.metrics.x3}}
          >
            <InfoIcon color={theme.colors.navBarIconUnactive}/>
          </IconButton>
        }
      </Box>
    </Box>
  );
};

NavigationBar.defaultProps={}

const MemorizedComponent = memo(NavigationBar);
export { MemorizedComponent as NavigationBar };