import React, { useState, useEffect, forwardRef } from 'react';
import { useStyles } from './TextInputStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { CloseIcon, ShowIcon, HideIcon, CopyIcon } from '~/assets/icons';
import { copyToClipboard } from '~/helpers/windowHelpers';
import cn from 'classnames';
import 'animate.css';

export type TextInputProps = {
  ref?: any;
  value: string;
  validationOk?: undefined | boolean;
  onErrorAnimationEnd?: () => void;
  placeholder?: string;
  onChangeText: (t: string) => void;
  bgColor?: string;
  color?: string;
  paddingLeft?: number;
  paddingRight?: number;
  borderFocused?: string;
  borderUnfocused?: string;
  borderError?: string;
  textAlign?: string;
  fontSize?: number;
  height?: number;
  maxLength?: number;
  editable?: boolean;
  label?: string;
  labelColor?: string;
  secure?: boolean;
  inputType?: string;
  borderRadius?: number;
  autoFocus?: boolean;
  copyBtn?: boolean;
  onClear?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const TextInput: React.FC<TextInputProps> = forwardRef(({
  value,
  validationOk,
  onErrorAnimationEnd,
  placeholder,
  onChangeText,
  bgColor,
  color,
  paddingLeft,
  paddingRight,
  borderFocused,
  borderError,
  borderUnfocused,
  textAlign,
  fontSize,
  height,
  maxLength,
  editable,
  label,
  labelColor,
  secure,
  inputType,
  borderRadius,
  autoFocus,
  copyBtn,
  onClear,
  onBlur,
  onFocus
}, ref) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const [secureEntry, setSecureEntry] = useState(true);

  useEffect(() => {
    if (validationOk === false && onErrorAnimationEnd)
      setTimeout(() => {
        onErrorAnimationEnd();
      }, 1000);
  }, [validationOk]);
  
  return (
    <Box className={cn(s.globalContainer)}>
      {label &&
        <p style={{margin: 0, color: labelColor ?? theme.colors.inputLabelColor, fontFamily: theme.fonts.f600.fontFamily, marginBottom: 4}}>
          {label}
        </p>
      }
      <Box 
        className={cn(s.container, "animate__animated", {"animate__pulse": validationOk === false})} 
        style={{
          height,
          backgroundColor: bgColor ?? theme.colors.inputBg, 
          paddingLeft, 
          paddingRight: (!secure && !copyBtn) ? paddingRight: theme.metrics.x7 * 2,
          border: validationOk === false ? (borderError ?? `solid 1px ${theme.colors.red}`) : focused? (borderFocused ?? `solid 1px ${theme.colors.inputBorderColorActive}`): (borderUnfocused ?? `solid 1px ${theme.colors.inputBorderColorUnactive}`),
          borderRadius
        }}
      >
        {secure &&
          <IconButton 
            onClick={() => setSecureEntry((v) => !v)} 
            size='small'
            className={cn(s.rightIconContainer)}
            // style={{height}}
            title={secureEntry ? t("Common.show") : t("Common.hide")}
          >
            {secureEntry ?
              <ShowIcon color={theme.colors.white}/>
            :
              <HideIcon color={theme.colors.white}/>
            }
          </IconButton>
        }
        {copyBtn &&
          <IconButton 
            onClick={() => copyToClipboard(value)} 
            size='small'
            className={cn(s.rightIconContainer)}
            // style={{height}}
            title={t("Common.copyToClipboard")}
          >
            <CopyIcon color={theme.colors.white} />
          </IconButton>
        }
        <input 
          ref={ref as any}
          autoFocus={autoFocus}
          onClick={(e) => e.stopPropagation()}
          spellCheck={false}
          // @ts-ignore
          style={{ color: color ?? theme.colors.inputText, textAlign, fontSize }}
          className={cn(s.input)}
          type={inputType ?? (secure && secureEntry ? 'password' : 'text')}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChangeText(event.target.value)}
          onFocus={() => {
            setFocused(true);
            onFocus && onFocus();
          }}
          onBlur={() => {
            setFocused(false);
            onBlur && onBlur();
          }}
          maxLength={maxLength}
          disabled={!editable}
        />
        {onClear &&
          <Box className={cn(s.clearBtn)} onClick={onClear} title={t("Common.clear")}>
            <CloseIcon color={value.length ? (color ?? theme.colors.white) : theme.colors.inputPlaceholder}/>
          </Box>
        }
      </Box>
    </Box>
  );
});

TextInput.defaultProps={
  validationOk: undefined,
  paddingLeft: 12,
  paddingRight: 12,
  placeholder: '',
  textAlign: 'left',
  fontSize: 18,
  height: 44,
  maxLength: undefined,
  editable: true,
  secure: false,
  borderRadius: 8
}

export { TextInput };