import React from 'react';
import { Box } from '@material-ui/core';
import { SyntaxHighlighter } from './SyntaxHighlighter';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { CONFIG } from '~/config';
import { TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '../TimelineItem';

const MOCK_DATA_TO_SHOW = jsonStringifyObjectFormatted({
  tool: CONFIG.PRODUCT_NAME,
  version: CONFIG.APP_INFO.DISPLAY_VERSION,
  build: Number(CONFIG.APP_INFO.DISPLAY_BUILD_NUMBER),
  official: true,
  details: {
    description: `${CONFIG.PRODUCT_NAME} is a tool for remote app testing, inspecting & debugging. `,
    mainComponents: [
      "JavaScript package that should be installed in your app",
      "GUI that allows you to send events to your app, inspect & debug it"
    ],
    supportedPlatforms: [
      "NodeJS",
      "ts-node",
      "React",
      "React Native"
    ]
  },
  links: [CONFIG.LINKS.DOCUMENTATION, CONFIG.LINKS.GITHUB],
  metaData: null
});

export type SyntaxHighlighterPreviewProps = {};

const SyntaxHighlighterPreview: React.FC<SyntaxHighlighterPreviewProps> = ({}) => {

  return (
    <Box style={{
      display: 'flex',
      width: "100%",
    }}>
      <SyntaxHighlighter 
        code={MOCK_DATA_TO_SHOW}
        customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
        enableFolding={true}
        foldingController={"inner"}
      />
    </Box>
  );
};

SyntaxHighlighterPreview.defaultProps={}

export { SyntaxHighlighterPreview };