import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  right: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: theme.metrics.x2,
    backgroundColor: theme.colors.bgQuaternary,
  },
  timeline: {
    position: 'relative',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 50,
  },
  timelineLeftSearchCont: {
    display: "flex",
    width: 200,
    flexDirection: "row",
    alignItems: "center",
    position: 'absolute',
    left: theme.metrics.x3
  },
  timelineRightButtonsCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: 'absolute',
    right: theme.metrics.x3
  },
  moreFeaturesButton: {
    display: "flex",
    width: 24, 
    height: 24,
    // backgroundColor: 'blue',
    // paddingBottom: theme.metrics.x,
    marginLeft: theme.metrics.x2_5
  },
  filterNetworkLogsButton: {
    display: "flex",
    width: 24, 
    height: 24,
    // backgroundColor: 'blue',
    paddingBottom: theme.metrics.x,
    marginLeft: theme.metrics.x2
  },
  sortNetworkLogsButton: {
    display: "flex",
    width: 24, 
    height: 24,
    marginLeft: theme.metrics.x2
  },
  clearNetworkLogsButton: {
    display: "flex",
    width: 22, 
    height: 22,
    marginLeft: theme.metrics.x2
  },
  networkEventsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.bgQuinary,
    borderRadius: 8,
    overflowY: "scroll",
    overflowX: 'hidden'
  },
  networkIcon: {
    display: "flex",
    transform: "scale(1.2)",
    marginBottom: theme.metrics.x3
  },
  whiteText: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily
  },
});