import React, { useCallback, useMemo, useRef } from 'react';
import { useStyles } from './NetworkTimelineStyles';
import { Box, Typography, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { TextInput } from '~/components/TextInput';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { /* SortIcon, */ FilterIcon, NetworkIcon, ListIcon } from '~/assets/icons';
import { TimelineListItem } from './TimelineListItem';
import { OptionsMenu, OptionsMenuMethods } from "~/components/OptionsMenu";
import { OpenInFloatingViewButton } from '~/components/FloatingView';
import { computeItemKey, getTimeSincePrevEvent } from "./virtuosoHelpers";
import { ScrollToButton } from "~/components/ScrollToButton";
import { useSearchValue, useStorageSnapshot } from '~/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { NetworkActions } from '~/logic/network/NetworkSlice';
import { userSelector } from '~/logic/user/UserSelectors';
import { networkSelector } from '~/logic/network/NetworkSelectors';
import { foundСoincidences } from '~/helpers/strings';
import DeleteIcon from './assets/delete';
import cn from 'classnames';

const MIN_AMOUNT_OF_ITEMS_TO_SHOW_SCROLL_TO_CONTROLS = 13;

export type NetworkTimelineProps = {};

const NetworkTimeline: React.FC<NetworkTimelineProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    appSettings,
    networkFiltersModalState,
  } = useSelector(userSelector);

  const {
    // timelineSorting,
    interceptedApiCalls,
    // timelineVirtuosoFirstItemIndex,
    // timelineVirtuosoTotalCount
  } = useSelector(networkSelector);

  const { onPressViewEntireStorage } = useStorageSnapshot();

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const moreFeaturesOptionsRef = useRef<OptionsMenuMethods>(null);

  const [searchTimelineValue, searchTimelineInnerValue, setSearchTimelineInnerValue] = useSearchValue("");

  // const onPressSortTimeline = useCallback(() => {
  //   const newSorting = timelineSorting === "newestOnTop" ? "oldestOnTop" : "newestOnTop";
  //   dispatch(NetworkActions.setTimelineSorting(newSorting));
  //   saveNetworkTimelineSortingToLocalStorage(newSorting);
  // }, [timelineSorting]);

  const onPressFilterTimeline = useCallback(() => {
    dispatch(UserActions.setNetworkFiltersModalVisible(true));
  }, []);

  const onPressClearAll = useCallback(() => {
    dispatch(NetworkActions.clearInterceptedApiCalls());
  }, []);

  const filteredInterceptedApiCalls = useMemo(() => {
    // We can skip filter if no filters applied
    if (searchTimelineValue.length === 0 && networkFiltersModalState.InterceptedReduxActionVisible && networkFiltersModalState.InterceptedApiCallVisible && networkFiltersModalState.InterceptedStorageActionVisible && networkFiltersModalState.CapturedEventVisible && networkFiltersModalState.InterceptedTanStackQueryEventVisible && networkFiltersModalState.InterceptedMobxEventVisible && networkFiltersModalState.CapturedCrashReportVisible)
      return interceptedApiCalls;

    return interceptedApiCalls.filter((timelineItem) => {
      if ("actionId" in timelineItem && !networkFiltersModalState.InterceptedReduxActionVisible)
        return false;
      if ("requestId" in timelineItem && !networkFiltersModalState.InterceptedApiCallVisible)
        return false;
      if ("storageActionId" in timelineItem && !networkFiltersModalState.InterceptedStorageActionVisible)
        return false;
      if ("capturedEventId" in timelineItem && !networkFiltersModalState.CapturedEventVisible)
        return false;
      if ("tanStackQueryEventId" in timelineItem && !networkFiltersModalState.InterceptedTanStackQueryEventVisible)
        return false;
      if ("mobxEventId" in timelineItem && !networkFiltersModalState.InterceptedMobxEventVisible)
        return false;
      if ("crashReportId" in timelineItem && !networkFiltersModalState.CapturedCrashReportVisible)
        return false;

      return foundСoincidences(timelineItem.sQuery ?? "", searchTimelineValue)
    });
  }, [interceptedApiCalls, searchTimelineValue, networkFiltersModalState]);

  return (
    <Box className={cn(s.right)}>
      <Box className={cn(s.timeline)}>
        <Box className={cn(s.timelineLeftSearchCont)}>
          <TextInput 
            value={searchTimelineInnerValue}
            onChangeText={(text) => setSearchTimelineInnerValue(text)}
            onClear={() => setSearchTimelineInnerValue("")}
            labelColor={theme.colors.black}
            color={theme.colors.inputTextSecondary}
            placeholder={t("MainPage.NetworkTab.timelineSearch")}
            validationOk={true}
            height={32}
            fontSize={16}
            paddingLeft={8}
            paddingRight={28}
            borderRadius={theme.metrics.x2}
            borderUnfocused={`solid 1px ${theme.colors.inputBorderColorUnactiveSecondary}`}
            bgColor={theme.colors.inputBgSecondary}
          />
        </Box>
        <Typography variant="h5" paragraph className={cn(s.whiteText)} style={{margin: 0}}>
          {t("Common.timeline")}
        </Typography>
        <Box className={cn(s.timelineRightButtonsCont)}>
          <OpenInFloatingViewButton content={"NetworkTimeline"} />
          
          <IconButton 
            onClick={() => moreFeaturesOptionsRef?.current?.open()} 
            size='small'
            className={cn(s.moreFeaturesButton)}
            title={t("Common.moreFeatures")}
          >
            <ListIcon color={theme.colors.iconsMain}/>
          </IconButton>
          <OptionsMenu 
            ref={moreFeaturesOptionsRef}
            id={"NT_MORE_FEATURES_OPTIONS"}
            optionItems={[
              {
                id: "o_view_entire_storage",
                label: t("Other.viewEntireStorage")
              }
            ]}
            optionHandlers={{"o_view_entire_storage": onPressViewEntireStorage}}
          />
          
          <IconButton 
            onClick={onPressFilterTimeline} 
            size='small'
            className={cn(s.filterNetworkLogsButton)}
            title={t("Common.filters")}
          >
            <FilterIcon color={theme.colors.iconsMain}/>
          </IconButton>
          {/* <IconButton 
            onClick={onPressSortTimeline} 
            size='small'
            className={cn(s.sortNetworkLogsButton)}
            title={"Reverse order"}
          >
            <SortIcon color={theme.colors.iconsMain}/>
          </IconButton> */}
          <IconButton 
            onClick={onPressClearAll} 
            size='small'
            className={cn(s.clearNetworkLogsButton)}
            title={t("Common.clearAll")}
          >
            <DeleteIcon color={theme.colors.iconsMain}/>
          </IconButton>
        </Box>
      </Box>

      <Box className={cn(s.networkEventsContainer)}>
        {filteredInterceptedApiCalls.length === 0 ?
          <Box className={cn(ds.fullSpaceCenteredCol)}>
            <Box className={cn(s.networkIcon)}>
              <NetworkIcon color={theme.colors.textBright}/>
            </Box>
            <Typography variant="h5" paragraph className={cn(s.whiteText)}>
              {interceptedApiCalls.length > 0 ? t("Common.nothingFound") : t("MainPage.NetworkTab.networkEventsWillAppearHere")}
            </Typography>
          </Box>
        :
          <Box className={cn(ds.fullSpaceCenteredColRelative)}>
            <Virtuoso
              ref={virtuosoRef}
              style={{ height: '100%', width: '100%' }}
              // totalCount={timelineVirtuosoTotalCount}
              // firstItemIndex={timelineVirtuosoFirstItemIndex}
              overscan={700}
              data={filteredInterceptedApiCalls}
              itemContent={(index, item) => (
                <TimelineListItem 
                  item={item}
                  timeSincePrevEvent={getTimeSincePrevEvent(item, filteredInterceptedApiCalls[index - 1])}
                />
              )}
              computeItemKey={computeItemKey}
            />

            {appSettings.showScrollToButtonsNetworkTimeline?.value && filteredInterceptedApiCalls.length > MIN_AMOUNT_OF_ITEMS_TO_SHOW_SCROLL_TO_CONTROLS &&
              <>
                <ScrollToButton 
                  type={"toTop"}
                  top={theme.metrics.x}
                  onPress={() => virtuosoRef?.current?.scrollToIndex({index: 0, behavior: "smooth"})}
                />
                <ScrollToButton 
                  type={"toBottom"}
                  bottom={theme.metrics.x}
                  onPress={() => virtuosoRef?.current?.scrollToIndex({index: "LAST", behavior: "smooth"})}
                />
              </>
            }
          </Box>
        }
      </Box>
    </Box>
  );
};

NetworkTimeline.defaultProps={}

export { NetworkTimeline };