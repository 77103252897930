export const eventStateDataSchema = {
  type: "object",
  properties: {
    id: {type: "string"},
    prototype: {
      type: "string",
      enum: ["login", "logout"]
    },
    instructionId: {type: "string"},
    description: {type: "string"},
    parametersDescription: {type: "object"},
    _groupId: {type: "string"},
    _groupDescription: {type: "string"},
    _groupColor: {type: "string"},
    instructionType: {
      type: "string",
      enum: ["default", "special"]
    },
    numOfArgs: {type: "integer"},
    params: {type: "object"},
    touched: {type: "boolean"},
    lastParamsSaved: {type: "object"},
    externalId: {type: "integer"},
  },
  required: ["id", "instructionId", "instructionType", "numOfArgs", "params"],
  additionalProperties: false,
};

export const exportableScenarioSchema = {
  type: "object",
  properties: {
    id: {type: "string"},
    scenario: {
      type: "array",
      items: eventStateDataSchema,
    },
    title: {type: "string"},
    description: {type: "string"}
  },
  required: ["id", "scenario", "title", "description"],
  additionalProperties: false,
};