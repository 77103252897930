import React, { ReactElement, useMemo, createContext } from 'react';
import { DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from "~/logic/user/UserSelectors";
import { UserActions } from "~/logic/user/UserSlice";
import { reorder, copy, move, prepareNewEventIfNeeded } from '~/helpers/draggableHelpers';
import { useSearchValue } from '~/hooks';
import { foundСoincidences } from '~/helpers/strings';
import { InstructionPublicFields } from '~/types/api';

export type DndMainTabContextT = {
  searchValue: string; 
  searchInnerValue: string;
  setSearchInnerValue: (s: string) => void;
  allInstructionsFiltered: InstructionPublicFields[];
  onDragEnd: (result: DropResult) => void;
};

export const DndMainTabContext = createContext<DndMainTabContextT>(null as any);

export type DndMainTabProviderProps = {
  children: ReactElement;
};

const DndMainTabProvider: React.FC<DndMainTabProviderProps> = ({children}) => {
  const dispatch = useDispatch();

  const {
    currentTestAccount,
    draggableInstuctionsState,
    allInstructions
  } = useSelector(userSelector);

  const [searchValue, searchInnerValue, setSearchInnerValue] = useSearchValue("");

  const allInstructionsFiltered = useMemo(() => {
    return allInstructions.filter((instruction) => foundСoincidences(instruction.sQuery, searchValue));
  }, [allInstructions, searchValue]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // console.log('==> result', result);

    // dropped outside the list
    if (!destination)
      return;

    // Still got no instructions
    if (allInstructionsFiltered?.length === 0)
      return;

    // if (constructorMode === "EVENT" && draggableInstuctionsState[destination.droppableId].length >= 1) {
    //   alert("Constructor mode is set to Event.\nIn order to send multiple instructions at once you can switch to Scenario mode");
    //   return;
    // }
    dispatch(UserActions.setToolsPanelMode("CONTROL"));

    switch (source.droppableId) {
      case destination.droppableId:
        dispatch(UserActions.updateDraggableInstuctionsState({
          key: destination.droppableId,
          newValue: reorder(
            draggableInstuctionsState[source.droppableId],
            source.index,
            destination.index
          )
        }));
        break;
      case 'ITEMS':
        const allInstructionsCopy = prepareNewEventIfNeeded(allInstructionsFiltered, source, currentTestAccount);
        dispatch(UserActions.updateDraggableInstuctionsState({
          key: destination.droppableId,
          newValue: copy(
            allInstructionsCopy,
            draggableInstuctionsState[destination.droppableId],
            source,
            destination
          )
        }));
        break;
      default:
        dispatch(UserActions.setDraggableInstuctionsState(
          move(
            draggableInstuctionsState[source.droppableId],
            draggableInstuctionsState[destination.droppableId],
            source,
            destination
          )
        ));
        break;
    }
  };

  return (
    <DndMainTabContext.Provider 
      value={{
        searchValue, 
        searchInnerValue, 
        setSearchInnerValue,
        allInstructionsFiltered,
        onDragEnd
      }}
    >
      {children}
    </DndMainTabContext.Provider>
  );
};

DndMainTabProvider.defaultProps={}

export { DndMainTabProvider };