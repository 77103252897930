import { Action } from '@reduxjs/toolkit';
import { put, delay, call } from 'redux-saga/effects';
import { takeLeading } from '~/store/sagaHelper';
import { initI18n } from '~/i18n';
import { AppSettingsActions, ColorTheme } from './AppSettingsSlice';
import { DEFAULT_NETWORK_FILTERS_MODAL_STATE, UserActions } from '../user/UserSlice';
import { getSyntaxHighlightingVariantFromLocalStorage, getColorThemeFromLocalStorage, getLanguageFromLocalStorage, getNetworkFiltersModalStateFromLocalStorage, getUserTokenFromLocalStorage } from '~/helpers/localStorage';
import { updateAuthorizationHeaderWithToken } from '~/api';
import { CurrentLangT } from '~/types/types';
import { SyntaxHighlightingVariant } from '~/constants/syntaxHighlightingTable';

function* getAppSettingsFromLocalStorage(action: Action) {
  if (AppSettingsActions.getAppSettingsFromLocalStorage.match(action)) {
    try {
      // SyntaxHighlightingVariant
      const syntaxHighlightingVariant: string | null = yield call(getSyntaxHighlightingVariantFromLocalStorage);
      if (syntaxHighlightingVariant) {
        yield put(AppSettingsActions.setSyntaxHighlightingVariant(syntaxHighlightingVariant as SyntaxHighlightingVariant));
      }

      // Theme
      const userTheme: string | null = yield call(getColorThemeFromLocalStorage);
      if (userTheme) {
        yield put(AppSettingsActions.setColorTheme(userTheme as ColorTheme));
      };
      //

      // Language
      let userLanguage: CurrentLangT | null | undefined = yield call(getLanguageFromLocalStorage);
      if (!userLanguage) {
        const defaultLanguage = 'en';
        userLanguage = defaultLanguage as CurrentLangT;
      }

      yield put(AppSettingsActions.setCurrentLang(userLanguage));
      initI18n(userLanguage);
      //

      // User token
      const userToken: string | null = yield call(getUserTokenFromLocalStorage);

      if (userToken) {
        yield put(UserActions.setUserToken(userToken));
        yield call(updateAuthorizationHeaderWithToken, userToken);
      }
      //

      // Main tab logs timeline sorting
      // const logsTimelineSorting: string | null = yield call(getLogsTimelineSortingFromLocalStorage);
      // if (logsTimelineSorting) {
      //   yield put(UserActions.setLogsTimelineSorting(logsTimelineSorting as TimelineSorting));
      // }

      // Network tab timeline sorting
      // const timelineSorting: string | null = yield call(getNetworkTimelineSortingFromLocalStorage);
      // if (timelineSorting) {
      //   yield put(NetworkActions.setTimelineSorting(timelineSorting as TimelineSorting));
      // }

      // Network tab filters
      const networkFiltersModalState: string | null = yield call(getNetworkFiltersModalStateFromLocalStorage);
      if (networkFiltersModalState) {
        const parsedNetworkFiltersModalState = JSON.parse(networkFiltersModalState);

        if (JSON.stringify(Object.keys(parsedNetworkFiltersModalState)) === JSON.stringify(Object.keys(DEFAULT_NETWORK_FILTERS_MODAL_STATE))) {
          yield put(UserActions.setNetworkFiltersModalState(parsedNetworkFiltersModalState));
        }
      }
      //

      yield delay(50);

      yield put(AppSettingsActions.appStartSettingsSetupDone(true));
    } catch (error) {
      console.log("getAppSettingsFromLocalStorage saga error: ", error);
      yield put(AppSettingsActions.appStartSettingsSetupDone(true));
    }
  }
}

export function* AppSettingsSaga() {
  yield* [
    takeLeading(AppSettingsActions.getAppSettingsFromLocalStorage.type, getAppSettingsFromLocalStorage),
  ];
}