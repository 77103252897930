import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { NewWindowIcon } from '~/assets/icons';
import { FloatingViewContext } from '~/providers';
import { FloatingViewContent } from '~/providers/FloatingViewContextProvider/types';
import cn from 'classnames';

export type OpenInFloatingViewButtonProps = {
  content: FloatingViewContent;
  iconColor?: string;
  marginLeft?: number;
};

const OpenInFloatingViewButton: React.FC<OpenInFloatingViewButtonProps> = ({
  content,
  iconColor,
  marginLeft = 8
}) => {
  const { theme, ds } = useThemeContext();
  const { t } = useTranslation();

  const {
    currentFloatingViewContent,
    openFloatingViewFor
  } = useContext(FloatingViewContext);

  if (currentFloatingViewContent === content)
    return null;

  return (
    <IconButton 
      onClick={() => openFloatingViewFor(content)} 
      size='small'
      className={cn(ds.openInFloatingWindowButton)}
      style={{marginLeft}}
      title={t("Common.openInFloatingWindow")}
    >
      <NewWindowIcon color={iconColor ?? theme.colors.iconsMain}/>
    </IconButton>
  );
};

OpenInFloatingViewButton.defaultProps={}

export { OpenInFloatingViewButton };