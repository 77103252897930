import React, { useState, useMemo } from 'react';
import { useStyles } from './ContextsTabStyles';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { useContextsTabAnalytics } from './useContextsTabAnalytics';
import { Box, Typography } from '@material-ui/core';
import { StateInspectorHeader } from '~/components/StateInspectorHeader';
import { PinnedPathsContainer } from '~/components/PinnedPathsContainer';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourContextsInspectorSelector } from '~/logic/ourContextsInspector/OurContextsInspectorSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ReactIcon } from '~/assets/icons';
import { OurContextsInspectorActions } from '~/logic/ourContextsInspector/OurContextsInspectorSlice';
import { SelectItem } from '~/types/types';
import cn from 'classnames';

export type ContextsTabProps = {};

const ContextsTab: React.FC<ContextsTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey
  } = useSelector(userSelector);

  const {
    viewingContextId,
    contextValueByContextIdTable,
    linesWithMarkupByContextIdTable,
    contextValueCopyNumOfLinesByContextIdTable,
    contextValueCopyTimestampByContextIdTable,
    pinnedPathsByContextIdTable
  } = useSelector(ourContextsInspectorSelector);

  const {
    trackUserChangedViewMode
  } = useContextsTabAnalytics();

  const [pinnedMode, setPinnedMode] = useState(false);

  const onPressUnpin = (path: string) => {
    if (viewingContextId)
      dispatch(OurContextsInspectorActions.unpinPath({contextId: viewingContextId, path, apiKey}));
  }

  const contextsSelectItems = useMemo<SelectItem[]>(
    () => Object.keys(contextValueByContextIdTable).map((key) => ({id: key, label: key})), 
    [contextValueByContextIdTable]
  );

  return (
    <Box className={cn(s.container)}>
      {(viewingContextId && contextValueByContextIdTable[viewingContextId]) ?
        <Box className={s?.syntaxContainer}>
          <StateInspectorHeader 
            pinnedMode={pinnedMode}
            stateCopyNumOfLines={contextValueCopyNumOfLinesByContextIdTable[viewingContextId] ?? 0}
            stateCopyTimestamp={contextValueCopyTimestampByContextIdTable[viewingContextId]}
            stateManagerName={"context"}
            dataName={"value"}
            selectValue={viewingContextId}
            selectLabel={"context"}
            selectItems={contextsSelectItems}
            onSelectChange={(contextId: string) => dispatch(OurContextsInspectorActions.setViewingContextId(contextId))}
            onPress={() => {
              trackUserChangedViewMode(pinnedMode ? "default" : "pinned");
              setPinnedMode((v) => !v);
            }}
            onPressClear={() => dispatch(OurContextsInspectorActions.clearSingleContextInspectorById(viewingContextId))}
          />
          <Box className={cn(s.dataContainer)}>
            {pinnedMode ?
              <PinnedPathsContainer 
                stateCopy={contextValueByContextIdTable[viewingContextId] ?? null}
                pinnedPaths={pinnedPathsByContextIdTable[viewingContextId] ?? []}
                onPressUnpin={onPressUnpin}
                icon={<ReactIcon fill={theme.colors.textBright} />}
              />
            :
              <SyntaxHighlighter 
                code={JSON.stringify(contextValueByContextIdTable[viewingContextId], null, 2)}
                linesWithMarkup={linesWithMarkupByContextIdTable[viewingContextId]}
                customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
                enableFolding={true}
                virtualize={true}
                withPins={true}
                pinnedPaths={pinnedPathsByContextIdTable[viewingContextId] ?? []}
                borderRadius={0}
                onInspectorFoldChange={(lineIndex, newOpened) => dispatch(OurContextsInspectorActions.handleInspectorFoldChange({contextId: viewingContextId, lineIndex, newOpened}))}
                onPinPath={(path) => dispatch(OurContextsInspectorActions.pinPath({contextId: viewingContextId, path, apiKey}))}
                onUnpinPath={(path) => dispatch(OurContextsInspectorActions.unpinPath({contextId: viewingContextId, path, apiKey}))}
              />
            }
          </Box>
        </Box>
      :
        <Box className={s?.syntaxContainer}>
          <Box className={ds.fullSpaceCenteredCol}>
            <Box className={cn(s.reactContextIcon)}>
              <ReactIcon fill={theme.colors.textBright} />
            </Box>
            <Typography variant="h5" className={cn(s.whiteText)}>
              {`Context value ${t("MainPage.StateManagerTab.willBeDisplayedHere")}`}
            </Typography>
          </Box>
        </Box>
      }
    </Box>
  );
};

ContextsTab.defaultProps={}

export { ContextsTab };