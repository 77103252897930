import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

const WIDTH = 145;

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    width: WIDTH,
    minWidth: WIDTH,
    height: '100%',
    padding: theme.metrics.x,
    alignItems: 'center',
    position: 'relative',
    // backgroundColor: 'blue',
  },
  iconCont: {
    position: 'relative',
    width: 36,
    marginLeft: theme.metrics.x,
    marginRight: theme.metrics.x,
    // backgroundColor: 'blue'
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    color: theme.colors.textMain,
    fontSize: 12,
    userSelect: 'none',
    pointerEvents: 'none',
    padding: 0,
    margin: 0,
    whiteSpace: 'nowrap'
  },
  connectionIndicator: {
    display: 'flex',
    position: 'absolute',
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    // backgroundColor: theme.colors.black50,
    top: theme.metrics.x05,
    left: 0
  },
  connectionIcon: {
    display: 'flex',
    scale: "0.7",
  },
  forceDisconnectBtn: {
    display: 'flex',
    position: 'absolute',
    right: -theme.metrics.x3_5,
    top: -theme.metrics.x3_5,
    transform: 'scale(0.55)',
  }
});