import React, { useMemo } from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { appSettingsSelector } from '~/logic/appSettings/AppSettingsSelectors';
import { ThemeContext } from '~/theme';
import { theme } from '~/theme/default/theme';
import { wildSandTheme } from "~/theme/default/wildSandTheme";
import { balticSeaTheme } from '~/theme/default/balticSeaTheme';
import { whiteTheme } from '~/theme/default/whiteTheme';

type ThemeProviderProps = {
  children: ReactElement;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({children}) => {
  const { colorTheme } = useSelector(appSettingsSelector);

  const currentThemeObj = useMemo(() => {
    switch (colorTheme) {
      case "blueBayoux":
        return theme;
      case "wildSand":
        return wildSandTheme;
      case "white":
        return whiteTheme;
      default:
        return balticSeaTheme;
    }
  }, [colorTheme]);

  return (
    <ThemeContext.Provider value={currentThemeObj}>{children}</ThemeContext.Provider>
  );
};
