import React, { memo, useState } from 'react';
import { useStyles } from './NoteStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { AlertTriangleIcon } from '~/assets/icons/alertTriangle';
import cn from 'classnames';
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useDerivedValue, withTiming } from 'react-native-reanimated';

export type NoteProps = {
  alt: string;
  fullText: string;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  zIndex?: number;
};

const Note: React.FC<NoteProps> = ({
  alt,
  fullText,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  zIndex
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const PADDING = theme.metrics.x05;

  const [opened, setOpened] = useState(false);

  const progress = useDerivedValue(() => withTiming(opened ? 1 : 0), [opened]);

  const animatedContStyle = useAnimatedStyle(() => ({
    display: "flex",
    position: "absolute",
    overflow: "hidden",
    flexDirection: "row",
    left: 0,
    top: 0,
    backgroundColor: theme.colors.bgContrastColor,
    padding: PADDING,
    borderRadius: minWidth! * 0.5 + PADDING,
    width: interpolate(
      progress.value,
      [0, 1],
      [minWidth! + PADDING * 2, maxWidth!]
    ),
    height: interpolate(
      progress.value,
      [0, 1],
      [minHeight! + PADDING * 2, maxHeight!]
    ),
  }), [theme.colors, progress, minWidth]);

  const fullTextOpacityStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      progress.value,
      [0.5, 1],
      [0, 1],
      Extrapolation.CLAMP
    ),
    color: theme.colors.bgContrastColorText,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0,
    userSelect: "none",
    fontSize: 14
  }), [progress, theme.colors]);

  return (
    <Box 
      className={cn(s.container)} 
      style={{
        zIndex,
        width: minWidth! + PADDING * 2,
        height: minHeight! + PADDING * 2,
      }}
    >

      <Animated.View style={animatedContStyle}>
        <IconButton
          size='small'
          style={{
            display: "flex",
            width: minWidth,
            height: minHeight
          }}
          title={alt}
          onClick={() => setOpened((v) => !v)}
        >
          <AlertTriangleIcon color={theme.colors.bgContrastColorText} />
        </IconButton>


        <Box 
          className={cn(s.fullTextCont)}
          style={{minWidth: maxWidth! - minWidth! - PADDING * 2}}
          onClick={() => setOpened(false)}
        >
          <Animated.Text style={fullTextOpacityStyle}>
            {fullText}
          </Animated.Text>
        </Box>
      </Animated.View>
    </Box>
  );
};

Note.defaultProps={
  zIndex: 6,
  minWidth: 24,
  minHeight: 24,
  maxWidth: 280,
  maxHeight: 120
}

const MemorizedComponent = memo(Note);
export { MemorizedComponent as Note };