import React from 'react';
import { Box } from '@material-ui/core';

export type ResizeHandleProps = {
  height?: string | number;
  width?: string | number;
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
};

const ResizeHandle: React.FC<ResizeHandleProps> = ({
  height,
  width,
  right,
  left,
  top,
  bottom
}) => {
  return (
    <Box 
      style={{
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height, 
        width,
        right,
        left,
        top,
        bottom
      }}
    />
  );
};

ResizeHandle.defaultProps={
  height: "100%",
  width: 4
}

export { ResizeHandle };