import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: theme.metrics.x3,
    // backgroundColor: 'blue'
  },
  subtitle: {
    fontFamily: theme.fonts.f600.fontFamily,
    letterSpacing: -0.3,
    fontSize: theme.metrics.x4,
    color: theme.colors.textTetriary,
    margin: 0,
    marginRight: theme.metrics.x1_5
  },
  text: {
    fontFamily: theme.fonts.f600.fontFamily,
    // width: '85%',
    letterSpacing: -0.3,
    fontSize: theme.metrics.x4,
    margin: 0
  },
  copyButton: {
    display: 'flex',
    width: 28,
    height: 24,
    cursor: 'pointer',
    marginLeft: theme.metrics.x2
  },
});