import React, { useState } from 'react';
import { useStyles } from './ReduxActionItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/components/TimelineItem';
import { InterceptedReduxAction } from '~/types/types';
import { Box, Button } from '@material-ui/core';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import 'animate.css';

export type ReduxActionItemProps = TimelineItemExternalProps & {
  interceptedReduxAction: InterceptedReduxAction;
};

const ReduxActionItem: React.FC<ReduxActionItemProps> = ({
  interceptedReduxAction,
  showBorderBottom,
  timeSincePrevEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();

  const [dataToShow, setDataToShow] = useState("");

  const timeToShow = useTimeToShow(interceptedReduxAction.timestamp);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      timeSincePrevEvent={timeSincePrevEvent}
      showBorderBottom={showBorderBottom}
      leftChildren={
        <p className={cn(s.purpleText)} title={`${t("Common.intercepted")} redux action`}>
          {"REDUX ACTION"} 
        </p>
      }
      rightChildren={
        <p className={cn(tds.grayText, tds.filledText)}>
          {`${interceptedReduxAction.action.type}`}
        </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {`TYPE: ${interceptedReduxAction.action.type}`}
              </p>
            </Box>

            <Box className={cn(tds.infoCol)}>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  ID:
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedReduxAction.actionId}
                </p>
              </Box>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {`${t("Other.timestamp")}:`}
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedReduxAction.timestamp}
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            {Object.keys(interceptedReduxAction.action).map((key) => {
              if (key === "type")
                return null;

              // @ts-ignore
              const value = interceptedReduxAction.action[key];

              return (
                <Button 
                  key={`ex_val_${key}`}
                  variant="contained" 
                  className={cn(tds.bottomButton)}
                  onClick={() => setDataToShow(jsonStringifyObjectFormatted(value, "payload"))}
                >
                  {key}
                </Button>
              );
            })}
            {interceptedReduxAction._stackTraceData &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedReduxAction._stackTraceData))}
              >
                TRACE
              </Button>
            }
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
                fallbackDataKey={"payload"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

ReduxActionItem.defaultProps={}

export { ReduxActionItem };