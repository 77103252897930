import { restActionCreatorHelper } from '~/helpers/restActionCreatorHelper';

const userRestAction = restActionCreatorHelper(`user`);

export const InitialSagaRestActions = userRestAction('initialSaga');

export const AuthRestActions = userRestAction('auth');

export const OtpRestActions = userRestAction('otp');

export const ResendOtpRestActions = userRestAction('resendOtp');

export const ProfileRestActions = userRestAction('profile');

export const EditProfileRestActions = userRestAction('editProfile');

export const PersonalSettingRestActions = userRestAction('personalSetting');

export const GenerateApiKeyRestActions = userRestAction('generateApiKey');

export const LogoutRestActions = userRestAction('logout');

export const CreateTestAccountRestActions = userRestAction('createTestAccount');

export const CheckMailBoxRestActions = userRestAction('checkMailBox');

export const TestAccountsListRestActions = userRestAction('testAccountsList');

export const ClearMailBoxRestActions = userRestAction('clearMailBox');

export const DeleteTestAccountRestActions = userRestAction('deleteTestAccount');

export const ForgotPasswordRestActions = userRestAction('forgotPassword');

export const EditTestAccountRestActions = userRestAction('editTestAccount');

export const ClientsQueueRestActions = userRestAction('clientsQueue');