import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const EXPORTABLE_SCENARIO_ITEM_HEIGHT = 52;

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    height: EXPORTABLE_SCENARIO_ITEM_HEIGHT,
    width: '100%',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderRadius: theme.metrics.x2,
    marginBottom: theme.metrics.x2,
    padding: theme.metrics.x2
    // backgroundColor: 'blue',
    // marginBottom: 30
  },
  left: {
    display: 'flex',
    height: '100%',
    width: '85%',
    alignItems: 'center'
  },
  indexCont: {
    display: 'flex',
    marginRight: theme.metrics.x3
  },
  leftCol: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  right: {
    display: 'flex',
    height: '100%',
    width: '15%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  title: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily,
    margin: 0
  },
  description: {
    color: theme.colors.textSecondary,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0
  },
  deleteBtn: {
    width: 24,
    height: 24
  }
});