import { makeStyles } from '@material-ui/core/styles';

export const ROW_ITEM_HEIGHT = 24;

export const useStyles = makeStyles({
  rowContainer: {
    display: "flex", 
    height: ROW_ITEM_HEIGHT,
    position: "relative", 
    alignItems: "center", 
    paddingLeft: 20
  },
  pinButton: {
    display: "flex",
    width: 24, 
    height: ROW_ITEM_HEIGHT, 
    position: "absolute", 
  },
  copyButton: {
    display: "flex", 
    width: 16, 
    height: 16, 
    cursor: "pointer", 
    position: "absolute", 
  },
  arrowButton: {
    display: "flex", 
    width: 20, 
    height: 20, 
    cursor: "pointer", 
    position: "absolute", 
  },
});