import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="100%"
    height="100%"
    viewBox="0 0 585.225 585.225"
  >
    <path d="M489.6 36.975c-44.625 0-81.6 30.6-90.525 71.4H183.6c-10.2-40.8-47.175-71.4-90.525-71.4-51 0-93.075 42.074-93.075 93.074 0 35.7 20.4 67.575 51 82.875V313.65c0 130.049 112.2 234.6 242.25 234.6 130.051 0 242.25-104.551 242.25-234.6V212.924c29.324-15.3 49.725-47.175 49.725-82.875-1.276-51-43.35-93.074-95.625-93.074zM169.575 229.5c0-14.025 11.475-26.775 26.775-26.775s26.775 11.475 26.775 26.775-11.475 26.775-26.775 26.775-26.775-11.476-26.775-26.775zM290.7 502.35c-48.45 0-89.25-52.275-89.25-116.025S240.975 270.3 290.7 270.3c49.726 0 89.249 52.275 89.249 116.025S340.426 502.35 290.7 502.35zm95.624-246.076c-15.299 0-26.773-11.475-26.773-26.775 0-14.025 11.475-26.775 26.773-26.775 14.025 0 26.775 11.475 26.775 26.775s-11.474 26.775-26.775 26.775z" />
    <path d="M244.8 337.875c-7.65 0-15.3 3.824-17.85 10.199-2.55 7.65-1.275 15.301 3.825 20.4l45.9 45.9c7.65 7.65 20.399 7.65 28.049 0l45.9-45.9c5.1-5.1 7.65-12.75 3.824-20.4-2.549-7.65-10.199-10.199-17.85-10.199H244.8z" />
  </svg>
)
export { SvgComponent as ZustandIcon }
