import React, { useState, useMemo } from 'react';
import { useStyles } from './SingleLogItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/components/TimelineItem';
import { Box, Button } from '@material-ui/core';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { EventLog, ScenarioLog } from '~/types/types';
import cn from 'classnames';
import 'animate.css';

export type SingleLogItemProps = TimelineItemExternalProps & {
  log: EventLog | ScenarioLog;
};

const SingleLogItem: React.FC<SingleLogItemProps> = ({
  log,
  showBorderBottom
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();

  const [dataToShow, setDataToShow] = useState("");

  const timeToShow = useTimeToShow(log.endTimestamp);

  const logType = useMemo(() => {
    return "scenario" in log ? "scenario" : "event";
  }, [log]);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      showBorderBottom={showBorderBottom}
      leftChildren={
        <p className={cn(log.ok ? s.greenText : s.redText)} title={logType === "event" ? "Detailed event log" : "Detailed scenario log"}>
          {logType === "event" ? "EVENT LOG" : "SCENARIO LOG"} 
        </p>
      }
      rightChildren={
        <p className={cn(tds.grayText, tds.filledText)}>
          {"scenario" in log ? `${t("Common.scenario")} ${log.scenario.id} (${log.scenario.events.length} ${t("Common.events")})` : `${t("Common.event")} ${log.event.id} (${log.event.instructionId})`}
        </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {`ID: ${"scenario" in log ? log.scenario.id : log.event.id}`}
              </p>
            </Box>

            <Box className={cn(tds.infoCol)}>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  OK:
                </p>
                <p className={cn(tds.yellowText)}>
                  {log.ok ? "True" : "False"}
                </p>
              </Box>
              {("scenario" in log) &&
                <Box className={cn(tds.rowDescBetween)}>
                  <p className={cn(tds.grayTextSmall)}>
                    Events:
                  </p>
                  <p className={cn(tds.yellowText)}>
                    {log.scenario.events.length}
                  </p>
                </Box>
              }
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {`${t("Other.duration")}:`}
                </p>
                <p className={cn(tds.yellowText)}>
                  {`${log.elapsedTime} ${t("Common.milliseconds")}`}
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            <Button 
              variant="contained" 
              className={cn(tds.bottomButton)}
              onClick={() => setDataToShow(jsonStringifyObjectFormatted(log))}
            >
              VIEW DETAILS
            </Button>
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

SingleLogItem.defaultProps={}

export { SingleLogItem };