import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 256 230"
  >
    <path
      fill="transparent"
      d="m157.98 142.487-4.91 8.527a8.288 8.288 0 0 1-7.182 4.151H108.27a8.288 8.288 0 0 1-7.182-4.151l-4.911-8.527zm13.747-23.87-8.658 15.034h-71.98l-8.658-15.034zm-8.34-23.342 8.354 14.506H82.417l8.354-14.506zm-17.5-22.066a8.288 8.288 0 0 1 7.183 4.151l5.228 9.079H95.86l5.229-9.079a8.288 8.288 0 0 1 7.182-4.151z"
    />
    <path
      fill="transparent"
      d="M53.523 69.252c-4.167-20.206-5.062-35.704-2.368-46.957 1.602-6.693 4.53-12.153 8.984-16.093 4.702-4.159 10.646-6.2 17.326-6.2 11.018 0 22.602 5.025 34.98 14.57 5.05 3.894 10.29 8.587 15.732 14.082.434-.557.923-1.083 1.469-1.57 15.386-13.71 28.34-22.23 39.42-25.514 6.588-1.954 12.773-2.14 18.405-.244 5.946 2 10.683 6.137 14.026 11.93 5.516 9.561 6.97 22.124 4.914 37.637-.838 6.323-2.271 13.21-4.296 20.673.764.092 1.53.262 2.288.513 19.521 6.47 33.345 13.426 41.714 21.377 4.98 4.73 8.231 9.996 9.407 15.826 1.24 6.153.03 12.324-3.308 18.113-5.506 9.548-15.63 17.077-30.052 23.041-5.79 2.395-12.343 4.564-19.664 6.515.334.754.594 1.555.767 2.395 4.167 20.206 5.061 35.704 2.368 46.957-1.602 6.693-4.531 12.153-8.985 16.093-4.701 4.159-10.646 6.2-17.325 6.2-11.019 0-22.602-5.025-34.98-14.57-5.104-3.936-10.402-8.687-15.907-14.258a11.737 11.737 0 0 1-2.084 2.442c-15.386 13.712-28.34 22.23-39.42 25.515-6.588 1.954-12.773 2.14-18.405.244-5.946-2-10.683-6.137-14.026-11.93-5.516-9.561-6.97-22.124-4.914-37.637.869-6.551 2.376-13.709 4.518-21.485a11.732 11.732 0 0 1-2.51-.537c-19.521-6.47-33.345-13.426-41.714-21.377-4.98-4.73-8.231-9.996-9.407-15.826-1.24-6.153-.03-12.325 3.308-18.114 5.506-9.547 15.63-17.077 30.052-23.04 5.963-2.467 12.734-4.693 20.32-6.689a11.81 11.81 0 0 1-.633-2.082"
    />
    <path
      d="M189.647 161.333a3.684 3.684 0 0 1 4.235 2.81l.023.112.207 1.075c6.71 35.276 1.983 52.915-14.18 52.915-15.813 0-35.944-15.051-60.392-45.153a3.684 3.684 0 0 1 2.777-6.005h.114l1.288.009c10.288.056 20.289-.303 30.004-1.076 11.467-.913 23.442-2.475 35.924-4.687M78.646 134.667l.062.105.646 1.127c5.177 9 10.57 17.542 16.18 25.627 6.608 9.52 14.038 19.158 22.29 28.914a3.684 3.684 0 0 1-.309 5.082l-.093.083-.83.715c-27.307 23.397-45.055 28.068-53.244 14.012-8.017-13.757-5.11-38.773 8.719-75.047a3.683 3.683 0 0 1 6.579-.618m124.857-52.054.112.037 1.028.354c33.705 11.725 46.51 24.61 38.416 38.655-7.916 13.736-30.93 23.738-69.041 30.004a3.683 3.683 0 0 1-3.773-5.501c5.458-9.286 10.375-18.524 14.749-27.717 4.96-10.425 9.615-21.616 13.965-33.57a3.684 3.684 0 0 1 4.432-2.295zM84.446 76.71a3.683 3.683 0 0 1 1.31 5.042c-5.46 9.285-10.376 18.524-14.75 27.717-4.96 10.425-9.615 21.615-13.965 33.57a3.684 3.684 0 0 1-4.544 2.262l-.112-.037-1.028-.355c-33.705-11.724-46.51-24.61-38.416-38.654 7.916-13.737 30.93-23.738 69.041-30.004.85-.14 1.722.022 2.464.459m108.206-57.748c8.017 13.758 5.11 38.774-8.719 75.048a3.683 3.683 0 0 1-6.579.618l-.062-.105-.646-1.127c-5.177-9-10.57-17.542-16.18-25.627-6.608-9.52-14.038-19.158-22.29-28.914a3.684 3.684 0 0 1 .309-5.082l.093-.083.83-.715c27.307-23.397 45.055-28.068 53.244-14.013M77.45 10.59c15.814 0 35.945 15.05 60.392 45.152a3.684 3.684 0 0 1-2.777 6.005h-.114l-1.288-.008c-10.287-.056-20.289.303-30.003 1.076-11.468.913-23.443 2.475-35.925 4.687a3.684 3.684 0 0 1-4.234-2.81l-.024-.113-.207-1.074C56.56 28.228 61.286 10.59 77.45 10.59"
    />
    <path
      d="M111.295 73.67h31.576a12.89 12.89 0 0 1 11.181 6.475l15.855 27.626a12.892 12.892 0 0 1 0 12.834l-15.855 27.626a12.892 12.892 0 0 1-11.181 6.475h-31.576c-4.618 0-8.883-2.47-11.182-6.475L84.26 120.605a12.892 12.892 0 0 1 0-12.834l15.854-27.626a12.892 12.892 0 0 1 11.182-6.475m26.763 8.338c4.62 0 8.888 2.473 11.185 6.481l11.056 19.288a12.892 12.892 0 0 1 0 12.822l-11.056 19.288a12.892 12.892 0 0 1-11.185 6.48h-21.95c-4.62 0-8.888-2.472-11.185-6.48l-11.056-19.288a12.892 12.892 0 0 1 0-12.822l11.056-19.288a12.892 12.892 0 0 1 11.184-6.48zm-5.187 9.12h-11.576a12.892 12.892 0 0 0-11.179 6.47l-5.842 10.167a12.892 12.892 0 0 0 0 12.846l5.842 10.168a12.892 12.892 0 0 0 11.179 6.47h11.576c4.616 0 8.88-2.468 11.179-6.47l5.842-10.168a12.892 12.892 0 0 0 0-12.846l-5.842-10.168a12.892 12.892 0 0 0-11.179-6.47m-4.994 8.729c4.612 0 8.873 2.464 11.173 6.46l.829 1.44a12.892 12.892 0 0 1 0 12.862l-.829 1.44a12.892 12.892 0 0 1-11.173 6.46h-1.588a12.892 12.892 0 0 1-11.173-6.46l-.829-1.44a12.892 12.892 0 0 1 0-12.862l.829-1.44a12.892 12.892 0 0 1 11.173-6.46zm-.792 8.599a5.738 5.738 0 0 0-4.97 2.866 5.729 5.729 0 0 0 0 5.732 5.738 5.738 0 0 0 9.937 0 5.729 5.729 0 0 0 0-5.732 5.736 5.736 0 0 0-4.967-2.866m-46.509 5.732h10.32"
    />
  </svg>
)

export { SvgComponent as TanStackIcon };