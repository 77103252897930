import React, { memo } from 'react';
import { useStyles } from './TestAccountStyles';
import { Box } from '@material-ui/core';
import { TestAccount as TestAccountT } from '~/types/types';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export type TestAccountProps = {
  currentTestAccount: TestAccountT | null;
  onPress: () => void;
};

const TestAccount: React.FC<TestAccountProps> = ({
  currentTestAccount,
  onPress
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  if (!currentTestAccount)
    return (
      <Box className={classNames(s.container)} onClick={onPress}>
        <p className={classNames(s.whiteText)}>
          {`${t("Other.testAccount")}:`}
        </p>
        <p className={classNames(s.whiteTextBold)}>
          {t("Other.notSelected")}
        </p>
      </Box>
    );

  return (
    <Box className={classNames(s.container)} onClick={onPress}>
      <p className={classNames(s.whiteText)}>
        {`${t("Other.testAccount")}:`}
      </p>
      <p className={classNames(s.whiteTextBold)}>
        {currentTestAccount.email}
      </p>
    </Box>
  );
};

TestAccount.defaultProps={}

const MemorizedComponent = memo(TestAccount);
export { MemorizedComponent as TestAccount };