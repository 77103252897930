import React from 'react';
import { useStyles } from './CheckboxStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import cn from 'classnames';
import Marked from "./assets/marked";

export type CheckboxProps = {
  mark: boolean;
  onPress?: () => void;
  markColor?: string;
  bgColorMarked?: string;
  bgColorUnmarked?: string;
  borderColorMarked?: string;
  borderColorUnmarked?: string;
  marginLeft?: number;
  marginRight?: number;
  disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  mark, 
  onPress,
  markColor,
  bgColorMarked,
  bgColorUnmarked,
  borderColorMarked,
  borderColorUnmarked,
  marginLeft,
  marginRight,
  disabled
}) => {
  const s = useStyles();
  const { theme } = useThemeContext();

  return (
    <Box
      onClick={() => { 
        onPress && !disabled && onPress() 
      }} 
      className={cn(s.container)}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: mark? (bgColorMarked ?? theme.colors.transparent): (bgColorUnmarked ?? theme.colors.transparent),
        borderColor: mark? (borderColorMarked ?? theme.colors.doveGray) : (borderColorUnmarked ?? theme.colors.doveGray),
        borderRadius: theme.metrics.x2,
        marginLeft,
        marginRight
      }}
    >
      {mark && (<Marked color={markColor ?? theme.colors.textBright}/>)}
    </Box>
  );
};

Checkbox.defaultProps={
  disabled: false
}

export { Checkbox };