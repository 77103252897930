import React, { useState } from 'react';
import { useStyles } from './ApiEventItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from "~/components/TimelineItem";
import { Box, Button, IconButton } from '@material-ui/core';
import { InterceptedApiCall } from '~/types/types';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { HttpResponseStatusCodeIndicator } from "~/components/HttpResponseStatusCodeIndicator";
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { showToast } from '~/helpers/alertService';
import { copyToClipboard } from '~/helpers/windowHelpers';
import fetchToCurl from 'fetch-to-curl';
import cn from 'classnames';
import CopyIcon from './assets/copy';
// import PostmanIcon from './assets/postman';
import 'animate.css';

export type ApiEventItemProps = TimelineItemExternalProps & {
  interceptedApiCall: InterceptedApiCall;
};

const ApiEventItem: React.FC<ApiEventItemProps> = ({
  interceptedApiCall,
  showBorderBottom,
  timeSincePrevEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();
  
  const [opened, setOpened] = useState(false);
  const [dataToShow, setDataToShow] = useState("");

  const getCURL = () => {
    try {
      const url = interceptedApiCall.request.url;
      const options = {
        headers: interceptedApiCall.request.requestHeaders ?? {"content-type": "application/json", "Accept": "application/json"},
        method: interceptedApiCall.request.method,
        body: interceptedApiCall.request.body ? JSON.stringify(interceptedApiCall.request.body) : undefined
      };

      const cURL = fetchToCurl(url, options);
      return cURL;
    } catch (e) {
      showToast(t("Toast.generatecUrlError"), "error");
      console.log(e);
      return null;
    }
  }

  const onPressCopyCURL = () => {
    const cURL = getCURL();

    if (cURL) {
      copyToClipboard(cURL);
      console.log(cURL);
    }
  };

  const timeToShow = useTimeToShow(interceptedApiCall.timestampRequest ?? interceptedApiCall.timestampResponse);
  const requestTime = useTimeToShow(interceptedApiCall.timestampRequest);
  const responseTime = useTimeToShow(interceptedApiCall.timestampResponse);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      timeSincePrevEvent={timeSincePrevEvent}
      showBorderBottom={showBorderBottom}
      onChangeOpened={(v) => setOpened(v)}
      leftChildren={
        <Box className={cn(s.leftChildrenCont)}>
          <p className={cn(s.orangeText)} title={`${t("Common.intercepted")} API ${interceptedApiCall.response ? t("Common.response") : t("Common.request")}`}>
            {interceptedApiCall.response ? "API RESPONSE" : "API REQUEST"} 
          </p>

          {(interceptedApiCall.response?.status !== undefined) &&
            <HttpResponseStatusCodeIndicator 
              statusCode={interceptedApiCall.response.status}
              marginBottom={theme.metrics.x05}
            />
          }
        </Box>
      }
      rightChildren={
        (opened && interceptedApiCall.request) ?
          <Box className={cn(s.row)}>
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                onPressCopyCURL();
              }} 
              size='small'
              className={cn(s.copyCURL)}
              title={t("Other.copyRequestcUrl")}
            >
              <CopyIcon color={theme.colors.mobster}/>
            </IconButton>
          </Box>
        :
          <p className={cn(tds.grayText, tds.filledText)}>
            {`${interceptedApiCall.request.method} ${interceptedApiCall.request.url}`}
          </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {interceptedApiCall.request.url}
              </p>
            </Box>

            <Box className={cn(tds.infoCol)}>
              {interceptedApiCall.response &&
                <Box className={cn(tds.rowDescBetween)}>
                  <p className={cn(tds.grayTextSmall)}>
                    {t("Other.statusCode")}
                  </p>
                  <p className={cn(tds.yellowText)} title={interceptedApiCall.response.statusText}>
                    {interceptedApiCall.response.status}
                  </p>
                </Box>
              }
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {t("Other.method")}
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedApiCall.request.method}
                </p>
              </Box>
              {!!requestTime &&
                <Box className={cn(tds.rowDescBetween)}>
                  <p className={cn(tds.grayTextSmall)}>
                    {t("Other.sent")}
                  </p>
                  <p className={cn(tds.yellowText)}>
                    {requestTime}
                  </p>
                </Box>
              }
              {!!responseTime &&
                <Box className={cn(tds.rowDescBetween)}>
                  <p className={cn(tds.grayTextSmall)}>
                    {t("Other.gotResponse")}
                  </p>
                  <p className={cn(tds.yellowText)}>
                    {responseTime}
                  </p>
                </Box>
              }
              {!!interceptedApiCall.duration &&
                <Box className={cn(tds.rowDescBetween)}>
                  <p className={cn(tds.grayTextSmall)}>
                    {t("Other.duration")}
                  </p>
                  <p className={cn(tds.yellowText)}>
                    {interceptedApiCall.duration}
                  </p>
                </Box>
              }
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            {interceptedApiCall.request?.body &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedApiCall.request.body))}
                title={"Request payload (body or query) represented as json"}
              >
                {t("Other.requestPayload")}
              </Button>
            }

            {interceptedApiCall.request?.requestHeaders &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedApiCall.request.requestHeaders))}
              >
                {t("Other.requestHeaders")}
              </Button>
            }

            {interceptedApiCall.response?.data &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedApiCall.response?.data))}
              >
                {t("Other.responseData")}
              </Button>
            }

            {interceptedApiCall.response?.responseHeaders &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedApiCall.response?.responseHeaders))}
              >
                {t("Other.responseHeaders")}
              </Button>
            }
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

ApiEventItem.defaultProps={}

export { ApiEventItem };