import { ObjectT, TanStackGetQueriesDataReturnType, TanStackQueryKey } from "~/types/types";
import { BaseParamType, ParamType } from "../types/api";

export const stringifyPrettySingleLine = (s: any) => JSON.stringify(s).replace(/\,/g, ', ').replace(/\:/g, ': ');

export const prepareParamFromString = (param: string, type: ParamType) => {
  try {
    param = param.replace(/'/g, `"`)
    type = type.toLowerCase() as ParamType;

    // if (type === 'object')
    // console.log('here param', param, JSON.parse(param));

    if (type[0] === "?") {
      if (param.length === 0)
        return undefined;

      type = type.slice(1, type.length) as BaseParamType;
    }

    switch (type) {
      case "number":
        return Number(param);
      case "string":
        return param;
      case "array":
        return JSON.parse(param);
      case "object":
        return JSON.parse(param);
      case "boolean":
        return param.toLowerCase() === "true";
      default:
        return param;
    }
  } catch (e) {
    return param;
  }
}

export const prepareParams = (params: {[key: string]: string}, parametersDescription: {[key: string]: ParamType}) => {
  const prepared: {[key: string]: any} = {};

  Object.keys(params).forEach((key) => {
    prepared[key] = prepareParamFromString(params[key], parametersDescription[key]);
  });

  return prepared;
}

export const getHtmlTagContent = (html: string, body: boolean = false) => {
  const htmlTags = body ? html.match(/<\/*body[^>]*>/gim) : html.match(/<\/*html[^>]*>/gim);

  if (htmlTags?.length) {
    const iFrom = html.indexOf(htmlTags[0]) + htmlTags[0].length;
    const iTo = html.indexOf(htmlTags[1]);

    const htmlContents = html.slice(iFrom, iTo);
  
    return htmlContents;
  }

  return html;
}

export const getHtmlFromPrettyJsonEscapeDividedLine = (line: string, fallback?: string): string => {
  try {
    const restoredJson = ["{", line, "}"].join("\n");
    const parsed = JSON.parse(restoredJson);
    const htmlString = (Object.values(parsed)[0] ?? line) as string;

    return getHtmlTagContent(htmlString, true);
  } catch (e) {
    return fallback ?? line;
  }
}

export const foundСoincidences = (searchIn: string, searchFor: string) => (searchIn.toLowerCase().includes(searchFor.toLowerCase()));

export const stringWithMaxLen = (s: string, max: number, replacer: string = "...") => s.length > max ? `${s.slice(0, max)}${replacer}` : s;

export const capitalizeString = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const prepareTanStackQueryPrettyKey = (tanStackQueryKey: TanStackQueryKey) => {
  if (tanStackQueryKey.length === 1 && typeof tanStackQueryKey[0] === "string")
    return tanStackQueryKey[0];

  return stringifyPrettySingleLine(tanStackQueryKey).replace(/\"/g, '');
}

export const prettifyTanStackQueriesData = (queriesData: TanStackGetQueriesDataReturnType) => {
  const queriesDataAsObject: ObjectT<any> = {};

  for (const queryData of queriesData) {
    const key = prepareTanStackQueryPrettyKey(queryData[0]);
    queriesDataAsObject[key] = queryData[1];
  }

  return queriesDataAsObject;
};