import React, { memo, useState } from 'react';
import { useStyles } from './ProjectItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { Project } from '~/types/types';
import { EditIcon, TrashIcon } from '~/assets/icons';
import { ManualItem } from "~/components/ManualItem";
import { copyToClipboard } from '~/helpers/windowHelpers';
import cn from 'classnames';

export type ProjectItemProps = {
  project: Project;
  isSelected: boolean;
  onPress: () => void;
  onPressDelete: () => void;
  onPressEdit: () => void;
};

const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  isSelected,
  onPress,
  onPressDelete,
  onPressEdit
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);

  return (
    <Box 
      className={cn(s.container)}
      onClick={onPress}
      style={{
        backgroundColor: hovered ? theme.colors.instructionItemBg : theme.colors.bgSeptenary,
        transitionDuration: "0.4s"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isSelected &&
        <Box 
          className={cn(s.selectedIndicator)}
          title={t("Other.thisProjectIsSelected")}
        />
      }
      <Box className={cn(s.left, {[s.leftBg]: !project.image})}>
        {project.image ?
          <img src={project.image} className={cn(ds.fullSpaceImg)}/>
        :
          <p className={cn(s.giantLetter)}>
            {project.name[0]}
          </p>
        }
      </Box>
      <Box className={cn(s.right)}>
        <Box>
          <p className={cn(s.projectNameText)}>
            {project.name}
          </p>
          <p className={cn(s.descriptionText)}>
            {project.description}
          </p>
          <Box style={{display: 'flex'}}>
            <ManualItem 
              subtitle={"Project id"}
              manualKey={project._id}
              onCopyPress={() => copyToClipboard(project._id)}
              color={theme.colors.textBright}
            />
          </Box>
        </Box>
        <p className={cn(s.membersText)}>
          {`${t("Other.members")}: ${project.members.length + 1}`}
        </p>

        <Box className={cn(s.buttonsCol)}>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              onPressDelete();
            }}
            size={'small'} 
            title={t("Other.deleteProject")}
          >
            <Box className={cn(s.icon)}>
              <TrashIcon color={theme.colors.iconsMain}/>
            </Box>
          </IconButton>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              onPressEdit();
            }} 
            size={'small'} 
            title={t("Other.editProject")}
          >
            <Box 
              className={cn(s.icon)}
              style={{
                opacity: hovered ? 1 : 0,
                transitionDuration: "0.4s"
              }}
            >
              <EditIcon color={theme.colors.iconsMain}/>
            </Box>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ProjectItem.defaultProps={}

const MemorizedComponent = memo(ProjectItem);
export { MemorizedComponent as ProjectItem };