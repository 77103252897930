import React, { createContext } from 'react';
import { ReactElement } from 'react';
import { useSearchValue } from '~/hooks';

export type AvailableInstructionsContextT = {
  searchInstructionsValue: string;
  searchInstructionsInnerValue: string;
  setSearchInstructionsInnerValue: (s: string) => void;
};

export const AvailableInstructionsContext = createContext<AvailableInstructionsContextT>(null as any);

export type AvailableInstructionsContextProviderProps = {
  children: ReactElement;
};

export const AvailableInstructionsProvider: React.FC<AvailableInstructionsContextProviderProps> = ({children}) => {
  const [searchInstructionsValue, searchInstructionsInnerValue, setSearchInstructionsInnerValue] = useSearchValue("");

  return (
    <AvailableInstructionsContext.Provider 
      value={{
        searchInstructionsValue,
        searchInstructionsInnerValue,
        setSearchInstructionsInnerValue
      }}
    >
      {children}
    </AvailableInstructionsContext.Provider>
  );
};
