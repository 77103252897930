import { ObjectT } from "~/types/types";
import { showToast } from "./alertService";
import i18next from "i18next";

export const isJsonString = (s: string) => {
  try {
    JSON.parse(s);
  } catch (e) {
    return false;
  }
  
  return true;
}

// Сделать объект stringified-строкой
// Если передан не объект, то завернет в объект и сделает stringified
export const jsonStringifyObjectFormatted = (obj: any, fallbackDataKey: string = "data") => {
  if (typeof obj === "object")
    return JSON.stringify(obj, null, 2);

  return JSON.stringify({[fallbackDataKey]: obj}, null, 2);
}

// Подготовить перехваченное значение asyncStorage / localStorage к отображению в SyntaxHighlighter
export const jsonStringifyInterceptedStorageValue = (valueString: any) => {
  try {
    const value = JSON.parse(valueString);
    return jsonStringifyObjectFormatted(value, "value");
  } catch (e) {
    return jsonStringifyObjectFormatted(valueString, "value");
  }
}

export const getDataByPath = (json: ObjectT<any>, path: string, removeStartingSlash: boolean = true) => {
  if (removeStartingSlash && path[0] === "/")
    path = path.slice(1, path.length);

  const keys = path.split('/');
  let data = json;
  
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    try {
      data = data[key];
    } catch (e) {
      return undefined;
    }
    
    if (data === undefined)
      return undefined;
  }
  
  return data;
}

export const getLenIfPossible = (data: any) => {
  try {
    const len = data.length;
    return len;
  } catch (e) {
    return 0;
  }
}

export const exportDataAsJsonFile = ({
  data,
  dataFallbackName = "data",
  fileName = "data",
  errorMessage = "Unable to download file",
  successCallback
}: {data: any, dataFallbackName?: string, fileName?: string, errorMessage?: string, successCallback?: () => void}) => {
  try {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      jsonStringifyObjectFormatted(data, dataFallbackName)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${fileName}.json`;
    link.click();
    link.remove();

    successCallback?.();
  } catch (e) {
    showToast(errorMessage, "warning");
  }
}

export const readJsonFile = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      try {
        const content = e.target.result;
        // console.log("Here", content, JSON.parse(content));
        resolve(JSON.parse(content));
      } catch (error) {
        showToast(i18next.t("Toast.fileParseError"), "warning");
        resolve({});
      }
    };

    reader.readAsText(file);
  });
}