import { useMemo } from "react";
import { useSelector } from "react-redux";
import { remoteSettingsSelector } from "~/logic/remoteSettings/RemoteSettingsSelectors";

export const useCodebudProjectsNamesTable = () => {
  const {
    projectList: {
      data: projectList
    }
  } = useSelector(remoteSettingsSelector);

  const projectNamesTable = useMemo(() => {
    let table: {[key: string]: string} = {};
    if (!projectList)
      return table;

    projectList.forEach((p) => {
      table[p._id] = p.name;
    });

    return table;
  }, [projectList]);

  return projectNamesTable;
}