import React, { memo, useRef } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './SlidingPickerV2Styles';
import { useThemeContext } from '~/theme';
import cn from 'classnames';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming
} from "react-native-reanimated";
import { useWindowSize } from '~/hooks';

export type SlidingPickerV2Props = {
  periods: string[];
  pickedPeriod: number;
  height?: number;
  bgColor?: string;
  mainLineBgColor?: string;
  mainLineBorderRadius?: number;
  animationDuration?: number;
  setPickedPeriod: (index: number) => void;
};

const SlidingPickerV2: React.FC<SlidingPickerV2Props> = ({
  periods,
  height,
  mainLineBgColor,
  pickedPeriod,
  bgColor,
  mainLineBorderRadius,
  animationDuration,
  setPickedPeriod
}) => {

  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const { windowWidth } = useWindowSize();

  const containerRef = useRef<null | HTMLDivElement>(null);

  // width style props for sliding block
  const w = useDerivedValue(() => {
    if (!containerRef?.current)
      return 0;

    return containerRef.current.offsetWidth / periods.length;
  }, [periods, windowWidth]);

  // left style prop for sliding block
  const left = useDerivedValue(() => {
    return withTiming(pickedPeriod * w.value, {duration: animationDuration});
  }, [pickedPeriod, w, animationDuration]);

  const slidingBlockStyle = useAnimatedStyle(() => ({
    display: "flex",
    position: 'absolute',
    zIndex: 2,
    width: w.value,
    // @ts-ignore
    height: height,
    left: left.value,
    borderRadius: mainLineBorderRadius,
    backgroundColor: mainLineBgColor ?? theme.colors.bgContrastColor,
    // boxShadow: `0px 0px 2px ${theme.colors.black50}`
  }), [w, height, left, mainLineBorderRadius, mainLineBgColor, theme.colors]);

  return (
    <div
      ref={containerRef}
      className={cn(s.container)}
      style={{
        borderRadius: mainLineBorderRadius,
        height,
        backgroundColor: bgColor ?? theme.colors.bgSecondary
      }}
    >
      {periods.map((item, index) =>
        <Box
          key={index}
          className={cn(s.spaceContainer)}
          style={{cursor: index === pickedPeriod ? "default" : "pointer"}}
          onClick={() => setPickedPeriod(index)}
        >
          <Box className={cn(s.block)}>
            <p className={cn(s.periodLabel)} style={{ color: index === pickedPeriod ? theme.colors.bgContrastColorText : theme.colors.textMain }}>
              {item}
            </p>
          </Box>
        </Box>
      )}
      <Animated.View style={slidingBlockStyle} />
    </div>
  )
};

SlidingPickerV2.defaultProps = {
  mainLineBorderRadius: 10,
  animationDuration: 150,
  height: 32
}

const MemorizedComponent = memo(SlidingPickerV2);
export { MemorizedComponent as SlidingPickerV2 };