import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 25.002 25.002"
  >
    <path
      stroke={props.color}
      fill={props.color2 ?? "#000000"}
      strokeMiterlimit={10}
      d="M15.498 23.5v-3h-2v3a1 1 0 1 1-2 0v-3h-2v3a1 1 0 1 1-2 0v-3h-2a1 1 0 0 1-1-1v-2h-3a1 1 0 0 1 0-2h3v-2h-3a1 1 0 0 1 0-2h3v-2h-3a1 1 0 0 1 0-2h3v-2a1 1 0 0 1 1-1h2v-3a1 1 0 0 1 2 0v3h2v-3a1 1 0 0 1 2 0v3h2v-3a1 1 0 0 1 2 0v3h2a1 1 0 0 1 1 1v2h3a1 1 0 1 1 0 2h-3v2h3a1 1 0 1 1 0 2h-3v2h3a1 1 0 1 1 0 2h-3v2a1 1 0 0 1-1 1h-2v3a1 1 0 1 1-2 0Zm-9-5h12v-12h-12Zm3-2a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1Zm1-2h4v-4h-4Z"
    />
  </svg>
)

export { SvgComponent as UnknownDeviceIcon };
