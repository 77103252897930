import { 
  atomOneDark as hljsAtomOneDark,
  atomOneLight as hljsAtomOneLight,
  gruvboxLight as hljsGruvboxLight,
  gruvboxDark as hljsGruvboxDark,
  atelierCaveLight as hljsAtelierCaveLight,
  atelierCaveDark as hljsAtelierCaveDark,
  dracula as hljsDraculaDark,
  darcula as hljsDraculaLight
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { 
  oneDark as prismOneDark,
  oneLight as prismOneLight,
  gruvboxLight as prismGruvboxLight,
  gruvboxDark as prismGruvboxDark,
  materialLight as prismMaterialLight,
  materialDark as prismMaterialDark,
  dracula as prismDraculaDark,
  darcula as prismDraculaLight,
} from 'react-syntax-highlighter/dist/esm/styles/prism';

export const SYNTAX_HIGHLIGHTING_TABLE = {
  "hljs": {
    oneDark: hljsAtomOneDark,
    oneLight: hljsAtomOneLight,
    gruvboxDark: hljsGruvboxDark,
    gruvboxLight: hljsGruvboxLight,
    materialDark: hljsAtelierCaveDark,
    materialLight: hljsAtelierCaveLight,
    draculaDark: hljsDraculaDark,
    draculaLight: hljsDraculaLight
  },
  "prism": {
    oneDark: prismOneDark,
    oneLight: prismOneLight,
    gruvboxDark: prismGruvboxDark,
    gruvboxLight: prismGruvboxLight,
    materialDark: prismMaterialDark,
    materialLight: prismMaterialLight,
    draculaDark: prismDraculaDark,
    draculaLight: prismDraculaLight
  }
} as const;

export type SyntaxHighlightingType = keyof typeof SYNTAX_HIGHLIGHTING_TABLE;

export type SyntaxHighlighting = keyof typeof SYNTAX_HIGHLIGHTING_TABLE.hljs;

export const SYNTAX_HIGHLIGHTING_VARIANTS_ARRAY = ["one", "gruvbox", "material", "dracula"] as const;

export type SyntaxHighlightingVariant = typeof SYNTAX_HIGHLIGHTING_VARIANTS_ARRAY[number];

export const SYNTAX_HIGHLIGHTING_NAMES_TABLE: {[key in SyntaxHighlightingVariant]: string} = {
  one: "One",
  gruvbox: "Gruvbox",
  material: "Material",
  dracula: "Dracula"
} as const;