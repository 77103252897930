import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    height: '60vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    paddingLeft: 16,
    paddingRight: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    // paddingLeft: 16,
    // paddingRight: 16
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4,
    marginTop: theme.metrics.x3
  }
});