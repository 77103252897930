import React, { memo } from 'react';
import { useStyles } from './ConnectedClientInfoModalStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CustomModal, CustomModalProps } from "~/components/CustomModal";
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { NoData } from '../NoData';
import { StringValueItem } from '~/components/StringValueItem';
import { useProjectIdInfo } from '~/hooks/useProjectIdInfo';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const IMPORTANT_KEYS_LOWERCASE = new Set([
  "node",
  "reactnativeversion",
  "os",
  "osversion"
]);

export type ConnectedClientInfoModalProps = CustomModalProps & {

};

const ConnectedClientInfoModal: React.FC<ConnectedClientInfoModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    bothSidesConnected,
    connectedClientProjectId,
    connectedClientEnvironmentInfo
  } = useSelector(userSelector);

  const { projectName } = useProjectIdInfo(connectedClientProjectId);

  return (
    <CustomModal 
      {...restProps} 
      classname={cn(s.modal)} 
      title={t("Other.connectedClientInfo")}
    >
      <Box className={cn(s.container)}>
        {bothSidesConnected ? 
          <>
            {!!connectedClientProjectId &&
              <>
                <Box className={cn(s.infoLine)}>
                  <StringValueItem
                    label={t("Other.projectId")}
                    // labelColor={theme.colors.textBright}
                    labelBold={true}
                    value={connectedClientProjectId}
                  />
                </Box>
                <Box className={cn(s.infoLine)}>
                  <StringValueItem
                    label={t("Other.projectName")}
                    // labelColor={theme.colors.textBright}
                    labelBold={true}
                    value={projectName}
                  />
                </Box>
              </>
            }
            {Object.keys(connectedClientEnvironmentInfo).map((key) => {
              const isImportant = IMPORTANT_KEYS_LOWERCASE.has(key.toLowerCase());
              return (
                <Box className={cn(s.infoLine)} key={key}>
                  <StringValueItem
                    key={key}
                    label={key}
                    // labelColor={isImportant ? theme.colors.textBright: undefined}
                    labelBold={isImportant}
                    value={JSON.stringify(connectedClientEnvironmentInfo[key])}
                    // valueColor={isImportant ? theme.colors.textBright : theme.colors.textBrightSecondary}
                    customHeight={56}
                  />
                </Box>
              )
            })}
          </>
        :
          <NoData 
            text={t("Common.noClientConnected")}
          />
        }
      </Box>
    </CustomModal>
  );
};

ConnectedClientInfoModal.defaultProps={}

const MemorizedComponent = memo(ConnectedClientInfoModal);
export { MemorizedComponent as ConnectedClientInfoModal };