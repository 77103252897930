// import { Action } from "@reduxjs/toolkit";
// import { ApiResponse } from "apisauce";
// import { put, call, select, delay, takeEvery, takeLatest } from "redux-saga/effects";
// import { api } from "~/api";
// import { OurZustandInspectorActions } from "./OurZustandInspectorSlice";
// import { takeLeading } from "~/store/sagaHelper";
import { /*CheckMailBoxResponse*/ } from "~/types/api";

export function* OurZustandInspectorSaga() {
  yield* [
    // takeLatest(UserActions.initialSaga.request.type, initialSaga),
  ];
}