import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    "--animate-duration": "1000ms"
  },
  input: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    border: '0',
    outline: 0,
    fontSize: 18,
    fontFamily: theme.fonts.f500.fontFamily,
    "&::placeholder": {
      color: theme.colors.inputPlaceholder
    },
    "&:-webkit-autofill": {
      WebkitBoxShadow: `0 0 0 100px ${theme.colors.black} inset`,
      WebkitTextFillColor: theme.colors.white,
    },
    "&:-webkit-autofill::first-line": {
      fontSize: 18,
    }
  },
  clearBtn: {
    position: 'absolute',
    right: 4,
    cursor: 'pointer',
    transform: "scale(0.65)"
  },
  rightIconContainer: {
    display: 'flex',
    position: 'absolute',
    right: theme.metrics.x2,
    zIndex: 3,
  },
});