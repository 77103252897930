import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <path d="M17 6c0 2.76-2.24 5-5 5S7 8.76 7 6s2.24-5 5-5 5 2.24 5 5zM4 17c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 3c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z" />
    <path d="M17.47 19.55 12 16.17l-5.47 3.38-1.06-1.7L11 14.44v-3.73h2v3.73l5.53 3.41z" />
  </svg>
)

export default SvgComponent
