import React, { memo } from "react";
import { useStyles } from "./ManualItemStyles";
import { Box, IconButton } from "@material-ui/core";
import { useThemeContext } from "../../theme";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "~/assets/icons";
import cn from "classnames";

export type ManualItemProps = {
  subtitle?: string;
  manualKey: string;
  color?: string;
  onCopyPress: () => void;
};

const ManualItem: React.FC<ManualItemProps> = ({
  subtitle,
  manualKey,
  onCopyPress,
  color,
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  return (
    <Box>
      <Box className={cn(s.container)}>
        {!!subtitle &&
          <p className={cn(s.subtitle)}>{`${subtitle}:`}</p>
        }
        <p className={cn(s.text)} style={{color: color ?? theme.colors.textMain}}>{manualKey}</p>
        <IconButton 
          className={cn(s.copyButton)} 
          onClick={(e) => {
            e.stopPropagation();
            onCopyPress();
          }}
          size="small" 
          title={t("Common.copyToClipboard")}
        >
          <CopyIcon color={theme.colors.textBright} />
        </IconButton>
      </Box>
    </Box>
  );
};

ManualItem.defaultProps = {};

const MemorizedComponent = memo(ManualItem);
export { MemorizedComponent as ManualItem };
