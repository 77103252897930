import { useLayoutEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppSettingsActions, COLOR_THEME_VARIANTS_ARRAY, ColorTheme } from '~/logic/appSettings/AppSettingsSlice';
import { getColorThemeFromLocalStorage } from '~/helpers/localStorage';
import { useDispatch } from 'react-redux';

export const useThemeParam = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  useLayoutEffect(() => {
    if (location.pathname.includes("/login") && params?.theme && COLOR_THEME_VARIANTS_ARRAY.indexOf(params.theme as any) >= 0) {
      const userTheme = getColorThemeFromLocalStorage();
      if (!userTheme)
        dispatch(AppSettingsActions.setColorTheme(params.theme as ColorTheme));
    }
  }, [location, params]);
}