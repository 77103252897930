import React, { ReactElement } from 'react';
import { useStyles } from './NoDataStyles';
import { Box } from '@material-ui/core';
import { CircularProgressColored } from '../CircularProgressColored';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type NoDataProps = {
  bgColor?: string;
  text?: string;
  textColor?: string;
  fontSize?: number;
  loading?: boolean;
  loaderSize?: number;
  bottomChildren?: ReactElement;
};

const NoData: React.FC<NoDataProps> = ({
  bgColor,
  text,
  textColor,
  fontSize,
  loading,
  loaderSize,
  bottomChildren
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  return (
    <Box className={cn(s.container)} style={{backgroundColor: bgColor}}>
      {loading ?
        <CircularProgressColored size={loaderSize} />
      :
        <Box className={cn(s.infoCont)}>
          <p className={cn(s.text)} style={{color: textColor ?? theme.colors.textMain, fontSize}}>
            {text ?? t("Common.noData")}
          </p>
          {!!bottomChildren &&
            bottomChildren
          }
        </Box>
      }
    </Box>
  );
};

NoData.defaultProps={
  fontSize: 28,
  loading: false,
  loaderSize: 16
}

export { NoData };