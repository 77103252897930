import { wildSandPaletteColors, gradients } from './colors';
import { emoji } from './emoji';
import { fonts } from './fonts';
import { iconsLightTheme } from './icons';
import { metrics } from './metrics';
import { metaData } from './metaData';

const hitSlop = { top: 10, left: 10, bottom: 10, right: 10 } as const;

const wildSandTheme = {
  fonts,
  colors: wildSandPaletteColors,
  gradients,
  metrics,
  icons: iconsLightTheme,
  hitSlop,
  emoji,
  isDark: false,
  metaData
} as const;

export { wildSandTheme };