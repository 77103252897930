import { useSelector, useDispatch } from "react-redux";
import { showToast } from "~/helpers/alertService";
import { UserActions } from "~/logic/user/UserSlice";
import { userSelector } from "~/logic/user/UserSelectors";
import { socketService } from "~/services/socketService";
import { InstructionPublicFields } from "~/types/api";
import { useTrackUserSentEvent } from "./analyticsCommon";
import { NavigationTab } from "~/types/types";
import { useTranslation } from "react-i18next";

export const useSendEvent = (tab: NavigationTab) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    allInstructions
  } = useSelector(userSelector);

  const {
    trackUserSentEvent
  } = useTrackUserSentEvent();

  const onPressSendEvent = (instructionId: string) => {
    const instruction = allInstructions.find((el) => el.instructionId === instructionId);
    
    if (!instruction) {
      showToast(t("Toast.unhandledErrorTryReloadPage"), "error");
      return;
    }

    const constructableEvent: InstructionPublicFields = JSON.parse(JSON.stringify(instruction));

    if (instruction.numOfArgs === 0) {
      socketService.emitEvent(constructableEvent);
      trackUserSentEvent(tab, 0);
    } else {
      dispatch(UserActions.setSingleEventConstructor(constructableEvent));
      dispatch(UserActions.setParamsModalVisible(true));
    }
  };

  return { onPressSendEvent };
}