import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={28}
    fill="none"
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M15.038 1.036c-.493-.203-1.12-.203-2.371-.203-1.252 0-1.878 0-2.372.203-.658.27-1.182.79-1.454 1.443-.125.299-.173.646-.193 1.152-.028.743-.412 1.431-1.061 1.803-.65.373-1.442.359-2.105.01-.451-.236-.778-.367-1.1-.41a2.702 2.702 0 0 0-1.987.529c-.424.323-.737.86-1.363 1.937C.406 8.576.092 9.114.023 9.64c-.093.701.099 1.41.532 1.971.198.256.477.472.909.741.635.396 1.043 1.07 1.043 1.815 0 .744-.409 1.418-1.043 1.814-.432.27-.71.485-.909.74a2.652 2.652 0 0 0-.532 1.972c.07.526.383 1.064 1.009 2.14.626 1.076.939 1.614 1.363 1.937.565.43 1.28.62 1.986.529.323-.043.65-.174 1.1-.41.664-.348 1.456-.362 2.106.01.65.372 1.033 1.06 1.061 1.804.02.506.068.852.193 1.15a2.677 2.677 0 0 0 1.454 1.444c.494.203 1.12.203 2.372.203 1.252 0 1.878 0 2.371-.203a2.676 2.676 0 0 0 1.455-1.443c.124-.299.173-.645.192-1.151.028-.744.412-1.432 1.061-1.804.65-.373 1.443-.359 2.106-.01.45.236.777.367 1.1.41a2.702 2.702 0 0 0 1.986-.529c.425-.323.738-.86 1.364-1.937.625-1.076.938-1.614 1.008-2.14a2.652 2.652 0 0 0-.532-1.971c-.198-.256-.476-.472-.908-.741-.635-.396-1.044-1.07-1.044-1.815 0-.744.409-1.418 1.044-1.814.432-.27.71-.485.908-.74.434-.562.625-1.27.532-1.972-.07-.526-.382-1.064-1.008-2.14s-.94-1.614-1.363-1.937a2.702 2.702 0 0 0-1.987-.528c-.322.042-.65.173-1.1.41-.663.348-1.456.362-2.105-.01-.65-.373-1.034-1.06-1.062-1.804-.02-.506-.068-.853-.192-1.152a2.677 2.677 0 0 0-1.455-1.443Zm-2.371 17.13c2.226 0 4.03-1.79 4.03-4 0-2.209-1.804-4-4.03-4s-4.03 1.791-4.03 4c0 2.21 1.804 4 4.03 4Z"
      clipRule="evenodd"
    />
  </svg>
)
export { SvgComponent as RemoteSettingsIconV2 }
