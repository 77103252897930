import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
  >
    <path
      fill="#FED337"
      fillRule="evenodd"
      d="M31.943 20.379a1.678 1.678 0 0 0-2.386 0l-3.87 3.901V5.701C25.688 4.761 24.933 4 24 4s-1.688.762-1.688 1.701v18.58l-3.869-3.902a1.678 1.678 0 0 0-2.386 0 1.711 1.711 0 0 0 0 2.406l6.75 6.806a1.678 1.678 0 0 0 2.386 0l6.75-6.806c.66-.664.66-1.742 0-2.406Z"
      clipRule="evenodd"
    />
    <path
      fill="#FED337"
      d="M36.932 23.85c-1.008 0-1.89.623-2.602 1.341l-6.75 6.806a5.033 5.033 0 0 1-7.16 0l-6.75-6.805c-.712-.72-1.594-1.341-2.602-1.341H6C6 33.874 14.059 42 24 42s18-8.126 18-18.15h-5.068Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M31.531 20.323a1.537 1.537 0 0 0-2.262 0L25.6 24.21V5.696C25.6 4.759 24.884 4 24 4c-.884 0-1.6.76-1.6 1.696V24.21l-3.669-3.888a1.537 1.537 0 0 0-2.262 0 1.768 1.768 0 0 0 0 2.398l6.4 6.782a1.537 1.537 0 0 0 2.262 0l6.4-6.782a1.768 1.768 0 0 0 0-2.398Z"
      clipRule="evenodd"
    />
  </svg>
)
export { SvgComponent as DropIcon }