import React, { useContext } from 'react';
import { useStyles } from './FloatingViewShortcutStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { FloatingViewContext } from '~/providers';
import { useTranslation } from 'react-i18next';
import { MaximizeWindowIcon } from "~/assets/icons";
import cn from 'classnames';

export type FloatingViewShortcutProps = {};

const FloatingViewShortcut: React.FC<FloatingViewShortcutProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    floatingViewMinimizedWithWindowAnimationDelay,
    maximizeFloatingWindow
  } = useContext(FloatingViewContext);

  return (
    <Box 
      className={cn(s.container, {[s.appeared]: floatingViewMinimizedWithWindowAnimationDelay})}
      title={t("Common.clickToMaximizeFloatingWindow")}
      onClick={maximizeFloatingWindow}
    >
      <MaximizeWindowIcon color={theme.colors.iconsMain} />
    </Box>
  );
};

FloatingViewShortcut.defaultProps={}

export { FloatingViewShortcut };