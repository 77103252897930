import React, { useMemo } from 'react';
import { useStyles } from './EventItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { InstructionTypeIndicator } from '~/components/InstructionTypeIndicator';
import { InstructionPrototype as InstructionPrototypeIndicator } from '../InstructionPrototype';
import { stringifyPrettySingleLine, prepareParams } from '~/helpers/strings';
import { CustomLoader } from '~/components/CustomLoader';
import { EventExecutionProgressBar } from '~/components/EventExecutionProgressBar';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { useDispatch } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { EventStateData } from '~/helpers/draggableHelpers';
import { useDependentValue } from '~/hooks';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { appSettingsSelector } from '~/logic/appSettings/AppSettingsSelectors';
import { SYNTAX_HIGHLIGHTING_TABLE } from '~/constants/syntaxHighlightingTable';
import { MenuIcon, CloseCircleIcon, CopyIcon } from "~/assets/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import cn from 'classnames';
import InheritIcon from './assets/inherit'
import HistoryIcon from './assets/history';

export type EventItemProps = {
  stateKey: string;
  eventIndex: number;
  event: EventStateData;
  prevEventOfScenario: EventStateData | undefined;
  isDragging: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  inheritanceType?: "PASSES" | "INHERITS";
  insideOfCondition?: boolean;
  onPressDelete: () => void;
  onPressFillWithAccountData: () => void;
  onPressFillWithLastParams: () => void;
};

const EventItem: React.FC<EventItemProps> = ({
  stateKey,
  eventIndex,
  event,
  prevEventOfScenario,
  isDragging,
  dragHandleProps,
  inheritanceType,
  insideOfCondition,
  onPressDelete,
  onPressFillWithAccountData,
  onPressFillWithLastParams
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    syntaxHighlighting
  } = useSelector(appSettingsSelector);

  const {
    currentTestAccount,
    paramsModalVisible,
    sentEventsExecutionStatusTable
  } = useSelector(userSelector);

  const eventIsExecuting = useMemo(() => {
    const key = event.externalId ?? "noId";
    return sentEventsExecutionStatusTable[key]?.executionStarted && !sentEventsExecutionStatusTable[key]?.executionCompleted;
  }, [event, sentEventsExecutionStatusTable]);

  const paramsText = useDependentValue(() => {
    if (event.touched && event.parametersDescription)
      return stringifyPrettySingleLine(prepareParams(event.params, event.parametersDescription));

    if (event.parametersDescription)
      return stringifyPrettySingleLine(event.parametersDescription);

    return '';
  }, [event]);

  const handleClickParams = () => {
    if (!paramsModalVisible) {
      dispatch(UserActions.setParamsModalState({key: stateKey, eventIndex}));
      dispatch(UserActions.setParamsModalVisible(true));
    }
  }

  return (
    <Box 
      className={cn(s.container)}
      style={{
        backgroundColor: isDragging? theme.colors.instructionItemBgDragging: theme.colors.bgSenary,
        border: isDragging? `1px dashed ${theme.colors.draggableItemBorderActive}`: `1px dashed ${theme.colors.draggableItemBorderUnactive}`,
        transitionDuration: '0.4s'
      }}
    >
      <Box className={cn(s.handleContainer)}>
        <div {...dragHandleProps} className={cn(s.handle)}>
          <MenuIcon color={theme.colors.textBright}/>
          {eventIsExecuting &&
            <Box className={cn(s.executingLoader)} title={t("Other.eventExecuting")}>
              <CustomLoader 
                width={theme.metrics.x05}
              />
            </Box>
          }
        </div>
      </Box>

      <Box className={cn(s.mainContent)}>
        <Box className={cn(s.rowBetween)}>
          <Box className={cn(s.block)}>
            <Box className={cn(s.rowCentered)}>
              <p className={cn(s.instructionIdText)}>
                {event.instructionId}
              </p>
              <InstructionPrototypeIndicator prototype={event.prototype}/>
              <InstructionTypeIndicator type={event.instructionType}/>
              {inheritanceType &&
                <Box className={cn(s.inheritIcon)} title={inheritanceType === "INHERITS" ? t("Other.inheritsParamsForwardDataEvent") : t("Other.passesResultToForwardDataEvent")}>
                  <InheritIcon fill={inheritanceType === "INHERITS" ? "green": "red"}/>
                </Box>
              }
              {insideOfCondition &&
                <Box className={cn(s.conditionIcon)} title={t("Other.thisEventWillBeExecutedIfConditionTrue")}>
                  <p className={cn(s.conditionSymbolText)}>
                    {`?`}
                  </p>
                </Box>
              }
            </Box>
            <p className={cn(s.descriptionText)}>
              {event.description ?? t("Common.noDescriptionProvided")}
            </p>
            
            {event.numOfArgs > 0 &&
              <Box className={cn(s.parametersContainer)} onClick={handleClickParams}>
                <p className={cn(s.paramsText)}>
                  {`${t("Common.params")}:`}
                </p>

                <SyntaxHighlighter 
                  language="javascript" 
                  style={SYNTAX_HIGHLIGHTING_TABLE.hljs[syntaxHighlighting]}
                  customStyle={{
                    margin: 0,
                    backgroundColor: 'transparent',
                    background: undefined,
                    paddingBottom: 5,
                  }}
                  className={cn(s.syntaxHighligher)}
                >
                  {paramsText}
                </SyntaxHighlighter>
              </Box>
            }
          </Box>

          <Box className={cn(s.rightColInstruments)}>
            <Box className={cn(s.deleteButton)} title={t("Other.removeEventFromConstructor")}>
              <IconButton onClick={() => onPressDelete()} aria-label="delete" size='small'>
                <Box style={{transform: 'scale(0.8)'}}>
                  <CloseCircleIcon color={theme.colors.mandy} bgColor={isDragging? theme.colors.instructionItemBgDragging: theme.colors.bgSenary}/>
                </Box>
              </IconButton>
            </Box>
            {(event.numOfArgs > 0 && currentTestAccount) &&
              <IconButton onClick={() => onPressFillWithAccountData()} size='small'>
                <Box className={cn(s.pasteButton)} title={t("Other.fillParamsWithAccDataIfPossible")}>
                  <CopyIcon color={theme.colors.iconsMain}/>
                </Box>
              </IconButton>
            }
            {!!event.lastParamsSaved &&
              <IconButton onClick={() => onPressFillWithLastParams()} size='small'>
                <Box className={cn(s.fillButton)} title={t("Other.fillParamsWithPrevValues")}>
                  <HistoryIcon color={theme.colors.iconsMain}/>
                </Box>
              </IconButton>
            }
          </Box>
        </Box>
        
        <EventExecutionProgressBar 
          event={event}
          prevEventOfScenario={prevEventOfScenario}
        />
        {/* <Box className={cn(s.bottomDecorLine)}/> */}
      </Box>
    </Box>
  );
};

EventItem.defaultProps={
  insideOfCondition: false
}

export { EventItem };