import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill={props.color}
  >
    <path d="M31 0H1a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1ZM2 2h12.36C11.89 7.34 11 15.52 11 15.9a1 1 0 0 0 .25.77A1 1 0 0 0 12 17h4.89a29.9 29.9 0 0 0 .25 7c-.37 0-.75.05-1.14.05a14.07 14.07 0 0 1-10.22-4.67 1 1 0 0 0-1.4-.16 1 1 0 0 0-.16 1.41A15.87 15.87 0 0 0 16 26c.53 0 1.05 0 1.55-.08A18.35 18.35 0 0 0 19.07 30H2Zm28 28h-8.61a15.57 15.57 0 0 1-1.86-4.42 15.91 15.91 0 0 0 8.25-4.95 1 1 0 1 0-1.56-1.25 14.13 14.13 0 0 1-7.09 4.24 27.91 27.91 0 0 1-.13-7.47A1 1 0 0 0 18 15h-4.87c.34-2.59 1.36-9.12 3.46-13H30Z" />
    <path d="M8 13a1 1 0 0 0 1-1V9a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1ZM24 13a1 1 0 0 0 1-1V9a1 1 0 0 0-2 0v3a1 1 0 0 0 1 1Z" />
  </svg>
)
export { SvgComponent as MacosIcon }
