import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SyntaxHighlighting, SyntaxHighlightingVariant } from '~/constants/syntaxHighlightingTable';
import { getRandomInt } from '~/helpers/random';
import { theme } from '~/theme/default/theme';
import { CurrentLangT } from '~/types/types';

export type ColorTheme = 'balticSea' | 'blueBayoux' | 'wildSand' | 'white'; 

export const COLOR_THEME_VARIANTS_ARRAY: readonly ColorTheme[] = ['balticSea', 'blueBayoux', 'wildSand', 'white'] as const;
export const COLOR_THEME_NAMES_TABLE: {[key in ColorTheme]: string} = {
  blueBayoux: "Blue Bayoux",
  balticSea: "Baltic Sea",
  wildSand: "Wild Sand",
  white: "White"
} as const;

export const APP_LANGUAGE_VARIANTS_ARRAY: readonly CurrentLangT[] = ["en", "ru", "es", "ua"];
export const APP_LANGUAGE_NAMES_TABLE: {[key in CurrentLangT]: string} = {
  en: "English",
  ru: "Русский",
  es: "Español",
  ua: "Український"
};

type AppSettingsState = {
  colorTheme: ColorTheme;
  initialSetupDone: boolean;
  manuallyShowMainPageLoader: boolean;
  currentLang: CurrentLangT;
  syntaxHighlightingVariant: SyntaxHighlightingVariant;
  syntaxHighlighting: SyntaxHighlighting;
};

const initialAppSettingsState: AppSettingsState = {
  // Фактическая тема в данный момент времени (может быть только light или dark). Нужно для рендера некоторых вещей
  colorTheme: "balticSea",
  // Завершена ли сага getAppSettingsFromLocalStorage
  initialSetupDone: false,
  // Показывать ли большой лоадер на main странице
  manuallyShowMainPageLoader: false,
  // Язык GUI
  currentLang: "en",
  // Выбранная подсветка синтаксиса (вариант)
  syntaxHighlightingVariant: "one",
  // Подсветка синтаксиса (ключ к таблице SYNTAX_HIGHLIGHTING_TABLE для соответствующего хайлайтера)
  syntaxHighlighting: "oneDark"
};

const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: initialAppSettingsState,
  reducers: {
    appStartSettingsSetupDone(state, action: PayloadAction<boolean>) {
      state.initialSetupDone = action.payload;
    },
    setColorTheme(state, action: PayloadAction<ColorTheme>) {
      state.colorTheme = action.payload;
      document.body.style.backgroundColor = theme.colors[`${action.payload}ThemeGlobalBg`];
      
      state.syntaxHighlighting = theme.metaData[`${action.payload}ThemeIsDarkSyntaxHighlightingDefault`] ? `${state.syntaxHighlightingVariant}Dark` : `${state.syntaxHighlightingVariant}Light`;
    },
    selectRandomTheme(state) {
      const randomTheme = COLOR_THEME_VARIANTS_ARRAY[getRandomInt(COLOR_THEME_VARIANTS_ARRAY.length)];
      state.colorTheme = randomTheme;
      document.body.style.backgroundColor = theme.colors[randomTheme];
    },
    setManuallyShowMainPageLoader(state, action: PayloadAction<boolean>) {
      state.manuallyShowMainPageLoader = action.payload;
    },
    setCurrentLang(state, action: PayloadAction<CurrentLangT>) {
      state.currentLang = action.payload;
    },
    setSyntaxHighlightingVariant(state, action: PayloadAction<SyntaxHighlightingVariant>) {
      state.syntaxHighlightingVariant = action.payload;
      state.syntaxHighlighting = theme.metaData[`${state.colorTheme}ThemeIsDarkSyntaxHighlightingDefault`] ? `${action.payload}Dark` : `${action.payload}Light`;
    },
  }
})

const appSettingsReducer = appSettingsSlice.reducer;
const AppSettingsActions = {
  ...appSettingsSlice.actions,
  getAppSettingsFromLocalStorage: createAction(`appSettings/getAppSettingsFromLocalStorage`),
};

export { appSettingsReducer, AppSettingsActions };