import React, { useMemo } from 'react';
import { useStyles } from './StateInspectorHeaderStyles';
import { Box, IconButton, FormControl, Select, MenuItem } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { TrashIcon, AlertTriangleIcon } from '~/assets/icons';
import { handleOpenLink } from '~/helpers/windowHelpers';
import { capitalizeString } from '~/helpers/strings';
import { CONFIG } from '~/config';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { SelectItem } from '~/types/types';
import { OpenInFloatingViewButton } from '../FloatingView';
import { FloatingViewContent } from '~/providers/FloatingViewContextProvider/types';
import useMeasure from 'react-use-measure';
import cn from 'classnames';

export type HeaderProps = {
  pinnedMode: boolean;
  stateCopyNumOfLines: number;
  stateCopyTimestamp: number | undefined;
  stateManagerName?: string;
  dataName?: string;
  selectValue?: string;
  selectLabel?: string;
  selectItems?: SelectItem[];
  floatingViewContent?: FloatingViewContent;
  onSelectChange?: (value: string) => void;
  onPress: () => void;
  onPressClear: () => void;
};

const Header: React.FC<HeaderProps> = ({
  pinnedMode,
  stateCopyNumOfLines,
  stateCopyTimestamp,
  stateManagerName,
  dataName,
  selectValue,
  selectLabel,
  selectItems,
  floatingViewContent,
  onSelectChange,
  onPress,
  onPressClear
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [containerRef, containerBounds] = useMeasure();

  const timeToShow = useTimeToShow(stateCopyTimestamp);

  const alertText = useMemo(() => {
    if (stateCopyNumOfLines > 15000)
      return `${stateManagerName} ${dataName} ${t("Other.isVeryLarge")} (${stateCopyNumOfLines} ${t("Other.lines")}). ${t("Other.ifInspectorWorksSlowerThanYouExpect")}`
    if (stateCopyNumOfLines > 10000)
      return `${stateManagerName} ${dataName} ${t("Other.consistsOf")} ${stateCopyNumOfLines} ${t("Other.lines")}. ${t("Other.ifInspectorWorksSlowerThanYouExpect")}`;

    return "";
  }, [stateCopyNumOfLines, stateManagerName, dataName, t]);

  return (
    <div ref={containerRef} className={cn(s.headerContainer)} onClick={onPress}>
      <Box className={cn(s.headerLeft)}>
        {selectItems &&
          <Box className={cn(s.selectContainer)}>
            <FormControl required={true} variant={"standard"}>
              <Select
                labelId="state-inspector-header-select-label"
                id="state-inspector-header-select"
                classes={{root: cn(s.select), icon: cn(s.selectIcon)}}
                value={selectValue}
                label={selectLabel}
                title={selectLabel}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    className: cn(s.paper)
                  }
                }}
                color={"secondary"}
                disableUnderline={true}
                onClick={(event) => event.stopPropagation()}
                onChange={(event: any) => onSelectChange?.(event.target.value)}
              >
                {selectItems.map((item) => (
                  <MenuItem   
                    key={`SIH_ITEM_${item.id}`} 
                    value={item.id}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
        {containerBounds.width >= 1150 &&
          <>
            {stateCopyTimestamp !== undefined &&
              <p 
                className={cn(s.timestampText)}
                title={`${capitalizeString(dataName!)} ${t("Other.notUpdating")}? ${CONFIG.LINKS.FAQ}`}
              >
                {`${t("Other.lastUpdate")}: ${timeToShow}`}
              </p>
            }
            {(!pinnedMode && stateCopyNumOfLines !== 0) &&
              <p 
                className={cn(s.timestampText)}
                style={{marginLeft: theme.metrics.x4}}
                title={`${t("Other.amountOfLinesPrettyJSON")} ${dataName}`}
              >
                {`${t("Other.totalLines")}: ${stateCopyNumOfLines}`}
              </p>
            }
          </>
        }
      </Box>
      <p className={cn(s.headerText)}>
        {pinnedMode ? `${t("Other.clickHereToViewFull")} ${dataName}` : t("Other.clickHereToViewPinned")}
      </p>
      <Box className={cn(s.headerRight)}>
        {stateCopyNumOfLines > 10000 &&
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              handleOpenLink(CONFIG.LINKS.FAQ)
            }} 
            size='small'
            className={cn(s.headerRightButton)}
            title={alertText}
          >
            <AlertTriangleIcon color={theme.colors.bgContrastColorText}/>
          </IconButton>
        }

        {!!floatingViewContent &&
          <OpenInFloatingViewButton 
            content={floatingViewContent} 
            iconColor={theme.colors.bgContrastColorText}
          />
        }

        <IconButton 
          onClick={(e) => {
            e.stopPropagation();
            onPressClear();
          }} 
          size='small'
          className={cn(s.headerRightButton)}
          title={`${t("Common.clear")} ${stateManagerName} ${dataName}`}
        >
          <TrashIcon color={theme.colors.bgContrastColorText}/>
        </IconButton>
      </Box>
    </div>
  );
};

Header.defaultProps={
  stateManagerName: "redux",
  dataName: "state"
}

export { Header as StateInspectorHeader };