import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import { handleFoldChange, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { deleteReduxPinnedPathsFromLocalStorage, saveReduxPinnedPathsToLocalStorage } from "~/helpers/localStorage";
import { showToast } from "~/helpers/alertService";
import { StateManagerStateCopy, StateManagerStateCopyWithTimestamp } from '~/types/types';
import i18next from 'i18next';

const MAX_PINNED_PATHS = 20;

type HandleFoldChangePayload = {
  lineIndex: number;
  newOpened: boolean;
};

type PinPathPayload = {
  path: string;
  apiKey: string;
}

type UnpinPathPayload = {
  path: string;
  apiKey: string;
}

type ClearPinnedPathsPayload = {
  apiKey: string;
}

type OurReduxInspectorState = {
  reduxStateCopy: StateManagerStateCopy | null;
  reduxStateCopyNumOfLines: number;
  reduxStateCopyTimestamp: number | undefined;
  pinnedPaths: string[];
  linesWithMarkup: LinesWithMarkup[];
  gotSomethingNewIndicator: boolean;
};

const initialOurReduxInspectorState: OurReduxInspectorState = {
  reduxStateCopy: null,
  reduxStateCopyNumOfLines: 0,
  reduxStateCopyTimestamp: undefined,
  pinnedPaths: [],
  linesWithMarkup: [],
  gotSomethingNewIndicator: false
};

const ourReduxInspectorSlice = createSlice({
  name: 'ourReduxInspector',
  initialState: initialOurReduxInspectorState,
  reducers: {
    setReduxStateCopy: {
      reducer(state, action: PayloadAction<StateManagerStateCopyWithTimestamp>) {
        const reduxStateCopy = {state: action.payload.state};
        const s = JSON.stringify(reduxStateCopy, null, 2);
        let prevData;
        if (state.reduxStateCopy && state.linesWithMarkup.length > 0) {
          prevData = {
            linesWithMarkup: state.linesWithMarkup
          }
        }
  
        const newLinesWithMarkup = markupJSON(s, reduxStateCopy, prevData);
        state.linesWithMarkup = newLinesWithMarkup;
        state.reduxStateCopy = reduxStateCopy;
        state.reduxStateCopyNumOfLines = newLinesWithMarkup.length;
        state.reduxStateCopyTimestamp = action.payload.timestamp;
      },
      prepare: prepareAutoBatched<StateManagerStateCopyWithTimestamp>(),
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    handleInspectorFoldChange(state, action: PayloadAction<HandleFoldChangePayload>) {
      const { lineIndex, newOpened } = action.payload;

      state.linesWithMarkup = handleFoldChange(state.linesWithMarkup, lineIndex, newOpened);
    },
    setPinnedPaths(state, action: PayloadAction<string[]>) {
      state.pinnedPaths = action.payload;
    },
    pinPath(state, action: PayloadAction<PinPathPayload>) {
      if (state.pinnedPaths.length === MAX_PINNED_PATHS) {
        showToast(`${i18next.t("Toast.pinsLimitReached")} ${i18next.t("Toast.youCannotPinMoreThan")} ${MAX_PINNED_PATHS} ${i18next.t("Common.objects")}.`, "warning");
        return;
      }

      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.add(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveReduxPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    unpinPath(state, action: PayloadAction<UnpinPathPayload>) {
      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.delete(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveReduxPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    clearPinnedPaths(state, action: PayloadAction<ClearPinnedPathsPayload>) {
      state.pinnedPaths = [];
      deleteReduxPinnedPathsFromLocalStorage(action.payload.apiKey);
    },
    clearAllState: {
      reducer() {
        return {
          ...initialOurReduxInspectorState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearReduxInspector: {
      reducer(state) {
        state.reduxStateCopy = initialOurReduxInspectorState.reduxStateCopy;
        state.reduxStateCopyNumOfLines = initialOurReduxInspectorState.reduxStateCopyNumOfLines;
        state.reduxStateCopyTimestamp = initialOurReduxInspectorState.reduxStateCopyTimestamp;
        state.linesWithMarkup = initialOurReduxInspectorState.linesWithMarkup;
        state.gotSomethingNewIndicator = initialOurReduxInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<void>(),
    }
  }
})

const ourReduxInspectorReducer = ourReduxInspectorSlice.reducer;
const OurReduxInspectorActions = ourReduxInspectorSlice.actions;

export { ourReduxInspectorReducer, OurReduxInspectorActions };