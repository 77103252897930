import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const use500PageAnalytics = () => {
  const trackTryAgain = () => {
    segmentAnalytics.track("500Page try again pressed");
  };

  useEffect(() => {
    segmentAnalytics.page("500Page", "500Page");
  }, []);

  return {
    trackTryAgain
  };
};