import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import { handleFoldChange, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { deleteMobxPinnedPathsFromLocalStorage, saveMobxPinnedPathsToLocalStorage } from "~/helpers/localStorage";
import { showToast } from "~/helpers/alertService";
import { StateManagerStateCopy, StateManagerStateCopyWithTimestamp } from '~/types/types';
import i18next from 'i18next';

const MAX_PINNED_PATHS = 20;

type HandleFoldChangePayload = {
  lineIndex: number;
  newOpened: boolean;
};

type PinPathPayload = {
  path: string;
  apiKey: string;
}

type UnpinPathPayload = {
  path: string;
  apiKey: string;
}

type ClearPinnedPathsPayload = {
  apiKey: string;
}

type OurMobxInspectorState = {
  mobxStateCopy: StateManagerStateCopy | null;
  mobxStateCopyNumOfLines: number;
  mobxStateCopyTimestamp: number | undefined;
  pinnedPaths: string[];
  linesWithMarkup: LinesWithMarkup[];
  gotSomethingNewIndicator: boolean;
};

const initialOurMobxInspectorState: OurMobxInspectorState = {
  mobxStateCopy: null,
  mobxStateCopyNumOfLines: 0,
  mobxStateCopyTimestamp: undefined,
  pinnedPaths: [],
  linesWithMarkup: [],
  gotSomethingNewIndicator: false
};

const ourMobxInspectorSlice = createSlice({
  name: 'ourMobxInspector',
  initialState: initialOurMobxInspectorState,
  reducers: {
    setMobxStateCopy: {
      reducer(state, action: PayloadAction<StateManagerStateCopyWithTimestamp>) {
        const mobxStateCopy = {state: action.payload.state};
        const s = JSON.stringify(mobxStateCopy, null, 2);
        let prevData;
        if (state.mobxStateCopy && state.linesWithMarkup.length > 0) {
          prevData = {
            linesWithMarkup: state.linesWithMarkup
          }
        }
  
        const newLinesWithMarkup = markupJSON(s, mobxStateCopy, prevData);
        state.linesWithMarkup = newLinesWithMarkup;
        state.mobxStateCopy = mobxStateCopy;
        state.mobxStateCopyNumOfLines = newLinesWithMarkup.length;
        state.mobxStateCopyTimestamp = action.payload.timestamp;
      },
      prepare: prepareAutoBatched<StateManagerStateCopyWithTimestamp>(),
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    handleInspectorFoldChange(state, action: PayloadAction<HandleFoldChangePayload>) {
      const { lineIndex, newOpened } = action.payload;

      state.linesWithMarkup = handleFoldChange(state.linesWithMarkup, lineIndex, newOpened);
    },
    setPinnedPaths(state, action: PayloadAction<string[]>) {
      state.pinnedPaths = action.payload;
    },
    pinPath(state, action: PayloadAction<PinPathPayload>) {
      if (state.pinnedPaths.length === MAX_PINNED_PATHS) {
        showToast(`${i18next.t("Toast.pinsLimitReached")} ${i18next.t("Toast.youCannotPinMoreThan")} ${MAX_PINNED_PATHS} ${i18next.t("Common.objects")}.`, "warning");
        return;
      }

      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.add(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveMobxPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    unpinPath(state, action: PayloadAction<UnpinPathPayload>) {
      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.delete(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveMobxPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    clearPinnedPaths(state, action: PayloadAction<ClearPinnedPathsPayload>) {
      state.pinnedPaths = [];
      deleteMobxPinnedPathsFromLocalStorage(action.payload.apiKey);
    },
    clearAllState: {
      reducer() {
        return {
          ...initialOurMobxInspectorState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearMobxInspector: {
      reducer(state) {
        state.mobxStateCopy = initialOurMobxInspectorState.mobxStateCopy;
        state.mobxStateCopyNumOfLines = initialOurMobxInspectorState.mobxStateCopyNumOfLines;
        state.mobxStateCopyTimestamp = initialOurMobxInspectorState.mobxStateCopyTimestamp;
        state.linesWithMarkup = initialOurMobxInspectorState.linesWithMarkup;
        state.gotSomethingNewIndicator = initialOurMobxInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<void>(),
    }
  }
})

const ourMobxInspectorReducer = ourMobxInspectorSlice.reducer;
const OurMobxInspectorActions = ourMobxInspectorSlice.actions;

export { ourMobxInspectorReducer, OurMobxInspectorActions };