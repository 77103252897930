import { configureStore, autoBatchEnhancer } from '@reduxjs/toolkit';
import { CONFIG } from '../config';
import { rootReducer } from './rootReducer';
import { enableMapSet } from 'immer';
import { listenerMiddleware } from './listenerMiddleware';
import createSagaMiddleware, {
  SagaMiddleware,
  SagaMiddlewareOptions,
} from 'redux-saga';

enableMapSet();

let sagaMiddleware: SagaMiddleware;

export function getSagaMiddleware(): SagaMiddleware {
  return sagaMiddleware;
}

function getOurMiddleware() {
  const middleware = [];
  let options: SagaMiddlewareOptions;

  if (/* __DEV__ || */ CONFIG.USE_REACTOTRON) {
    const reactotron = require('../ReactotronConfig').reactotron;
    const sagaMonitor = reactotron.createSagaMonitor();
    options = {sagaMonitor};
  } else {
    options = {};
  }
  middleware.push(listenerMiddleware.middleware);
  sagaMiddleware = createSagaMiddleware(options);
  middleware.push(sagaMiddleware);
  return middleware;
}

function getOurEnhancers() {
  const enhancers = [];

  if (/* __DEV__ || */ CONFIG.USE_REACTOTRON) {
    const reactotron = require('../ReactotronConfig').reactotron;
    enhancers.push(reactotron.createEnhancer());
  }

  enhancers.push(autoBatchEnhancer());

  return enhancers;
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getOurMiddleware(),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: ['user/setActionModalState'],
  //     ignoredActionPaths: ['payload.navigate'],
  //     ignoredPaths: ['user.actionModalState'],
  //   },
  // }).concat(getOurMiddleware()),
  enhancers: getOurEnhancers(),
  devTools: CONFIG.RUN_MODE === "DEVELOPMENT"
});

export type RootStore = typeof store;