import { makeStyles } from '@material-ui/core/styles';
import { CONFIG } from '~/config';
import { Theme } from '~/theme';

export const NAVIGATION_BAR_TAB_HEIGHT = 64;

export const useStyles = (theme: Theme) => makeStyles({
  tab: {
    display: "flex",
    position: 'relative',
    height: NAVIGATION_BAR_TAB_HEIGHT,
    width: '100%',
    minWidth: CONFIG.NAV_BAR_WIDTH - theme.metrics.x05,
    maxWidth: CONFIG.NAV_BAR_WIDTH,
    alignItems: 'center',
    marginBottom: 8,
  },
  somethingNewIndicatorContainer: {
    display: "flex",
    position: 'absolute',
    right: 4,
    top: 4
  },
  tabButton: {
    display: "flex",
    height: "100%",
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    paddingTop: theme.metrics.x,
    paddingBottom: theme.metrics.x,
    borderRadius: theme.metrics.x05,
    transitionDuration: "0.25s"
  },
  tabButtonHovered: {
    backgroundColor: theme.colors.white10
  },
  tabTitle: {
    margin: 0,
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 14,
    textAlign: 'center',
    pointerEvents: "none",
    userSelect: "none"
  },
  mainTabIcon: {
    display: "flex",
    // width: "100%",
    height: 36
  },
  mailTabIconV2: {
    display: "flex",
    width: 28,
    height: 30
  },
  reduxTabIcon: {
    display: "flex",
    width: "76%",
    height: 29,
    marginBottom: 4
  },
  zustandTabIcon: {
    display: "flex",
    width: "78%",
    height: 31,
    marginBottom: 4
  },
  tanStackQueryTabIcon: {
    display: "flex",
    width: "78%",
    height: 36,
    marginBottom: 4
  },
  contextTabIcon: {
    display: "flex",
    width: "85%",
    height: 36,
    marginBottom: 4
  },
  mobxTabIcon: {
    display: "flex",
    width: "78%",
    height: 30,
    marginBottom: 4
  },
  remoteSettingsTabIconV2: {},
  otherTabIcon: {
    display: "flex",
    width: "100%",
    height: 34,
    marginBottom: 4
  },
  networkTabIconV2: {},
});