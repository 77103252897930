import { useEffect, useState } from "react";

export const useDependentState = <DependentValueType = any>(
  processor: () => any,
  deps: ReadonlyArray<any>,
): [DependentValueType, React.Dispatch<any>] => {
  const [value, setValue] = useState(processor());

  useEffect(() => {
    const updateValue = () => {
      setValue(processor());
    };

    updateValue();
  }, deps);

  return [value, setValue];
};