import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 21h8M20.065 7.394 7.1 20.411A2 2 0 0 1 5.683 21h-1.68A1.007 1.007 0 0 1 3 19.992V18.3a2 2 0 0 1 .583-1.412l12.969-13.02c3.011-2.52 6.023 1.008 3.513 3.527ZM15.31 5.31l3.417 3.418"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export { SvgComponent as EditIcon }