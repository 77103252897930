import React, { useState, useRef, useEffect } from 'react';
import { useStyles } from './LoginPageStyles';
import { Box, Typography, Button } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { CustomButton } from '~/components/CustomButton';
import { CONFIG } from '~/config';
import { useLoginPageAnalytics } from './useLoginPageAnalytics';
import { validateEmail, validatePassword } from '~/helpers/validationHelper';
import { useDispatch, useSelector } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { useNavigate } from 'react-router-dom';
import { userSelector } from '~/logic/user/UserSelectors';
import { useThemeContext } from '~/theme';
import { appSettingsSelector } from '~/logic/appSettings/AppSettingsSelectors';
import { useTranslation } from 'react-i18next';
import { useThemeParam, useCheckMobileScreen } from '~/hooks';
import { showToast } from '~/helpers/alertService';
import classNames from 'classnames';
import ReCAPTCHA from "react-google-recaptcha";

export type LoginPageProps = {};

const LoginPage: React.FC<LoginPageProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useThemeParam();

  const {
    colorTheme
  } = useSelector(appSettingsSelector);

  const {
    userToken,
    auth: {
      fetching: authFetching
    },
    resendOtp: {
      fetching: resendOtpFetching
    }
  } = useSelector(userSelector);

  const {
    trackUserAuthAttempt
  } = useLoginPageAnalytics();

  const isMobileScreen = useCheckMobileScreen();

  const [hideErrors, setHideErrors] = useState(true);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const goBtnDisabled = !captchaValue;

  const onPressGo = () => {
    if (captchaValue && validateEmail(email) && validatePassword(password)) {
      captchaRef?.current?.reset();

      if (forgotPasswordMode) {
        dispatch(UserActions.resendOtp.request({email, successCallback: () => navigate("/otp")}));
        dispatch(UserActions.setOtpCallback(
          (code: string) => dispatch(
            UserActions.forgotPassword.request({
              email,
              password,
              reCaptchaToken: captchaValue,
              code,
              navigate
            })
          )
        ));  
      } else {
        dispatch(UserActions.setOtpCallback(null));
        dispatch(UserActions.auth.request({
          email,
          password,
          reCaptchaToken: captchaValue,
          navigate
        }));
        trackUserAuthAttempt(email);
      }
    } else {
      setHideErrors(false);
    }
  }

  const handleForgotPassword = () => {
    setForgotPasswordMode((m) => !m);
  }

  const redirectToMainIfHaveToken = () => {
    if (userToken)
      navigate("/main");
    else if (isMobileScreen)
      showToast(t("Toast.thisPanelIsDesignedForDesktop"), "info", {autoClose: 1e4});
  }

  // ComponentDidMount
  useEffect(() => {
    redirectToMainIfHaveToken();
  }, []);

  return (
    <Box className={classNames(ds.fullSpaceCentered)} style={{backgroundColor: theme.colors.bgSecondary}}>
      <Box className={classNames(s.formCont)}>
        <Box className={classNames(ds.fullWidthCenteredCol)}>
          <Typography variant="h5" paragraph className={classNames(s.mainText, s.bolder)}>
            {forgotPasswordMode ? t("LoginPage.passwordRecovery") : CONFIG.PRODUCT_NAME}
          </Typography>
          {!forgotPasswordMode &&
            <Typography variant="h6" paragraph className={classNames(s.mainText)} style={{margin: 0}}>
              {`${t("LoginPage.login")} / ${t("LoginPage.registration")}`}
            </Typography>
          }

          <Box className={classNames(ds.inputContainterMarginTop)}>
            <TextInput 
              value={email}
              inputType={"email"}
              onChangeText={(text) => setEmail(text)}
              label={t("LoginPage.yourEmail")}
              labelColor={theme.colors.textMain}
              placeholder={"example@service.com"}
              validationOk={hideErrors || validateEmail(email)}
              onErrorAnimationEnd={() => setHideErrors(true)}
            />
          </Box>

          <Box className={classNames(ds.inputContainterMarginTop)}>
            <TextInput 
              value={password}
              onChangeText={(text) => setPassword(text)}
              label={forgotPasswordMode ? t("LoginPage.newPassword") : t("LoginPage.yourPassword")}
              labelColor={theme.colors.textMain}
              placeholder={t("LoginPage.enterPassword")}
              secure={true}
              validationOk={hideErrors || validatePassword(password)}
              onErrorAnimationEnd={() => setHideErrors(true)}
            />
          </Box>
        </Box>

        <ReCAPTCHA 
          sitekey={CONFIG.RE_CAPTCHA.SITE_KEY}
          ref={captchaRef}
          onChange={(token) => setCaptchaValue(token)}
          theme={colorTheme === "balticSea" ? "dark" : "light"}
        />


        <Box className={classNames(ds.fullWidthCenteredCol)}>
          <Box className={classNames(s.bottomBtn)} title={goBtnDisabled ? t("Common.pleaseFillAllFields") : t("Common.go")}>
            <CustomButton 
              onPress={onPressGo}
              title={t("Common.go")}
              spinner={forgotPasswordMode ? resendOtpFetching : authFetching}
              disabled={goBtnDisabled}
              colorActive={theme.colors.formButtonTextActive}
              bgColorActive={theme.colors.formButtonBgColorActive}
            />
          </Box>

          <Button 
            variant="text" style={{color: theme.colors.formContrastColor, marginTop: 8, fontFamily: theme.fonts.f600.fontFamily, fontSize: 14}}
            onClick={handleForgotPassword}
          >
            {forgotPasswordMode ? t("LoginPage.iRememberPassword") : t("LoginPage.forgotPassword")}
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

LoginPage.defaultProps={}

export { LoginPage };