import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%',
    padding: 20
  },
  bottomBorder: {
    border: "1px solid #444444",
    borderWidth: 0,
    borderBottomWidth: 1
  },
  orangeText: {
    margin: 0,
    color: theme.colors.crail,
    fontFamily: theme.fonts.f400.fontFamily
  },
  grayTextSmall: {
    margin: 0,
    fontSize: 14,
    color: theme.colors.mobster,
    fontFamily: theme.fonts.f400.fontFamily
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "27%"
  },
  center: {
    display: "flex",
    width: "68%"
  },
  right: {
    display: "flex",
    width: "5%",
    justifyContent: "flex-end"
  },
  unpinButton: {
    display: "flex",
    width: 24,
    height: 24
  }
});