import React, { memo } from 'react';
import { useStyles } from './Page404Styles';
import { use404PageAnalytics } from './use404PageAnalytics';
import { Box } from '@material-ui/core';
import { CustomButton } from '~/components/CustomButton';
import { useNavigate } from "react-router-dom";
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type Page404Props = {};

const Page404: React.FC<Page404Props> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const navigate = useNavigate();
  const { t } = useTranslation();

  use404PageAnalytics();

  return (
    <Box className={cn(s.container)}>
      <Box className={cn(s.center)}>
        <p className={cn(s.whiteText)}>
          {t("Page404.pageNotFound")}
        </p>
        <Box className={cn(s.button)}>
          <CustomButton 
            title={t("Page404.goToHome")}
            onPress={() => navigate("/main")}
          />
        </Box>
      </Box>
    </Box>
  );
};

Page404.defaultProps={}

const MemorizedComponent = memo(Page404);
export { MemorizedComponent as Page404 };