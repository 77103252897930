import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../logic/user/UserSelectors';
import moment from "moment";

export const useTimeToShow = (timestamp: number | undefined): string => {
  const {
    appSettings
  } = useSelector(userSelector);

  const timeToShow = useMemo(() => {
    if (timestamp === undefined)
      return "";

    const hoursFormat = appSettings.twentyFourHoursTimeFormat.value ? "HH" : "hh";
    const secondsFormat = appSettings.enableMsOnNetworkTimeline.value ? "ss.SSS" : "ss";
    return moment(timestamp).format(`${hoursFormat}:mm:${secondsFormat}`);
  }, [timestamp, appSettings]);

  return timeToShow;
}