import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';
import { FLOATING_VIEW_ANIMATIONS_DURATIONS } from "~/providers";

export const useStyles = (theme: Theme, ) => makeStyles({
  draggable: {
    display: "flex",
    zIndex: 5,
    position: "absolute",
    alignSelf: "center",
    borderRadius: theme.metrics.x2,
    // backgroundColor: 'blue'
    // overflow: "hidden"
  },
  hidden: {
    visibility: "hidden"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    // position: 'absolute',
    // zIndex: 5,
    width: "100%",
    height: "100%",
    padding: theme.metrics.x2,
    borderRadius: theme.metrics.x2,
    overflow: 'hidden',
    // backgroundColor: 'rgba(255, 255, 255, 0.1)',
    // backgroundColor: 'rgba(0, 0, 0, 0.1)'
    transformOrigin: "bottom right",
    transition: `transform ${FLOATING_VIEW_ANIMATIONS_DURATIONS.VIEW_MINIMIZATION.CSS} linear`
  },
  blurFilter: {
    backdropFilter: `blur(6px) saturate(3) brightness(${1 + 0.12 * (theme.isDark ? 1 : -1)})`
  },
  contentContainer: {
    display: "flex",
    position: "relative",
    width: "100%",
    height: "100%",
    borderRadius: theme.metrics.x,
    overflow: "hidden"
  },
  headerCont: {
    display: "flex",
    width: "100%",
    height: 40,
    justifyContent: "flex-end"
  },
  headerBtn: {
    cursor: "pointer",
    marginLeft: theme.metrics.x3
  }
});