import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 -0.5 20 20"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M13 3a1 1 0 0 1 1-1h1V1a1 1 0 0 1 2 0v1h1a1 1 0 0 1 0 2h-1v1a1 1 0 0 1-2 0V4h-1a1 1 0 0 1-1-1m-1 10.221a2 2 0 1 1-4 0 2 2 0 0 1 4 0M10 17a9.59 9.59 0 0 1-7.601-3.779A9.589 9.589 0 0 1 10 9.441a9.589 9.589 0 0 1 7.601 3.78A9.59 9.59 0 0 1 10 17m0-9.559c-4.276 0-8.001 2.328-10 5.78C1.999 16.672 5.724 19 10 19s8.001-2.328 10-5.779c-1.999-3.452-5.724-5.78-10-5.78"
    />
  </svg>
)
export { SvgComponent as ViewMoreIcon }
