import { ObjectT } from "~/types/types";
import { getDataByPath, getLenIfPossible } from "./jsonHelpers";

// HTML5: <!DOCTYPE html>
// HTML4.01: <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
// XHTML1: <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">
const DOCTYPE_HTML_DECLARATION = "<!DOCTYPE html"; // is not case-sensitive, should be compared in lowercase
const DOCTYPE_HTML_DECLARATION_LOWERCASE = DOCTYPE_HTML_DECLARATION.toLowerCase();
const MIN_LINE_LENGTH_THAT_MIGHT_CONTAIN_HTML = 31; // console.log(`"<!DOCTYPE html><html>a</html>"`.length) // html5 variant here

type BraceType = "curly" | "square";

export type LinesWithMarkup = {
  isOB?: boolean; // isOpeningBrace
  showArrow?: boolean; // showArrowButton
  showPin?: boolean; // showPinButton
  containsHTML?: boolean; // Is DOCTYPE html detected
  // Next three params are defined only if isOB === true
  closingBI?: number; // closingBraceIndex
  opened?: boolean;
  key?: string;
  itemsCount?: number;

  isCB?: boolean; // isClosingBrace
  // Next param is defined only if isCB === true
  openingBI?: number; // openingBraceIndex

  // Defined if line ends with [] or {} or [], or {}
  isSelfClosing?: boolean;
  // Is defined only if isOB or isCB or self-closing
  braceT?: BraceType; // braceType

  origIdx: number; // originalIndex
  line: string; // originalLine
  visible: boolean;
  depth: number;
  // Path to this line
  path: string;
};

type CodeData = {
  // code: string;
  linesWithMarkup: LinesWithMarkup[]
};

const getKeyFromLine = (line: string) => {
  const tokens = line.split(":");
  let key = tokens.length >= 2 ? tokens.slice(0, tokens.length - 1).join(":") : "";
  key = key.trim().replace(/(\'|\")/g, "");

  return key;
}

const getOpenedPaths = (data: CodeData | undefined): Set<string> => {
  const openedPaths = new Set<string>();
  
  if (!data)
    return openedPaths;
  
  for (let i = 0; i < data.linesWithMarkup.length; i++)
    if (data.linesWithMarkup[i].opened)
      openedPaths.add(data.linesWithMarkup[i].path);

  return openedPaths;
}

export const markupJSON = (code: string, parsedCode?: ObjectT<any>, prevData?: CodeData): LinesWithMarkup[] => {
  if (parsedCode === undefined)
    parsedCode = JSON.parse(code);

  const previouslyOpenedPaths = getOpenedPaths(prevData);
  // console.log('previouslyOpenedPaths', previouslyOpenedPaths);
  const lines = code.split("\n");

  // key is opening brace index
  const openingBracesTable: {[key: string]: {
    openingBI: number;
    closingBI: number;
  }} = {};
  // key is closing brace index
  const closingBracesTable: {[key: string]: {
    openingBI: number;
    closingBI: number;
  }} = {};
  const selfClosingTable: {[key: string]: boolean} = {};
  // Key is index, value is type
  const bracesTypeTable: {[key: string]: BraceType} = {};
  const openingBraces: number[] = [];
  const openingSquareBraces: number[] = [];
  const depthArray: number[] = [];
  const insideOfArray: boolean[] = [];
  const latestopeningBIArray: number[] = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const lineTrim = line.trim();
    const endSymbol = line[line.length - 1];

    latestopeningBIArray.push(openingBraces[openingBraces.length - 1]);

    // if (i === 0) {
    //   console.log('line', line);
    //   console.log(endSymbol === "{")
    // }

    // Явл. открывающей скобкой
    if (endSymbol === "{") {
      depthArray.push(openingBraces.length);
      openingBraces.push(i);
      bracesTypeTable[i] = "curly";
      // Явл. закрывающей скобкой
    } else if (lineTrim === "}," || (endSymbol === "}" && line.slice(line.length - 2, line.length) !== "{}")) {
      const openingBI = openingBraces.pop();

      if (openingBI !== undefined) {
        openingBracesTable[openingBI] = {
          openingBI: openingBI,
          closingBI: i
        };
        closingBracesTable[i] = {
          openingBI: openingBI,
          closingBI: i
        };
      }

      depthArray.push(openingBraces.length);
      bracesTypeTable[i] = "curly";
    } else if (endSymbol === "[") {
      depthArray.push(openingBraces.length);
      openingBraces.push(i);
      openingSquareBraces.push(i);
      bracesTypeTable[i] = "square";
    } else if (lineTrim === "]," || (endSymbol === "]" && line.slice(line.length - 2, line.length) !== "[]")) {
      const openingBI = openingBraces.pop();
      openingSquareBraces.pop()

      if (openingBI !== undefined) {
        openingBracesTable[openingBI] = {
          openingBI: openingBI,
          closingBI: i
        };
        closingBracesTable[i] = {
          openingBI: openingBI,
          closingBI: i
        };
      }

      depthArray.push(openingBraces.length);
      bracesTypeTable[i] = "square";
    } else {
      if (lineTrim === "{}" || lineTrim === "{},") {
        selfClosingTable[i] = true;
        bracesTypeTable[i] = "curly";
      } else if (lineTrim === "[]" || lineTrim === "[],") {
        selfClosingTable[i] = true;
        bracesTypeTable[i] = "square";
      }

      depthArray.push(openingBraces.length);
    }

    insideOfArray.push(openingSquareBraces.length > 0);
  }

  const path: string[] = [];

  // Key - index of array's opening brace, value - last index of this array
  const arrayLastIndexTable: {[key: string]: number} = {};

  const linesWithMarkup: LinesWithMarkup[] = lines.map((line, index) => {
    const depth = depthArray[index];
    const outerBraces = openingBracesTable["0"];
    
    let keyName: string | undefined = undefined;
    const isOB = !!openingBracesTable[index];
    const isCB = !!closingBracesTable[index];
    const isSelfClosing = !!selfClosingTable[index];
    const braceT = bracesTypeTable[index];
    const showArrow = isOB && index > 0;
    const showPin = isOB && index > 0 && !insideOfArray[index];
    const containsHTML = line.length >= MIN_LINE_LENGTH_THAT_MIGHT_CONTAIN_HTML && line.slice(0, 50).toLowerCase().includes(DOCTYPE_HTML_DECLARATION_LOWERCASE);

    const parentPathStr = path.join("/");

    // Update path to element
    const latestopeningBI = latestopeningBIArray[index];
    let key: string = "";

    if (bracesTypeTable[latestopeningBI] === "curly") {
      key = getKeyFromLine(line);
    } else if (bracesTypeTable[latestopeningBI] === "square") {
      if (arrayLastIndexTable[latestopeningBI] === undefined) {
        arrayLastIndexTable[latestopeningBI] = 0;
        key = "0";
      } else {
        key = (++arrayLastIndexTable[latestopeningBI]).toString();
      }
    }

    if (isOB || isSelfClosing) {
      path.push(key);
      keyName = key;
    }

    const pathStr = path.join("/");
    const visible = depth === 0 || (depth === 1 && !isCB) || previouslyOpenedPaths.has(parentPathStr) || previouslyOpenedPaths.has(pathStr);
    const opened = index === outerBraces.openingBI || (isOB && previouslyOpenedPaths.has(pathStr));
    const itemsCount = (braceT === "square") ? getLenIfPossible(getDataByPath(parsedCode!, pathStr)) : undefined;

    if (isCB || isSelfClosing)
      path.pop();

    const openingBI = closingBracesTable[index]?.openingBI;
    const closingBI = openingBracesTable[index]?.closingBI;

    return {
      origIdx: index,
      line: lines[index],
      visible,
      depth,
      path: pathStr,
      // only include if true-like
      ...(isOB && {isOB}),
      ...(opened && {opened}),
      ...(isCB && {isCB}),
      // ...(isSelfClosing && {isSelfClosing}),
      ...(showArrow && {showArrow}),
      ...(showPin && {showPin}),
      ...(containsHTML && {containsHTML}),
      ...(keyName !== undefined && {key: keyName}),
      ...(openingBI !== undefined && {openingBI}),
      ...(closingBI !== undefined && {closingBI}),
      ...(braceT && {braceT}),
      ...(itemsCount !== undefined && {itemsCount})
    };
  });

  // const test = linesWithMarkup.filter((el) => el.visible === true);
  // console.log('linesWithMarkup', linesWithMarkup)
  // console.log("TEST:", linesWithMarkup.filter((el) => el.isCB).length === linesWithMarkup.filter((el) => el.isOB).length)
  // if (linesWithMarkup.length < 100)
  //   console.log('linesWithMarkup', linesWithMarkup);
  return linesWithMarkup;
}

export const handleFoldChange = (linesWithMarkup: LinesWithMarkup[], lineIndex: number, newOpened: boolean, deepCopy: boolean = false) => {
  if (deepCopy)
    linesWithMarkup = JSON.parse(JSON.stringify(linesWithMarkup));

  linesWithMarkup[lineIndex].opened = newOpened;

  if (linesWithMarkup[lineIndex]?.closingBI) {
    if (newOpened) {
      let closestInnerclosingBI: number | undefined = undefined;
      for (let i = lineIndex + 1; i <= (linesWithMarkup[lineIndex].closingBI as number); i++) {
        if (closestInnerclosingBI !== undefined) {
          if (i === closestInnerclosingBI)
            closestInnerclosingBI = undefined;
          
          continue;
        }
        linesWithMarkup[i].visible = true;
        if (linesWithMarkup[i].closingBI)
          closestInnerclosingBI = linesWithMarkup[i].closingBI;
      }
    } else {
      for (let i = lineIndex + 1; i <= (linesWithMarkup[lineIndex].closingBI as number); i++) {
        linesWithMarkup[i].visible = false;
        if (linesWithMarkup[i].isOB)
          linesWithMarkup[i].opened = false;
      }
    }
  }
  return linesWithMarkup;
}

export const jsonFromStringsWithBracesRecovery = (lines: string[], linesWithMarkupFilteredVisible: LinesWithMarkup[]) => {
  return lines.map((line, index) => {
    if (linesWithMarkupFilteredVisible[index].isOB && !linesWithMarkupFilteredVisible[index].opened)
      return line + `${linesWithMarkupFilteredVisible[index].braceT === "curly" ? "}" : "]"}${linesWithMarkupFilteredVisible[index + 1].isCB ? "" : ","}`;

    return line;
  }).join("\n");
}