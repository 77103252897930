import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={22}
    fill="none"
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M2.23 2.062C.666 3.624.666 6.138.666 11.167c0 5.028 0 7.542 1.562 9.104 1.562 1.563 4.076 1.563 9.105 1.563h5.333c5.028 0 7.542 0 9.105-1.563 1.562-1.562 1.562-4.076 1.562-9.104 0-5.029 0-7.543-1.562-9.105C24.209.5 21.695.5 16.667.5h-5.333C6.305.5 3.79.5 2.229 2.062Zm20.538 3.131a1 1 0 0 1-.128 1.409l-2.928 2.44c-1.182.985-2.14 1.783-2.985 2.327-.88.566-1.739.924-2.727.924-.988 0-1.845-.357-2.726-.924-.845-.544-1.803-1.342-2.985-2.327L5.36 6.602a1 1 0 0 1 1.28-1.537L9.52 7.464c1.244 1.037 2.108 1.754 2.837 2.223.706.454 1.184.606 1.644.606.46 0 .94-.152 1.645-.606.729-.469 1.593-1.186 2.837-2.223l2.878-2.399a1 1 0 0 1 1.409.128Z"
      clipRule="evenodd"
    />
  </svg>
)
export { SvgComponent as MailIconV2 }