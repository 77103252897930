import { NavigationBarTabT } from "~/components/NavigationBarTab";
import { EventStateData, ExportableScenario } from "./draggableHelpers";
import { CurrentLangT, ObjectT } from "~/types/types";
import { SyntaxHighlightingVariant } from "~/constants/syntaxHighlightingTable";

export type DefaultLocalStorageType = string | null;

const _createStoreFunction = <DataT = any>(localStorageKey: string) => (data: DataT) => {
  const s = JSON.stringify(data);
  localStorage.setItem(localStorageKey, s);
}

const _createGetFunction = (localStorageKey: string) => () => {
  const value = localStorage.getItem(localStorageKey);
  return value;
}

const _createDeleteFunction = (localStorageKey: string) => () => {
  localStorage.removeItem(localStorageKey);
}

export const saveUserTokenToLocalStorage = (token: string) => {
  localStorage.setItem("user_auth_token", token);
};

export const getUserTokenFromLocalStorage = () => {
  const value = localStorage.getItem("user_auth_token");
  return value;
};

export const deleteUserTokenFromLocalStorage = () => {
  localStorage.removeItem("user_auth_token");
};

export const saveApiKeyToLocalStorage = (apiKey: string) => {
  localStorage.setItem("user_api_key", apiKey);
};

export const getApiKeyFromLocalStorage = () => {
  const value = localStorage.getItem("user_api_key");
  return value;
};

export const deleteApiKeyFromLocalStorage = () => {
  localStorage.removeItem("user_api_key");
};

export const saveEventLastParamsToLocalStorage = (event: EventStateData) => {
  const key = `event_${event.instructionId}`;
  const value = JSON.stringify(event.params);

  localStorage.setItem(key, value);
};

export const getEventLastParamsFromLocalStorage = (instructionId: string) => {
  const key = `event_${instructionId}`;
  
  const value = localStorage.getItem(key);
  return value;
};

export const saveAppSettingsToLocalStorage = (apiKey: string, settings: {[key: string] : any}) => {
  const key = `app_settings_for_apikey_${apiKey}`;
  const value = JSON.stringify(settings);

  localStorage.setItem(key, value);
};

export const getAppSettingsFromLocalStorage = (apiKey: string) => {
  const key = `app_settings_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteAppSettingsFromLocalStorage = (apiKey: string) => {
  const key = `app_settings_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveReduxPinnedPathsToLocalStorage = (apiKey: string, paths: string[]) => {
  const key = `pinned_paths_for_apikey_${apiKey}`;
  const value = JSON.stringify(paths);

  localStorage.setItem(key, value);
};

export const getReduxPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `pinned_paths_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteReduxPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `pinned_paths_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveZustandPinnedPathsToLocalStorage = (apiKey: string, paths: string[]) => {
  const key = `zustand_pinned_paths_for_apikey_${apiKey}`;
  const value = JSON.stringify(paths);

  localStorage.setItem(key, value);
};

export const getZustandPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `zustand_pinned_paths_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteZustandPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `zustand_pinned_paths_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveTanStackQueryPinnedPathsToLocalStorage = (apiKey: string, paths: string[]) => {
  const key = `tanstack_query_pinned_paths_for_apikey_${apiKey}`;
  const value = JSON.stringify(paths);

  localStorage.setItem(key, value);
};

export const getTanStackQueryPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `tanstack_query_pinned_paths_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteTanStackQueryPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `tanstack_query_pinned_paths_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveContextsPinnedPathsToLocalStorage = (apiKey: string, pathsByContextIdTable: ObjectT<string[] | undefined>) => {
  const key = `contexts_pinned_paths_for_apikey_${apiKey}`;
  const value = JSON.stringify(pathsByContextIdTable);

  localStorage.setItem(key, value);
};

export const getContextsPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `contexts_pinned_paths_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteContextsPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `contexts_pinned_paths_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveMobxPinnedPathsToLocalStorage = (apiKey: string, paths: string[]) => {
  const key = `mobx_pinned_paths_for_apikey_${apiKey}`;
  const value = JSON.stringify(paths);

  localStorage.setItem(key, value);
};

export const getMobxPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `mobx_pinned_paths_for_apikey_${apiKey}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteMobxPinnedPathsFromLocalStorage = (apiKey: string) => {
  const key = `mobx_pinned_paths_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveRndDefaultSizeSettingsToLocalStorage = (apiKey: string, token: string, data: any) => {
  const key = `rnd_default_size_settings_for_${apiKey}_token_${token}`;
  const value = JSON.stringify(data);

  localStorage.setItem(key, value);
};

export const getRndDefaultSizeSettingsFromLocalStorage = (apiKey: string, token: string) => {
  const key = `rnd_default_size_settings_for_${apiKey}_token_${token}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteRndDefaultSizeSettingsFromLocalStorage = (apiKey: string, token: string) => {
  const key = `rnd_default_size_settings_for_${apiKey}_token_${token}`;

  localStorage.removeItem(key);
};

export const saveColorThemeToLocalStorage = (theme: string) => {
  localStorage.setItem("gui_color_theme", theme);
};

export const getColorThemeFromLocalStorage = () => {
  const value = localStorage.getItem("gui_color_theme");
  return value;
};

export const deleteColorThemeFromLocalStorage = () => {
  localStorage.removeItem("gui_color_theme");
};

export const saveNetworkTimelineSortingToLocalStorage = (s: string) => {
  localStorage.setItem("network_tab_timeline_sorting", s);
};

export const getNetworkTimelineSortingFromLocalStorage = () => {
  const value = localStorage.getItem("network_tab_timeline_sorting");
  return value;
};

export const saveLogsTimelineSortingToLocalStorage = (s: string) => {
  localStorage.setItem("main_tab_logs_timeline_sorting", s);
};

export const getLogsTimelineSortingFromLocalStorage = () => {
  const value = localStorage.getItem("main_tab_logs_timeline_sorting");
  return value;
};

export const saveNetworkFiltersModalStateToLocalStorage = _createStoreFunction("app_network_tab_filters_modal_state");
export const getNetworkFiltersModalStateFromLocalStorage = _createGetFunction("app_network_tab_filters_modal_state");
export const deleteNetworkFiltersModalStateFromLocalStorage = _createDeleteFunction("app_network_tab_filters_modal_state");

export const saveScenariosCollectionToLocalStorage = (apiKey: string, projectId: string, collection: ExportableScenario[]) => {
  const key = `scenarios_collection_for_apikey_${apiKey}_projectId_${projectId}`;
  const value = JSON.stringify(collection);

  localStorage.setItem(key, value);
};

export const getScenariosCollectionFromLocalStorage = (apiKey: string, projectId: string) => {
  const key = `scenarios_collection_for_apikey_${apiKey}_projectId_${projectId}`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteScenarioFromCollectionFromLocalStorage = (apiKey: string, projectId: string, scenarioId: string): ExportableScenario[] => {
  const collectionStr = getScenariosCollectionFromLocalStorage(apiKey, projectId);

  if (collectionStr) {
    const scenarios: ExportableScenario[] = JSON.parse(collectionStr);
    const newScenarios = scenarios.filter((el) => el.id !== scenarioId);
    saveScenariosCollectionToLocalStorage(apiKey, projectId, newScenarios);
    return newScenarios;
  }

  return [];
};

export const deleteScenariosCollectionFromLocalStorage = (apiKey: string, projectId: string) => {
  const key = `scenarios_collection_for_apikey_${apiKey}_projectId_${projectId}`;

  localStorage.removeItem(key);
};

export const saveChosenNavBarTabsToLocalStorage = (apiKey: string, tabs: NavigationBarTabT[]) => {
  const key = `chosen_nav_bar_tabs_for_apikey_${apiKey}`;
  const value = JSON.stringify(tabs);

  localStorage.setItem(key, value);
};

export const getChosenNavBarTabsFromLocalStorage = (apiKey: string) => {
  const key = `chosen_nav_bar_tabs_for_apikey_${apiKey}`;
  
  const value = localStorage.getItem(key);
  return value;
};

export const deleteChosenNavBarTabsFromLocalStorage = (apiKey: string) => {
  const key = `chosen_nav_bar_tabs_for_apikey_${apiKey}`;

  localStorage.removeItem(key);
};

export const saveLanguageToLocalStorage = (language: CurrentLangT) => {
  const key = `gui_language`;

  localStorage.setItem(key, language);
};

export const getLanguageFromLocalStorage = () => {
  const key = `gui_language`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteLanguageFromLocalStorage = () => {
  const key = `gui_language`;

  localStorage.removeItem(key);
};

export const saveSyntaxHighlightingVariantToLocalStorage = (highlightingVariant: SyntaxHighlightingVariant) => {
  const key = `gui_syntax_highlighting_variant`;

  localStorage.setItem(key, highlightingVariant);
};

export const getSyntaxHighlightingVariantFromLocalStorage = () => {
  const key = `gui_syntax_highlighting_variant`;

  const value = localStorage.getItem(key);
  return value;
};

export const deleteSyntaxHighlightingVariantFromLocalStorage = () => {
  const key = `gui_syntax_highlighting_variant`;

  localStorage.removeItem(key);
};