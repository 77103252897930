import React, { memo, useMemo } from 'react';
import { useStyles } from './EventExecutionProgressBarStyles';
import { animated, useSpring, easings } from '@react-spring/web';
import { useThemeContext } from '~/theme';
import cn from 'classnames';
import { EventStateData } from '~/helpers/draggableHelpers';
import { userSelector } from '~/logic/user/UserSelectors';
import { useSelector } from 'react-redux';

const DEFAULT_ANIMATION_DURATION = 150;

export type EventExecutionProgressBarProps = {
  event: EventStateData;
  prevEventOfScenario: EventStateData | undefined;
  absoluteLeft?: number;
};

const EventExecutionProgressBar: React.FC<EventExecutionProgressBarProps> = ({
  event,
  prevEventOfScenario,
  absoluteLeft
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const {
    sentEventsExecutionStatusTable
  } = useSelector(userSelector);

  const progressBarAnimationDuration = useMemo(() => {
    const progressBarAnimationDurationStr = event.instructionId === "delay" ? event.params.delayInMs : undefined;
    const progressBarAnimationDuration = progressBarAnimationDurationStr === undefined ? undefined : Number(progressBarAnimationDurationStr);

    return progressBarAnimationDuration ?? DEFAULT_ANIMATION_DURATION;
  }, [event]);

  const [animatedMainProgressBarStyle] = useSpring(() => {
    const key = event.externalId ?? "noId";
    // console.log("event", event);
    const prevEventOfScenarioKey = prevEventOfScenario?.externalId ?? "noId";

    const prevEventOfScenarioEndConfirmed = !prevEventOfScenario || sentEventsExecutionStatusTable[prevEventOfScenarioKey]?.executionCompleted;

    const startConfirmed = prevEventOfScenarioEndConfirmed && sentEventsExecutionStatusTable[key]?.executionStarted;
    const endConfirmed = startConfirmed && sentEventsExecutionStatusTable[key]?.executionCompleted;
    const ok = sentEventsExecutionStatusTable[key]?.ok ?? true;
    const showProgressBar = startConfirmed || !ok;
    // console.log("startConfirmed", startConfirmed);
    // console.log('showProgressBar', showProgressBar)
    const duration = startConfirmed ? progressBarAnimationDuration : 10;

    return (
      {
        width: showProgressBar ? (endConfirmed || event.instructionId === "delay" || !ok) ? "100%" : "40%": "0%",
        backgroundColor: ok ? theme.colors.progressBarSuccess : theme.colors.progressBarFailure,
        config: {
          easing: easings.linear,
          duration,
        },
      }
    );
  }, [event, prevEventOfScenario, sentEventsExecutionStatusTable, theme]);

  return (
    <div className={cn(s.progressBarCont)} style={{left: absoluteLeft}}>
      {/* {eventIsExecuting &&
        <animated.div style={animatedSecondaryProgressBarStyle} className={cn(s.secondaryProgressBar)}/>
      } */}
      <animated.div style={animatedMainProgressBarStyle} className={cn(s.mainProgressBar)}/>
    </div>
  );
};

EventExecutionProgressBar.defaultProps={}

const MemorizedComponent = memo(EventExecutionProgressBar);
export { MemorizedComponent as EventExecutionProgressBar };