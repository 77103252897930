import { ApiResponse } from 'apisauce';

const requestErrorFromResponse = (response: ApiResponse<any>) => {
  const _description = response.data?.message ?? response.data?.description ?? "Ошибка (описание отсутствует)";

  return {
    description: _description,
    problem: response.problem,
    originalError: response.originalError,
    code: response.status ?? -3,
    invalidParameters: response.data?.invalidParameters,
    duration: response.duration
  };
};

type RequestErrorFromResponse = ReturnType<typeof requestErrorFromResponse>;

export { requestErrorFromResponse, type RequestErrorFromResponse };