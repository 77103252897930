import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vh',
    height: '50vh',
    minWidth: 300,
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sharkLight,
    borderRadius: theme.metrics.x4,
    overflow: 'hidden',
    padding: theme.metrics.x4
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: `${theme.colors.bgForm} !important`,
    color: theme.colors.textFormMain,
    overflow: 'scroll',
  },
});