import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import {
  addAllRestReducers,
  createRestActions,
  getDefaultRestState,
} from "~/store/restHelper";
import { AddProjectMemberRestActions, CreateProjectRestActions, DeleteProjectRestActions, EditProjectRestActions, GetRemoteSettingsRestActions, ProjectByIdRestActions, ProjectListRestActions, RemoveProjectMemberRestActions, SetRemoteSettingsRestActions } from "./RemoteSettingsActions";
import { ObjectT, Project } from "~/types/types";
import fp from "lodash/fp";

export type RemoteSettingsEnv = "dev" | "stg" | "prod";

export type RemoteSettings = {[env in RemoteSettingsEnv]: ObjectT<string>};

type GetRemoteSettingsResponse = RemoteSettings;

type SetRemoteSettingsResponse = {
  message: string;
};

type CreateProjectResponse = Project;

type EditProjectResponse = Project;

type ProjectListResponse = Project[];

type ProjectByIdResponse = Project;

type SetRemoteSettingsPayload = {
  remoteSettings: Partial<RemoteSettings>;
  projectId: string;
};

type CreateProjectPayload = {
  name: string;
  image?: string;
  description?: string;
  successCallback?: () => void;
};

type GetRemoteSettingsPayload = {
  projectId: string;
};

type EditProjectPayload = {
  id: string;
  name?: string;
  image?: string;
  description?: string;
  successCallback?: () => void;
};

type DeleteProjectPayload = {
  id: string;
};

type ProjectListPayload = {
  index: number;
};

type ProjectByIdPayload = {
  id: string;
};

type AddProjectMemberPayload = {
  projectId: string;
  apiKey: string;
  successCallback?: () => void;
};

type RemoveProjectMemberPayload = {
  projectId: string;
  apiKey: string;
  successCallback?: () => void;
};

type UpdateRemoteSettingsByProjectIdTablePayload = {
  remoteSettings: RemoteSettings;
  projectId: string;
};

const getRemoteSettingsRestActions = createRestActions<
  GetRemoteSettingsResponse,
  GetRemoteSettingsPayload
>(GetRemoteSettingsRestActions);

const setRemoteSettingsRestActions = createRestActions<
  SetRemoteSettingsResponse,
  SetRemoteSettingsPayload
>(SetRemoteSettingsRestActions);

const createProjectRestActions = createRestActions<
  CreateProjectResponse,
  CreateProjectPayload
>(CreateProjectRestActions);

const editProjectRestActions = createRestActions<
  EditProjectResponse,
  EditProjectPayload
>(EditProjectRestActions);

const deleteProjectRestActions = createRestActions<
  void,
  DeleteProjectPayload
>(DeleteProjectRestActions);

const projectListRestActions = createRestActions<
  ProjectListResponse,
  ProjectListPayload
>(ProjectListRestActions);

const projectByIdRestActions = createRestActions<
  ProjectByIdResponse,
  ProjectByIdPayload
>(ProjectByIdRestActions);

const addProjectMemberRestActions = createRestActions<
  void,
  AddProjectMemberPayload
>(AddProjectMemberRestActions);

const removeProjectMemberRestActions = createRestActions<
  void,
  RemoveProjectMemberPayload
>(RemoveProjectMemberRestActions);

const RemoteSettingsRestActions = {
  getRemoteSettings: getRemoteSettingsRestActions,
  setRemoteSettings: setRemoteSettingsRestActions,
  createProject: createProjectRestActions,
  editProject: editProjectRestActions,
  deleteProject: deleteProjectRestActions,
  projectList: projectListRestActions,
  projectById: projectByIdRestActions,
  addProjectMember: addProjectMemberRestActions,
  removeProjectMember: removeProjectMemberRestActions
};

type RemoteSettingsState = {
  selectedProjectId: string | undefined;
  remoteSettingsByProjectIdTable: {[key: string]: RemoteSettings};
};

const initialRemoteSettingsState: RemoteSettingsState = {
  selectedProjectId: undefined,
  remoteSettingsByProjectIdTable: {}
};

const initialRestState = {
  getRemoteSettings: getDefaultRestState<GetRemoteSettingsResponse>(),
  setRemoteSettings: getDefaultRestState(),
  createProject: getDefaultRestState<CreateProjectResponse>(),
  editProject: getDefaultRestState<EditProjectResponse>(),
  deleteProject: getDefaultRestState(),
  projectList: getDefaultRestState<ProjectListResponse>(),
  projectById: getDefaultRestState<ProjectByIdResponse>(),
  addProjectMember: getDefaultRestState(),
  removeProjectMember: getDefaultRestState()
};

const remoteSettingsSlice = createSlice({
  name: 'remoteSettings',
  initialState: { ...initialRemoteSettingsState, ...initialRestState },
  reducers: {
    setSelectedProjectId(state, action: PayloadAction<string | undefined>) {
      state.selectedProjectId = action.payload;
    },
    updateRemoteSettingsByProjectIdTable(state, action: PayloadAction<UpdateRemoteSettingsByProjectIdTablePayload>) {
      state.remoteSettingsByProjectIdTable[action.payload.projectId] = action.payload.remoteSettings;
    },
    clearAllState: {
      reducer() {
        return {
          ...initialRemoteSettingsState, 
          ...initialRestState
        }
      },
      prepare: prepareAutoBatched<void>(),
    }
  },
  extraReducers: (builder) => fp.flow(addAllRestReducers<typeof RemoteSettingsRestActions>(RemoteSettingsRestActions))(builder)
})

const remoteSettingsReducer = remoteSettingsSlice.reducer;
const RemoteSettingsActions = { ...RemoteSettingsRestActions, ...remoteSettingsSlice.actions };

export { remoteSettingsReducer, RemoteSettingsActions };