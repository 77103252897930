import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    fill={props.color}
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m15 10-9 5V5l9 5z" />
  </svg>
)

export default SvgComponent
