import { combineReducers } from '@reduxjs/toolkit'
import { appSettingsReducer } from '../logic/appSettings/AppSettingsSlice';
import { userReducer } from '../logic/user/UserSlice';
import { ourReduxInspectorReducer } from '../logic/ourReduxInspector/OurReduxInspectorSlice';
import { remoteSettingsReducer } from '../logic/remoteSettings/RemoteSettingsSlice';
import { networkReducer } from '../logic/network/NetworkSlice';
import { ourZustandInspectorReducer } from '../logic/ourZustandInspector/OurZustandInspectorSlice';
import { ourTanStackQueryInspectorReducer } from '../logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSlice';
import { ourContextsInspectorReducer } from '../logic/ourContextsInspector/OurContextsInspectorSlice';
import { ourMobxInspectorReducer } from '../logic/ourMobxInspector/OurMobxInspectorSlice';

export const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  user: userReducer,
  ourReduxInspector: ourReduxInspectorReducer,
  remoteSettings: remoteSettingsReducer,
  network: networkReducer,
  ourZustandInspector: ourZustandInspectorReducer,
  ourTanStackQueryInspector: ourTanStackQueryInspectorReducer,
  ourContextsInspector: ourContextsInspectorReducer,
  ourMobxInspector: ourMobxInspectorReducer
});

export type RootState = ReturnType<typeof rootReducer>;