import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  addAllRestReducers,
} from "~/store/restHelper";
import { InterceptedReduxAction, InterceptedStorageAction, InterceptedRequest, InterceptedResponse, TimelineItem, CapturedEvent, TimelineSorting, InterceptedTanStackQueryEvent, CapturedCrashReport, InterceptedMobxEvent } from "~/types/types";
import { addItemsToTimelineItemsArray, makeInterceptedApiCallsCopy, sortAndSliceNetworkTimeline } from "~/helpers/sort";
import fp from "lodash/fp";

export const INTERCEPTED_ARRAY_MAX_LEN = 512;

type AddInterceptedRequestPayload = {
  request: InterceptedRequest;
  requestId: string;
  timestamp: number;
};

type AddInterceptedResponsePayload = {
  response: InterceptedResponse;
  request: InterceptedRequest;
  requestId: string;
  timestamp: number;
};

const NetworkRestActions = {

};

type NetworkState = {
  interceptedArrayMaxLen: number;
  timelineSorting: TimelineSorting;
  interceptedApiCalls: TimelineItem[];
  gotSomethingNewIndicator: boolean;
  timelineVirtuosoFirstItemIndex: number;
  timelineVirtuosoTotalCount: number;
};

const initialNetworkState: NetworkState = {
  interceptedArrayMaxLen: INTERCEPTED_ARRAY_MAX_LEN,
  timelineSorting: "oldestOnTop",
  interceptedApiCalls: [],
  gotSomethingNewIndicator: false,
  timelineVirtuosoFirstItemIndex: 0,
  timelineVirtuosoTotalCount: 0
};

const initialRestState = {

};

const networkSlice = createSlice({
  name: 'network',
  initialState: { ...initialNetworkState, ...initialRestState },
  reducers: {
    setInterceptedArrayMaxLen: {
      reducer(state, action: PayloadAction<number>) {
        state.interceptedArrayMaxLen = action.payload;
      },
      prepare: prepareAutoBatched<number>(),
    },
    setTimelineSorting: {
      reducer(state, action: PayloadAction<TimelineSorting>) {
        state.timelineSorting = action.payload;
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);
        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, action.payload, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<TimelineSorting>(),
    },
    addInterceptedRequest: {
      reducer(state, action: PayloadAction<AddInterceptedRequestPayload>) {
        const { request, requestId, timestamp } = action.payload;

        const index = state.interceptedApiCalls.findIndex((el) => {
          if ('requestId' in el)
            return el.requestId === requestId;

          return false;
        });

        if (index === -1) {
          let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

          addItemsToTimelineItemsArray({
            destination: interceptedApiCallsCopy, 
            itemsToAdd: [
              {
                requestId,
                request,
                timestampRequest: timestamp
              }
            ],
            sorting: state.timelineSorting,
            itemsType: "InterceptedApiCall"
          });

          state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
        }
      },
      prepare: prepareAutoBatched<AddInterceptedRequestPayload>(),
    },
    addInterceptedResponse: {
      reducer(state, action: PayloadAction<AddInterceptedResponsePayload>) {
        const { response, request, requestId, timestamp } = action.payload;

        const index = state.interceptedApiCalls.findIndex((el) => {
          if ('requestId' in el)
            return el.requestId === requestId;

          return false;
        });

        if (index >= 0) {
          state.interceptedApiCalls[index] = {
            ...state.interceptedApiCalls[index],
            response,
            timestampResponse: timestamp,
            // @ts-ignore
            duration: timestamp - (state.interceptedApiCalls[index].timestampRequest as number)
          };
        } else {
          let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

          addItemsToTimelineItemsArray({
            destination: interceptedApiCallsCopy,
            itemsToAdd: [
              {
                requestId,
                request,
                response,
                timestampResponse: timestamp
              }
            ],
            sorting: state.timelineSorting,
            itemsType: "InterceptedApiCall"
          });

          state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
        }
      },
      prepare: prepareAutoBatched<AddInterceptedResponsePayload>(),
    },
    clearInterceptedApiCalls(state) {
      state.interceptedApiCalls = [];
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    addInterceptedReduxActions: {
      reducer(state, action: PayloadAction<InterceptedReduxAction[]>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: action.payload, 
          sorting: state.timelineSorting,
          itemsType: "InterceptedReduxAction"
        });
  
        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<InterceptedReduxAction[]>(),
    },
    addInterceptedStorageActions: {
      reducer(state, action: PayloadAction<InterceptedStorageAction[]>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: action.payload, 
          sorting: state.timelineSorting,
          itemsType: "InterceptedStorageAction"
        });

        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<InterceptedStorageAction[]>(),
    },
    addCapturedEvent: {
      reducer(state, action: PayloadAction<CapturedEvent>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: [action.payload], 
          sorting: state.timelineSorting,
          itemsType: "CapturedEvent"
        });
  
        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<CapturedEvent>(),
    },
    addCapturedCrashReport: {
      reducer(state, action: PayloadAction<CapturedCrashReport>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: [action.payload], 
          sorting: state.timelineSorting,
          itemsType: "CapturedCrashReport"
        });

        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<CapturedCrashReport>()
    },
    addInterceptedTanStackQueryEvents: {
      reducer(state, action: PayloadAction<InterceptedTanStackQueryEvent[]>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: action.payload, 
          sorting: state.timelineSorting,
          itemsType: "InterceptedTanStackQueryEvent"
        });

        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<InterceptedTanStackQueryEvent[]>(),
    },
    addInterceptedMobxEvents: {
      reducer(state, action: PayloadAction<InterceptedMobxEvent[]>) {
        let interceptedApiCallsCopy = makeInterceptedApiCallsCopy(state.interceptedApiCalls);

        addItemsToTimelineItemsArray({
          destination: interceptedApiCallsCopy, 
          itemsToAdd: action.payload, 
          sorting: state.timelineSorting,
          itemsType: "InterceptedMobxEvent"
        });

        state.interceptedApiCalls = sortAndSliceNetworkTimeline(interceptedApiCallsCopy, state.timelineSorting, state.interceptedArrayMaxLen);
      },
      prepare: prepareAutoBatched<InterceptedMobxEvent[]>()
    },
    setTimelineVirtuosoFirstItemIndex: {
      reducer(state, action: PayloadAction<number>) {
        state.timelineVirtuosoFirstItemIndex = action.payload;
      },
      prepare: prepareAutoBatched<number>()
    },
    setTimelineVirtuosoTotalCount: {
      reducer(state, action: PayloadAction<number>) {
        state.timelineVirtuosoTotalCount = action.payload;
      },
      prepare: prepareAutoBatched<number>()
    },
    clearAllState: {
      reducer() {
        return {
          ...initialNetworkState, 
          ...initialRestState
        }
      },
      prepare: prepareAutoBatched<void>(),
    }
  },
  extraReducers: (builder) => fp.flow(addAllRestReducers<typeof NetworkRestActions>(NetworkRestActions))(builder)
})

const networkReducer = networkSlice.reducer;
const NetworkActions = { ...NetworkRestActions, ...networkSlice.actions };

export { networkReducer, NetworkActions };