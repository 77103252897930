import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  innerCont: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    paddingLeft: theme.metrics.x5,
    paddingRight: theme.metrics.x5,
    paddingTop: theme.metrics.x2,
    paddingBottom: theme.metrics.x2,
    cursor: "pointer"
  },
  borderBottom: {
    border: `1px solid ${theme.colors.borderSecondary}`,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    width: '20%',
    height: '100%',
    justifyContent: 'space-between'
  },
  timeText: {
    fontSize: 15,
    margin: 0,
    fontFamily: theme.fonts.f300.fontFamily,
    color: theme.colors.textTetriary,
  },
  timeGapText: {
    fontSize: 10,
    margin: 0,
    fontFamily: theme.fonts.f500.fontFamily,
    color: theme.colors.textBrightSecondary,
  },
  right: {
    display: 'flex',
    width: '77%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  arrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'scale(0.8)',
    transition: 'transform .2s ease-in-out'
  },
  arrowHovered: {
    transform: 'scale(0.8) rotate(180deg)'
  },
});