import React, { ReactElement } from 'react';
import { useStyles } from './PinnedPathsContainerStyles';
import { Box, Typography } from '@material-ui/core';
import { PinnedPath } from '~/components/PinnedPath';
import { ObjectT } from '~/types/types';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type PinnedContainerProps = {
  stateCopy: ObjectT<any> | null;
  pinnedPaths: string[];
  icon?: ReactElement;
  onPressUnpin: (path: string) => void;
};

const PinnedContainer: React.FC<PinnedContainerProps> = ({
  stateCopy,
  pinnedPaths,
  icon,
  onPressUnpin
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  return (
    <Box className={cn(s.pinnedContainer)}>
      {pinnedPaths.length ?
        <Box className={cn(s.pinnedScrollable)}>
          {pinnedPaths.map((path: string, index: number) => (
            <PinnedPath 
              key={path}
              path={path}
              stateCopy={stateCopy}
              withBottomBorder={index !== pinnedPaths.length - 1}
              onPressUnpin={onPressUnpin}
            />
          ))}
        </Box>
      :
        <Box className={ds.fullSpaceCenteredCol}>
          {icon &&
            <Box className={cn(s.icon)}>
              {icon}
            </Box>
          }
          <Typography variant="h5" className={cn(s.whiteText)}>
            {t("Other.youHaveNoPinnedPaths")}
          </Typography>
        </Box>
      }
    </Box>
  );
};

PinnedContainer.defaultProps={}

export { PinnedContainer as PinnedPathsContainer };