import React, { memo, useCallback } from 'react';
import { useStyles } from './AccountsModalStyles';
import { Box } from '@material-ui/core';
import { AccountItem } from '~/components/AccountItem';
import { CustomButton } from '~/components/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_TEST_ACCOUNTS_COUNT, UserActions } from '~/logic/user/UserSlice';
import { userSelector } from '~/logic/user/UserSelectors';
import { useDependentValue } from '~/hooks';
import { TestAccount } from '~/types/types';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { CustomModal, CustomModalProps } from '../CustomModal';
import cn from 'classnames';

export type AccountsModalProps = CustomModalProps & {};

const AccountsModal: React.FC<AccountsModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    testAccounts,
    createTestAccount: {
      fetching: createTestAccountFetching
    },
    currentTestAccount
  } = useSelector(userSelector);

  const createButtonDisabled = useDependentValue<boolean>(() => {
    let numOfFavorites = 0;

    testAccounts.forEach((account) => numOfFavorites += account.isFavorite ? 1: 0);

    return numOfFavorites === MAX_TEST_ACCOUNTS_COUNT;
  }, [testAccounts]);

  const handleFavorite = (isFavorite: boolean, id: string) => {
    dispatch(UserActions.editTestAccount.request({
      id,
      isFavorite
    }));
  }

  const onPressDeleteAccount = (id: string, mail: string) => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: `${t("Toast.deletingTestAcc")}: ${mail}`,
      onPressYes: () => dispatch(UserActions.deleteTestAccount.request({id})),
      onPressNo: () => {}
    }));
  }

  const handleGenerateNewAccount = useCallback(() => {
    dispatch(UserActions.createTestAccount.request({}));
  }, []);

  return (
    <CustomModal
      {...restProps}
      classname={cn(s.modal)}
      title={t("Other.testAccounts")}
    >
      <>
        <Box className={cn(s.itemsContainer)}>
          {testAccounts.map((account: TestAccount) => (
            <AccountItem 
              key={account.email}
              account={account}
              isFavorite={account.isFavorite}
              isSelected={!!currentTestAccount && currentTestAccount._id === account._id}
              onPress={() => {
                dispatch(UserActions.setCurrentTestAccount(account));
                dispatch(UserActions.setTestAccountsModalVisible(false));
              }}
              onPressFavorite={(f) => {
                handleFavorite(f, account._id)
              }}
              onPressDeleteAccount={() => onPressDeleteAccount(account._id, account.email)}
            />
          ))}
        </Box>
        <Box className={cn(s.bottomButton)} title={createButtonDisabled? t("Other.maxFavAccountsReached") : t("Other.generateNewAccount")}>
          <CustomButton 
            title={t("Other.generateNewAccount")}
            onPress={handleGenerateNewAccount}
            spinner={createTestAccountFetching}
            disabled={createButtonDisabled}
          />
        </Box>
      </>
    </CustomModal>
  );
};

AccountsModal.defaultProps={}

const MemorizedComponent = memo(AccountsModal);
export { MemorizedComponent as AccountsModal };