import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_CHOSEN_NAVIGATION_BAR_TABS } from "~/components/NavigationBar";
import { deleteAppSettingsFromLocalStorage, deleteChosenNavBarTabsFromLocalStorage, deleteColorThemeFromLocalStorage, deleteContextsPinnedPathsFromLocalStorage, deleteLanguageFromLocalStorage, deleteMobxPinnedPathsFromLocalStorage, deleteNetworkFiltersModalStateFromLocalStorage, deleteReduxPinnedPathsFromLocalStorage, deleteRndDefaultSizeSettingsFromLocalStorage, deleteSyntaxHighlightingVariantFromLocalStorage, deleteTanStackQueryPinnedPathsFromLocalStorage, deleteZustandPinnedPathsFromLocalStorage } from "~/helpers/localStorage";
import { AppSettingsActions } from "~/logic/appSettings/AppSettingsSlice";
import { OurContextsInspectorActions } from "~/logic/ourContextsInspector/OurContextsInspectorSlice";
import { OurMobxInspectorActions } from "~/logic/ourMobxInspector/OurMobxInspectorSlice";
import { OurReduxInspectorActions } from "~/logic/ourReduxInspector/OurReduxInspectorSlice";
import { OurTanStackQueryInspectorActions } from "~/logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSlice";
import { OurZustandInspectorActions } from "~/logic/ourZustandInspector/OurZustandInspectorSlice";
import { userSelector } from "~/logic/user/UserSelectors";
import { DEFAULT_APP_SETTINGS, DEFAULT_NETWORK_FILTERS_MODAL_STATE, UserActions } from "~/logic/user/UserSlice";
import { initI18n } from '~/i18n';

export const useRevertToDefaultSettings = () => {
  const dispatch = useDispatch();

  const {
    apiKey,
    userToken
  } = useSelector(userSelector);

  const handleRevertToDefaultSettings = () => {
    // Revert app settings
    dispatch(UserActions.applyAndSetAppSettings({
      appSettings: JSON.parse(JSON.stringify(DEFAULT_APP_SETTINGS)),
      updateLocalStorage: false
    }));
    deleteAppSettingsFromLocalStorage(apiKey);

    // Revert Redux pinned paths
    dispatch(OurReduxInspectorActions.setPinnedPaths([]));
    deleteReduxPinnedPathsFromLocalStorage(apiKey);

    // Revert Zustand pinned paths
    dispatch(OurZustandInspectorActions.setPinnedPaths([]));
    deleteZustandPinnedPathsFromLocalStorage(apiKey);

    // Revert TanStack Query pinned paths
    dispatch(OurTanStackQueryInspectorActions.setPinnedPaths([]));
    deleteTanStackQueryPinnedPathsFromLocalStorage(apiKey);

    // Revert React Contexts pinned paths
    dispatch(OurContextsInspectorActions.setPinnedPathsByContextIdTable({}));
    deleteContextsPinnedPathsFromLocalStorage(apiKey);

    // Revert MobX pinned paths
    dispatch(OurMobxInspectorActions.setPinnedPaths([]));
    deleteMobxPinnedPathsFromLocalStorage(apiKey);

    // Revert all Rnd size settings
    if (userToken) {
      dispatch(UserActions.setFullRndDefaultSizeSettings({}));
      deleteRndDefaultSizeSettingsFromLocalStorage(apiKey, userToken);
    }

    // Revert GUI color theme
    dispatch(AppSettingsActions.setColorTheme("balticSea"));
    deleteColorThemeFromLocalStorage();

    // Revert network tab filters modal state
    dispatch(UserActions.setNetworkFiltersModalState(JSON.parse(JSON.stringify(DEFAULT_NETWORK_FILTERS_MODAL_STATE))));
    deleteNetworkFiltersModalStateFromLocalStorage();

    // Revert chosen navigation bar tabs
    dispatch(UserActions.setChosenNavBarTabs(JSON.parse(JSON.stringify(DEFAULT_CHOSEN_NAVIGATION_BAR_TABS))));
    deleteChosenNavBarTabsFromLocalStorage(apiKey);

    // Revert GUI language
    dispatch(AppSettingsActions.setCurrentLang("en"));
    initI18n("en");
    deleteLanguageFromLocalStorage();

    // Revert GUI syntax highlighting variant setting
    dispatch(AppSettingsActions.setSyntaxHighlightingVariant("one"));
    deleteSyntaxHighlightingVariantFromLocalStorage();
  }

  return {
    handleRevertToDefaultSettings
  }
}