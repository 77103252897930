import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const use404PageAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    segmentAnalytics.page("404Page", "404Page", {location});
  }, []);
};