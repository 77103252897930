import React, { ReactElement, memo, useState } from 'react';
import { useStyles } from './ClientPreviewItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import {
  AppleIcon,
  MacosIcon,
  IosIcon,
  AndroidIcon,
  BrowserIcon,
  ChromeIcon,
  WindowsIcon,
  LinuxIcon,
  UnknownDeviceIcon,
  ConnectionIcon,
  CloseCircleIcon
} from "~/assets/icons";
import { DeviceType } from './types';
import cn from 'classnames';

const ICON_TABLE: {[k in DeviceType]: (props: any) => ReactElement} = {
  "apple": AppleIcon,
  "macos": MacosIcon,
  "ios": IosIcon,
  "android": AndroidIcon,
  "web": BrowserIcon,
  "chrome": ChromeIcon,
  "windows": WindowsIcon,
  "linux": LinuxIcon,
  "unknown": UnknownDeviceIcon
};

export type ClientPreviewItemProps = {
  type: DeviceType;
  title: string;
  projectName?: string;
  description?: string;
  connected: boolean;
  marginRight?: number;
  onPress: () => void;
  onPressForceDisconnect: () => void;
};

const ClientPreviewItem: React.FC<ClientPreviewItemProps> = ({
  type,
  title,
  projectName,
  description,
  connected,
  marginRight,
  onPress,
  onPressForceDisconnect
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const Icon = ICON_TABLE[type];

  const [hovered, setHovered] = useState(false);

  return (
    <Box 
      className={cn(s.container)}
      style={{
        marginRight,
        cursor: connected ? undefined : 'pointer'
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={connected ? undefined : onPress}
    >
      <Box className={cn(s.iconCont)}>
        <Icon 
          color={connected ? theme.colors.textBright : theme.colors.iconsMain}
          color2={theme.colors.bgTetriary}
        />
        <IconButton 
          className={cn(s.forceDisconnectBtn)}
          onClick={(e) => {
            e.stopPropagation();
            onPressForceDisconnect();
          }} 
          aria-label="disconnect" 
          size='small' 
          title={t("Other.forceDisconnect")}
        >
          <Box 
            style={{
              transitionDuration: "0.4s",
              opacity: hovered ? 1 : 0
            }}
          >
            <CloseCircleIcon color={theme.colors.mandy} bgColor={theme.colors.bgTetriary}/>
          </Box>
        </IconButton>
      </Box>

      <Box className={cn(s.col)}>
        <p className={cn(s.text)}>
          {title}
        </p>
        {!!projectName &&
          <p className={cn(s.text)}>
            {projectName}
          </p>
        }
        {!!description &&
          <p className={cn(s.text)}>
            {description}
          </p>
        }
      </Box>

      {connected &&
        <Box className={cn(s.connectionIndicator)} title={t("Other.currentlyConnected")}>
          <Box className={cn(s.connectionIcon)}>
            <ConnectionIcon color={theme.colors.gotSomethingNewIndicator}/>
          </Box>
        </Box>
      }
    </Box>
  );
};

ClientPreviewItem.defaultProps={}

const MemorizedComponent = memo(ClientPreviewItem);
export { MemorizedComponent as ClientPreviewItem };