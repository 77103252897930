import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import { handleFoldChange, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { deleteTanStackQueryPinnedPathsFromLocalStorage, saveTanStackQueryPinnedPathsToLocalStorage } from "~/helpers/localStorage";
import { showToast } from "~/helpers/alertService";
import { TanStackQueriesDataCopyPretty, TanStackQueriesDataCopyPrettyWithTimestamp } from '~/types/types';
import i18next from 'i18next';

const MAX_PINNED_PATHS = 20;

type HandleFoldChangePayload = {
  lineIndex: number;
  newOpened: boolean;
};

type PinPathPayload = {
  path: string;
  apiKey: string;
}

type UnpinPathPayload = {
  path: string;
  apiKey: string;
}

type ClearPinnedPathsPayload = {
  apiKey: string;
}

type OurTanStackQueryInspectorState = {
  tanStackQueriesDataCopy: TanStackQueriesDataCopyPretty | null;
  tanStackQueriesDataCopyNumOfLines: number;
  tanStackQueriesDataCopyTimestamp: number | undefined;
  pinnedPaths: string[];
  linesWithMarkup: LinesWithMarkup[];
  gotSomethingNewIndicator: boolean;
};

const initialOurTanStackQueryInspectorState: OurTanStackQueryInspectorState = {
  tanStackQueriesDataCopy: null,
  tanStackQueriesDataCopyNumOfLines: 0,
  tanStackQueriesDataCopyTimestamp: undefined,
  pinnedPaths: [],
  linesWithMarkup: [],
  gotSomethingNewIndicator: false
};

const ourTanStackQueryInspectorSlice = createSlice({
  name: 'ourTanStackQueryInspector',
  initialState: initialOurTanStackQueryInspectorState,
  reducers: {
    setTanStackQueriesDataCopy: {
      reducer(state, action: PayloadAction<TanStackQueriesDataCopyPrettyWithTimestamp>) {
        const tanStackQueriesDataCopy = {queriesData: action.payload.queriesData};
        const s = JSON.stringify(tanStackQueriesDataCopy, null, 2);
        let prevData;
        if (state.tanStackQueriesDataCopy && state.linesWithMarkup.length > 0) {
          prevData = {
            linesWithMarkup: state.linesWithMarkup
          }
        }
  
        const newLinesWithMarkup = markupJSON(s, tanStackQueriesDataCopy, prevData);
        state.linesWithMarkup = newLinesWithMarkup;
        state.tanStackQueriesDataCopy = tanStackQueriesDataCopy;
        state.tanStackQueriesDataCopyNumOfLines = newLinesWithMarkup.length;
        state.tanStackQueriesDataCopyTimestamp = action.payload.timestamp;
      },
      prepare: prepareAutoBatched<TanStackQueriesDataCopyPrettyWithTimestamp>(),
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    handleInspectorFoldChange(state, action: PayloadAction<HandleFoldChangePayload>) {
      const { lineIndex, newOpened } = action.payload;

      state.linesWithMarkup = handleFoldChange(state.linesWithMarkup, lineIndex, newOpened);
    },
    setPinnedPaths(state, action: PayloadAction<string[]>) {
      state.pinnedPaths = action.payload;
    },
    pinPath(state, action: PayloadAction<PinPathPayload>) {
      if (state.pinnedPaths.length === MAX_PINNED_PATHS) {
        showToast(`${i18next.t("Toast.pinsLimitReached")} ${i18next.t("Toast.youCannotPinMoreThan")} ${MAX_PINNED_PATHS} ${i18next.t("Common.objects")}.`, "warning");
        return;
      }

      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.add(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveTanStackQueryPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    unpinPath(state, action: PayloadAction<UnpinPathPayload>) {
      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.delete(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveTanStackQueryPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    clearPinnedPaths(state, action: PayloadAction<ClearPinnedPathsPayload>) {
      state.pinnedPaths = [];
      deleteTanStackQueryPinnedPathsFromLocalStorage(action.payload.apiKey);
    },
    clearAllState: {
      reducer() {
        return {
          ...initialOurTanStackQueryInspectorState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearTanStackQueryInspector: {
      reducer(state) {
        state.tanStackQueriesDataCopy = initialOurTanStackQueryInspectorState.tanStackQueriesDataCopy;
        state.tanStackQueriesDataCopyNumOfLines = initialOurTanStackQueryInspectorState.tanStackQueriesDataCopyNumOfLines;
        state.tanStackQueriesDataCopyTimestamp = initialOurTanStackQueryInspectorState.tanStackQueriesDataCopyTimestamp;
        state.linesWithMarkup = initialOurTanStackQueryInspectorState.linesWithMarkup;
        state.gotSomethingNewIndicator = initialOurTanStackQueryInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<void>(),
    }
  }
})

const ourTanStackQueryInspectorReducer = ourTanStackQueryInspectorSlice.reducer;
const OurTanStackQueryInspectorActions = ourTanStackQueryInspectorSlice.actions;

export { ourTanStackQueryInspectorReducer, OurTanStackQueryInspectorActions };