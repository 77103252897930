export const validateLogin = (login) => /^[\w\@\.\-]{3,100}$/.test(login);

export const validatePassword = (password) => /^[A-Za-zА-Яа-я0-9_\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\`\{\|\}\~]{8,16}$/.test(password);

export const validatePhone = (phone) => /\w+\d{10,14}/.test(phone);
export const validateName = (name) => /^[\wа-яА-Я\sіІєЄґҐїЇ']{2,100}$/.test(name);

export const validateBtcAddress = (address) => /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/.test(address);

export const validateEthAddress = (address) => /^0x[a-fA-F0-9]{40}$/.test(address);

export const validateClearingNumber = (number) => /^[0-9]{4,6}/.test(number);
export const validateAccountNumber = (account) => /^[0-9]{2,12}/.test(account);
export const validateQuestion = (question) => /^.{1,100}/.test(question);

export const validateFloat = (num) => /^-?\d*(\.\d{0,18})?$/.test(num);
export const validateInt = (num) => !/\D/.test(num);
export const validateEmail = (email) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

export const validateApiKey = (key) => /^[a-z0-9]{16}$/.test(key);

export const validateOtpCode = (code) => /^\d{6}$/.test(code);