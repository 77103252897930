import { NetworkFiltersModalState } from "~/logic/user/UserSlice";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useTrackNetworkFiltersApplied = () => {
  const trackNetworkFiltersApplied = (networkFiltersModalState: NetworkFiltersModalState) => {
    segmentAnalytics.track("NetworkTab filters applied", networkFiltersModalState);
  };

  return {
    trackNetworkFiltersApplied
  };
};