import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { RootState } from './rootReducer';
// import { NetworkActions } from '~/logic/network/NetworkSlice';

export const listenerMiddleware = createListenerMiddleware();

export const startAppListening = listenerMiddleware.startListening as TypedStartListening<RootState>;

// startAppListening({
//   matcher: isAnyOf(
//     NetworkActions.addInterceptedRequest,
//     NetworkActions.addInterceptedResponse,
//     NetworkActions.addInterceptedReduxActions,
//     NetworkActions.addInterceptedStorageActions,
//     NetworkActions.addCapturedEvent,
//   ),
//   effect: async (action, listenerApi) => {
//     const prevState = listenerApi.getOriginalState();
//     const state = listenerApi.getState();

//     const count = state.network.interceptedApiCalls.length - prevState.network.interceptedApiCalls.length;

//     if (count !== 0) {
//       // listenerApi.dispatch(NetworkActions.setTimelineVirtuosoTotalCount(state.network.timelineVirtuosoTotalCount + count));
//       listenerApi.dispatch(NetworkActions.setTimelineVirtuosoTotalCount(state.network.interceptedApiCalls.length));
//       listenerApi.dispatch(NetworkActions.setTimelineVirtuosoFirstItemIndex(state.network.timelineVirtuosoFirstItemIndex - count));
//     }
//   }
// });