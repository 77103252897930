import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    position: 'relative',
    // overflowY: 'hidden',
    overflowX: 'hidden'
  },
  sideButtonCont: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: theme.metrics.x,
    top: theme.metrics.x,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.colors.black20,
    borderRadius: theme.metrics.x2,
    zIndex: 3
  },
  copyAll: {
    display: "flex", 
    width: 22, 
    height: 22
  },
  containerOverflowYHidden: {
    overflowY: 'hidden'
  },
  containerOverflowYScroll: {
    overflowY: 'scroll'
  },
  syntaxHighlightingItemContainer: {
    display: "flex",
    width: 32,
    height: 32,
    borderRadius: 16,
    cursor: "pointer",
    overflow: 'hidden',
  },
  syntaxHighlightingItemRotationCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
    rotate: "-60deg"
  },
  syntaxHighlightingItemPart: {
    display: "flex",
    width: "100%",
    height: "50%",
  },
});