import { makeStyles } from '@material-ui/core/styles';
import { Theme } from './theme';

export const useStyles = (theme: Theme) => makeStyles({
  globalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.globalBg
  },
});