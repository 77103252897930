import { makeStyles } from '@material-ui/core/styles';
import { CONFIG } from '~/config';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  currentTabContainer: {
    display: 'flex',
    width: `calc(100% - ${CONFIG.NAV_BAR_WIDTH}px)`,
    height: '100%',
    justifyContent: 'center',
    position: 'relative'
  },
  marginBottom: {
    marginBottom: 8
  },
  rowEnd: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  whiteText: {
    color: theme.colors.textMain,
    margin: 0
  },
  grayText: {
    color: theme.colors.alto,
    margin: 0
  },
  block: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
});