import React, { memo, useState } from 'react';
import { useStyles } from './AccountItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { useDispatch } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { useDependentState } from '~/hooks';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { TestAccount } from '~/types/types';
import cn from 'classnames';
import StarIcon from './assets/star';
import EditIcon from './assets/edit';
import DeleteIcon from './assets/delete';
import { capitalizeString } from '~/helpers/strings';

export type AccountItemProps = {
  account: TestAccount;
  isFavorite?: boolean;
  bgColor?: string;
  bgColorHovered?: string;
  isSelected: boolean;
  marginBottom?: number;
  onPress: () => void;
  onPressFavorite: (fav: boolean) => void;
  onPressDeleteAccount: () => void;
};

const AccountItem: React.FC<AccountItemProps> = ({
  account,
  isFavorite,
  bgColor,
  bgColorHovered,
  isSelected,
  marginBottom,
  onPress,
  onPressFavorite,
  onPressDeleteAccount
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);
  const [description, setDescription] = useDependentState(() => (account?.description ?? ""), [account]);
  const [editDescriptionMode, setEditDescriptionMode] = useState(false);

  const onPressEditDescription = () => {
    setEditDescriptionMode(true);
  }

  const handleDoneEditingDesc = () => {
    dispatch(UserActions.editTestAccount.request({
      id: account._id,
      description
    }));
    setEditDescriptionMode(false);
  }

  return (
    <Box 
      className={cn(s.container)}
      style={{
        marginBottom,
        backgroundColor: hovered ? (bgColorHovered ?? theme.colors.bgSeptenary) : (bgColor ?? theme.colors.bgSenary),
        transitionDuration: "0.4s"
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onPress}
    >
      {isSelected &&
        <Box 
          className={cn(s.selectedIndicator)}
          title={t("Other.thisMailboxIsSelected")}
        />
      }
      <Box className={cn(s.left)}>
        <Box className={cn(s.star)} onClick={(e) => {
          e.stopPropagation();
          onPressFavorite(!isFavorite);
        }}>
          <StarIcon fill={isFavorite? theme.colors.textBright: theme.colors.doveGray}/>
        </Box>
      </Box>
      <Box className={cn(s.infoCont)}>
        <Box className={cn(s.block)}>
          <Box className={cn(s.col)}>
            <p className={cn(s.whiteTextBold)}>
              {`email: ${account.email}`}
            </p>
            <p className={cn(s.whiteText)}>
              {`Pass: ${account.password}`}
            </p>
          </Box>
          <IconButton 
            onClick={(e) => {
              e.stopPropagation();
              onPressDeleteAccount();
            }} 
            size='small'
            className={cn(s.deleteAccountButton)}
            title={t("Other.deleteThisAcc")}
          >
            <DeleteIcon color={theme.colors.iconsMain}/>
          </IconButton>
        </Box>
        <Box className={cn(s.rowCentered)}>
          <Box className={cn(s.row)}>
            <p className={cn(s.whiteText)}>
              {capitalizeString(`${t("Common.description")}: ${!editDescriptionMode ? description : ""}`)}
            </p>
            {editDescriptionMode &&
              <Box className={cn(s.input)}>
                <TextInput 
                  value={description}
                  onChangeText={(text) => setDescription(text)}
                  onBlur={() => handleDoneEditingDesc()}
                  height={20}
                  maxLength={40}
                  fontSize={14}
                  borderRadius={theme.metrics.x}
                  paddingLeft={theme.metrics.x}
                  paddingRight={theme.metrics.x}
                />
              </Box>
            }
          </Box>

          <Box 
            className={cn(s.editDescButton)} 
            title={t("Other.editDescription")}
            style={{
              opacity: hovered ? 1 : 0,
              transitionDuration: "0.4s"
            }}
          >
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                onPressEditDescription();
              }} 
              size='small'
            >
              <EditIcon color={theme.colors.iconsMain} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AccountItem.defaultProps={
  isFavorite: false,
  marginBottom: 8
}

const MemorizedComponent = memo(AccountItem);
export { MemorizedComponent as AccountItem };