import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  grayText: {
    margin: 0,
    color: theme.colors.textTetriary,
    fontFamily: theme.fonts.f300.fontFamily
  },
  grayTextSmall: {
    margin: 0,
    fontSize: 14,
    color: theme.colors.textTetriary,
    fontFamily: theme.fonts.f400.fontFamily
  },
  detailsCont: {
    display: 'flex', 
    flexDirection: 'column',
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 28,
    paddingRight: 28,
  },
  detailsColumn: {
    display: 'flex', 
    flexDirection: 'column',
    width: "100%",
  },
  syntaxHighligherCont: {
    display: 'flex',
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8
  },
  filledText: {
    textOverflow: 'ellipsis',
    overflow: "hidden",
    whiteSpace: 'pre',
    fontFamily: theme.fonts.f400.fontFamily
  },
  wrapText: {
    display: 'flex',
    maxWidth: '100%',
    overflowWrap: 'break-word'
  },
  bottomButton: {
    marginRight: 12,
    marginBottom: 8,
    fontFamily: theme.fonts.f600.fontFamily
  },
  rowDescBetween: {
    display: 'flex', 
    width: 200,
    justifyContent: 'space-between'
  },
  infoCol: {
    display: 'flex', 
    flexDirection: 'column',
    width: "100%",
    paddingTop: 16,
    paddingBottom: 16
  },
  buttonsRow: {
    display: 'flex',
    width: "100%",
    flexWrap: "wrap"
  },
  yellowText: {
    margin: 0,
    fontSize: 14,
    color: theme.colors.textBrightSecondary,
    fontFamily: theme.fonts.f400.fontFamily
  },
});

export const TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE = {
  margin: 0,
  backgroundColor: 'transparent',
  maxWidth: "100%",
  overflowX: "scroll",
  background: undefined,
  padding: "1em",
} as React.CSSProperties;