import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  whiteText: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f400.fontFamily
  },
  whiteTextBolder: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily
  },
  descriptionText: {
    color: theme.colors.alto,
    margin: 0,
    fontFamily: theme.fonts.f400.fontFamily
  },
  left: {
    display: 'flex',
    height: '100%', 
    width: '100%', 
    padding: theme.metrics.x3, 
    position: 'relative'
  },
  constructorCont: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    overflowY: 'scroll',
    overflowX: 'hidden',
    // backgroundColor: theme.colors.bgQuaternary,
    backgroundColor: theme.colors.bgBackdrop,
    borderRadius: theme.metrics.x2
  },
  constructorHeader: {
    position: 'relative',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 50,
    // backgroundColor: 'green'
  },
  constructorKeysSearchCont: {
    display: "flex",
    width: 160,
    flexDirection: "row",
    alignItems: "center",
    position: 'absolute',
    left: theme.metrics.x3
  },
  constructorHeaderRightCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: 'absolute',
    right: theme.metrics.x3,
  },
  constructorValuesCont: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.colors.bgQuaternary,
    backgroundColor: theme.colors.bgQuinary,
    borderRadius: theme.metrics.x2,
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    // overflowY: "scroll",
    // overflowX: 'hidden'
  },
  envSlidingPickerCont: {
    display: "flex",
    width: 180
  },
  rightContainer: {
    display: 'flex', 
    position: 'absolute', 
    right: 0, 
    height: '100%', 
    padding: theme.metrics.x3
  },
  projectsCont: {
    display: "flex",
    flexDirection: 'column',
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.bgBackdrop,
    borderRadius: theme.metrics.x2
  },
  projectsControlBlock: {
    position: 'relative',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 50,
  },
  projectsTitleTextBold: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily
  },
  projectListCont: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    flexGrow: 1,
    backgroundColor: theme.colors.bgQuinary,
    padding: theme.metrics.x2,
    borderRadius: 8,
    overflowY: "scroll",
    overflowX: 'hidden'
  },
  projectsLeftButtonsCont: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: 'absolute',
    left: theme.metrics.x3
  },
  projectsRightButtonsCont: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    position: 'absolute',
    right: theme.metrics.x3
  },
  refreshButton: {
    width: 28,
    height: 28,
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.metrics.x6,
    paddingRight: theme.metrics.x6,
    marginTop: theme.metrics.x3,
    marginBottom: theme.metrics.x
  }
});