import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  leftSide: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 12,
    flexDirection: 'column',
    // backgroundColor: 'blue',
    justifyContent: 'space-between',
    position: 'relative'
  },
  rightContainer: {
    display: 'flex', 
    position: 'absolute', 
    right: 0, 
    height: '100%', 
    padding: 12,
    // backgroundColor: 'green'
  },
  rightSide: {
    display: 'flex',
    width: '100%',
    height: '100%', 
    flexDirection: 'column',
    position: 'relative'
    // backgroundColor: 'green'
  },
  mailBoxContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    paddingBottom: 12,
    position: 'relative',
    // backgroundColor: 'green'
  },
  mailBoxBg: {
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.colors.bgQuaternary,
    borderRadius: 8,
    paddingTop: 40,
    // backgroundColor: 'green'
  },
  mailIconCont: {
    marginBottom: theme.metrics.x4,
    transform: 'scale(1.2)'
  },
  accountInfoContainer: {
    display: 'flex',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    // backgroundColor: 'red'
  },
  accountInfoBg: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors.bgTetriary,
    borderRadius: 8,
  },
  testAccountsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.bgQuaternary,
    borderRadius: 8,
    overflow: 'hidden'
  },
  testAccountsContainerOverflow: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    padding: theme.metrics.x2,
    overflow: 'scroll',
    "&::-webkit-scrollbar": {
      display: "none"
    }
  },
  whiteText: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f400.fontFamily
  },
  whiteTextBold: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily
  },
  infoCol: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
    padding: theme.metrics.x4,
    overflowY: 'scroll',
    overflowX: 'hidden',
    position: 'relative'
  },
  editIconButton: {
    display: 'flex',
    position: 'absolute',
    right: theme.metrics.x3,
    top: theme.metrics.x3,
    height: 28,
    width: 28
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 12,
    marginBottom: 12
  },
  inputCont: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.metrics.x2
  },
  buttonsRowAbsolute: {
    display: 'flex',
    width: '100%',
    justifyContent: "space-between",
    alignItems: 'center',
    position: 'absolute',
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    left: 0,
    top: 4,
    // backgroundColor: 'blue'
  },
  trashIconCont: {
    width: 20,
    height: 20
  },
  refreshIconCont: {
    width: 23,
    height: 23,
    // backgroundColor: 'red'
  },
  backIconCont: {
    width: 23,
    height: 23
  },
  column: {
    flexDirection: 'column'
  }
});