import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useTanStackQueryTabAnalytics = () => {
  const trackUserChangedViewMode = (mode: "default" | "pinned") => {
    segmentAnalytics.track("TanStackQueryTab view mode changed", {mode});
  };

  useEffect(() => {
    segmentAnalytics.page("MainPage", "TanStackQueryTab");
  }, []);

  return {
    trackUserChangedViewMode
  };
};