import React, { useMemo, useRef, useState } from 'react';
import { useStyles } from './ParamsModalStyles';
import { Box } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { EventStateData } from '~/helpers/draggableHelpers';
import { CustomButton } from '../CustomButton';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type ParamsModalProps = CustomModalProps & {
  constructableEvent: EventStateData | undefined | null;
  onPressSend?: (numOfParams: number) => void;
  onChangeText: (param: string, text: string) => void;
};

const ParamsModal: React.FC<ParamsModalProps> = ({
  constructableEvent,
  onPressSend,
  onChangeText,
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [focusedInputIndex, setFocusedInputIndex] = useState(-1);
  
  const inputsRefs = useRef<React.RefObject<HTMLInputElement>[]>([]);
  inputsRefs.current = Object.keys(constructableEvent?.parametersDescription ?? {}).map(
    (_, i) => inputsRefs.current[i] = React.createRef<HTMLInputElement>()
  );
  const sendButtonRef = useRef<HTMLDivElement>(null);

  const numOfParams = useMemo(() => Object.keys(constructableEvent?.parametersDescription ?? {}).length, [constructableEvent]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, ) => {
    if (event.code === "Enter") {
      if (focusedInputIndex >= 0) {
        if (inputsRefs.current[focusedInputIndex + 1]) {
          inputsRefs.current[focusedInputIndex + 1].current?.focus();
        } else {
          inputsRefs.current[focusedInputIndex]?.current?.blur();
          sendButtonRef?.current?.focus();
        }
      } else {
        onPressSend?.(numOfParams);
      }
    }
  }

  return (
    <CustomModal
      {...restProps}
      classname={cn(s.paramsModal)}
      title={t("Other.eventParams")}
      enableBlur={false}
      onKeyDown={onKeyDown}
    >
      <>
        <Box className={cn(s.paramsEnterBlock)}>
          {constructableEvent &&
            Object.keys(constructableEvent.parametersDescription ?? {}).map((param, index) => (
              <Box key={param} className={cn(s.inputCont)}>
                <TextInput 
                  ref={inputsRefs.current[index]}
                  autoFocus={index === 0}
                  label={`${param}:`}
                  labelColor={theme.colors.textMain}
                  value={constructableEvent.params[param]}
                  onChangeText={(text) => onChangeText(param, text)}
                  // @ts-ignore
                  placeholder={constructableEvent.parametersDescription[param]}
                  onFocus={() => setFocusedInputIndex(index)}
                  onBlur={() => setFocusedInputIndex(-1)}
                />
              </Box>
            ))
          }
        </Box>
        {onPressSend &&
          <div className={cn(s.bottomButton)} title={t("Other.sendSingleEvent")}>
            <CustomButton
              ref={sendButtonRef}
              title={t("Common.send")}
              bgColorActive={theme.colors.black}
              colorActive={theme.colors.white}
              onPress={() => onPressSend(numOfParams)}
            />
          </div>
        }
      </>
    </CustomModal>
  );
};

ParamsModal.defaultProps={}

export { ParamsModal };