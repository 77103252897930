import React, { useCallback, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useStyles } from './MailTabStyles';
import { useMailTabAnalytics } from './useMailTabAnalytics';
import { Rnd as ResizeableView } from "react-rnd";
import { Box, Typography } from '@material-ui/core';
import { SwitchColored } from '~/components/SwitchColored';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { AccountItem } from '~/components/AccountItem';
import { TextInput } from '~/components/TextInput';
import { ObjectConstructor } from '~/components/ObjectConstructor';
import { MAX_TEST_ACCOUNTS_COUNT, UserActions } from '~/logic/user/UserSlice';
import { MailBox } from './MailBox';
import { CustomButton } from '~/components/CustomButton';
import { useDependentValue, useDependentState } from '~/hooks';
import { ResizeHandle } from '~/components/ResizeHandle';
import { resizeHandleBottomStyle, resizeHandleRightStyle } from '~/constants/resizeHandleStyles';
import { useWindowSize } from '~/hooks/useWindowSize';
import { useRndSizeState } from '~/hooks/useRndSizeState';
import { useThemeContext } from '~/theme';
import { ObjectT, TestAccount } from '~/types/types';
import { NoData } from '~/components/NoData';
import { UserIcon } from '~/assets/icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const RND_LEFT_ID = "MAIL_TAB_LEFT_RND";
const RND_RIGHT_ID = "MAIL_TAB_RIGHT_RND";
const RND_MAILBOX_TOP_ID = "MAIL_TAB_MAILBOX_TOP_RND";
const RND_MAILBOX_BOTTOM_ID = "MAIL_TAB_MAILBOX_BOTTOM_RND";

export type MailTabProps = {};

const MailTab: React.FC<MailTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const {
    currentTestAccount,
    testAccounts,
    createTestAccount: {
      fetching: createTestAccountFetching
    },
    testAccountsList: {
      fetching: fetchingTestAccounts
    },
    editTestAccount: {
      fetching: fetchingEditTestAccount
    }
  } = useSelector(userSelector);

  const {
    trackUserViewingMailbox
  } = useMailTabAnalytics();

  const { windowWidth, windowHeight } = useWindowSize();
  const [w, setW] = useState(windowWidth);
  const [h, setH] = useState(windowHeight);
  const [lw, setLw] = useRndSizeState(RND_LEFT_ID, w, 0.4);
  const [rw, setRw] = useRndSizeState(RND_RIGHT_ID, w, 0.6);
  const [th, setTh] = useRndSizeState(RND_MAILBOX_TOP_ID, h, 0.65);
  const [bh, setBh] = useRndSizeState(RND_MAILBOX_BOTTOM_ID, h, 0.35);
  const containerRef = useRef<HTMLDivElement>(null);

  const [login, setLogin] = useDependentState(() => (currentTestAccount?.login ?? ""), [currentTestAccount]);
  const [description, setDescription] = useDependentState(() => (currentTestAccount?.description ?? ""), [currentTestAccount]);
  const [isFavoriteCurrent, setIsFavoriteCurrent] = useDependentState(() => (currentTestAccount?.isFavorite ?? false), [currentTestAccount]);

  const handleFavorite = (isFavorite: boolean, id: string) => {
    dispatch(UserActions.editTestAccount.request({
      id,
      isFavorite
    }))
  }

  const createButtonDisabled = useDependentValue(() => {
    let numOfFavorites = 0;

    testAccounts.forEach((account) => numOfFavorites += account.isFavorite ? 1: 0);

    return numOfFavorites === MAX_TEST_ACCOUNTS_COUNT;
  }, [testAccounts]);

  const handleGenerateNewAccount = useCallback(() => {
    dispatch(UserActions.createTestAccount.request({}));
  }, []);

  const onPressDeleteAccount = (id: string, mail: string) => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: `${t("Toast.deletingTestAcc")}: ${mail}`,
      onPressYes: () => dispatch(UserActions.deleteTestAccount.request({id})),
      onPressNo: () => {}
    }));
  }

  const handleRefresh = useCallback((mailBoxId: string | undefined) => {
    if (mailBoxId)
      dispatch(UserActions.checkMailBox.request({id: mailBoxId}));
  }, []);

  const handleSaveChanges = (additionalData: ObjectT<string>) => {
    if (currentTestAccount)
      dispatch(UserActions.editTestAccount.request({
        login,
        description,
        isFavorite: isFavoriteCurrent,
        additionalData,
        id: currentTestAccount._id
      }));
  }

  useLayoutEffect(() => {
    setW(containerRef?.current?.offsetWidth ?? windowWidth);
    setH(containerRef?.current?.offsetHeight ?? windowHeight);
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    if (testAccounts.length === 0)
      dispatch(
        UserActions.testAccountsList.request({
          index: 0
        })
      );
  }, []);

  return (
    <div className={cn(ds.fullSpaceRelative)} ref={containerRef}>
      <ResizeableView
        disableDragging={true}
        enableResizing={{"right": true}}
        size={{ width: lw,  height: "100%" }}
        minWidth={windowWidth * 0.28}
        maxWidth={windowWidth * 0.53}
        onResize={(e, direction, ref) => {
          setLw(ref.offsetWidth);
          setRw(w - ref.offsetWidth);
        }}
        onResizeStop={(e, direction, ref) => dispatch(UserActions.setRndDefaultSizeSettings([{key: RND_LEFT_ID, value: ref.offsetWidth / w}, {key: RND_RIGHT_ID, value: (w - ref.offsetWidth) / w}]))}
        resizeHandleStyles={{"right": resizeHandleRightStyle}}
      >
        <Box className={cn(s.leftSide)}>
          <Box className={cn(s.testAccountsContainer)}>
            <Box className={cn(s.testAccountsContainerOverflow)}>
              {!fetchingTestAccounts && testAccounts.length > 0 ? testAccounts.map((account: TestAccount) => (
                  <AccountItem 
                    key={account.email}
                    account={account}
                    isFavorite={account.isFavorite}
                    isSelected={!!currentTestAccount && currentTestAccount._id === account._id}
                    onPress={() => {
                      dispatch(UserActions.setCurrentTestAccount(account));
                      dispatch(UserActions.setTestAccountsModalVisible(false));
                      trackUserViewingMailbox();
                    }}
                    onPressFavorite={(f) => {
                      handleFavorite(f, account._id)
                    }}
                    onPressDeleteAccount={() => onPressDeleteAccount(account._id, account.email)}
                  />
                ))
              :
                <NoData 
                  text={t("Common.listEmpty")}
                  textColor={theme.colors.white}
                  loading={fetchingTestAccounts}
                />
              }
            </Box>
            <Box className={cn(s.bottomButton)} title={createButtonDisabled? `${t("MainPage.MailTab.maxFavAccsReached")}!` : t("MainPage.MailTab.generateNewAcc")}>
              <CustomButton 
                title={t("MainPage.MailTab.generateNewAcc")}
                onPress={handleGenerateNewAccount}
                spinner={createTestAccountFetching}
                disabled={createButtonDisabled}
              />
            </Box>
          </Box>

          <ResizeHandle height={"calc(100% - 48px)"} top={24} right={4}/>
        </Box>
      </ResizeableView>

      <div style={{width: rw}} className={cn(s.rightContainer)}>
        <Box className={cn(s.rightSide)}>
          <ResizeableView
            disableDragging={true}
            enableResizing={{"bottom": true}}
            size={{ width: rw - 24, height: th }}
            minHeight={windowHeight * 0.35}
            maxHeight={windowHeight * 0.8}
            onResize={(e, direction, ref) => {
              setTh(ref.offsetHeight);
              setBh(h - ref.offsetHeight);
            }}
            onResizeStop={(e, direction, ref) => dispatch(UserActions.setRndDefaultSizeSettings([{key: RND_MAILBOX_TOP_ID, value: ref.offsetHeight / h}, {key: RND_MAILBOX_BOTTOM_ID, value: (h - ref.offsetHeight) / h}]))}
            resizeHandleStyles={{"bottom": resizeHandleBottomStyle}}
          >
            <Box className={cn(s.mailBoxContainer)}>
              <Box className={cn(s.mailBoxBg)}>
                <MailBox handleRefresh={(id: string) => handleRefresh(id)}/>
              </Box>
              <ResizeHandle width={"calc(100% - 32px)"} height={4} bottom={4} left={16}/>
            </Box>
          </ResizeableView>
          
          
          <Box className={cn(s.accountInfoContainer)} style={{height: bh - 24}}>
            <Box className={cn(s.accountInfoBg)}>
              {currentTestAccount ?
                <Box className={cn(s.infoCol)}>
                  <Box className={cn(s.inputCont)}>
                    <TextInput 
                      value={currentTestAccount.email}
                      editable={false}
                      onChangeText={(text) => {}}
                      label={"email"}
                      labelColor={theme.colors.textMain}
                      copyBtn={true}
                    />
                  </Box>
                  <Box className={cn(s.inputCont)}>
                    <TextInput 
                      value={currentTestAccount.password}
                      editable={false}
                      onChangeText={(text) => {}}
                      label={t("Common.password")}
                      labelColor={theme.colors.textMain}
                      copyBtn={true}
                    />
                  </Box>
                  <Box className={cn(s.inputCont)}>
                    <TextInput 
                      value={login}
                      editable={true}
                      onChangeText={(text) => setLogin(text)}
                      label={t("Common.login")}
                      labelColor={theme.colors.textMain}
                    />
                  </Box>
                  <Box className={cn(s.inputCont)}>
                    <TextInput 
                      value={description}
                      editable={true}
                      onChangeText={(text) => setDescription(text)}
                      label={t("Common.description")}
                      labelColor={theme.colors.textMain}
                    />
                  </Box>
                  <Box className={cn(s.row)}>
                    <p className={cn(s.whiteText)}>
                      {`${t("Common.favorite")}:`}
                    </p>
                    <SwitchColored
                      checked={isFavoriteCurrent}
                      onChange={(e) => setIsFavoriteCurrent(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Box>
                  <ObjectConstructor 
                    value={currentTestAccount.additionalData ?? {}}
                    onChangeValue={(value) => handleSaveChanges(value)}
                    fetchingSave={fetchingEditTestAccount}
                  />
                </Box>
              :
                <Box className={cn(ds.fullSpaceCenteredCol)}>
                  <Box style={{marginBottom: theme.metrics.x2}}>
                    <UserIcon 
                      color1={theme.colors.textBright} 
                      color2={theme.colors.textBright}
                    />
                  </Box>
                  <Typography variant="h5" paragraph className={cn(s.whiteTextBold)}>
                    {t("MainPage.MailTab.noTestAccSelected")}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

MailTab.defaultProps={}

export { MailTab };