import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) =>  makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.bgSecondary
  },
  whiteText: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily,
    fontSize: 32,
    padding: 0,
    margin: 0,
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    marginTop: 20,
    width: 250
  }
});