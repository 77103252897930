import React from 'react';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';

export type ConnectionStatusProps = {
  connected: boolean;
  size?: number;
};

const ConnectionStatus: React.FC<ConnectionStatusProps> = ({
  connected,
  size
}) => {
  const { theme: { colors } } = useThemeContext();
  const { t } = useTranslation();

  return (
    <Box 
      style={{
        width: size,
        height: size,
        borderRadius: size! * 0.5,
        backgroundColor: connected ? colors.connectionStatusActive: colors.connectionStatusUnactive
      }} 
      title={connected ? t("Common.connected"): t("Common.notConnected")}
    />
  );
};

ConnectionStatus.defaultProps={
  size: 6
}

export { ConnectionStatus };