import React, { useMemo } from 'react';
import { useStyles } from './ObjectConstructorStyles';
import { Box, IconButton } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { CustomButton } from '~/components/CustomButton';
import { ObjectT } from '~/types/types';
import { useDependentState } from '~/hooks';
import { showToast } from '~/helpers/alertService';
import { CloseCircleIcon, PlusIcon } from "~/assets/icons";
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { foundСoincidences } from '~/helpers/strings';
import cn from 'classnames';

export const MAX_KEY_VALUE_PAIRS = 1e2;
const INPUTS = new Array(MAX_KEY_VALUE_PAIRS).fill("0").map((_, i) => `Input ${i + 1}`);

export type ObjectConstructorProps = {
  value: ObjectT<string>;
  fetchingSave?: boolean;
  disabledSave?: boolean;
  searchQuery?: string;
  keyInputWidth?: string | number;
  valueInputWidth?: string | number;
  onChangeValue: (newObj: ObjectT<string>) => void;
};

const ObjectConstructor: React.FC<ObjectConstructorProps> = ({
  value,
  fetchingSave,
  disabledSave,
  searchQuery,
  keyInputWidth,
  valueInputWidth,
  onChangeValue
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [keys, setKeys] = useDependentState<string[]>(() => Object.keys(value), [value]);
  const [values, setValues] = useDependentState<string[]>(() => Object.values(value), [value]);

  const inputsFiltered = useMemo(() => 
    INPUTS.map((id, index) => ({id, origIndex: index}))
    .filter(({origIndex}) => origIndex < keys.length && foundСoincidences(keys[origIndex], searchQuery ?? "")), 
    [keys, searchQuery]
  );

  const handleChangeKey = (index: number, newKey: string) => {
    const copyKeys = [...keys];
    copyKeys[index] = newKey;
    setKeys(copyKeys);
  };

  const handleChangeValue = (index: number, newValue: string) => {
    const copyValues = [...values];
    copyValues[index] = newValue;
    setValues(copyValues);
  };

  const onPressAddKeyValuePair = () => {
    const copyKeys = [...keys];
    const copyValues = [...values];
    copyKeys.push("");
    copyValues.push("");
    setKeys(copyKeys);
    setValues(copyValues);
  };

  const onPressDeleteKeyValuePair = (index: number) => {
    const copyKeys = [...keys];
    const copyValues = [...values];
    copyKeys.splice(index, 1);
    copyValues.splice(index, 1);
    setKeys(copyKeys);
    setValues(copyValues);
  };

  const validationOk = () => {
    const keySet = new Set();

    for (const key of keys) {
      if (keySet.has(key)) {
        showToast(t("Toast.objectConstructorIdenticalKeysErrorMessage"), "warning");
        return false;
      }
      if (key === "") {
        showToast(t("Toast.objectConstructorEmptyKeysErrorMessage"), "warning");
        return false;
      }
      if (/\d/.test(key)) {
        showToast(t("Toast.objectConstructorKeysContainDigitsErrorMessage"), "warning");
        return false;
      }
      if (/\s/g.test(key)) {
        showToast(t("Toast.objectConstructorKeysContainSpacesErrorMessage"), "warning");
        return false;
      }
      keySet.add(key);
    }

    return true;
  }

  const onPressSaveChanges = () => {
    if (validationOk()) {
      const constructedObject: ObjectT<string> = {};
      for (let i = 0; i < keys.length; i++)
        constructedObject[keys[i]] = values[i];

      onChangeValue(constructedObject);
    }
  };
 
  return (
    <Box className={cn(s.container)}>
      <Box className={cn(s.col)}>
        {inputsFiltered.map(({id, origIndex}) => (
          <Box key={id} className={cn(s.keyValueRow)}>
            <Box 
              className={cn(s.keyInput)}
              style={{width: keyInputWidth}}
            >
              <TextInput
                value={keys[origIndex]}
                onChangeText={(t: string) => handleChangeKey(origIndex, t)}
                placeholder={"key"}
              />
            </Box>
            <Box className={cn(s.betweenInputs)}>
              <p className={cn(s.dividerText)}>
                {":"}
              </p>
            </Box>
            <Box 
              className={cn(s.valueInput)}
              style={{width: valueInputWidth}}
            >
              <TextInput
                value={values[origIndex]}
                onChangeText={(t: string) => handleChangeValue(origIndex, t)}
                placeholder={"value"}
              />
              <IconButton 
                onClick={(e) => onPressDeleteKeyValuePair(origIndex)} 
                size={'small'}
                className={cn(s.deleteButton)}
              >
                <CloseCircleIcon color={theme.colors.mandy} bgColor={theme.colors.bgTetriary}/>
              </IconButton>
            </Box>
          </Box>
        ))}
        <Box className={cn(s.bottomCol)}>
          {keys.length < MAX_KEY_VALUE_PAIRS &&
            <Box title={t(!!searchQuery ? "MainPage.RemoteSettingsTab.disabledNonEmptySearch" : "MainPage.RemoteSettingsTab.addKeyValuePair")}>
              <IconButton 
                onClick={(e) => onPressAddKeyValuePair()} 
                size={'small'}
                className={cn(s.plusButton)}
                disabled={!!searchQuery}
              >
                <PlusIcon color={!!searchQuery ? theme.colors.buttonBgUnactive : theme.colors.textBright}/>
              </IconButton>
            </Box>
          }
        </Box>
      </Box>
      <Box className={s?.saveButton}>
        <CustomButton 
          title={t("Other.saveChanges")}
          onPress={onPressSaveChanges}
          spinner={fetchingSave}
          disabled={disabledSave}
        />
      </Box>
    </Box>
  );
};

ObjectConstructor.defaultProps={
  fetchingSave: false,
  disabledSave: false,
  keyInputWidth: "20%",
  valueInputWidth: "75%"
}

export { ObjectConstructor };