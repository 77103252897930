import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: theme.metrics.x,
    transitionDuration: "0.4s"
  },
});