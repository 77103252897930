import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  containerModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    height: '60vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    // backgroundColor: 'green',
    overflow: 'scroll'
  },
});