import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import { put, call, select, delay, takeLatest } from "redux-saga/effects";
import { RequestErrorFromResponse, api, requestErrorFromResponse, updateAuthorizationHeaderWithApiKey, updateAuthorizationHeaderWithToken } from "~/api";
import { DEFAULT_APP_SETTINGS, UserActions } from "./UserSlice";
import { takeEveryRegexp, takeLeading } from "~/store/sagaHelper";
import { apiKeySelector, checkMailBoxDataSelector, currentTestAccountSelector, navigationTabSelector, projectsSettingSelector, testAccountsSelector, userTokenSelector } from "./UserSelectors";
import { AuthResponse, CheckMailBoxResponse, ClearMailBoxResponse, ClientsQueueResponse, CreateTestAccountResponse, DeleteTestAccountResponse, EditProfileResponse, EditTestAccountResponse, ForgotPasswordResponse, OtpResponse, PersonalSettingResponse, ProfileResponse, ResendOtpResponse, TestAccountsListResponse } from "~/types/api";
import { deleteApiKeyFromLocalStorage, deleteRndDefaultSizeSettingsFromLocalStorage, deleteUserTokenFromLocalStorage, getAppSettingsFromLocalStorage, getChosenNavBarTabsFromLocalStorage, getRndDefaultSizeSettingsFromLocalStorage, saveUserTokenToLocalStorage } from "~/helpers/localStorage";
import { NetworkActions } from "../network/NetworkSlice";
import { OurReduxInspectorActions } from "../ourReduxInspector/OurReduxInspectorSlice";
import { socketService } from "~/services/socketService";
import { showToast } from "~/helpers/alertService";
import { RemoteSettingsActions } from "../remoteSettings/RemoteSettingsSlice";
import { classicApiResponseValidator, retryApiCall } from "~/helpers/sagaHelpers";
import { NavigationTab, PersonalProjectsSetting, TestAccount } from "~/types/types";
import { OurZustandInspectorActions } from "../ourZustandInspector/OurZustandInspectorSlice";
import { OurTanStackQueryInspectorActions } from "../ourTanStackQueryInspector/ourTanStackQueryInspectorSlice";
import { OurContextsInspectorActions } from "../ourContextsInspector/OurContextsInspectorSlice";
import { OurMobxInspectorActions } from "../ourMobxInspector/OurMobxInspectorSlice";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";
import { validateSavedNavBarTabs } from "~/components/NavigationBar/validateSavedNavBarTabs";
import { ERRORS_500_PROBLEMS } from "~/constants/criticalProblems";
import i18next from "i18next";

function* initialSaga(action: Action) {
  if (UserActions.initialSaga.request.match(action)) {
    try { 
      yield put(UserActions.detectUserOS());
      
    } catch (error) {
      yield put(UserActions.initialSaga.failure(error));
    }
  }
}

function* authRequest(action: Action) {
  if (UserActions.auth.request.match(action)) {
    try {
      const { navigate } = action.payload;

      const authResponse: ApiResponse<AuthResponse> = yield call(
        api.authPost,
        action.payload
      );
      if (authResponse.ok && authResponse.data) {
        const responseData = authResponse.data;

        yield call(saveUserTokenToLocalStorage, responseData.access_token);
        yield put(UserActions.setUserToken(responseData.access_token));
        yield call(updateAuthorizationHeaderWithToken, responseData.access_token);
        yield put(UserActions.setUserEmail(action.payload.email));

        const shouldOTP = !responseData.activated;

        if (shouldOTP) {
          navigate("/otp");
        } else {
          navigate("/main", {replace: true});
        }

        yield put(UserActions.auth.success({...responseData, shouldOTP}));
      } else {
        yield call(showToast, authResponse.data?.message ?? "Server error", "error");

        throw requestErrorFromResponse(authResponse);
      }
    } catch (error) {
      yield put(UserActions.auth.failure(error));
    }
  }
}

function* otpRequest(action: Action) {
  if (UserActions.otp.request.match(action)) {
    try {
      const otpResponse: ApiResponse<OtpResponse> = yield call(
        api.otpPost,
        action.payload
      );
      if (otpResponse.ok && otpResponse.data) {
        action.payload.navigate("/main");

        yield put(UserActions.otp.success({message: "OK"}));
      } else {
        if (otpResponse.status === 403) {
          yield call(showToast, i18next.t("Toast.wrongOtpCode"), "warning");
        } else {
          yield call(showToast, i18next.t("Toast.serverErrorTryAgain"), "error");
        }
        
        throw requestErrorFromResponse(otpResponse);
      }
    } catch (error) {
      yield put(UserActions.otp.failure(error));
    }
  }
}

function* resendOtpRequest(action: Action) {
  if (UserActions.resendOtp.request.match(action)) {
    try {
      const resendOtpResponse: ApiResponse<ResendOtpResponse> = yield call(
        api.resendOtpPost,
        action.payload
      );
      if (resendOtpResponse.ok && resendOtpResponse.data) {
        yield put(UserActions.setUserEmail(action.payload.email));
        yield call(showToast, i18next.t("Toast.weSentYouVerificationCode"), "success");

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield put(UserActions.resendOtp.success({message: "OK"}));
      } else {
        yield call(showToast, resendOtpResponse.status === 404 ? i18next.t("Toast.userNotFound") : "Server error", "error");
        throw requestErrorFromResponse(resendOtpResponse);
      }
    } catch (error) {
      yield put(UserActions.resendOtp.failure(error));
    }
  }
}

function* profileRequest(action: Action) {
  if (UserActions.profile.request.match(action)) {
    const userToken: string = yield select(userTokenSelector);

    try {
      const profileResponse: ApiResponse<ProfileResponse> = yield call(retryApiCall, {
        apiRequest: api.profileGet
      });
      if (profileResponse.ok && profileResponse.data) {
        const responseData = profileResponse.data;

        yield call({context: segmentAnalytics, fn: "identify" }, {
          id: responseData.client._id,
          apiKey: responseData.client.apiKey,
          email: responseData.client.email
        });

        const rndDefaultSizeSettings: string | null = yield call(getRndDefaultSizeSettingsFromLocalStorage, responseData.client.apiKey, userToken as string);
        if (rndDefaultSizeSettings) {
          yield put(UserActions.setFullRndDefaultSizeSettings(JSON.parse(rndDefaultSizeSettings)));
        }

        const chosenNavBarTabs: string | null = yield call(getChosenNavBarTabsFromLocalStorage, responseData.client.apiKey);
        if (chosenNavBarTabs) {
          const parsedChosenNavBarTabs = JSON.parse(chosenNavBarTabs);
          const savedTabsAreValid: boolean = yield call(validateSavedNavBarTabs, parsedChosenNavBarTabs);

          if (savedTabsAreValid) {
            yield put(UserActions.setChosenNavBarTabs(parsedChosenNavBarTabs));
          }
        }

        yield call({context: socketService, fn: "init"}, responseData.client.apiKey, action.payload.navigate);

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield put(UserActions.profile.success(responseData.client));
      } else {
        if (profileResponse.status === null || (typeof profileResponse.status === "number" && profileResponse.status >= 500)) {
          yield call(showToast, i18next.t("Toast.unableToLoadProfileData"), "error");
          if (action.payload.navigate) {
            action.payload.navigate("/internal_error", {state: {problem: ERRORS_500_PROBLEMS.UNABLE_TO_LOAD_PROFILE_DATA}});
          }
        }

        throw requestErrorFromResponse(profileResponse);
      }
    } catch (error) {
      yield put(UserActions.profile.failure(error));
    }
  }
}

function* editProfileRequest(action: Action): any {
  if (UserActions.editProfile.request.match(action)) {
    try {
      const projectsSetting: PersonalProjectsSetting = yield select(projectsSettingSelector);

      // Optimistic update
      yield put(UserActions.updateProjectsSetting({
        personalProjectsSetting: action.payload.projectsSetting
      }));

      const editProfileResponse: ApiResponse<EditProfileResponse> = yield call(
        api.editProfilePost,
        action.payload
      );
      if (classicApiResponseValidator(editProfileResponse)) {
        // const responseData = editProfileResponse.data!;
        yield put(UserActions.editProfile.success());
      } else {
        // Reverse optimistic update
        yield put(UserActions.updateProjectsSetting({
          personalProjectsSetting: projectsSetting
        }));

        throw requestErrorFromResponse(editProfileResponse);
      }
    } catch (error) {
      yield put(UserActions.editProfile.failure(error));
    }
  }
}

function* personalSettingRequest(action: Action): any {
  if (UserActions.personalSetting.request.match(action)) {
    try {
      const personalSettingResponse: ApiResponse<PersonalSettingResponse> = yield call(
        api.personalSettingGet,
        action.payload
      );
      if (classicApiResponseValidator(personalSettingResponse)) {
        const responseData = personalSettingResponse.data!;
        yield put(UserActions.personalSetting.success(responseData.client));
      } else {
        throw requestErrorFromResponse(personalSettingResponse);
      }
    } catch (error) {
      yield put(UserActions.personalSetting.failure(error));
    }
  }
}

function* handleGotApiKey(action: Action) {
  if (UserActions.handleGotApiKey.match(action)) {
    const apiKey = action.payload;

    yield put(UserActions.setApiKey(apiKey));
    yield call(updateAuthorizationHeaderWithApiKey, apiKey);

    if (apiKey) {
      const savedAppSettings: string | null = yield call(getAppSettingsFromLocalStorage, apiKey);
      if (savedAppSettings) {
        const savedSettings = JSON.parse(savedAppSettings);
        const actualSettings = JSON.parse(JSON.stringify(DEFAULT_APP_SETTINGS));
        for (const key of Object.keys(actualSettings))
          if (savedSettings[key])
            actualSettings[key].value = savedSettings[key].value;

        yield put(UserActions.applyAndSetAppSettings({
          appSettings: actualSettings,
          updateLocalStorage: false
        }));
      }

      yield put(UserActions.testAccountsList.request({index: 0}));
      yield put(UserActions.clientsQueue.request());
    }
  }
}

function* applyAndSetAppSettings(action: Action) {
  if (UserActions.applyAndSetAppSettings.match(action)) {
    // Apply appSettings if needed here

    // Set new appSettings
    yield put(UserActions.setAppSettings(action.payload));
  }
}

function* tokenRelevanceCheck(action: {type: string, error: RequestErrorFromResponse}) {
  try {
    let { error } = action;

    if (error?.code === 401) { // 401 means 'not authorized'
      yield put(UserActions.logout.request());
    }

  } catch (error) {
    yield put(UserActions.logout.request());
  }
}

function* logoutRequest(action: Action) {
  if (UserActions.logout.request.match(action)) {
    try {
      const apiKey: string = yield select(apiKeySelector);
      const userToken: string = yield select(userTokenSelector);

      // Disconnect socket && clear SocketService
      yield call({context: socketService, fn: "disconnect"});

      // Clear localStorage
      yield call(deleteRndDefaultSizeSettingsFromLocalStorage, apiKey, userToken);
      yield call(deleteApiKeyFromLocalStorage);
      yield call(deleteUserTokenFromLocalStorage);

      // Clear network redux
      yield put(NetworkActions.clearAllState());
      
      // Clear ourReduxInspector redux
      yield put(OurReduxInspectorActions.clearAllState());

      // Clear ourZustandInspector redux
      yield put(OurZustandInspectorActions.clearAllState());

      // Clear ourTanStackQueryInspector redux
      yield put(OurTanStackQueryInspectorActions.clearAllState());

      // Clear ourContextsInspector redux
      yield put(OurContextsInspectorActions.clearAllState());

      // Clear ourMobxInspector redux
      yield put(OurMobxInspectorActions.clearAllState());

      // Clear remoteSettings redux
      yield put(RemoteSettingsActions.clearAllState());

      // Clear user redux
      yield put(UserActions.clearAllState());

      // Clear ApiSauce instances auth headers
      yield call(updateAuthorizationHeaderWithApiKey, "");
      yield call(updateAuthorizationHeaderWithToken, "");
    } catch (error) {
      yield put(UserActions.logout.failure(error));
    }
  }
}

function* generateApiKeyRequest(action: Action) {
  if (UserActions.generateApiKey.request.match(action)) {
    try {
      const generateApiKeyResponse: ApiResponse<ProfileResponse> = yield call(
        api.generateApiKeyGet
      );
      if (generateApiKeyResponse.ok && generateApiKeyResponse.data) {
        const responseData = generateApiKeyResponse.data;
        yield call({context: socketService, fn: "init"}, responseData.client.apiKey);
        yield put(UserActions.profile.success(responseData.client));
        yield put(UserActions.generateApiKey.success(responseData.client));
      } else {
        throw requestErrorFromResponse(generateApiKeyResponse);
      }
    } catch (error) {
      yield put(UserActions.generateApiKey.failure(error));
    }
  }
}

function* createTestAccountRequest(action: Action) {
  if (UserActions.createTestAccount.request.match(action)) {
    try {
      const apiKey: string = yield select(apiKeySelector);

      const createTestAccountResponse: ApiResponse<CreateTestAccountResponse> = yield call(
        api.createTestAccountPost,
        action.payload
      );
      if (createTestAccountResponse.ok && createTestAccountResponse.data) {
        const responseData = createTestAccountResponse.data;
        const testAccount = {...responseData.account, pass: responseData.account.password};
        yield put(UserActions.addTestAccount({
          account: testAccount,
          apiKey
        }));
        yield put(UserActions.createTestAccount.success(testAccount));
      } else {
        throw requestErrorFromResponse(createTestAccountResponse);
      }
    } catch (error) {
      yield put(UserActions.createTestAccount.failure(error));
    }
  }
}

function* checkMailBoxRequest(action: Action) {
  if (UserActions.checkMailBox.request.match(action)) {
    try {
      const checkMailBoxResponse: ApiResponse<CheckMailBoxResponse> = yield call(
        api.checkMailBoxGet,
        action.payload
      );
      if (checkMailBoxResponse.ok && checkMailBoxResponse.data) {
        const responseData = checkMailBoxResponse.data;
        yield put(UserActions.checkMailBox.success({...responseData.account, password: responseData.account.password}));
      } else {
        throw requestErrorFromResponse(checkMailBoxResponse);
      }
    } catch (error) {
      yield put(UserActions.checkMailBox.failure(error));
    }
  }
}

function* testAccountsListRequest(action: Action) {
  if (UserActions.testAccountsList.request.match(action)) {
    try {
      const testAccountsListResponse: ApiResponse<TestAccountsListResponse> = yield call(
        api.testAccountsListGet,
        action.payload
      );
      if (testAccountsListResponse.ok && testAccountsListResponse.data) {
        const responseData = testAccountsListResponse.data;
        yield put(UserActions.setTestAccounts(responseData.accounts));
        yield put(UserActions.testAccountsList.success(responseData.accounts));
      } else {
        throw requestErrorFromResponse(testAccountsListResponse);
      }
    } catch (error) {
      yield put(UserActions.testAccountsList.failure(error));
    }
  }
}

function* clearMailBoxRequest(action: Action) {
  if (UserActions.clearMailBox.request.match(action)) {
    try {
      const clearMailBoxResponse: ApiResponse<ClearMailBoxResponse> = yield call(
        api.clearMailBoxDelete,
        action.payload
      );
      if (clearMailBoxResponse.ok && clearMailBoxResponse.data) {
        const responseData = clearMailBoxResponse.data;
        const testAccount = {...responseData.account, pass: responseData.account.password};
        yield put(UserActions.checkMailBox.success(testAccount))
        yield put(UserActions.clearMailBox.success(testAccount));
      } else {
        throw requestErrorFromResponse(clearMailBoxResponse);
      }
    } catch (error) {
      yield put(UserActions.clearMailBox.failure(error));
    }
  }
}

function* deleteTestAccountRequest(action: Action) {
  if (UserActions.deleteTestAccount.request.match(action)) {
    try {
      const deleteTestAccountResponse: ApiResponse<DeleteTestAccountResponse> = yield call(
        api.deleteTestAccountDelete,
        action.payload
      );
      if (deleteTestAccountResponse.ok && deleteTestAccountResponse.data) {
        const testAccounts: TestAccount[] = yield select(testAccountsSelector);

        yield put(UserActions.setTestAccounts(testAccounts.filter((a) => a._id !== action.payload.id)));

        const checkMailBoxData: ReturnType<typeof checkMailBoxDataSelector> = yield select(checkMailBoxDataSelector);
        if (checkMailBoxData && checkMailBoxData._id === action.payload.id) {
          yield put(UserActions.clearCheckMailBox());
          yield put(UserActions.setViewingMail(null));
        }

        const currentTestAccount: ReturnType<typeof currentTestAccountSelector> = yield select(currentTestAccountSelector);
        if (currentTestAccount && currentTestAccount._id === action.payload.id) {
          yield put(UserActions.setCurrentTestAccount(null));
        }

        yield put(UserActions.deleteTestAccount.success({message: "OK"}));
      } else {
        yield call(showToast, i18next.t("Toast.errorWhileDeletingTestAcc"), "error");
        throw requestErrorFromResponse(deleteTestAccountResponse);
      }
    } catch (error) {
      yield put(UserActions.deleteTestAccount.failure(error));
    }
  }
}

function* setNavigationTabHandler(action: Action) {
  if (UserActions.setNavigationTabWithProcessing.match(action)) {
    const prevTab: NavigationTab = yield select(navigationTabSelector);

    yield put(UserActions.setNavigationTab(action.payload));

    switch (prevTab) {
      case "MAIN_TAB":
        yield put(UserActions.setGotSomethingNewIndicator(false));
        break;
      case "MAIL_TAB":
        yield put(UserActions.setEmailTabGotSomethingNewIndicator(false));
        break;
      case "REDUX_INSPECTOR_TAB":
        yield put(OurReduxInspectorActions.setGotSomethingNewIndicator(false));
        break;
      case "ZUSTAND_INSPECTOR_TAB":
        yield put(OurZustandInspectorActions.setGotSomethingNewIndicator(false));
        break;
      case "TANSTACK_QUERY_INSPECTOR_TAB":
        yield put(OurTanStackQueryInspectorActions.setGotSomethingNewIndicator(false));
        break;
      case "CONTEXTS_INSPECTOR_TAB": 
        yield put(OurContextsInspectorActions.setGotSomethingNewIndicator(false));
        break;
      case "MOBX_INSPECTOR_TAB":
        yield put(OurMobxInspectorActions.setGotSomethingNewIndicator(false));
        break;
      case "NETWORK_TAB":
        yield put(NetworkActions.setGotSomethingNewIndicator(false));
        break;
    
      default:
        break;
    }
  }
}

function* forgotPasswordRequest(action: Action) {
  if (UserActions.forgotPassword.request.match(action)) {
    try {
      const forgotPasswordResponse: ApiResponse<ForgotPasswordResponse> = yield call(
        api.forgotPasswordPost,
        action.payload
      );
      if (forgotPasswordResponse.ok && forgotPasswordResponse.data) {
        const responseData = forgotPasswordResponse.data;
        yield put(UserActions.setOtpCallback(null));

        // Automatically login user after successfully password recovery
        yield call(saveUserTokenToLocalStorage, responseData.access_token);
        yield put(UserActions.setUserToken(responseData.access_token));
        yield call(updateAuthorizationHeaderWithToken, responseData.access_token);
        yield put(UserActions.setUserEmail(action.payload.email));

        action.payload.navigate("/main");

        yield delay(500);
        yield call(showToast, i18next.t("Toast.yourPasswordWasUpdated"), "success");

        yield put(UserActions.forgotPassword.success({message: 'OK'}));
      } else {
        throw requestErrorFromResponse(forgotPasswordResponse);
      }
    } catch (error) {
      yield put(UserActions.forgotPassword.failure(error));
    }
  }
}

function* editTestAccountRequest(action: Action) {
  if (UserActions.editTestAccount.request.match(action)) {
    try {
      const editTestAccountResponse: ApiResponse<EditTestAccountResponse> = yield call(
        api.editTestAccountPost,
        action.payload
      );
      if (classicApiResponseValidator(editTestAccountResponse)) {
        const responseData = editTestAccountResponse.data!;
        const testAccounts: TestAccount[] = yield select(testAccountsSelector);

        yield put(UserActions.setTestAccounts(testAccounts.map((a) => a._id === action.payload.id ? responseData.account : a)));

        yield call(showToast, i18next.t("Toast.testAccWasEdited"), "success");

        const currentTestAccount: TestAccount | null = yield select(currentTestAccountSelector);

        if (currentTestAccount && currentTestAccount._id === responseData.account._id) {
          yield put(UserActions.setCurrentTestAccount(responseData.account));
        }

        yield put(UserActions.editTestAccount.success());
      } else {
        yield call(showToast, i18next.t("Toast.testAccEditErrorMessage"), "error");

        throw requestErrorFromResponse(editTestAccountResponse);
      }
    } catch (error) {
      yield put(UserActions.editTestAccount.failure(error));
    }
  }
}

function* clientsQueueRequest(action: Action) {
  if (UserActions.clientsQueue.request.match(action)) {
    try {
      const clientsQueueResponse: ApiResponse<ClientsQueueResponse> = yield call(
        api.clientsQueueGet
      );
      if (classicApiResponseValidator(clientsQueueResponse)) {
        const responseData = clientsQueueResponse.data!;

        yield put(UserActions.setClientsQueueTable(responseData.queue));

        yield put(UserActions.clientsQueue.success());
      } else {
        throw requestErrorFromResponse(clientsQueueResponse);
      }
    } catch (error) {
      yield put(UserActions.clientsQueue.failure(error));
    }
  }
}

export function* UserSaga() {
  yield* [
    takeLatest(UserActions.initialSaga.request.type, initialSaga),
    takeLatest(UserActions.auth.request.type, authRequest),
    takeLatest(UserActions.otp.request.type, otpRequest),
    takeLatest(UserActions.resendOtp.request.type, resendOtpRequest),
    takeLatest(UserActions.profile.request.type, profileRequest),
    takeLatest(UserActions.editProfile.request.type, editProfileRequest),
    takeLatest(UserActions.personalSetting.request.type, personalSettingRequest),
    takeLatest(UserActions.handleGotApiKey.type, handleGotApiKey),
    takeLatest(UserActions.applyAndSetAppSettings.type, applyAndSetAppSettings),
    takeLatest(UserActions.generateApiKey.request.type, generateApiKeyRequest),
    takeEveryRegexp(/_failure$/, tokenRelevanceCheck),
    takeLatest(UserActions.logout.request.type, logoutRequest),
    takeLatest(UserActions.createTestAccount.request.type, createTestAccountRequest),
    takeLatest(UserActions.checkMailBox.request.type, checkMailBoxRequest),
    takeLatest(UserActions.testAccountsList.request.type, testAccountsListRequest),
    takeLatest(UserActions.clearMailBox.request.type, clearMailBoxRequest),
    takeLatest(UserActions.deleteTestAccount.request.type, deleteTestAccountRequest),
    takeLatest(UserActions.setNavigationTabWithProcessing.type, setNavigationTabHandler),
    takeLatest(UserActions.forgotPassword.request.type, forgotPasswordRequest),
    takeLatest(UserActions.editTestAccount.request.type, editTestAccountRequest),
    takeLeading(UserActions.clientsQueue.request.type, clientsQueueRequest)
  ];
}