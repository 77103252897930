import { CustomCommand } from "reactotron-core-client";
import { RemoteSettingsActions } from '~/logic/remoteSettings/RemoteSettingsSlice';
import { store } from "~/store/store";

const GetRemoteSettings: CustomCommand = {
  command: "GetRemoteSettings",
  handler: () => {
    store.dispatch(RemoteSettingsActions.getRemoteSettings.request({
      projectId: "6532e1cd3e49487f6da126d6",
    }));
  },
};

const SetRemoteSettings: CustomCommand = {
  command: "SetRemoteSettings",
  handler: () => {
    store.dispatch(RemoteSettingsActions.setRemoteSettings.request({
      remoteSettings: {
        dev: {
          a: "2",
          b: "string",
          c: "[1, 2, 3]",
          d: "{x: 10, y: 20}",
          e: "null",
          veryVeryVeryVeryLongLongLongKey: "laaaaarge striiiiiiiiiing"
        },
      },
      projectId: "6532e1cd3e49487f6da126d6",
    }));
  },
};

const CreateProject: CustomCommand = {
  command: "CreateProject",
  handler: () => {
    store.dispatch(RemoteSettingsActions.createProject.request({
      name: "ZedCex",
      description: "AppKlaar ZedCex mobile app",
      image: "https://s2.coinmarketcap.com/static/img/coins/64x64/12457.png"
    }))
  }
}

const ProjectList: CustomCommand = {
  command: "ProjectList",
  handler: () => {
    store.dispatch(RemoteSettingsActions.projectList.request({
      index: 0
    }))
  }
}

const ProjectById: CustomCommand = {
  command: "ProjectById",
  handler: () => {
    store.dispatch(RemoteSettingsActions.projectById.request({
      id: "6532e1cd3e49487f6da126d6"
    }))
  }
}

const DeleteProject: CustomCommand = {
  command: "DeleteProject",
  handler: () => {
    store.dispatch(RemoteSettingsActions.deleteProject.request({
      id: "6532e1cd3e49487f6da126d6"
    }))
  }
};

const EditProject: CustomCommand = {
  command: "EditProject",
  handler: () => {
    store.dispatch(RemoteSettingsActions.editProject.request({
      id: "6532e1cd3e49487f6da126d6",
      description: "AppKlaar ZedCex mobile app - edited",
    }))
  }
}

const AddProjectMember: CustomCommand = {
  command: "AddProjectMember",
  handler: () => {
    store.dispatch(RemoteSettingsActions.addProjectMember.request({
      projectId: "6532e1cd3e49487f6da126d6",
      apiKey: "atq1mnrnlnz55z1r"
    }))
  }
};

const RemoveProjectMember: CustomCommand = {
  command: "RemoveProjectMember",
  handler: () => {
    store.dispatch(RemoteSettingsActions.removeProjectMember.request({
      projectId: "6532e1cd3e49487f6da126d6",
      apiKey: "atq1mnrnlnz55z1r"
    }))
  }
};

const commands = [
  GetRemoteSettings,
  SetRemoteSettings,
  CreateProject,
  ProjectList,
  ProjectById,
  DeleteProject,
  EditProject,
  AddProjectMember,
  RemoveProjectMember
];

export { commands };