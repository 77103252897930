import {CONFIG} from '../config';
import {rootSaga} from './rootSaga';
import {getSagaMiddleware} from './store';

Promise.resolve().then(() => {
  getSagaMiddleware().run(rootSaga);

  if (CONFIG.USE_REACTOTRON) {
    const registerCommands = require('../commands').registerCommands;    
    registerCommands();
  }
});
