import React from 'react';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';

export type SomethingNewIndicatorProps = {
  color?: string;
  title?: string;
};

const SomethingNewIndicator: React.FC<SomethingNewIndicatorProps> = ({
  color,
  title
}) => {
  const { theme } = useThemeContext();

  return (
    <Box 
      title={title}
      style={{
        display: "flex",
        height: 6,
        width: 6,
        borderRadius: 3,
        backgroundColor: color ?? theme.colors.gotSomethingNewIndicator,
        zIndex: 3
      }}
    />
  );
};

SomethingNewIndicator.defaultProps={}

export { SomethingNewIndicator };