import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  greenText: {
    margin: 0,
    color: theme.colors.greenHaze,
    fontFamily: theme.fonts.f700.fontFamily
  },
  redText: {
    margin: 0,
    color: theme.colors.mandy,
    fontFamily: theme.fonts.f700.fontFamily
  },
});