import React, { useState } from 'react';
import { useStyles } from './CapturedEventItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/components/TimelineItem';
import { Box, Button } from '@material-ui/core';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { CapturedEvent } from '~/types/types';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import 'animate.css';

export type CapturedEventItemProps = TimelineItemExternalProps & {
  capturedEvent: CapturedEvent;
};

const CapturedEventItem: React.FC<CapturedEventItemProps> = ({
  capturedEvent,
  showBorderBottom,
  timeSincePrevEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();

  const [dataToShow, setDataToShow] = useState("");

  const timeToShow = useTimeToShow(capturedEvent.timestamp);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      timeSincePrevEvent={timeSincePrevEvent}
      showBorderBottom={showBorderBottom}
      leftChildren={
        <p className={cn(s.blueText)} title={t("Other.capturedCustomEvent")}>
          {"CAPTURED EVENT"} 
        </p>
      }
      rightChildren={
        <p className={cn(tds.grayText, tds.filledText)}>
          {capturedEvent.title}
        </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {`${t("Other.title")}: ${capturedEvent.title}`}
              </p>
            </Box>

            <Box className={cn(tds.infoCol)}>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  ID:
                </p>
                <p className={cn(tds.yellowText)}>
                  {capturedEvent.capturedEventId}
                </p>
              </Box>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {`${t("Other.timestamp")}:`}
                </p>
                <p className={cn(tds.yellowText)}>
                  {capturedEvent.timestamp}
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            {capturedEvent.data &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(capturedEvent.data))}
              >
                DATA
              </Button>
            }
            {capturedEvent._stackTraceData &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(capturedEvent._stackTraceData))}
              >
                TRACE
              </Button>
            }
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

CapturedEventItem.defaultProps={}

export { CapturedEventItem };