import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    // paddingLeft: 16,
    // paddingRight: 16
  },
  "&__content": {outline: "none"},
  wrapper: {
    display: 'flex', 
    width: '100%', 
    height: '100%', 
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: theme.metrics.x6,
    position: 'relative',
    // backgroundColor: 'blue'
  },
  title: {
    fontSize: 24,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily,
    margin: 0
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0
  }
});