import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    width: "100%",
    // height: 94,
    minHeight: 80,
    borderRadius: 8,
    // backgroundColor: 'red',
    cursor: "pointer"
  },
  selectedIndicator: {
    position: "absolute",
    left: -17,
    top: -17,
    width: 32,
    height: 32,
    transform: "rotate(45deg)",
    backgroundColor: theme.colors.bgContrastColor
    // opacity: 1
  },
  containerBorder: {
    border: "2px solid #121212",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  infoCont: {
    display: "flex",
    flexDirection: 'column',
    width: '90%',
    height: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: 'center'
  },
  whiteText: {
    margin: 0,
    color: theme.colors.textMain,
    fontSize: 12,
    fontFamily: theme.fonts.f400.fontFamily
  },
  whiteTextBold: {
    fontSize: 14,
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily
  },
  col: {
    display: "flex",
    flexDirection: 'column',
  },
  left: {
    display: "flex",
    flexDirection: 'column',
    width: '10%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    paddingLeft: theme.metrics.x2
  },
  star: {
    zIndex: 3,
    display: "flex",
    width: 24, 
    height: 24,
    cursor: 'pointer'
  },
  editDescButton: {
    width: 20, 
    height: 20,
    marginBottom: 4
  },
  deleteAccountButton: {
    width: 22, 
    height: 22,
  },
  rowCentered: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 8,
    minHeight: 24
  },
  input: {
    display: "flex",
    width: '50%',
    marginLeft: 8
  },
  row: {
    width: '90%',
    display: "flex",
    alignItems: 'center'
  },
  block: {
    display: "flex",
    justifyContent: 'space-between',
    width: '100%',
  }
});