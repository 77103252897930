import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './SyntaxHighlighterStyles';
import { useThemeContext } from '~/theme';
import { SyntaxHighlightingVariant } from '~/constants/syntaxHighlightingTable';
import cn from 'classnames';

export type SyntaxHighlightingItemProps = {
  selected: boolean;
  highlightingVariant: SyntaxHighlightingVariant;
  marginRight?: number;
  title: string;
  marginBottom?: number;
  onPress: () => void;
};

const SyntaxHighlightingItem: React.FC<SyntaxHighlightingItemProps> = ({
  selected,
  highlightingVariant,
  marginRight,
  title,
  marginBottom,
  onPress
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const previewBgColorTable = useMemo(() => ({
    one: [theme.colors.highlighting_one_preview_1, theme.colors.highlighting_one_preview_2],
    gruvbox: [theme.colors.highlighting_gruvbox_preview_1, theme.colors.highlighting_gruvbox_preview_2],
    material: [theme.colors.highlighting_material_preview_1, theme.colors.highlighting_material_preview_2],
    dracula: [theme.colors.highlighting_dracula_preview_1, theme.colors.highlighting_dracula_preview_2],
  }), [theme.colors]);

  return (
    <Box 
      className={cn(s.syntaxHighlightingItemContainer)}
      title={title}
      style={{
        marginRight,
        marginBottom,
        border: `1px solid ${selected ? theme.colors.textBright : theme.colors.greySubText}`
      }}
      onClick={onPress}
    >
      <Box className={cn(s.syntaxHighlightingItemRotationCont)}>
        <Box 
          className={cn(s.syntaxHighlightingItemPart)}
          style={{backgroundColor: previewBgColorTable[highlightingVariant][0]}}
          // style={{backgroundColor: 'gray'}}
        />
        <Box 
          className={cn(s.syntaxHighlightingItemPart)}
          style={{backgroundColor: previewBgColorTable[highlightingVariant][1]}}
          // style={{backgroundColor: 'gray'}}
        />
      </Box>
    </Box>
  );
};

SyntaxHighlightingItem.defaultProps={}

export { SyntaxHighlightingItem };