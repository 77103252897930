import React, { useMemo } from 'react';
import { useStyles } from './PinnedPathStyles';
import { Box, IconButton } from '@material-ui/core';
import { getDataByPath } from '~/helpers/jsonHelpers';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ObjectT } from '~/types/types';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import cn from 'classnames';
import DeleteIcon from './assets/delete';

export type PinnedPathProps = {
  withBottomBorder: boolean;
  path: string;
  stateCopy: ObjectT<any> | null;
  onPressUnpin: (path: string) => void;
};

const PinnedPath: React.FC<PinnedPathProps> = ({
  withBottomBorder,
  path,
  stateCopy,
  onPressUnpin
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const objectName = useMemo(() => {
    const keys = path.split('/');

    return (keys.pop() ?? "Unknown");
  }, [path]);

  const objectByPath = useMemo(() => {
    if (!stateCopy)
      return undefined;

    return getDataByPath(stateCopy, path);
  }, [stateCopy, path]);

  return (
    <Box className={cn(s.container, {[s.bottomBorder]: withBottomBorder})}>
      <Box className={cn(s.left)}>
        <p className={cn(s.orangeText)} style={{fontSize: objectName.length > 44 ? 14 : undefined}}>
          {objectName} 
        </p>
        <p className={cn(s.grayTextSmall)} style={{fontSize: path.length > 50 ? 12 : 14}}>
          {path} 
        </p>
      </Box>

      <Box className={cn(s.center)}>
        {objectByPath ?
          <SyntaxHighlighter 
            code={JSON.stringify(objectByPath, null, 2)}
            customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
            enableFolding={true}
            // virtualize={true}
            foldingController={"inner"}
          />
        :
          <p className={cn(s.orangeText)}>
            {objectByPath === null ? "null" : "undefined"} 
          </p>
        }
      </Box>

      <Box className={cn(s.right)}>
        <IconButton 
          onClick={(e) => onPressUnpin(path)} 
          size='small'
          className={cn(s.unpinButton)}
          title={t("Other.unpin")}
        >
          <DeleteIcon color={theme.colors.iconsMain}/>
        </IconButton>
      </Box>
    </Box>
  );
};

PinnedPath.defaultProps={}

export { PinnedPath };