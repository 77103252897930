import React, { memo } from 'react';
import { useStyles } from './OtherTabStyles';
import { useOtherTabAnalytics } from './useOtherTabAnalytics';
import { Box, IconButton } from '@material-ui/core';
import { SwitchColored } from '~/components/SwitchColored';
import { useSelector, useDispatch } from 'react-redux';
import { notChosenNavBarTabsSelector, userSelector } from '~/logic/user/UserSelectors';
import { UserActions } from '~/logic/user/UserSlice';
import { copyToClipboard, handleOpenLink } from '~/helpers/windowHelpers';
import { CONFIG } from '~/config';
import { useThemeContext } from '~/theme';
import { APP_LANGUAGE_NAMES_TABLE, APP_LANGUAGE_VARIANTS_ARRAY, AppSettingsActions, COLOR_THEME_NAMES_TABLE, COLOR_THEME_VARIANTS_ARRAY, ColorTheme } from '~/logic/appSettings/AppSettingsSlice';
import { ThemeItem } from "~/components/ThemeItem";
import { LanguageItem } from "~/components/LanguageItem";
import { SyntaxHighlighterPreview, SyntaxHighlightingItem } from "~/components/SyntaxHighlighter";
import { appSettingsSelector } from '~/logic/appSettings/AppSettingsSelectors';
import { deleteChosenNavBarTabsFromLocalStorage, saveChosenNavBarTabsToLocalStorage, saveColorThemeToLocalStorage, saveLanguageToLocalStorage, saveSyntaxHighlightingVariantToLocalStorage } from '~/helpers/localStorage';
import { CustomButton } from '~/components/CustomButton';
import { DraggableNavigationBarTabs } from '~/components/DraggableNavigationBarTabs';
import { NAVIGATION_BAR_TAB_HEIGHT } from '~/components/NavigationBarTab';
import { CopyIcon, LockIcon, UnlockIcon } from "~/assets/icons";
import { NoData } from '~/components/NoData';
import { DEFAULT_CHOSEN_NAVIGATION_BAR_TABS, REQUIRED_NAVIGATION_BAR_TABS } from '~/components/NavigationBar';
import { PrivateText } from '~/components/PrivateText';
import { CurrentLangT } from '~/types/types';
import { changeLanguage } from '~/i18n';
import { useTranslation } from 'react-i18next';
import { SYNTAX_HIGHLIGHTING_NAMES_TABLE, SYNTAX_HIGHLIGHTING_VARIANTS_ARRAY, SyntaxHighlightingVariant } from '~/constants/syntaxHighlightingTable';
import { useRevertToDefaultSettings } from '~/hooks';
import cn from 'classnames';
import moment from 'moment';
import RefreshIcon from './assets/refresh';

const CURRENT_YEAR = moment().year();

export type OtherTabProps = {};

const OtherTab: React.FC<OtherTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey,
    appSettings,
    editingNavBarTabs,
    chosenNavBarTabs,
    profile: {
      data: profile,
    }
  } = useSelector(userSelector);

  const {
    colorTheme,
    currentLang,
    syntaxHighlightingVariant
  } = useSelector(appSettingsSelector);

  const notChosenNavBarTabs = useSelector(notChosenNavBarTabsSelector);

  const { 
    trackUserSelectedTheme,
    trackUserSelectedNavBarTabs
  } = useOtherTabAnalytics();

  const { handleRevertToDefaultSettings } = useRevertToDefaultSettings();

  const handleToogle = (checked: boolean, key: string) => {
    const settingsCopy = JSON.parse(JSON.stringify(appSettings));
    settingsCopy[key].value = checked;
    dispatch(UserActions.applyAndSetAppSettings({
      appSettings: settingsCopy,
      updateLocalStorage: true
    }));
  };

  const onPressRevertToDefaultSettings = () => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: t("Toast.youWantToRevertSettingsToDefault"),
      onPressYes: handleRevertToDefaultSettings,
      onPressNo: () => {}
    }));
  }

  const onPressLogout = () => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: t("Toast.youWantToLogOut"),
      onPressYes: () => dispatch(UserActions.logout.request()),
      onPressNo: () => {}
    }));
  }

  const onPressGetNewApiKey = () => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: t("Toast.youWantToGetNewApiKey"),
      onPressYes: () => dispatch(UserActions.generateApiKey.request()),
      onPressNo: () => console.log("User canceled getting new apikey")
    }));
  }

  const handleChangeTheme = (t: ColorTheme) => {
    dispatch(AppSettingsActions.setColorTheme(t));
    saveColorThemeToLocalStorage(t);
    trackUserSelectedTheme();
  }

  const handleChangeLanguage = (lang: CurrentLangT) => {
    dispatch(AppSettingsActions.setCurrentLang(lang));
    saveLanguageToLocalStorage(lang);
    changeLanguage(lang);
  }

  const handleChangeSyntaxHighlighting = (highlightingVariant: SyntaxHighlightingVariant) => {
    dispatch(AppSettingsActions.setSyntaxHighlightingVariant(highlightingVariant));
    saveSyntaxHighlightingVariantToLocalStorage(highlightingVariant);
  } 

  const onPressLock = () => {
    const newEditing = !editingNavBarTabs;
    dispatch(UserActions.setEditingNavBarTabs(newEditing));
    // If done editing
    if (!newEditing) {
      saveChosenNavBarTabsToLocalStorage(apiKey, chosenNavBarTabs);
      trackUserSelectedNavBarTabs();
    }
  }

  const resetChosenNavBarTabsToDefault = () => {
    dispatch(UserActions.setChosenNavBarTabs(DEFAULT_CHOSEN_NAVIGATION_BAR_TABS));
    dispatch(UserActions.setEditingNavBarTabs(false));
    deleteChosenNavBarTabsFromLocalStorage(apiKey);
    trackUserSelectedNavBarTabs();
  }

  return (
    <Box className={cn(ds.fullSpaceColRelative)}>
      <Box className={cn(s.topCont)}>
        {!!profile &&
          <>
            <p className={cn(s.groupLabelText)}>
              {t("MainPage.OtherTab.accountInfo")}
            </p>
            <Box className={cn(s.group)}>
              <Box className={cn(s.apiKeyCont)} >
                <p className={cn(s.whiteTextBold)}>
                  {`${t("MainPage.OtherTab.yourApiKeyIs")}:`}
                </p>
                <PrivateText text={apiKey}/>
                <IconButton 
                  onClick={(e) => copyToClipboard(apiKey)} 
                  size='small'
                  className={cn(s.copyApiKey)}
                  title={t("Common.copyToClipboard")}
                >
                  <CopyIcon color={theme.colors.white}/>
                </IconButton>
                <IconButton 
                  onClick={(e) => onPressGetNewApiKey()} 
                  size='small'
                  className={cn(s.getNewApiKey)}
                  title={t("MainPage.OtherTab.getNewApiKey")}
                >
                  <RefreshIcon color={theme.colors.white}/>
                </IconButton>
              </Box>
            </Box>
          </>
        }

        <Box className={cn(s.labelRow)}>
          <p className={cn(s.groupLabelText)}>
            {t("MainPage.OtherTab.navBarCustomization")}
          </p>
          <Box className={cn(ds.rowCentered)}>
            <IconButton 
              onClick={resetChosenNavBarTabsToDefault} 
              size='small'
              className={cn(s.getNewApiKey)}
              title={t("Common.resetToDefaults")}
            >
              <RefreshIcon color={theme.colors.textBrightSecondary}/>
            </IconButton>
            <IconButton onClick={onPressLock} size='small'>
              <Box title={editingNavBarTabs ? t("Common.lockChanges") : t("Common.unlockToChange")}>
                {editingNavBarTabs ?
                  <UnlockIcon color={theme.colors.iconsMain}/>
                :
                  <LockIcon color={theme.colors.coralRed}/>
                }
              </Box>
            </IconButton>
          </Box>
        </Box>
        <Box className={cn(s.group)} style={{minHeight: NAVIGATION_BAR_TAB_HEIGHT + theme.metrics.x6 + theme.metrics.x05}}>
          <DraggableNavigationBarTabs 
            droppableId={"dnd_navbar_tabs_other_pool"}
            tabs={notChosenNavBarTabs}
            tabJustifyContent={"flex-end"}
            isDragDisabled={!editingNavBarTabs}
            isDropDisabled={!editingNavBarTabs}
            isDragDisabledForTab={(tabId) => REQUIRED_NAVIGATION_BAR_TABS.has(tabId)}
            withHoverEffect={editingNavBarTabs}
          />

          {notChosenNavBarTabs.length === 0 &&
            <Box className={cn(s.noDataCenterAbsolute)}>
              <NoData 
                text={editingNavBarTabs ? t("MainPage.OtherTab.dndEnabled") : t("MainPage.OtherTab.unlockChangesAndDropTabs")} 
                textColor={theme.colors.textTetriary}
                fontSize={16}
              />
            </Box>
          }
        </Box>

        <p className={cn(s.groupLabelText)}>
          {t("Common.settings")}
        </p>
        <Box className={cn(s.group)}>
          {Object.keys(appSettings).map((key, index) => (
            <Box className={cn(s.row, {[s.bottomBorder]: index < Object.keys(appSettings).length - 1})} key={key}>
              <SwitchColored
                // @ts-ignore
                checked={!!appSettings[key].value}
                onChange={(e) => handleToogle(e.target.checked, key)}
                inputProps={{ 'aria-label': 'controlled' }}
                // @ts-ignore
                title={t(`Other.guiSettings.${appSettings[key].key}`)}
              />

              <p className={cn(s.whiteTextBold)}>
                {// @ts-ignore
                  `${t(`Other.guiSettings.${appSettings[key].key}`)}`
                }
              </p>
            </Box>
          ))}
        </Box>

        <p className={cn(s.groupLabelText)}>
          {t("MainPage.OtherTab.guiLanguage")}
        </p>
        <Box className={cn(s.groupRow)}>
          {APP_LANGUAGE_VARIANTS_ARRAY.map((language) => {
            const selected = language === currentLang;

            return (
              <LanguageItem 
                key={language}
                selected={selected}
                language={language}
                title={selected ? `${t("MainPage.OtherTab.currentLanguage")} (${APP_LANGUAGE_NAMES_TABLE[language]})` : APP_LANGUAGE_NAMES_TABLE[language]}
                onPress={() => !selected && handleChangeLanguage(language)}
                marginRight={theme.metrics.x3}
              />
            )
          })}
        </Box>

        <p className={cn(s.groupLabelText)}>
          {t("MainPage.OtherTab.guiTheme")}
        </p>
        <Box className={cn(s.groupRow)}>
          {COLOR_THEME_VARIANTS_ARRAY.map((item) => {
            const selected = item === colorTheme;

            return (
              <ThemeItem 
                key={item}
                selected={selected}
                title={selected ? `${t("MainPage.OtherTab.currentTheme")} (${COLOR_THEME_NAMES_TABLE[item]})` : COLOR_THEME_NAMES_TABLE[item]}
                onPress={() => !selected && handleChangeTheme(item)}
                themeName={item}
                marginRight={theme.metrics.x3}
              />
            )
          })}
        </Box>

        <p className={cn(s.groupLabelText)}>
          {t("MainPage.OtherTab.syntaxHighlighting")}
        </p>
        <Box className={cn(s.groupRow)}>
          <Box style={{display: 'flex', flexDirection: 'column'}}>
            {SYNTAX_HIGHLIGHTING_VARIANTS_ARRAY.map((item) => {
              const selected = item === syntaxHighlightingVariant;

              return (
                <SyntaxHighlightingItem 
                  key={item}
                  selected={selected}
                  title={selected ? `${t("MainPage.OtherTab.currentSyntaxHighlighting")} (${SYNTAX_HIGHLIGHTING_NAMES_TABLE[item]})` : SYNTAX_HIGHLIGHTING_NAMES_TABLE[item]}
                  onPress={() => !selected && handleChangeSyntaxHighlighting(item)}
                  highlightingVariant={item}
                  marginBottom={theme.metrics.x3}
                />
              )
            })}
          </Box>

          <Box className={cn(s.syntaxHighlighterPreviewContainer)}>
            <SyntaxHighlighterPreview />
          </Box>
        </Box>

        <p className={cn(s.groupLabelText)}>
          {t("MainPage.OtherTab.usefulLinks")}
        </p>
        <Box className={cn(s.group)}>
          <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.DOCUMENTATION)}>
            {`· ${t("MainPage.OtherTab.documentation")}`}
          </p>
          <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.CODEBUD_LANDING_WEBSITE)}>
            {`· ${CONFIG.PRODUCT_NAME} ${t("Common.website")}`}
          </p>
          {/* <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.APPKLAAR_WEBSITE)}>
            {`· ${CONFIG.COMPANY_NAME} ${t("Common.website")}`}
          </p> */}
          <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.GITHUB)}>
            {'· GitHub'}
          </p>
          <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.TELEGRAM)}>
            {`· ${t("MainPage.OtherTab.contactUs")}`}
          </p>
        </Box>

        <Box className={cn(s.largeBtn)} style={{marginBottom: theme.metrics.x4}}>
          <CustomButton 
            colorActive={theme.colors.red}
            bgColorActive={theme.colors.transparent}
            borderWidth={theme.metrics.x025}
            borderColorActive={theme.colors.red}
            onPress={onPressRevertToDefaultSettings}
            title={t("MainPage.OtherTab.revertToDefaultSettings")}
          />
        </Box>

        <Box className={cn(s.largeBtn)}>
          <CustomButton 
            onPress={onPressLogout}
            title={t("MainPage.OtherTab.logout")}
          />
        </Box>
      </Box>

      <Box className={cn(s.bottomCont)}>
        <Box className={cn(s.appKlaarSmallLogoCont)}>
          <img src={theme.icons.CODEBUD_LOGO_SMALL} className={ds.fullSpaceImg}/>
        </Box>

        <p className={cn(s.whiteText)}>
          {`${CONFIG.PRODUCT_NAME} GUI ${CONFIG.APP_INFO.DISPLAY_VERSION} (${CONFIG.APP_INFO.DISPLAY_BUILD_NUMBER}), ${CURRENT_YEAR}`}
        </p>
      </Box>
    </Box>
  );
};

OtherTab.defaultProps={}

const MemorizedComponent = memo(OtherTab);
export { MemorizedComponent as OtherTab };