import React, { useCallback, useContext, useMemo } from 'react';
import { useStyles } from './MainStyles';
import { useMainTabAnalytics } from './useMainTabAnalytics';
import { Box, IconButton } from '@material-ui/core';
import { DndMainTabContext } from '~/providers';
import { DroppableInstructions } from './DroppableInstructions';
import { DroppableConstructor } from './DroppableConstructor';
import { TextInput } from "~/components/TextInput";
import { TestAccount } from '~/components/TestAccount';
import { FloatingViewCompatibleContentOrPlaceholder } from '~/blocks/FloatingViewCompatibleHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { UserActions, ALL_INSTRUCTIONS_SORTING_DESCRIPTION } from '~/logic/user/UserSlice';
import { prepareExportableScenario } from '~/helpers/draggableHelpers';
import { useThemeContext } from '~/theme';
import { SortIcon, CopyIcon, PlayAddIcon, TrashIcon, CollectionIcon, ListIcon } from '~/assets/icons';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { copyToClipboard } from '~/helpers/windowHelpers';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type MainProps = {};

const Main: React.FC<MainProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    currentTestAccount,
    draggableInstuctionsState,
    bothSidesConnected,
    connectedClientProjectId,
    allInstructionsSorting,
    appSettings,
    scenarioIsExecuting
  } = useSelector(userSelector);

  const {
    searchInnerValue,
    setSearchInnerValue,
    allInstructionsFiltered
  } = useContext(DndMainTabContext);

  useMainTabAnalytics();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [constructorEmpty, scenarioInConstructor] = useMemo(() => {
    const keys = Object.keys(draggableInstuctionsState);
    return [draggableInstuctionsState[keys[0]].length === 0, draggableInstuctionsState[keys[0]].length > 1];
  }, [draggableInstuctionsState]);

  const handleSortInstructions = useCallback(() => {
    dispatch(UserActions.setAllInstructionsSorting(allInstructionsSorting === "NO_SORTING" ? "INSTRUCTION_ID": "NO_SORTING"));
  }, [allInstructionsSorting]);

  // const handleDownloadScenario = () => {
  //   dispatch(UserActions.setExportScenarioModalVisible(true));
  // }

  const handleSaveScenario = () => {
    dispatch(UserActions.setExportScenarioModalVisible(true));
  }

  const handleImportScenario = () => {
    dispatch(UserActions.setImportScenarioModalVisible(true));
  }

  const handleOpenScenarioCollection = () => {
    dispatch(UserActions.setScenarioCollectionModalVisible(true));
  }

  const handleCopyScenario = () => {
    const exportableScenario = prepareExportableScenario(draggableInstuctionsState, "Copied scenario", "No description");
    const scenarioString = jsonStringifyObjectFormatted(exportableScenario, "scenario");
    copyToClipboard(scenarioString);
  }

  const handleClearConstructor = () => {
    dispatch(UserActions.clearConstructorStateFromInstructions());
  }

  return (
    <Box className={cn(ds.fullSpace)}>
      <Box className={cn(s.leftSide)}>
        <Box className={cn(s.availableInstructionsContainer)}>
          <Box className={cn(s.block)} style={{paddingLeft: theme.metrics.x2, paddingRight: theme.metrics.x2}}>
            <p className={cn(ds.whiteTextBolder)} style={{color: theme.colors.textMain}}>
              {`${t("MainPage.MainTab.availableInstructions")}:`}
            </p>
            <Box className={cn(s.sortButton)} title={ALL_INSTRUCTIONS_SORTING_DESCRIPTION[allInstructionsSorting]}>
              <IconButton onClick={handleSortInstructions} size='small'>
                <SortIcon color={allInstructionsSorting === "NO_SORTING"? 'gray': 'white'}/>
              </IconButton>
            </Box>
          </Box>

          {appSettings.showInstructionsSearch.value &&
            <Box className={cn(s.searchInputContainer)}>
              <TextInput 
                value={searchInnerValue}
                onChangeText={(text) => setSearchInnerValue(text)}
                onClear={() => setSearchInnerValue("")}
                labelColor={theme.colors.black}
                color={theme.colors.inputTextSecondary}
                placeholder={t("Common.searchForInstructions")}
                validationOk={true}
                height={32}
                fontSize={16}
                paddingLeft={8}
                paddingRight={28}
                borderRadius={theme.metrics.x2}
                borderUnfocused={`solid 1px ${theme.colors.inputBorderColorUnactiveSecondary}`}
                bgColor={theme.colors.inputBgSecondary}
              />
            </Box>
          }

          <DroppableInstructions 
            bothSidesConnected={bothSidesConnected}
            instructions={allInstructionsFiltered}
            draggingDisabled={scenarioIsExecuting}
            // onPressSendEvent={onPressSendEvent}
          />
        </Box>
      </Box>

      <Box className={cn(s.rightSide)}>
        <Box className={cn(s.settingsBlock)}>
          {appSettings.showSelectedTestAccountOnMainTab.value ?
            <TestAccount 
              currentTestAccount={currentTestAccount}
              onPress={() => dispatch(UserActions.setTestAccountsModalVisible(true))}
            />
          : 
            <Box />
          }

          <Box 
            className={cn(s.constructorMenu)}
          >
            {scenarioInConstructor &&
              <>
                {/* <Box className={cn(s.constructorMenuButton)} title={"Export scenario"}>
                  <IconButton onClick={handleDownloadScenario} size='small'>
                    <DownloadIcon color={theme.colors.iconsMain}/>
                  </IconButton>
                </Box> */}
                <Box className={cn(s.constructorMenuButton)} title={t("MainPage.MainTab.saveScenarioToCollection")}>
                  <IconButton onClick={handleSaveScenario} size='small'>
                    <CollectionIcon color={theme.colors.iconsMain}/>
                  </IconButton>
                </Box>
                <Box className={cn(s.constructorMenuButton)} title={t("MainPage.MainTab.copyScenario")}>
                  <IconButton onClick={handleCopyScenario} size='small'>
                    <CopyIcon color={theme.colors.iconsMain}/>
                  </IconButton>
                </Box>
              </>
            }
            {(bothSidesConnected && connectedClientProjectId) &&
              <Box className={cn(s.constructorMenuButton)} title={t("MainPage.MainTab.openScenarioCollection")}>
                <IconButton onClick={handleOpenScenarioCollection} size='small'>
                  <ListIcon color={theme.colors.iconsMain}/>
                </IconButton>
              </Box>
            }
            <Box className={cn(s.constructorMenuButton)} title={t("MainPage.MainTab.importScenario")}>
              <IconButton onClick={handleImportScenario} size='small'>
                <PlayAddIcon color={theme.colors.iconsMain}/>
              </IconButton>
            </Box>
            <Box className={cn(s.constructorMenuButton, s.clearConstructorIcon)} title={t("MainPage.MainTab.clearConstructor")}>
              <IconButton onClick={handleClearConstructor} size='small'>
                <TrashIcon color={theme.colors.iconsMain}/>
              </IconButton>
            </Box>
          </Box>
        </Box>
        {/* <SettingsPanel /> */}
        <Box className={cn(s.constructorContainer)}>
          <DroppableConstructor 
            isDropDisabled={scenarioIsExecuting}
            isDragDisabled={scenarioIsExecuting}
          />
        </Box>
        
        <Box className={cn(s.toolsPanelCont)}>
          <FloatingViewCompatibleContentOrPlaceholder 
            content={"ToolsPanel"} 
          />
        </Box>
      </Box>
    </Box>
  );
};

Main.defaultProps={}

export { Main };