import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useStyles } from './ToolsPanelStyles';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { SingleLogItem } from '~/components/SingleLogItem';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { useDispatch } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { CustomButton } from '~/components/CustomButton';
import { OpenInFloatingViewButton } from '~/components/FloatingView';
import { socketService } from '~/services/socketService';
import { EventStateData } from '~/helpers/draggableHelpers';
import { useThemeContext } from '~/theme';
import { showToast } from '~/helpers/alertService';
import { useTrackUserSentEvent, useTrackUserSentScenario } from '~/hooks/analyticsCommon';
import { /* SortIcon, */ TrashIcon } from '~/assets/icons';
// import { saveLogsTimelineSortingToLocalStorage } from '~/helpers/localStorage';
import { computeItemKey } from '../../Pages/Main/Tabs/MainTab/computeItemKey';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import TabIcon from '../../Pages/Main/Tabs/MainTab/assets/tab';

export type ToolsPanelProps = {};

const ToolsPanel: React.FC<ToolsPanelProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    logs,
    logsTimelineSorting,
    toolsPanelMode,
    bothSidesConnected,
    constructorMode,
    draggableInstuctionsState,
    scenarioIsExecuting,
    stopScenarioExecutionFetching
  } = useSelector(userSelector);

  const { trackUserSentEvent} = useTrackUserSentEvent();
  const { trackUserSentScenario } = useTrackUserSentScenario();

  const logsVirtuosoRef = useRef<VirtuosoHandle>(null);

  const { preparedInfo, eventsCount, paramsCount } = useMemo(() => {
    let eventsCount = 0;
    let paramsCount = 0;

    for (const list of Object.keys(draggableInstuctionsState))
      for (const event of draggableInstuctionsState[list]) {
        eventsCount++;
        paramsCount += event.numOfArgs;
      }

    return {
      preparedInfo: `${t("Common.prepared")} ${eventsCount} ${t("Common.eventsGenitive").toLowerCase()} ${t("Common.with")} ${paramsCount} ${t("Common.param")}(${t("Common.multipleItemsWordEndingCounting")})`,
      eventsCount, 
      paramsCount
    };
  }, [draggableInstuctionsState, t]);

  const sendButtonDisabled = useMemo(() => {
    const lists = Object.keys(draggableInstuctionsState);
    const numOfEvents = draggableInstuctionsState[lists[0]].length;

    // return false;
    return numOfEvents === 0 || !bothSidesConnected;
  }, [bothSidesConnected, draggableInstuctionsState]);

  const handleClearLogs = () => {
    dispatch(UserActions.clearLogs());
  };

  // const onPressSortLogsTimeline = useCallback(() => {
  //   const newSorting = logsTimelineSorting === "newestOnTop" ? "oldestOnTop" : "newestOnTop";
  //   dispatch(UserActions.setLogsTimelineSorting(newSorting));
  //   saveLogsTimelineSortingToLocalStorage(newSorting);
  // }, [logsTimelineSorting])

  const handleSwitchMode = useCallback(() => {
    dispatch(UserActions.setToolsPanelMode(toolsPanelMode === 'CONTROL'? 'LOGS': 'CONTROL'));
  }, [toolsPanelMode]);

  const checkValidationOk = () => {
    const lists = Object.keys(draggableInstuctionsState);
    const eventsInConstructor: EventStateData[] = draggableInstuctionsState[lists[0]];
    const firstEventInConstructor: EventStateData = eventsInConstructor[0];
    const lastEventInConstructor: EventStateData = eventsInConstructor[eventsInConstructor.length - 1];

    const instructionsThatCantBeFirstOrLast = new Set(["forwardData", "condition"]);
    
    if (instructionsThatCantBeFirstOrLast.has(firstEventInConstructor.instructionId)) {
      showToast(`${firstEventInConstructor.instructionId} ${t("Toast.eventCannotBeFirstOrLast")}!`, "warning");
      return false;
    }

    if (instructionsThatCantBeFirstOrLast.has(lastEventInConstructor.instructionId)) {
      showToast(`${lastEventInConstructor.instructionId} ${t("Toast.eventCannotBeFirstOrLast")}!`, "warning");
      return false;
    }

    for (let i = 1; i < eventsInConstructor.length; i++)
      if (eventsInConstructor[i].instructionId === "delay" && eventsInConstructor[i - 1].instructionId === "delay") {
        showToast(`${t("Toast.twoConsecutiveDelaysReplace")}`, "warning");
        return false;
      }

    return true;
  };

  const handleSend = () => {
    if (checkValidationOk()) {
      if (constructorMode === "EVENT")
        trackUserSentEvent("MAIN_TAB", paramsCount);
      else
        trackUserSentScenario(eventsCount, paramsCount);

      if (constructorMode === "EVENT")
        socketService.emitEventFromConstructor(draggableInstuctionsState);
      else if (constructorMode === "SCENARIO")
        socketService.emitScenarioFromConstructor(draggableInstuctionsState);

      dispatch(UserActions.handleCurrentDraggableInstuctionsStateBeingSent());
    }
  };

  const handleStopScenario = () => {
    socketService.tryToStopScenarioExecution();
  }

  useEffect(() => {
    if (logsVirtuosoRef?.current) {
      logsVirtuosoRef.current.scrollToIndex({index: logsTimelineSorting === "newestOnTop" ? 0 : logs.length - 1});
    }
  }, [logs, logsTimelineSorting]);

  return (
    <Box className={cn(s.toolsPanelBg)}>
      <Box className={cn(s.syntaxHighlighterButtonsAbsoluteCont)}>
        {toolsPanelMode === "LOGS" &&
          <IconButton onClick={handleClearLogs} size={'small'} title={t("MainPage.MainTab.clearLogs")}>
            <Box className={cn(s.trashIconCont)}>
              <TrashIcon color={theme.colors.iconsMain}/>
            </Box>
          </IconButton>
        }

        <OpenInFloatingViewButton 
          content={"ToolsPanel"}
          marginLeft={0}
        />
        
        {/* {toolsPanelMode === "LOGS" &&
          <IconButton 
            onClick={onPressSortLogsTimeline} 
            size='small'
            className={cn(s.sortNetworkLogsButton)}
            title={"Reverse order"}
          >
            <SortIcon color={theme.colors.iconsMain}/>
          </IconButton>
        } */}
        <IconButton onClick={handleSwitchMode} size={'small'} title={`${t("Common.switchTo")} ${toolsPanelMode === "CONTROL"? "logger": "controller"} ${t("Common.mode")}`}>
          <Box className={cn(s.tabIconCont)}>
            <TabIcon color={theme.colors.iconsMain}/>
          </Box>
        </IconButton>
      </Box>
      {(toolsPanelMode === "LOGS" && logs.length === 0) &&
        <Box className={cn(s.logsEmpty)}>
          <Typography variant="h5" paragraph className={cn(s.whiteText)}>
            {t("MainPage.MainTab.logsWillAppearHere")}
          </Typography>
        </Box>
      }
      {toolsPanelMode === "LOGS" ?
        <Virtuoso
          ref={logsVirtuosoRef}
          style={{ height: '100%', width: '100%' }}
          overscan={500}
          data={logs}
          itemContent={(index, log) => (
            <SingleLogItem log={log}/>
          )}
          computeItemKey={computeItemKey}
        />
      :
        <Box className={cn(s.bottomControllerCont)}>
          <Typography variant="h6" className={cn(s.grayText)}>
            {preparedInfo}
          </Typography>

          {scenarioIsExecuting ?
            <Box className={cn(s.sendButton)} title={t("MainPage.MainTab.tryStopScenarioExec")}>
              <CustomButton
                onPress={handleStopScenario} 
                bgColorActive={theme.colors.coralRed}
                title={t("MainPage.MainTab.stopScenarioExec")}
                spinner={stopScenarioExecutionFetching}
                disabled={stopScenarioExecutionFetching}
              /> 
            </Box>
          :
            <Box className={cn(s.sendButton)} title={bothSidesConnected ? t("MainPage.MainTab.readyToSend") : `${t("Common.noClientConnected")}!`}>
              <CustomButton
                onPress={handleSend} 
                title={`${t("Common.send")} ${constructorMode === 'EVENT'? t("Common.event") : t("Common.scenario")}`}
                disabled={sendButtonDisabled}
              /> 
            </Box>
          }
        </Box>
      }
    </Box>
  );
};

ToolsPanel.defaultProps={}

export { ToolsPanel };