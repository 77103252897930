import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './AppStyles';
import { BrowserRouter } from "react-router-dom";
import { socketService } from './services/socketService';
import { ToastContainer } from 'react-toastify';
import { useThemeContext } from './theme';
import "react-toastify/dist/ReactToastify.css";
import Router from './router';
import cn from 'classnames';

socketService.init("");

function App() {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <Box className={cn(s.globalContainer)}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>  

      <ToastContainer 
        position='top-center'
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        theme={theme.isDark ? "dark" : "light"}
      />
    </Box>
  );
}

export default App;