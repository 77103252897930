import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

const FOOTER_HEIGHT = 40;

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '45vw',
    height: '72vh',
    minWidth: 420,
    minHeight: 520,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sharkLight,
    borderRadius: theme.metrics.x4,
    overflow: 'hidden',
    padding: theme.metrics.x4
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: `calc(100% - ${FOOTER_HEIGHT}px)`
  },
  headerLeft: {
    display: 'flex',
    alignItems: "center",
    position: "absolute",
    left: 0
  },
  refreshButton: {
    width: 28,
    height: 28
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: FOOTER_HEIGHT
  },
  timestampText: {
    fontFamily: theme.fonts.f500.fontFamily,
    color: theme.colors.textMain,
    margin: 0,
    paddingTop: theme.metrics.x,
    fontSize: 14,
  },
  linkText: {
    color: theme.colors.textBright,
    margin: 0,
    fontSize: 18,
    fontFamily: theme.fonts.f600.fontFamily,
    userSelect: 'none',
    cursor: 'pointer'
  },
});