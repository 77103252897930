import React, { memo, useMemo } from 'react';
import { CLIENT_SELECTOR_BORDER_WIDTH, CLIENT_SELECTOR_HANDLE_WIDTH, CLIENT_SELECTOR_MAIN_HEIGHT, useStyles } from './ClientSelectorStyles';
import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import Animated, { useSharedValue, withTiming, useAnimatedStyle, useDerivedValue } from 'react-native-reanimated';
import cn from 'classnames';
import { ConnectionStatus } from '~/components/ConnectionStatus';
import { NoData } from '~/components/NoData';
import { ClientPreviewItem, prepareClientPreviewItemInfo } from '~/components/ClientPreviewItem';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { socketService } from '~/services/socketService';
import { RefreshIcon, TrashIcon } from '~/assets/icons';
import { UserActions } from '~/logic/user/UserSlice';
import { useCodebudProjectsNamesTable } from '~/hooks/useCodebudProjectsNamesTable';
import { stringWithMaxLen } from '~/helpers/strings';
import { useTranslation } from 'react-i18next';

const ANIMATION_DURATION = 125;
const MIN_HANDLE_OPACITY = 0.55;
const MAX_HANDLE_OPACITY = 1;

export type ClientSelectorProps = {};

const ClientSelector: React.FC<ClientSelectorProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    bothSidesConnected,
    connectedClientSocketId,
    connectedClientSocketRouteId,
    clientsQueue: {
      fetching: fetchingClientsQueue
    },
    clientsQueueTable
  } = useSelector(userSelector);

  const codebudProjectsNamesTable = useCodebudProjectsNamesTable();

  const opened = useSharedValue(false);
  const hovered = useSharedValue(false);
  const translateY = useSharedValue(CLIENT_SELECTOR_MAIN_HEIGHT);

  const opacity = useDerivedValue(() => {
    if (hovered.value || opened.value)
      return withTiming(MAX_HANDLE_OPACITY);

    return withTiming(MIN_HANDLE_OPACITY);
  }, [opened, hovered]);

  const numOfClientsInQueue = useMemo(() => Object.keys(clientsQueueTable).length, [clientsQueueTable]);

  const onMouseEnter = () => {
    hovered.value = true;
  }

  const onMouseLeave = () => {
    hovered.value = false;
  }

  const handleRefreshClientsQueue = () => {
    dispatch(UserActions.clientsQueue.request());
  }

  const handleForceDisconnectAllClients = () => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: t("Toast.thisActionWillForceDisconnectAllClients"),
      onPressYes: () => socketService.forceAllClientsFromQueueDisconnect(),
      onPressNo: () => {}
    }));
  }

  const onHandleClick = () => {
    if (opened.value) {
      translateY.value = withTiming(CLIENT_SELECTOR_MAIN_HEIGHT, {duration: ANIMATION_DURATION});
    } else {
      translateY.value = withTiming(0, {duration: ANIMATION_DURATION});
    }

    setTimeout(() => {
      opened.value = !opened.value;
    }, ANIMATION_DURATION);
  }

  const handleSwitchToClient = (socketId: string) => {
    socketService.switchToOtherClient(socketId);
  }

  const handleSingleClientForceDisconnect = (socketId: string) => {
    socketService.forceSingleClientDisconnect(socketId, socketId === connectedClientSocketId);
  }

  const animatedStyle = useAnimatedStyle(() => ({
    flexDirection: 'row',
    position: 'fixed',
    bottom: 0,
    transform: [{ translateY: translateY.value }],
    opacity: opacity.value,
    height: CLIENT_SELECTOR_MAIN_HEIGHT,
    width: "50%",
    minWidth: CLIENT_SELECTOR_HANDLE_WIDTH * 2,
    backgroundColor: theme.colors.bgTetriary,
    borderTopLeftRadius: theme.metrics.x2,
    borderTopRightRadius: theme.metrics.x2,
    zIndex: 5,
    justifyContent: 'center',
    border: `${CLIENT_SELECTOR_BORDER_WIDTH}px solid ${theme.colors.borderSecondary}`,
    borderBottomWidth: 0,
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2
    // boxShadow: `0 0 64px ${theme.colors.textMain}`
  }), [theme.colors, translateY, opacity]);

  return (
    <Animated.View 
      style={animatedStyle}
    >
      <Box 
        className={cn(s.handle)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onHandleClick}
      >
        <Box className={cn(s.handleCircle, s.handleCircleLeft)}/>
        <Box className={cn(s.handleCircle, s.handleCircleRight)}/>

        <Box className={cn(s.handleLeftButtonsRow)}>
          {fetchingClientsQueue ?
            <Box style={{padding: 4}}>
              <CircularProgress size={12}/>
            </Box>
          :
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                handleRefreshClientsQueue();
              }} 
              size='small'
              className={cn(s.refreshIcon)}
              title={t("MainPage.MainTab.refreshClientsQueue")}
            >
              <RefreshIcon color={theme.colors.iconsMain}/>
            </IconButton>
          }

          {numOfClientsInQueue > 0 &&
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                handleForceDisconnectAllClients();
              }} 
              size='small'
              className={cn(s.trashIcon)}
              title={t("MainPage.MainTab.forceDisconnectAllClients")}
            >
              <TrashIcon color={theme.colors.iconsMain}/>
            </IconButton>
          }
        </Box>

        <p className={cn(s.clientsText)}>
          {`${numOfClientsInQueue} ${t("MainPage.MainTab.client")}(${t("Common.multipleItemsWordEnding")}) | ${bothSidesConnected ? t("Common.connected") : t("Common.notConnected")}`}
        </p>

        <Box className={cn(s.statusIndicatorContainer)}>
          <ConnectionStatus connected={bothSidesConnected} />
        </Box>
      </Box>

      {numOfClientsInQueue > 0 ?
        <Box className={cn(s.clientsContainer)}>
          {Object.keys(clientsQueueTable).map((key, index) => {
            const client = clientsQueueTable[key];
            const clientKey = `client_${client.meta.socketRouteId}_${client.meta.socketId}`;
            const connected = client.meta.socketRouteId === connectedClientSocketRouteId && client.meta.socketId === connectedClientSocketId;
            const clientInfo = prepareClientPreviewItemInfo(client.meta);
            const projectName = (client.meta.projectId && codebudProjectsNamesTable[client.meta.projectId]) ? codebudProjectsNamesTable[client.meta.projectId] : undefined;

            return (
              <ClientPreviewItem 
                key={clientKey}
                type={clientInfo.type}
                title={stringWithMaxLen(clientInfo.title, 14, "..")}
                projectName={projectName ? stringWithMaxLen(projectName, 14, "..") : undefined}
                description={stringWithMaxLen(clientInfo.description, 14, "..")}
                connected={connected}
                marginRight={theme.metrics.x2}
                onPress={() => handleSwitchToClient(client.meta.socketId)}
                onPressForceDisconnect={() => handleSingleClientForceDisconnect(client.meta.socketId)}
              />
            )
          })}
        </Box>
      :
        <NoData 
          text={t("MainPage.MainTab.noClientsAvailable")}
          fontSize={18}
        />
      }
    </Animated.View>
  );
};

ClientSelector.defaultProps={}

const MemorizedComponent = memo(ClientSelector);
export { MemorizedComponent as ClientSelector };