import React, { useContext, useMemo } from "react";
import { theme as blueBayouxTheme } from "./default/theme";
import { balticSeaTheme } from "./default/balticSeaTheme";
import { wildSandTheme } from "./default/wildSandTheme";
import { whiteTheme } from "./default/whiteTheme";
import { useStyles as useDefaultStyle } from './default/styles';

const ThemeContext = React.createContext<Theme>(balticSeaTheme);

export type Theme = typeof blueBayouxTheme | typeof balticSeaTheme | typeof wildSandTheme | typeof whiteTheme;

type ThemeResult<S> = {
  theme: Theme;
  s: S | undefined;
  ds: ReturnType<typeof useDefaultStyle>;
};

type Keys<T, P = keyof T> = P;
export type IconNames =
  | Keys<typeof blueBayouxTheme.icons>
  | Keys<typeof balticSeaTheme.icons>
  | Keys<typeof wildSandTheme.icons>
  | Keys<typeof whiteTheme.icons>;
export type ThemeColors =
  | Keys<typeof blueBayouxTheme.colors>
  | Keys<typeof balticSeaTheme.colors>
  | Keys<typeof wildSandTheme.colors>
  | Keys<typeof whiteTheme.colors>;

function useThemeContext<S>(
  createStyleSheet?: (theme: Theme) => S
): ThemeResult<S> {
  const currentTheme = useContext(ThemeContext) || balticSeaTheme || blueBayouxTheme || wildSandTheme || whiteTheme;
  const defaultStyles = useDefaultStyle();

  const result = useMemo(() => {
    if (typeof createStyleSheet === "function") {
      const styles = createStyleSheet(currentTheme);
      return {
        theme: currentTheme,
        s: styles,
        ds: defaultStyles
      };
    }
    return {
      theme: currentTheme,
      s: undefined,
      ds: defaultStyles
    };
  }, [currentTheme, createStyleSheet, defaultStyles]);

  return result;
}

export { ThemeContext, useThemeContext };