import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 -0.5 25 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.54 16.745a1.756 1.756 0 0 0 1.582-1.854v.005a1.823 1.823 0 0 0-1.801-1.736h-.065a1.804 1.804 0 0 0-1.738 1.869v-.003c.026.473.22.897.522 1.217l-.001-.001a11.716 11.716 0 0 1-5.204 5.031l-.069.03a8.538 8.538 0 0 1-4.127 1.048c-.383 0-.761-.025-1.13-.073l.044.005a4.552 4.552 0 0 1-3.277-1.884l-.009-.014a4.671 4.671 0 0 1-.858-2.711c0-.837.218-1.623.601-2.305l-.012.024a7.519 7.519 0 0 1 2.194-2.562l.021-.014a9.756 9.756 0 0 1-.432-1.56l-.011-.066C-.913 14.54-.438 19.121.984 21.286a6.727 6.727 0 0 0 5.603 2.59h-.014l.119.001a8.26 8.26 0 0 0 1.88-.216l-.056.011a12.411 12.411 0 0 0 8.974-6.814l.032-.074z" />
    <path d="M23.18 12.799c-2.403-2.74-5.91-4.46-9.82-4.46a14.1 14.1 0 0 0-.357.005h.018-.538a1.703 1.703 0 0 0-1.526-.95l-.059.001h.003-.039c-.966.014-1.744.8-1.744 1.768l.002.085v-.004a1.822 1.822 0 0 0 1.8 1.738h.079a1.784 1.784 0 0 0 1.577-1.094l.004-.012h.585c2.554.003 4.924.787 6.884 2.128l-.042-.027a8.719 8.719 0 0 1 3.392 4.055l.021.058c.26.542.411 1.178.411 1.849 0 .71-.169 1.38-.47 1.972l.011-.025c-.777 1.586-2.379 2.658-4.231 2.658-.068 0-.135-.001-.202-.004h.01a8.23 8.23 0 0 1-3.183-.707l.052.021c-.379.316-1.011.836-1.47 1.153a9.972 9.972 0 0 0 4.143.994h.012a7.052 7.052 0 0 0 6.223-3.381l.018-.031a7.1 7.1 0 0 0 .54-2.742c0-1.98-.796-3.774-2.085-5.079l.001.001-.015.032z" />
    <path d="M6.844 17.316a1.82 1.82 0 0 0 1.803 1.738h.064a1.802 1.802 0 0 0-.047-3.601h-.067l-.033-.001a.658.658 0 0 0-.209.033l.005-.001a11.671 11.671 0 0 1-1.703-6.107c0-.364.016-.724.049-1.079l-.003.046A8.553 8.553 0 0 1 8.61 3.337l-.012.016a5.603 5.603 0 0 1 3.944-1.803h.009c3.412-.064 4.835 4.188 4.945 5.878l1.582.473C18.71 2.72 15.488 0 12.407 0a7.33 7.33 0 0 0-6.622 5.131l-.014.052a13.023 13.023 0 0 0-.692 4.229c0 2.588.741 5.003 2.021 7.044l-.032-.055a1.387 1.387 0 0 0-.219.922l-.001-.006z" />
  </svg>
)

export { SvgComponent as ReduxIcon }