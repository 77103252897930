import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  toolsPanelBg: {
    display: 'flex',
    position: 'relative',
    // flex: 5,
    height: '100%',
    width: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: theme.colors.bgQuinary
  },
  syntaxHighlighterButtonsAbsoluteCont: {
    position: 'absolute',
    right: theme.metrics.x4,
    top: theme.metrics.x2,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.bgSecondary,
    // backgroundColor: 'red',
    borderRadius: 8,
    paddingLeft: theme.metrics.x, 
    paddingRight: theme.metrics.x1_5,
    paddingBottom: theme.metrics.x05,
    zIndex: 3
  },
  trashIconCont: {
    width: 16,
    height: 16,
    marginBottom: theme.metrics.x025
    // backgroundColor: 'blue'
    // transform: "scale(0.8)",
  },
  styleIconCont: {
    width: 18,
    height: 18
  },
  tabIconCont: {
    width: 16,
    height: 16,
    marginBottom: theme.metrics.x025
  },
  logsEmpty: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  whiteText: {
    color: theme.colors.textMain,
    margin: 0,
    fontFamily: theme.fonts.f400.fontFamily
  },
  grayText: {
    color: theme.colors.textTetriary,
    margin: 0,
    fontFamily: theme.fonts.f400.fontFamily
  },
  bottomControllerCont: {
    display: "flex",
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.bgTetriary
  },
  sendButton: {
    width: "50%",
    marginTop: 20
  }
});