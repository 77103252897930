import React from 'react';
import { useStyles } from './ScenarioCollectionModalStyles';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { NoData } from '../NoData';
import { useProjectIdInfo } from '~/hooks/useProjectIdInfo';
import { ExportableScenarioItem } from '~/components/ExportableScenarioItem';
import { Virtuoso } from 'react-virtuoso';
import { useScenarioCollection } from '~/hooks/useScenarioCollection';
import cn from 'classnames';

export type ScenarioCollectionModalProps = CustomModalProps & {};

const ScenarioCollectionModal: React.FC<ScenarioCollectionModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    connectedClientProjectId,
    scenariosCollection
  } = useSelector(userSelector);

  const { projectName } = useProjectIdInfo(connectedClientProjectId);

  const { 
    fillConstructorWithScenario,
    deleteScenarioFromCollection
  } = useScenarioCollection();

  return (
    <CustomModal
      {...restProps}
      classname={cn(s.containerModal)}
      title={`${t("Other.scenarioCollection")} (${projectName})`}
      enableBlur={true}
    >
      {connectedClientProjectId && scenariosCollection.length > 0 ?
        <Virtuoso
          style={{ height: '100%', width: '100%' }}
          data={scenariosCollection}
          itemContent={(index, item) => (
            <ExportableScenarioItem 
              scenario={item}
              onPress={() => {
                fillConstructorWithScenario(item);
                restProps.onClose();
              }}
              index={index + 1}
              onPressDelete={() => deleteScenarioFromCollection(item.id)}
            />
          )}
        />
      :
        <NoData text={connectedClientProjectId ? t("Other.noScenariosForThisProject") : t("Other.somethingWentWrongMakeSureSetUpProjectId")}/>
      }
    </CustomModal>
  );
};

ScenarioCollectionModal.defaultProps={}

export { ScenarioCollectionModal };