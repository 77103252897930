import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 3h20v18H2V3zm2 2v14h16V9h-8V5H4z" fill={props.color} />
  </svg>
)

export default SvgComponent
