import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: 100,
    justifyContent: 'space-between',
    borderRadius: theme.metrics.x2,
    overflow: 'hidden'
  },
  bottomDecorLine: {
    height: 2,
    width: '100%',
    backgroundColor: 'white'
  },
  handleContainer: {
    display: 'flex',
    height: '100%',
    width: '9%',
  },
  handle: {
    width: '100%', 
    height: '100%', 
    position: 'relative',
    justifyContent: 'center', 
    alignItems: 'center',
    display: "flex",
    alignContent: "center",
    userSelect: "none",
    padding: "0.5rem",
    borderRadius: "3px 0 0 3px",
    background: theme.colors.instructionItemBgDragging,
  },
  mainContent: {
    display: 'flex',
    // flex: 1,
    height: '100%',
    width: '91%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  instructionIdText: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily,
  },
  conditionSymbolText: {
    margin: 0,
    color: theme.colors.textBright,
    fontFamily: theme.fonts.f600.fontFamily,
    pointerEvents: "none"
  },
  descriptionText: {
    margin: 0,
    color: theme.colors.textTetriary,
    fontSize: 14,
    marginTop: theme.metrics.x,
    fontFamily: theme.fonts.f400.fontFamily,
    pointerEvents: "none"
  },
  block: {
    display: 'flex',
    width: "calc(100% - 36px)",
    height: '100%',
    flexDirection: 'column',
    paddingTop: theme.metrics.x,
    paddingBottom: theme.metrics.x,
    paddingLeft: theme.metrics.x3,
    paddingRight: theme.metrics.x3,
    // backgroundColor: 'blue'
  },
  rowCentered: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  deleteButton: {
    display: 'flex',
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.metrics.x05
  },
  pasteButton: {
    display: 'flex',
    width: 23,
    height: 23,
    transform: "scale(0.7)",
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingLeft: theme.metrics.x
    // backgroundColor: 'red'
  },
  fillButton: {
    display: 'flex',
    width: 18,
    height: 18,
    // justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.metrics.x05
  },
  rowBetween: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    height: '100%',
    // backgroundColor: 'blue'
  },
  rightColInstruments: {
    display: 'flex',
    height: '100%',
    paddingRight: theme.metrics.x,
    paddingTop: theme.metrics.x,
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  parametersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%', 
    overflow: 'scroll', 
    overflowY: "hidden",
    paddingTop: theme.metrics.x2,
    paddingBottom: theme.metrics.x,
    // backgroundColor: 'blue',
    '&::-webkit-scrollbar':{
      width: 0,
      display: 'none'
    }
  },
  syntaxHighligher: {
    '&::-webkit-scrollbar':{
      width: 0,
      display: 'none'
    },
  },
  paramsText: {
    margin: 0,
    marginRight: 4,
    color: theme.colors.textTetriary,
    fontFamily: theme.fonts.f400.fontFamily,
    pointerEvents: 'none'
  },
  inheritIcon: {
    width: 14,
    height: 14,
    marginBottom: 2,
    marginLeft: theme.metrics.x
  },
  conditionIcon: {
    marginLeft: 6,
    cursor: "pointer"
  },
  executingLoader: {
    position: 'absolute',
    right: theme.metrics.x,
    bottom: theme.metrics.x
  }
});