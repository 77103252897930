import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: "flex",
    width: 32,
    height: 32,
    borderRadius: 16,
    cursor: "pointer"
  },
});