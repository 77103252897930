import React, { memo, useMemo, useState } from 'react';
import { useStyles } from './Page500Styles';
import { use500PageAnalytics } from './use500PageAnalytics';
import { Box } from '@material-ui/core';
import { CustomButton } from '~/components/CustomButton';
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { UserActions } from '~/logic/user/UserSlice';
import { useOnlineStatus } from '~/hooks/useOnlineStatus';
import { CriticalProblem500 } from '~/constants/criticalProblems';
import { CONFIG } from '~/config';
import cn from 'classnames';

export type Page500Props = {};

type Page500Params = {
  problem: CriticalProblem500;
};

const Page500: React.FC<Page500Props> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { problem } = state as Page500Params; // Read values passed on state

  const {
    profile: {
      fetching: fetchingProfile
    }
  } = useSelector(userSelector);

  const {
    trackTryAgain
  } = use500PageAnalytics();

  const online = useOnlineStatus();

  // Actually does nothing, just for user to not panic
  const [loader, setLoader] = useState(false);

  const messageText = useMemo(() => {
    if (problem === "socket_server_connection_error") {
      return online ? t("Page500.serverUnavailable") : ("Page500.noInternetConnectionMessage");
    } else {
      if (fetchingProfile)
        return `${t("Page500.attemptingToReconnect")}...`;

      return online ? t("Page500.serverErrorOccured") : t("Page500.noInternetConnectionMessage");
    }
  }, [problem, online, fetchingProfile, t]);

  const buttonTitle = useMemo(() => {
    return t("Page500.tryToReconnect");
  }, [problem, t]);

  const handleTryAgain = () => {
    if (problem === "socket_server_connection_error") {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
      }, CONFIG.SOCKET_RECONNECTION_DELAY);
    } else {
      dispatch(UserActions.profile.request({
        successCallback: () => navigate("/main")
      }));
    }
    
    trackTryAgain();
  }

  return (
    <Box className={cn(s.container)}>
      <Box className={cn(s.center)}>
        <Box className={cn("animate__animated animate__hinge animate__delay-1s")}>
          <Box className={cn(s.logoCont)}>
            <img src={theme.icons.CODEBUD_LOGO} className={ds.fullSpaceImg}/>
          </Box>
        </Box>
        <p className={cn(s.whiteText)}>
          {messageText}
        </p>
        <Box className={cn(s.button)}>
          <CustomButton 
            title={buttonTitle}
            onPress={handleTryAgain}
            spinner={fetchingProfile || loader}
          />
        </Box>
      </Box>
    </Box>
  );
};

Page500.defaultProps={}

const MemorizedComponent = memo(Page500);
export { MemorizedComponent as Page500 };