import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  formCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "50%",
    minWidth: 400,
    maxWidth: 600,
    minHeight: 500,
    padding: theme.metrics.x5,
    borderRadius: theme.metrics.x4,
    backgroundColor: theme.colors.bgForm
  },
  mainText: {
    color: theme.colors.textFormMain,
    fontFamily: theme.fonts.f400.fontFamily
  },
  bolder: {
    fontFamily: theme.fonts.f600.fontFamily
  },
  bottomBtn: {
    width: '100%',
    marginTop: theme.metrics.x6
  },
});