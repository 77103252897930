import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill={props.color}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M14.62 8.35c-.42.28-1.75 1-1.95 1.19a.82.82 0 0 1-1.14 0c-.2-.16-1.53-.92-1.95-1.19s-.45-.7.08-.92a6.16 6.16 0 0 1 4.91 0c.49.21.51.6 0 .9m7.22 7.28A19.09 19.09 0 0 0 18 10a4.31 4.31 0 0 1-1.06-1.88c-.1-.33-.17-.67-.24-1a11.32 11.32 0 0 0-.7-2.65A4.06 4.06 0 0 0 12.16 2a4.2 4.2 0 0 0-3.95 2.4 5.9 5.9 0 0 0-.46 1.34c-.17.76-.32 1.55-.5 2.32a3.38 3.38 0 0 1-1 1.71 19.53 19.53 0 0 0-3.88 5.35A6.09 6.09 0 0 0 2 16c-.19.66.29 1.12 1 1 .44-.09.88-.18 1.3-.31s.57 0 .67.35a6.73 6.73 0 0 0 4.24 4.5c4.12 1.56 8.93-.66 10-4.58.07-.27.17-.37.47-.27.46.14.93.24 1.4.35a.72.72 0 0 0 .92-.64 1.44 1.44 0 0 0-.16-.73" />
  </svg>
)
export { SvgComponent as LinuxIcon }
