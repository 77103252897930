import { CustomCommand } from 'reactotron-core-client';
import { reactotron } from '../ReactotronConfig';

type Command = CustomCommand & {removeHandler?: () => void};
const commands: Command[] = 
[
  ...require('./UserLoginCommand').commands,
  ...require('./RemoteSettingsCommand').commands
];

function registerCommands() {
  // console.log('registerCommands');
  commands.forEach(command => {
    command.removeHandler?.();
    command.removeHandler = reactotron.onCustomCommand(command);
  });
}
export { registerCommands };

