import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  progressBarCont: {
    display: 'flex',
    position: 'absolute',
    width: "100%",
    height: theme.metrics.x,
    minHeight: theme.metrics.x,
    bottom: 0
  },
  secondaryProgressBar: {
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: 2,
    zIndex: 0
  },
  mainProgressBar: {
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    borderRadius: 2,
    zIndex: 1
  },
});