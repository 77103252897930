import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useMailTabAnalytics = () => {
  const trackUserViewingMailbox = () => {
    segmentAnalytics.track("Viewing mailbox");
  };

  useEffect(() => {
    segmentAnalytics.page("MainPage", "MailTab");
  }, []);

  return {
    trackUserViewingMailbox
  };
};