import { FloatingViewSizings } from "./types";

export const FLOATING_VIEW_SIZINGS: FloatingViewSizings = {
  WIDTH: {
    NetworkTimeline: 750,
    NetworkAvailableInstructions: 340,
    ReduxInspector: 550,
    ToolsPanel: 750
  },
  HEIGHT: {
    NetworkTimeline: 600,
    NetworkAvailableInstructions: 340,
    ReduxInspector: 400,
    ToolsPanel: 300
  }
} as const;

export const FLOATING_VIEW_SHORTCUT_POSITION = {
  RIGHT: 12,
  BOTTOM: 12
} as const;

export const FLOATING_VIEW_ANIMATIONS_DURATIONS = {
  VIEW_MINIMIZATION: {
    CSS: "0.325s",
    JS: 325
  },
  SHORTCUT_ENTRY: {
    CSS: "0.125s",
    JS: 125
  }
} as const;