import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { InstructionType } from '~/types/api';
import { theme } from '~/theme/default/theme';
import { useTranslation } from 'react-i18next';

// Global constants
const COLOR_BY_TYPE: {[k in InstructionType]: string} = {
  default: theme.colors.emperor,
  special: theme.colors.selectiveYellow
};

export type InstructionTypeIndicatorProps = {
  type: InstructionType;
  marginLeft?: number;
  marginRight?: number;
};

const InstructionTypeIndicator: React.FC<InstructionTypeIndicatorProps> = ({
  type,
  marginLeft,
  marginRight
}) => {
  const { t } = useTranslation();

  const ALT_BY_TYPE: {[k in InstructionType]: string} = useMemo(() => ({
    default: t("Other.yourInstruction"),
    special: t("Other.specialInstruction")
  }), [t]);

  return (
    <Box 
      style={{
        height: 12, width: 12, borderRadius: 6,
        marginLeft,
        marginRight,
        backgroundColor: COLOR_BY_TYPE[type]
      }}
      title={ALT_BY_TYPE[type]}
    />
  );
};

InstructionTypeIndicator.defaultProps={
  marginLeft: 8,
  marginRight: 0
}

export { InstructionTypeIndicator };