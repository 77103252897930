import { useMemo } from "react";
import { useSelector } from "react-redux";
import { remoteSettingsSelector } from "~/logic/remoteSettings/RemoteSettingsSelectors";

export const useProjectIdInfo = (projectId: string | undefined) => {
  const {
    projectList: {
      data: projectList
    }
  } = useSelector(remoteSettingsSelector);

  const projectName = useMemo(() => {
    if (!projectId || !projectList)
      return "";

    const project = projectList.find((p) => p._id === projectId);

    return project?.name ?? "";
  }, [projectId, projectList]);

  return {
    projectName
  };
}