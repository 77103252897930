import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  exportScenarioModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '45vw',
    height: '60vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'scroll'
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 12
  },
  inputCont: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.metrics.x4
  },
  label: {
    fontSize: 18,
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.textMain,
    margin: 0
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.metrics.x2
  },
  checkboxesCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.metrics.x4
  }
});