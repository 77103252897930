import React, { useMemo, useEffect, useContext } from 'react';
import { useStyles } from './MainStyles';
import { useTrackUserSentEvent } from '~/hooks/analyticsCommon';
import { Box } from '@material-ui/core';
import { FloatingView, FloatingViewShortcut } from "~/components/FloatingView";
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { DndMainTabContext, DndOtherTabContext } from '~/providers';
import { NavigationBar } from '~/components/NavigationBar';
import { ClientSelector } from '~/components/ClientSelector';
import { MainTab } from './Tabs/MainTab';
import { MailTab } from './Tabs/MailTab';
import { ReduxTab } from './Tabs/ReduxTab';
import { ZustandTab } from './Tabs/ZustandTab';
import { TanStackQueryTab } from './Tabs/TanStackQueryTab';
import { ContextsTab } from './Tabs/ContextsTab';
import { MobxTab } from './Tabs/MobxTab';
import { RemoteSettingsTab } from './Tabs/RemoteSettingsTab';
import { NetworkTab } from './Tabs/NetworkTab';
import { OtherTab } from './Tabs/OtherTab';
import { ActionModal } from '~/components/ActionModal';
import { ParamsModal } from '~/components/ParamsModal';
import { ImportScenarioModal } from '~/components/ImportScenarioModal';
import { AccountsModal } from '~/components/AccountsModal';
import { NetworkFiltersModal } from '~/components/NetworkFiltersModal';
import { StorageSnapshotModal } from '~/components/StorageSnapshotModal';
import { ConnectedClientInfoModal } from '~/components/ConnectedClientInfoModal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserActions } from '~/logic/user/UserSlice';
import { userSelector } from '~/logic/user/UserSelectors';
import { socketService } from '~/services/socketService';
import { useThemeContext } from '~/theme';
import { ExportScenarioModal } from '~/components/ExportScenarioModal';
import { ScenarioCollectionModal } from '~/components/ScenarioCollectionModal';
import { ParseHtmlModal } from '~/components/ParseHtmlModal';
import cn from 'classnames';

export type MainProps = {};

const Main: React.FC<MainProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    navigationTab,
    draggableInstuctionsState,
    paramsModalVisible,
    importScenarioModalVisible,
    exportScenarioModalVisible,
    scenarioCollectionModalVisible,
    paramsModalState,
    actionModalState,
    testAccountsModalVisible,
    parseHtmlModalVisible,
    singleEventConstructor,
    networkFiltersModalVisible,
    connectedClientInfoModalVisible,
    storageSnapshotModalVisible,
    viewingStorageSnapshot
  } = useSelector(userSelector);

  const {
    onDragEnd: onDragEndMainTab
  } = useContext(DndMainTabContext);

  const {
    onDragEnd: onDragEndOtherTab
  } = useContext(DndOtherTabContext);

  const {
    trackUserSentEvent
  } = useTrackUserSentEvent();

  const onDragEnd = (result: DropResult) => {
    switch (navigationTab) {
      case "MAIN_TAB":
        onDragEndMainTab(result);
        break;
      case "OTHER_TAB":
        onDragEndOtherTab(result);
        break;
      default:
        break;
    }
  }

  const handleSendSingleConstructableEvent = () => {
    if (singleEventConstructor) {
      socketService.emitEvent(singleEventConstructor);
      dispatch(UserActions.setParamsModalVisible(false));
      dispatch(UserActions.setSingleEventConstructor(null));
    }
  }

  const profileRequest = () => {
    dispatch(UserActions.profile.request({navigate}));
  }

  const CurrentTab = useMemo(() => {
    switch (navigationTab) {
      case "MAIN_TAB":
        return (<MainTab />);
      case "MAIL_TAB":
        return (<MailTab />);
      case "REDUX_INSPECTOR_TAB":
        return (<ReduxTab />);
      case "ZUSTAND_INSPECTOR_TAB":
        return (<ZustandTab />);
      case "TANSTACK_QUERY_INSPECTOR_TAB":
        return (<TanStackQueryTab />);
      case "CONTEXTS_INSPECTOR_TAB":
        return (<ContextsTab />);
      case "MOBX_INSPECTOR_TAB":
        return (<MobxTab />);
      case "REMOTE_SETTINGS_TAB":
        return (<RemoteSettingsTab />);
      case "NETWORK_TAB":
        return (<NetworkTab />);
      case "OTHER_TAB":
        return (<OtherTab />);
    
      default:
        return null;
    }
  }, [navigationTab]);

  // ComponentDidMount
  useEffect(() => {
    socketService.clearOnSuccessConnectCallback();
    profileRequest();
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box className={cn(ds.fullSpace)} style={{justifyContent: 'flex-end'}}>
        <NavigationBar />

        <Box className={cn(s.currentTabContainer)}>
          {CurrentTab}

          <FloatingView />

          <FloatingViewShortcut />
         
          <ClientSelector />
        </Box>

        <ParamsModal
          visible={paramsModalVisible}
          onClose={() => dispatch(UserActions.setParamsModalVisible(false))}
          constructableEvent={navigationTab === "MAIN_TAB" ? paramsModalState ? draggableInstuctionsState[paramsModalState.key][paramsModalState.eventIndex] : undefined : singleEventConstructor}
          onChangeText={(param, value) => {
            if (navigationTab === "MAIN_TAB") {
              paramsModalState && dispatch(UserActions.setParamValue({
                key: paramsModalState.key,
                index: paramsModalState.eventIndex,
                param, 
                value
              }));
            } else {
              dispatch(UserActions.setSingleEventConstructorParamValue({param, value}));
            }
          }}
          onPressSend={navigationTab === "MAIN_TAB" ? undefined : 
            (numOfParams) => {
              handleSendSingleConstructableEvent();
              trackUserSentEvent("NETWORK_TAB", numOfParams);
            }
          }
        />

        {navigationTab === "MAIN_TAB" &&
          <>
            <ImportScenarioModal 
              visible={importScenarioModalVisible}
              onClose={() => dispatch(UserActions.setImportScenarioModalVisible(false))}
            />
            <ExportScenarioModal 
              visible={exportScenarioModalVisible}
              onClose={() => dispatch(UserActions.setExportScenarioModalVisible(false))}
            />
            <ScenarioCollectionModal 
              visible={scenarioCollectionModalVisible}
              onClose={() => dispatch(UserActions.setScenarioCollectionModalVisible(false))}
            />
          </>
        }

        {navigationTab === "NETWORK_TAB" &&
          <>
            <NetworkFiltersModal 
              visible={networkFiltersModalVisible}
              onClose={() => dispatch(UserActions.setNetworkFiltersModalVisible(false))}
            />
            <StorageSnapshotModal 
              visible={storageSnapshotModalVisible}
              snapshot={viewingStorageSnapshot}
              onClose={() => dispatch(UserActions.setStorageSnapshotModalVisible(false))}
            />
          </>
        }

        <AccountsModal 
          visible={testAccountsModalVisible}
          onClose={() => dispatch(UserActions.setTestAccountsModalVisible(false))}
        />

        <ParseHtmlModal 
          visible={parseHtmlModalVisible}
          onClose={() => dispatch(UserActions.setParseHtmlModalVisible(false))}
        />

        <ConnectedClientInfoModal 
          visible={connectedClientInfoModalVisible}
          onClose={() => dispatch(UserActions.setConnectedClientInfoModalVisible(false))}
        />

        <ActionModal 
          visible={actionModalState?.visible ?? false}
          onClose={() => dispatch(UserActions.setActionModalState(null))}
          onPressYes={actionModalState?.onPressYes ?? (() => {})}
          onPressNo={actionModalState?.onPressNo}
          title={actionModalState?.title}
          text={actionModalState?.text}
        />
      </Box>
    </DragDropContext>
  );
};

Main.defaultProps={}

export { Main };