import { CapturedCrashReport, CapturedEvent, InterceptedApiCall, InterceptedMobxEvent, InterceptedReduxAction, InterceptedStorageAction, InterceptedTanStackQueryEvent, LogsTimelineItem, TimelineItem, TimelineItemType, TimelineSorting } from "../types/types";
import { prepareTanStackQueryPrettyKey } from "./strings";

export const makeInterceptedApiCallsCopy = (timelineItems: TimelineItem[]) => {
  return [...timelineItems];
};

export const makeLogsTimelineCopy = (logsTimelineItems : LogsTimelineItem[]) => {
  return [...logsTimelineItems];
}

export const addSearchQueryToTimelineItems = (timelineItems: TimelineItem[], itemsType: TimelineItemType) => {
  switch (itemsType) {
    case "InterceptedApiCall":
      (timelineItems as InterceptedApiCall[]).forEach((el) => {el.sQuery = `${el.request.method} ${el.request.url}`});
      break;
    case "InterceptedReduxAction":
      (timelineItems as InterceptedReduxAction[]).forEach((el) => {el.sQuery = el.action.type});
      break;
    case "InterceptedStorageAction":
      (timelineItems as InterceptedStorageAction[]).forEach((el) => {el.sQuery = el.action});
      break;
    case "CapturedEvent":
      (timelineItems as CapturedEvent[]).forEach((el) => {el.sQuery = el.title});
      break;
    case "InterceptedTanStackQueryEvent":
      (timelineItems as InterceptedTanStackQueryEvent[]).forEach((el) => {
        let sQuery = el.event.type;
        
        if (el.event.query?.queryHash)
          sQuery += ` ${el.event.query.queryHash}`;
        if (el.event.query?.queryKey)
          sQuery += ` ${prepareTanStackQueryPrettyKey(el.event.query.queryKey)}`;

        el.sQuery = sQuery;
      });
      break;
    case "InterceptedMobxEvent":
      (timelineItems as InterceptedMobxEvent[]).forEach((el) => {el.sQuery = `${el.event.type} ${el.event.name ?? ""} ${el.event.message ?? ""}`});
      break;
    case "CapturedCrashReport":
      (timelineItems as CapturedCrashReport[]).forEach((el) => {el.sQuery = `crash or error report ${el.type}`});
      break;
  
    default:
      break;
  }
};

export const addItemsToTimelineItemsArray = ({
  destination,
  itemsToAdd,
  sorting,
  itemsType
}: {destination: TimelineItem[], itemsToAdd: TimelineItem[], sorting: TimelineSorting, itemsType: TimelineItemType}) => {
  addSearchQueryToTimelineItems(itemsToAdd, itemsType);

  if (sorting === "newestOnTop") {
    destination.unshift(...itemsToAdd);
  } else {
    destination.push(...itemsToAdd);
  }
};

export const sortAndSliceNetworkTimeline = (timelineItems: TimelineItem[], sorting: TimelineSorting, maxItems: number, sliceItemsToClear: number = Math.floor(maxItems / 2)) => {
  return timelineItems.sort((a, b) => {
    const aTimestamp = "requestId" in a ? (a.timestampRequest ?? a.timestampResponse) : a.timestamp;
    const bTimestamp = "requestId" in b ? (b.timestampRequest ?? b.timestampResponse) : b.timestamp;

    if (sorting === "newestOnTop")
      return aTimestamp! < bTimestamp! ? 1 : -1;
      
    return aTimestamp! >= bTimestamp! ? 1 : -1;
  }).slice(
    sorting === "oldestOnTop" ? (maxItems >= timelineItems.length ? 0 : timelineItems.length - maxItems + sliceItemsToClear) : 0,
    sorting === "oldestOnTop" ? timelineItems.length : maxItems >= timelineItems.length ? maxItems : maxItems - sliceItemsToClear
  );
}

export const sortAndSliceLogsTimeline = (logsTimelineItems : LogsTimelineItem[], sorting: TimelineSorting, maxItems: number, sliceItemsToClear: number = Math.floor(maxItems / 2)) => {
  return logsTimelineItems.sort((a, b) => {
    if (sorting === "newestOnTop") {
      if (a.endTimestamp === b.endTimestamp) {
        // Both scenario
        if ("scenario" in a && "scenario" in b)
          return a.scenario.id < b.scenario.id ? 1 : -1;
        // Both event
        if ("event" in a && "event" in b)
          return a.event.id < b.event.id ? 1 : -1;
        // One log is event and other is scenario
        return "event" in a ? 1 : -1;
      }
      return a.endTimestamp < b.endTimestamp ? 1 : -1;
    }

    if (a.endTimestamp === b.endTimestamp) {
      // Both scenario
      if ("scenario" in a && "scenario" in b)
        return a.scenario.id > b.scenario.id ? 1 : -1;
      // Both event
      if ("event" in a && "event" in b)
        return a.event.id > b.event.id ? 1 : -1;
      // One log is event and other is scenario
      return "scenario" in a ? 1 : -1;
    }
    return a.endTimestamp > b.endTimestamp ? 1 : -1;
  }).slice(
    sorting === "oldestOnTop" ? (maxItems >= logsTimelineItems.length ? 0 : logsTimelineItems.length - maxItems + sliceItemsToClear) : 0,
    sorting === "oldestOnTop" ? logsTimelineItems.length : maxItems >= logsTimelineItems.length ? maxItems : maxItems - sliceItemsToClear
  );
}