import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeWidth={3}
      d="M4 6h24M4 16h24M4 26h24"
    />
  </svg>
)
export { SvgComponent as MenuIcon }
