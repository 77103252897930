import React from 'react';
import { useStyles } from './LanguageItemStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CurrentLangT } from '~/types/types';
import { FlagsImagesSmall } from '~/assets/flagsImages';
import cn from 'classnames';

const LANGUAGE_IMAGES_TABLE: {[key in CurrentLangT]: any} = {
  en: FlagsImagesSmall.UnitedKingdom,
  ru: FlagsImagesSmall.Russia,
  es: FlagsImagesSmall.Spain,
  ua: FlagsImagesSmall.Ukraine
};

export type LanguageItemProps = {
  selected: boolean;
  language: CurrentLangT;
  marginRight?: number;
  title: string;
  onPress: () => void;
};

const LanguageItem: React.FC<LanguageItemProps> = ({
  selected,
  language,
  marginRight,
  title,
  onPress
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <Box 
      className={cn(s.container)}
      title={title}
      style={{
        marginRight,
        border: `1px solid ${selected ? theme.colors.textBright : theme.colors.greySubText}`
      }}
      onClick={onPress}
    >
      <img src={LANGUAGE_IMAGES_TABLE[language]} className={cn(ds.fullSpaceImg)} />
    </Box>
  );
};

LanguageItem.defaultProps={}

export { LanguageItem };