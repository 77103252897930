import React, { memo, useMemo } from 'react';
import { useStyles } from './ParseHtmlModalStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { useTranslation } from 'react-i18next';
import parse from "html-react-parser";
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { NoData } from '../NoData';
import { getHtmlFromPrettyJsonEscapeDividedLine } from '~/helpers/strings';

export type ParseHtmlModalProps = CustomModalProps & {

};

const ParseHtmlModal: React.FC<ParseHtmlModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    parseHtmlModalValue: rawLineThatContainsHtml,
    parseHtmlModalVisible
  } = useSelector(userSelector);

  // console.log("parseHtmlModalValue", parseHtmlModalValue)

  const html = useMemo(() => parseHtmlModalVisible ? getHtmlFromPrettyJsonEscapeDividedLine(rawLineThatContainsHtml) : "", [parseHtmlModalVisible, rawLineThatContainsHtml]);

  // console.log("preparedHtml", html);

  return (
    <CustomModal
      {...restProps} 
      onClose={restProps.onClose}
      classname={cn(s.modal)} 
      title={t("Common.htmlViewer")}
      enableBlur={true}
    >
      <Box className={cn(s.content)}>
        {html.length > 0 ?
          parse(html, {trim: true})
        :
          <NoData text={t("Other.htmlParseError")} />
        }
      </Box>
    </CustomModal>
  );
};

ParseHtmlModal.defaultProps={}

const MemorizedComponent = memo(ParseHtmlModal);
export { MemorizedComponent as ParseHtmlModal };