import { useRoutes, Navigate, Outlet } from "react-router-dom";
import { DndMainTabProvider, DndOtherTabProvider, FloatingViewProvider, AvailableInstructionsProvider } from '~/providers';
import { Main } from './Pages/Main';
import { Page404 } from "./Pages/Page404";
import { Page500 } from "./Pages/Page500";
import { LoginPage } from './Pages/Login';
import { OtpPage } from './Pages/Otp';
import { useSelector } from "react-redux";
import { appSettingsSelector } from "./logic/appSettings/AppSettingsSelectors";
import { userSelector } from "~/logic/user/UserSelectors";
import { NoData } from "./components/NoData";

type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectPath: string;
  children: any;
};

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/landing',
  children,
}: ProtectedRouteProps) => {
  if (!isAllowed)
    return (<Navigate to={redirectPath} replace />);

  return (children ?? <Outlet />);
};

type PageWithLoadingPlaceholderProps = {
  loading: boolean;
  children: any;
};

const PageWithLoadingPlaceholder = ({
  loading,
  children
}: PageWithLoadingPlaceholderProps) => {
  if (loading) {
    return (
      <NoData 
        loading={true} 
        loaderSize={56}
      />
    );
  }

  return (children ?? <Outlet />);
};

export default function Router() {
  const {
    initialSetupDone,
    manuallyShowMainPageLoader
  } = useSelector(appSettingsSelector);

  const { 
    userToken,
    profile: {
      data: profile,
      fetching: fetchingProfile
    }
  } = useSelector(userSelector);

  return useRoutes([
    {
      path: "/",
      element: (
        <PageWithLoadingPlaceholder loading={!initialSetupDone}>
          <LoginPage />
        </PageWithLoadingPlaceholder>
      )
    },
    {
      path: "/login/:theme?",
      element: (
        <PageWithLoadingPlaceholder loading={!initialSetupDone}>
          <LoginPage />
        </PageWithLoadingPlaceholder>
      )
    },
    {
      path: "/otp",
      element: <OtpPage />
    },
    {
      path: "/main",
      element: (
        <ProtectedRoute isAllowed={!!userToken} redirectPath="/">
          <PageWithLoadingPlaceholder loading={manuallyShowMainPageLoader || !profile && fetchingProfile}>
            <DndMainTabProvider>
              <DndOtherTabProvider>
                <FloatingViewProvider>
                  <AvailableInstructionsProvider>
                    <Main />
                  </AvailableInstructionsProvider>
                </FloatingViewProvider>
              </DndOtherTabProvider>
            </DndMainTabProvider>
          </PageWithLoadingPlaceholder>
        </ProtectedRoute>
      ),
    },
    { 
      path: "/internal_error", 
      element: (
        <ProtectedRoute isAllowed={!!userToken} redirectPath="/">
          <Page500 /> 
        </ProtectedRoute>
      )
    },
    { path: "*", element: <Page404 /> },
  ]);
}