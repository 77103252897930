import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  loader: {
    display: "inline-block",
    borderRadius: "50%",
    position: "relative",
    boxSizing: "border-box",
    animation: `$rotation 1s linear infinite`,

    // animation: `$rotation 1s linear infinite`,
    // "&::after": {
    //   content: '', 
    //   position: "absolute",
    //   left: "50%",
    //   top: "50%",
    //   transform: "translate(-50%, -50%)",
    //   width: 40,
    //   height: 40,
    //   borderRadius: 20,
    //   border: "3px solid #FF3D00",
    // },
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  }
});