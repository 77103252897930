import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Zm1.5-26a1.5 1.5 0 0 0-3 0v4.5H14a1.5 1.5 0 0 0 0 3h4.5V26a1.5 1.5 0 0 0 3 0v-4.5H26a1.5 1.5 0 1 0 0-3h-4.5V14Z"
      clipRule="evenodd"
    />
  </svg>
)

export { SvgComponent as PlusIcon }