import { segmentAnalytics } from "~/services/segmentAnalyticsService";
import { NavigationTab } from "~/types/types";

export const useTrackUserSentEvent = () => {
  const trackUserSentEvent = (tab: NavigationTab, numOfParams: number) => {
    segmentAnalytics.track("Event sent", {tab, numOfParams})
  };

  return {
    trackUserSentEvent
  };
};