import React, { memo } from 'react';
import { useStyles } from './NetworkFiltersModalStyles';
import { Box } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { useTrackNetworkFiltersApplied } from '~/hooks/analyticsCommon';
import { Checkbox } from '../Checkbox';
import { UserActions } from '~/logic/user/UserSlice';
import { CustomModal, CustomModalProps } from '../CustomModal';
import cn from 'classnames';

const FILTER_LABELS_TABLE = {
  InterceptedApiCallVisible: "API Requests",
  InterceptedReduxActionVisible: "Redux Actions",
  InterceptedStorageActionVisible: "Storage Actions",
  CapturedEventVisible: "Captured Events",
  InterceptedTanStackQueryEventVisible: "TanStack Query Events",
  InterceptedMobxEventVisible: "MobX Events",
  CapturedCrashReportVisible: "Crash Reports"
};

export type NetworkFiltersModalProps = CustomModalProps & {};

const NetworkFiltersModal: React.FC<NetworkFiltersModalProps> = ({
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    networkFiltersModalState
  } = useSelector(userSelector);

  const {
    trackNetworkFiltersApplied
  } = useTrackNetworkFiltersApplied();

  const onPressFilter = (key: string, value: boolean) => {
    dispatch(UserActions.updateNetworkFiltersModalState({
      // @ts-ignore
      key,
      value
    }))
  }

  const innerOnClose = () => {
    trackNetworkFiltersApplied(networkFiltersModalState);
    restProps.onClose();
  }

  return (
    <CustomModal
      {...restProps} 
      onClose={innerOnClose}
      classname={cn(s.modal)} 
      title={t("Other.timelineFilter")}
      enableBlur={false}
    >
      <Box className={cn(s.content)}>
        {Object.keys(networkFiltersModalState).map((key) => (
          <Box key={`${key}_filter`} className={cn(s.checkboxRow)}>
            <Checkbox 
              // @ts-ignore
              mark={networkFiltersModalState[key]}
              // @ts-ignore
              onPress={() => onPressFilter(key, !networkFiltersModalState[key])}
            />
            <p className={cn(s.filterTitleText)}>
              {/* @ts-ignore */}
              {FILTER_LABELS_TABLE[key]}
            </p>
          </Box>
        ))}
      </Box>
    </CustomModal>
  );
};

NetworkFiltersModal.defaultProps={}

const MemorizedComponent = memo(NetworkFiltersModal);
export { MemorizedComponent as NetworkFiltersModal };