import { NavigationTab } from '~/types/types';
import { NavigationBarTabT } from '../NavigationBarTab';
import {
  MainIconV3, 
  MailIconV2,
  ReduxIcon,
  RemoteSettingsIconV2,
  NetworkIconV2,
  OtherIcon,
  ZustandIcon,
  TanStackIcon,
  ContextIcon,
  MobxIcon
} from "./assets";
import { ReactElement } from 'react';

export const REQUIRED_NAVIGATION_BAR_TABS = new Set<NavigationTab>(["MAIN_TAB", "OTHER_TAB"]);

export const ALL_NAVIGATION_BAR_TABS: NavigationBarTabT[] = [
  {
    id: "MAIN_TAB",
    defaultIndex: 0
  },
  {
    id: "MAIL_TAB",
    defaultIndex: 1
  },
  {
    id: "REDUX_INSPECTOR_TAB",
    defaultIndex: 2
  },
  {
    id: "ZUSTAND_INSPECTOR_TAB",
    defaultIndex: -1,
  },
  {
    id: "TANSTACK_QUERY_INSPECTOR_TAB",
    defaultIndex: -1,
  },
  {
    id: "CONTEXTS_INSPECTOR_TAB",
    defaultIndex: -1
  },
  {
    id: "MOBX_INSPECTOR_TAB",
    defaultIndex: -1
  },
  {
    id: "REMOTE_SETTINGS_TAB",
    defaultIndex: 3
  },
  {
    id: "NETWORK_TAB",
    defaultIndex: 4
  },
  {
    id: "OTHER_TAB",
    defaultIndex: 5
  }
];

export const DEFAULT_CHOSEN_NAVIGATION_BAR_TABS = ALL_NAVIGATION_BAR_TABS.filter((tab) => tab.id !== "ZUSTAND_INSPECTOR_TAB" && tab.id !== "TANSTACK_QUERY_INSPECTOR_TAB" && tab.id !== "CONTEXTS_INSPECTOR_TAB" && tab.id !== "MOBX_INSPECTOR_TAB");

export const NAVIGATION_BAR_TABS_ICON_TABLE: {[k in NavigationTab]: (props: any) => ReactElement} = {
  "MAIN_TAB": MainIconV3,
  "MAIL_TAB": MailIconV2,
  "REDUX_INSPECTOR_TAB": ReduxIcon,
  "ZUSTAND_INSPECTOR_TAB": ZustandIcon,
  "TANSTACK_QUERY_INSPECTOR_TAB": TanStackIcon,
  "CONTEXTS_INSPECTOR_TAB": ContextIcon,
  "MOBX_INSPECTOR_TAB": MobxIcon,
  "REMOTE_SETTINGS_TAB": RemoteSettingsIconV2,
  "NETWORK_TAB": NetworkIconV2,
  "OTHER_TAB": OtherIcon
};

export const NAVIGATION_BAR_TABS_TITLE_TABLE: {[k in NavigationTab]: string} = {
  "MAIN_TAB": "Main",
  "MAIL_TAB": "Mail",
  "REDUX_INSPECTOR_TAB": "Redux",
  "ZUSTAND_INSPECTOR_TAB": "Zustand",
  "TANSTACK_QUERY_INSPECTOR_TAB": "TQuery",
  "CONTEXTS_INSPECTOR_TAB": "Context",
  "MOBX_INSPECTOR_TAB": "MobX",
  "REMOTE_SETTINGS_TAB": "Control",
  "NETWORK_TAB": "Network",
  "OTHER_TAB": "Other"
};

export const NAVIGATION_BAR_TABS_STYLE_TABLE: {[k in NavigationTab]: string} = {
  "MAIN_TAB": "mainTabIcon",
  "MAIL_TAB": "mailTabIconV2",
  "REDUX_INSPECTOR_TAB": "reduxTabIcon",
  "ZUSTAND_INSPECTOR_TAB": "zustandTabIcon",
  "TANSTACK_QUERY_INSPECTOR_TAB": "tanStackQueryTabIcon",
  "CONTEXTS_INSPECTOR_TAB": "contextTabIcon",
  "MOBX_INSPECTOR_TAB": "mobxTabIcon",
  "REMOTE_SETTINGS_TAB": "remoteSettingsTabIconV2",
  "NETWORK_TAB": "networkTabIconV2",
  "OTHER_TAB": "otherTabIcon",
};