const black = "#000000";
const black10 = "#00000010";
const black18 = "#00000018";
const black1b = "#0000001b";
const black20 = "#00000020";
const black40 = "#00000040";
const black50 = "#00000050";
const white = "#ffffff";
const white10 = "#ffffff10";
const white50 = "#ffffff50";
const blackOpacityBackground = "rgba(0, 0, 0, 0.2)";
const blackRgba05 = "rgba(0, 0, 0, 0.5)";
const yellowMain = "#FED337";
const yellowMainOpacity = "rgba(254, 211, 55, 0.1)";
const subBackground = "#202427";
const greySubText = "#6D6D7B";
const itemBackground = "#D9D9D9";
const inactiveIcons = "#707070";
const textSub = "#FEF8FF";
const boldText = "#F9FBFE";
const greySubTextDesc = "#9F9FA0";
const lightGreen = "#40FDC8";
const transparent = "transparent";
const borderMdm = "rgba(255, 255, 255, 0.3)";
const borderSml = "rgba(255, 255, 255, 0.1)";
const mineShaft = "#323232";
const disabledBackground = "#0E1011";
const textDescription = "rgba(255, 255, 255, 0.6)";
const red = "#ff0000";
const redOpacity = "rgba(232, 111, 111, 0.1)";
const bayOfMany = "#293883";
const photoBackground = "#2C2C2C";
const iron = "#D5DBDC";
const shark = "#191C1E";
const sharkLight = "#282c34";
const abbey = "#47494B";
const green100 = "#ECFAF0";
const red100 = "#EFE1E9";
const blue100 = "#E9F7FC";
const yellow100 = "#FDF7EA";
const grey200 = "#D5DBDC";
const grey800 = "#374244";
const dodgerBlue = "#4B7DFF";
const green = "#00ff00";
const descOpacityText = "#FEF1EB";
const sulu = "#AAEA78";
const feijoa = "#92D37B";
const woodsmoke = "#0E0E0F";
const woodsmokeDarker = "#080709";
const woodsmokeLight = "#141317";
const bamboo = "#D96901";
const azure = "#007AFF";
const greenError = "#56D46A";
const greenHaze = "#009A61";
const headerBlack = "#171A1C";
const blurBg = "#000000b0";
const lightBackground = "#252525c4";
const tan = "#D4A994";
const caramel = "#FAD59E";
const peach = "#FFEBAD";
const thistle = "#DDB6E3";
const bluebell = "#A69BD1";
const nyanza = "#E2FCDA";
const powderBlue = "#B5E6E5";
const floralWhite = "#FCFAED";
const peachOrange = "#FEC591";
const blueBayoux = "#475678";
const yellow = "#ff0";
const doveGray = "#666666";
const laser = "#cba768";
const fedora = "#797879";
const alto = "#dddddd";
const easternBlue = "#1893A3";
const easternBlue80 = "#1893a380";
const astral = "#3281a8";
const greenPea = "#236b41";
const mobster = "#7b757d";
const crail = "#c06548";
const mako = "#3f4149";
const selectiveYellow = "#fcba03";
const silver = "#bbbbbb";
const blueRibbon = "#3d3dff";
const emperor = "#525252";
const cornflowerBlue = "#5d4efc";
const silverChalice = "#ababab";
const forestGreen = "#1da835";
const bermudaGray = "#7488a6";
const coralRed = "#ff4444";
const guardsmanRed = "#c70202";
const mandy = "#DF4D50";
const aqua = "#29e6ff";
const codGray = "#1f1e1e";
const codGrayDarker = "#0D0D0D";
const scooter = "#2eaed9";
const fog = "#E2DFFE";
const balticSea = "#19181d";
const balticSeaPurple = "#1C1A1E";
const balticSeaPurpleLight = "#19181C";
const balticSeaPurpleIntense = "#1F1D22";
const gray = "#808080";
const ebonyClay = "#1f2533";
const shipGray = "#29262E";
const shipGrayLight = "#423D4A";
const persianBlue = "#2124c2";
const jaguar = "#190940";
const cinder = "#09070E";
const frenchGray = "#BEBDC1";
const wildSand = "#f4f3f3";
const wildSandLight = "#f4f4f4";
const jetStream = "#bfd8d5";
const scorpion = "#575757";
const casper = "#b1bed5";
const pearlBush = "#E1D7C6";
const pearlBushLight = "#ECE8DD";
const makara = "#8f806a";
const donkeyBrown = "#a8977d";
const tundora = "#444444";
const arrowtown = "#94856d";
const bisonHide = "#C3B7A5";
const softAmber = "#cfc1ae";
const walnut = "#75481d";
const madang = "#aaf0ae";
const aquamarineBlue = "#69e5ae";
const japaneseLaurel = "#008000";
const japaneseLaurel40 = "#00800040";
const tango = "#f16e27";
const brightTurquoise = "#0edae8";
const gallery = "#EBEBEB";
const boulder = "#777777";
const jumbo = "#7C7B80";
const cardinGreen = "#013220";
const seashell = "#F1F1F1";

const pastelColors = [
  tan,
  caramel,
  peach,
  thistle,
  bluebell,
  nyanza,
  powderBlue,
  floralWhite,
  peachOrange
];

const highlightingColorsPreviewsDark = {
  highlighting_one_preview_1: "#e06c76",
  highlighting_one_preview_2: "#98c379",
  highlighting_gruvbox_preview_1: "#fb4834",
  highlighting_gruvbox_preview_2: "#b9bb25",
  highlighting_material_preview_1: "#80cbc4",
  highlighting_material_preview_2: "#a5e844",
  highlighting_dracula_preview_1: "#f877c1",
  highlighting_dracula_preview_2: "#4ffa7b",
};

const highlightingColorsPreviewsLight = {
  highlighting_one_preview_1: "#e45549",
  highlighting_one_preview_2: "#53a251",
  highlighting_gruvbox_preview_1: "#9d0006",
  highlighting_gruvbox_preview_2: "#797403",
  highlighting_material_preview_1: "#38adb5",
  highlighting_material_preview_2: "#f6a434",
  highlighting_dracula_preview_1: "#9b7aad",
  highlighting_dracula_preview_2: "#6b885a",
};

const blueBayouxPaletteColors = {
  black,
  black10,
  black18,
  black1b,
  black20,
  black40,
  black50,
  white,
  white10,
  white50,
  blackOpacityBackground,
  yellowMain,
  boldText,
  subBackground,
  greySubText,
  itemBackground,
  inactiveIcons,
  textSub,
  transparent,
  borderMdm,
  borderSml,
  mineShaft,
  textDescription,
  red,
  greySubTextDesc,
  lightGreen,
  bayOfMany,
  iron,
  shark,
  abbey,
  green100,
  red100,
  blue100,
  yellow100,
  grey200,
  grey800,
  disabledBackground,
  dodgerBlue,
  green,
  descOpacityText,
  yellowMainOpacity,
  redOpacity,
  sulu,
  woodsmoke,
  woodsmokeDarker,
  woodsmokeLight,
  photoBackground,
  feijoa,
  bamboo,
  azure,
  greenError,
  headerBlack,
  blurBg,
  lightBackground,
  blueBayoux,
  yellow,
  doveGray,
  laser,
  fedora,
  alto,
  easternBlue,
  easternBlue80,
  astral,
  greenPea,
  mobster,
  crail,
  mako,
  selectiveYellow,
  silver,
  blueRibbon,
  emperor,
  cornflowerBlue,
  silverChalice,
  forestGreen,
  bermudaGray,
  coralRed,
  aqua,
  codGray,
  scooter,
  fog,
  balticSea,
  balticSeaPurple,
  balticSeaPurpleIntense,
  balticSeaPurpleLight,
  gray,
  sharkLight,
  cinder,
  greenHaze,
  guardsmanRed,
  shipGray,
  shipGrayLight,
  frenchGray,
  mandy,
  wildSand,
  jaguar,
  scorpion,
  casper,
  pearlBush,
  pearlBushLight,
  makara,
  donkeyBrown,
  tundora,
  arrowtown,
  walnut,
  softAmber,
  bisonHide,
  madang,
  aquamarineBlue,
  tango,
  brightTurquoise,
  jetStream,
  cardinGreen,

  // Special constants
  blueBayouxThemeGlobalBg: blueBayoux,
  balticSeaThemeGlobalBg: balticSea,
  wildSandThemeGlobalBg: wildSand,
  whiteThemeGlobalBg: wildSandLight,

  // Theme dependent colors below
  globalBg: blueBayoux,
  bgSecondary: ebonyClay,
  bgTetriary: sharkLight,
  bgQuaternary: codGray,
  bgQuinary: balticSea,
  bgSenary: sharkLight,
  bgSeptenary: woodsmokeLight,
  bgBackdrop: black20,
  bgForm: white,
  textMain: white,
  textFormMain: black,
  textReversed: black,
  textSecondary: mobster,
  textTetriary: mobster,
  textBright: yellowMain,
  textBrightSecondary: laser,
  inputBg: black,
  inputText: white,
  inputTextSecondary: white,
  inputBgSecondary: black50,
  inputLabelColor: white,
  inputBorderColorUnactive: black,
  inputBorderColorUnactiveSecondary: transparent,
  inputBorderColorActive: persianBlue,
  inputPlaceholder: gray,
  formContrastColor: azure,
  formButtonBgColorActive: azure,
  formButtonTextActive: white,
  connectionStatusActive: green,
  connectionStatusUnactive: red,
  instructionItemBg: sharkLight,
  instructionItemBgDragging: mako,
  instructionItemTextMain: white,
  bgContrastColor: greenPea,
  bgContrastColorText: white,
  buttonTextActive: woodsmoke,
  buttonTextUnactive: gray,
  buttonBgActive: white,
  buttonBgUnactive: black,
  borderMain: mineShaft,
  borderSecondary: tundora,
  iconsMain: white,
  iconsSecondary: white,
  navBarTextActive: fog,
  navBarTextUnactive: white,
  navBarIconActive: cornflowerBlue,
  navBarIconUnactive: silverChalice,
  modalBgColor: blackRgba05,
  progressBarSuccess: japaneseLaurel,
  progressBarFailure: red,
  progressBarFetching: japaneseLaurel40,
  droppableAreaBorderActive: alto,
  droppableAreaBorderUnactive: transparent,
  draggableItemBorderActive: white,
  draggableItemBorderUnactive: transparent,
  gotSomethingNewIndicator: green,
  successColor: green,
  errorColor: red,
  switchTrackCheckedColor: green,
  switchCheckedColor: white,
  loaderColor: white,
  ...highlightingColorsPreviewsDark
} as const;

const balticSeaPaletteColors = {
  ...blueBayouxPaletteColors,
  // Theme dependent colors below
  globalBg: balticSea,
  bgSecondary: woodsmokeDarker,
  bgTetriary: cinder,
  bgQuaternary: woodsmokeLight,
  bgQuinary: balticSeaPurpleIntense,
  bgSenary: balticSeaPurpleIntense,
  bgSeptenary: woodsmokeLight,
  bgBackdrop: black20,
  bgForm: balticSea,
  textMain: white,
  textFormMain: white,
  textReversed: black,
  textSecondary: frenchGray,
  textTetriary: frenchGray,
  textBright: yellowMain,
  textBrightSecondary: laser,
  inputBg: shipGray,
  inputText: white,
  inputTextSecondary: white,
  inputBgSecondary: white10,
  inputLabelColor: white,
  inputBorderColorUnactive: shipGrayLight,
  inputBorderColorUnactiveSecondary: shipGrayLight,
  inputBorderColorActive: white,
  inputPlaceholder: shipGrayLight,
  formContrastColor: yellowMain,
  formButtonBgColorActive: white,
  formButtonTextActive: black,
  connectionStatusActive: greenHaze,
  connectionStatusUnactive: guardsmanRed,
  instructionItemBg: balticSeaPurple,
  instructionItemBgDragging: shipGray,
  instructionItemTextMain: white,
  bgContrastColor: yellowMain,
  bgContrastColorText: black,
  buttonTextActive: woodsmoke,
  buttonTextUnactive: gray,
  buttonBgActive: white,
  buttonBgUnactive: black,
  borderMain: mineShaft,
  borderSecondary: tundora,
  iconsMain: white,
  iconsSecondary: white,
  navBarTextActive: white,
  navBarTextUnactive: jumbo,
  navBarIconActive: white,
  navBarIconUnactive: jumbo,
  modalBgColor: blackRgba05,
  progressBarSuccess: japaneseLaurel,
  progressBarFailure: red,
  progressBarFetching: japaneseLaurel40,
  droppableAreaBorderActive: alto,
  droppableAreaBorderUnactive: transparent,
  draggableItemBorderActive: white,
  draggableItemBorderUnactive: transparent,
  gotSomethingNewIndicator: green,
  successColor: green,
  errorColor: red,
  switchTrackCheckedColor: green,
  switchCheckedColor: white,
  loaderColor: white,
  ...highlightingColorsPreviewsDark
} as const;

const wildSandPaletteColors = {
  ...blueBayouxPaletteColors,
  // Theme dependent colors below
  globalBg: wildSand,
  bgSecondary: white,
  bgTetriary: jetStream,
  bgQuaternary: pearlBush,
  bgQuinary: pearlBushLight,
  bgSenary: pearlBushLight,
  bgSeptenary: softAmber,
  bgBackdrop: black10,
  bgForm: wildSand,
  textMain: black,
  textFormMain: black,
  textReversed: white,
  textSecondary: floralWhite,
  textTetriary: woodsmokeDarker,
  textBright: bamboo,
  textBrightSecondary: walnut,
  inputBg: shipGray,
  inputText: white,
  inputTextSecondary: black,
  inputBgSecondary: white10,
  inputLabelColor: white,
  inputBorderColorUnactive: shipGrayLight,
  inputBorderColorUnactiveSecondary: frenchGray,
  inputBorderColorActive: woodsmokeDarker,
  inputPlaceholder: gray,
  formContrastColor: bamboo,
  formButtonBgColorActive: black,
  formButtonTextActive: white,
  connectionStatusActive: greenHaze,
  connectionStatusUnactive: guardsmanRed,
  instructionItemBg: donkeyBrown,
  instructionItemBgDragging: makara,
  instructionItemTextMain: white,
  bgContrastColor: aquamarineBlue,
  bgContrastColorText: black,
  buttonTextActive: white,
  buttonTextUnactive: woodsmoke,
  buttonBgActive: black,
  buttonBgUnactive: white,
  borderMain: tundora,
  borderSecondary: frenchGray,
  iconsMain: black,
  iconsSecondary: white,
  navBarTextActive: jaguar,
  navBarTextUnactive: black,
  navBarIconActive: cornflowerBlue,
  navBarIconUnactive: scorpion,
  modalBgColor: blackRgba05,
  progressBarSuccess: japaneseLaurel,
  progressBarFailure: red,
  progressBarFetching: japaneseLaurel40,
  droppableAreaBorderActive: walnut,
  droppableAreaBorderUnactive: transparent,
  draggableItemBorderActive: white,
  draggableItemBorderUnactive: transparent,
  gotSomethingNewIndicator: forestGreen,
  successColor: forestGreen,
  errorColor: red,
  switchTrackCheckedColor: forestGreen,
  switchCheckedColor: forestGreen,
  loaderColor: blueRibbon,
  ...highlightingColorsPreviewsLight
};

const whitePaletteColors = {
  ...blueBayouxPaletteColors,
  // Theme dependent colors below
  globalBg: wildSandLight,
  bgSecondary: white,
  bgTetriary: white,
  bgQuaternary: gallery,
  bgQuinary: white,
  bgSenary: white,
  bgSeptenary: wildSand,
  bgBackdrop: gallery,
  bgForm: wildSand,
  textMain: black,
  textFormMain: black,
  textReversed: white,
  textSecondary: boulder,
  textTetriary: woodsmokeDarker,
  textBright: bamboo,
  textBrightSecondary: walnut,
  inputBg: shipGray,
  inputText: white,
  inputTextSecondary: black,
  inputBgSecondary: white10,
  inputLabelColor: white,
  inputBorderColorUnactive: shipGrayLight,
  inputBorderColorUnactiveSecondary: frenchGray,
  inputBorderColorActive: woodsmokeDarker,
  inputPlaceholder: gray,
  formContrastColor: bamboo,
  formButtonBgColorActive: black,
  formButtonTextActive: white,
  connectionStatusActive: greenHaze,
  connectionStatusUnactive: guardsmanRed,
  instructionItemBg: white,
  instructionItemBgDragging: seashell,
  instructionItemTextMain: black,
  bgContrastColor: yellowMain,
  bgContrastColorText: codGray,
  buttonTextActive: white,
  buttonTextUnactive: woodsmoke,
  buttonBgActive: black,
  buttonBgUnactive: frenchGray,
  borderMain: tundora,
  borderSecondary: frenchGray,
  iconsMain: black,
  iconsSecondary: white,
  navBarTextActive: codGray,
  navBarTextUnactive: boulder,
  navBarIconActive: codGray,
  navBarIconUnactive: boulder,
  modalBgColor: blackRgba05,
  progressBarSuccess: japaneseLaurel,
  progressBarFailure: red,
  progressBarFetching: japaneseLaurel40,
  droppableAreaBorderActive: codGray,
  droppableAreaBorderUnactive: transparent,
  draggableItemBorderActive: codGray,
  draggableItemBorderUnactive: transparent,
  gotSomethingNewIndicator: forestGreen,
  successColor: forestGreen,
  errorColor: red,
  switchTrackCheckedColor: forestGreen,
  switchCheckedColor: forestGreen,
  loaderColor: codGrayDarker,
  ...highlightingColorsPreviewsLight
};

const gradients = {
  g1: ["rgba(255,255,255,.01)", white],
};

export { pastelColors, gradients, blueBayouxPaletteColors, balticSeaPaletteColors, wildSandPaletteColors, whitePaletteColors };