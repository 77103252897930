import React, { memo, useState } from 'react';
import { useStyles } from './ExportableScenarioItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ExportableScenario } from '~/helpers/draggableHelpers';
import { TrashIcon } from "~/assets/icons";
import cn from 'classnames';

export type ExportableScenarioItemProps = {
  scenario: ExportableScenario;
  index: number;
  onPress: () => void;
  onPressDelete: () => void;
};

const ExportableScenarioItem: React.FC<ExportableScenarioItemProps> = ({
  scenario,
  index,
  onPress,
  onPressDelete
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [hovered, setHovered] = useState(false);

  return (
    <Box 
      className={cn(s.container)} 
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        backgroundColor: hovered ? theme.colors.bgSeptenary : theme.colors.bgQuinary,
        transitionDuration: "0.4s"
      }}
      onClick={onPress}
    >
      <Box className={cn(s.left)}>
        <Box className={cn(s.indexCont)}>
          <p className={cn(s.title)}>
            {`#${index}`}
          </p>
        </Box>
        <Box className={cn(s.leftCol)}>
          <p className={cn(s.title)}>
            {scenario.title}
          </p>
          <p className={cn(s.description)}>
            {scenario.description}
          </p>
        </Box>
      </Box>
      <Box className={cn(s.right)}>
        <Box className={cn(s.deleteBtn)} title={t("Other.deleteFromCollection")}>
          <IconButton onClick={(e) => {
            e.stopPropagation();
            onPressDelete();
          }} size='small'>
            <TrashIcon color={theme.colors.iconsMain}/>
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ExportableScenarioItem.defaultProps={}

const MemorizedComponent = memo(ExportableScenarioItem);
export { MemorizedComponent as ExportableScenarioItem };