import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  label: {
    margin: 0
  },
  value: {
    fontFamily: theme.fonts.f400.fontFamily,
    maxWidth: "75%",
    margin: 0,
    textAlign: "right"
  },
});