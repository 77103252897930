import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    flexDirection: 'column',
    width: "100%",
    height: "100%",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  col: {
    display: "flex",
    flexDirection: 'column',
    width: '100%',
  },
  keyValueRow: {
    display: "flex",
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  keyInput: {
    display: "flex"
  },
  valueInput: {
    display: "flex",
    alignItems: 'center'
  },
  betweenInputs: {
    display: "flex",
    width: "5%",
    alignItems: 'center',
    height: "100%",
    paddingLeft: 8
  },
  dividerText: {
    margin: 0,
    color: theme.colors.selectiveYellow,
    fontSize: 18,
    fontFamily: theme.fonts.f600.fontFamily
  },
  plusButton: {
    display: "flex",
    width: 48,
    height: 48,
    marginBottom: 8
  },
  deleteButton: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  bottomCol: {
    display: "flex",
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  saveButton: {
    display: "flex",
    width: '50%',
    marginBottom: 12
  }
});