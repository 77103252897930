import React, { memo, useState } from 'react';
import { useStyles } from './OtpPageStyles';
import { useOtpPageAnalytics } from './useOtpPageAnalytics';
import { Box, Typography, Button } from '@material-ui/core';
import { TextInput } from '~/components/TextInput';
import { validateOtpCode } from '~/helpers/validationHelper';
import { CustomButton } from '~/components/CustomButton';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserActions } from '~/logic/user/UserSlice';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export type OtpPageProps = {};

const OtpPage: React.FC<OtpPageProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    userEmail,
    otp: {
      fetching: fetchingOtp
    },
    forgotPassword: {
      fetching: fetchingForgotPassword
    },
    otpCallback
  } = useSelector(userSelector);

  const {
    trackOtpResendTriggered
  } = useOtpPageAnalytics();

  const [hideErrors, setHideErrors] = useState(true);
  const [code, setCode] = useState('');

  const onPressSend = () => {
    if (validateOtpCode(code)) {
      if (otpCallback) {
        otpCallback(code);
      } else {
        dispatch(UserActions.otp.request({
          code,
          navigate
        }));
      }
    } else {
      setHideErrors(false);
    }
  }

  const handleResend = () => {
    dispatch(UserActions.resendOtp.request({email: userEmail as string}));
    trackOtpResendTriggered();
  }

  return (
    <Box className={classNames(ds.fullSpaceCentered)} style={{backgroundColor: theme.colors.bgSecondary}}>
      <Box className={classNames(s.formCont)}>
        <Box className={classNames(ds.fullWidthCenteredCol)}>
          <Typography variant="h5" paragraph className={classNames(s.blackText)}>
            {t("OtpPage.emailVerification")}
          </Typography>

          <p style={{color: theme.colors.textFormMain}}>
            {`${t("OtpPage.enterVerificationCodeWeSentYou")} ` + (userEmail ?? "email")}
          </p>

          <Box className={classNames(ds.inputContainterMarginTop)}>
            <TextInput 
              value={code}
              onChangeText={(text) => setCode(text)}
              label={t("OtpPage.verificationCode")}
              labelColor={theme.colors.textFormMain}
              placeholder={"000000"}
              validationOk={hideErrors || validateOtpCode(code)}
              onErrorAnimationEnd={() => setHideErrors(true)}
            />
          </Box>
        </Box>

        <Box className={classNames(ds.fullWidthCenteredCol)}>
          <Box className={classNames(s.bottomBtn)} title={t("OtpPage.sendCode")}>
            <CustomButton 
              onPress={onPressSend}
              title={t("Common.confirm")}
              colorActive={theme.colors.formButtonTextActive}
              bgColorActive={theme.colors.formButtonBgColorActive}
              spinner={fetchingOtp || fetchingForgotPassword}
            />
          </Box>

          <Button 
            variant="text" style={{color: theme.colors.formContrastColor, marginTop: 8, fontFamily: theme.fonts.f600.fontFamily, fontSize: 18}}
            onClick={handleResend}
          >
            {t("OtpPage.resendVerificationCode")}
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

OtpPage.defaultProps={}

const MemorizedComponent = memo(OtpPage);
export { MemorizedComponent as OtpPage };