import React, { ReactElement } from 'react';
import { useStyles } from './CustomModalStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CloseCircleIcon } from "~/assets/icons";
import Modal from 'react-modal';
import cn from 'classnames';

Modal.setAppElement('#root');

// Global constants
const MODAL_CUSTOM_STYLE = {
  "content": {
    display: 'flex',
  },
  overlay: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9
  },
}

export type CustomModalProps = {
  visible: boolean;
  onClose: () => void;
  title?: string;
  headerLeft?: ReactElement;
  children?: ReactElement;
  classname?: string;
  enableBlur?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
};

const CustomModal: React.FC<CustomModalProps> = ({
  visible,
  onClose,
  title,
  headerLeft,
  children,
  classname,
  enableBlur,
  onKeyDown
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <Modal
      isOpen={visible}
      onRequestClose={onClose}
      contentLabel="Custom modal content label"
      className={cn(s.modal, s["&__content"], classname)}
      style={{
        ...MODAL_CUSTOM_STYLE, 
        overlay: {
          ...MODAL_CUSTOM_STYLE.overlay, 
          backgroundColor: theme.colors.modalBgColor,
          backdropFilter: enableBlur ? `blur(${theme.metrics.modalBackdropBlurRadius}px)` : 'none',
        }
      } as any}
    >
      <Box className={cn(s.wrapper)} onKeyDown={onKeyDown}>
        <Box className={cn(s.header)}>
          {!!headerLeft &&
            headerLeft
          }
          {!!title &&
            <p className={cn(s.title)}>
              {title}
            </p>
          }
          <IconButton 
            onClick={onClose} 
            className={cn(s.closeBtn)}
            size='small'
          >
            <CloseCircleIcon color={theme.colors.iconsMain} bgColor={theme.colors.globalBg}/>
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

CustomModal.defaultProps={
  enableBlur: true
}

export { CustomModal };