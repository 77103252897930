import React, { useState } from 'react';
import { useStyles } from './MobxTabStyles';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { useMobxTabAnalytics } from './useMobxTabAnalytics';
import { Box, Typography } from '@material-ui/core';
import { StateInspectorHeader } from '~/components/StateInspectorHeader';
import { PinnedPathsContainer } from '~/components/PinnedPathsContainer';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourMobxInspectorSelector } from '~/logic/ourMobxInspector/OurMobxInspectorSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { MobxIcon } from '~/assets/icons';
import { OurMobxInspectorActions } from '~/logic/ourMobxInspector/OurMobxInspectorSlice';
import cn from 'classnames';

export type MobxTabProps = {};

const MobxTab: React.FC<MobxTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey
  } = useSelector(userSelector);

  const {
    mobxStateCopy,
    linesWithMarkup,
    mobxStateCopyNumOfLines,
    mobxStateCopyTimestamp,
    pinnedPaths
  } = useSelector(ourMobxInspectorSelector);

  const {
    trackUserChangedViewMode
  } = useMobxTabAnalytics();

  const [pinnedMode, setPinnedMode] = useState(false);

  const onPressUnpin = (path: string) => {
    dispatch(OurMobxInspectorActions.unpinPath({path, apiKey}));
  }

  return (
    <Box className={cn(s.container)}>
      {mobxStateCopy ?
        <Box className={s?.syntaxContainer}>
          <StateInspectorHeader 
            pinnedMode={pinnedMode}
            stateCopyNumOfLines={mobxStateCopyNumOfLines}
            stateCopyTimestamp={mobxStateCopyTimestamp}
            stateManagerName={"MobX"}
            onPress={() => {
              trackUserChangedViewMode(pinnedMode ? "default" : "pinned");
              setPinnedMode((v) => !v)
            }}
            onPressClear={() => dispatch(OurMobxInspectorActions.clearMobxInspector())}
          />
          <Box className={cn(s.dataContainer)}>
            {pinnedMode ?
              <PinnedPathsContainer 
                stateCopy={mobxStateCopy}
                pinnedPaths={pinnedPaths}
                onPressUnpin={onPressUnpin}
                icon={<MobxIcon fill={theme.colors.textBright} />}
              />
            :
              <SyntaxHighlighter 
                code={JSON.stringify(mobxStateCopy, null, 2)}
                linesWithMarkup={linesWithMarkup}
                customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
                enableFolding={true}
                virtualize={true}
                withPins={true}
                pinnedPaths={pinnedPaths}
                borderRadius={0}
                onInspectorFoldChange={(lineIndex, newOpened) => dispatch(OurMobxInspectorActions.handleInspectorFoldChange({lineIndex, newOpened}))}
                onPinPath={(path) => dispatch(OurMobxInspectorActions.pinPath({path, apiKey}))}
                onUnpinPath={(path) => dispatch(OurMobxInspectorActions.unpinPath({path, apiKey}))}
              />
            }
          </Box>
        </Box>
      :
        <Box className={s?.syntaxContainer}>
          <Box className={ds.fullSpaceCenteredCol}>
            <Box className={cn(s.mobxIcon)}>
              <MobxIcon fill={theme.colors.textBright}/>
            </Box>
            <Typography variant="h5" className={cn(s.whiteText)}>
              {`MobX state ${t("MainPage.StateManagerTab.willBeDisplayedHere")}`}
            </Typography>
          </Box>
        </Box>
      }
    </Box>
  );
};

MobxTab.defaultProps={}

export { MobxTab };