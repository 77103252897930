import React, { memo, useCallback, useEffect } from 'react';
import { useStyles } from './MailTabStyles';
import { Box, Typography, CircularProgress, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { MailItem } from '~/components/MailItem';
import { Mail } from '~/helpers/draggableHelpers';
import { UserActions } from '~/logic/user/UserSlice';
import { ViewEmail } from '~/components/ViewEmail';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { MailIcon } from "~/assets/icons";
import { useDependentState } from '~/hooks';
import cn from 'classnames';
import TrashIcon from './assets/trash';
import RefreshIcon from './assets/refresh';
import BackIcon from './assets/back';

export type MailBoxProps = {
  handleRefresh: (id: string) => void;
};

const MailBox: React.FC<MailBoxProps> = ({
  handleRefresh
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  
  const {
    appSettings,
    viewingMail,
    currentTestAccount,
    checkMailBox: {
      data: checkMailData,
      fetching: fetchingMailBox
    },
    clearMailBox: {
      fetching: fetchingClearMailBox
    }
  } = useSelector(userSelector);

  const [viewMailMode, setViewMailMode] = useDependentState(() => false, [currentTestAccount]);

  const handleClearMailBox = useCallback(() => {
    if (checkMailData)
      dispatch(UserActions.clearMailBox.request({id: checkMailData._id}));
  }, [checkMailData]);

  const handleGoBack = useCallback(() => {
    setViewMailMode(false);
  }, []);

  const handlePressMailItem = useCallback((mail: Mail) => {
    dispatch(UserActions.setViewingMail(mail));
    setViewMailMode(true);
  }, []);

  useEffect(() => {
    if (currentTestAccount)
      handleRefresh(currentTestAccount._id);
  }, [currentTestAccount]);

  if (fetchingMailBox || fetchingClearMailBox)
    return (
      <Box className={cn(ds.fullSpaceCentered)}>
        <CircularProgress color='secondary'/>
      </Box>
    );

  return (
    <Box className={cn(ds.fullSpace, s.column)} style={{maxHeight: '100%', overflowY: 'scroll', overflowX: 'hidden'}}>
      {viewMailMode?
        <Box className={cn(s.buttonsRowAbsolute)}>
          <IconButton onClick={handleGoBack} size={'small'} title={t("MainPage.MailTab.backToMailList")}>
            <Box className={cn(s.backIconCont)}>
              <BackIcon color={theme.colors.iconsMain}/>
            </Box>
          </IconButton>
        </Box>
      :
        <Box className={cn(s.buttonsRowAbsolute)}>
          <Box />
          <Box>
            <IconButton onClick={handleClearMailBox} size={'small'} title={t("MainPage.MailTab.clearMailbox")}>
              <Box className={cn(s.trashIconCont)}>
                <TrashIcon color={theme.colors.iconsMain}/>
              </Box>
            </IconButton>
            <IconButton onClick={() => currentTestAccount && handleRefresh(currentTestAccount._id)} size={'small'} title={t("Common.refresh")}>
              <Box className={cn(s.refreshIconCont)}>
                <RefreshIcon color={theme.colors.iconsMain}/>
              </Box>
            </IconButton>
          </Box>
        </Box>
      }

      {viewMailMode?
        <>
          <ViewEmail mail={viewingMail}/>
        </>
      :
        <>
          {!checkMailData?.mailBox.length &&
            <Box className={cn(ds.fullSpaceCenteredCol)}>
              <Box className={cn(s.mailIconCont)}>
                <MailIcon color={theme.colors.textBright}/>
              </Box>
              <Typography variant="h5" paragraph className={cn(s.whiteTextBold)}>
                {!currentTestAccount ? t("MainPage.MailTab.mailboxWillBeHere") : t("MainPage.MailTab.mailboxEmpty")}
              </Typography>
            </Box>
          }

          {checkMailData?.mailBox &&
            checkMailData.mailBox.map((mail: Mail) => (
              <MailItem 
                key={mail.messageId}
                mail={mail}
                twentyFourHoursTimeFormat={appSettings.twentyFourHoursTimeFormat.value}
                onPress={() => handlePressMailItem(mail)}
              />
            ))
          }
        </>
      }

    </Box>
  );
};

MailBox.defaultProps={}

const MemorizedComponent = memo(MailBox);
export { MemorizedComponent as MailBox };