import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  arrowContainer: {
    display: "flex",
    transform: "scale(0.75)"
  },
  rotate: {
    transform: "scale(0.75) rotate(180deg)"
  }
});