import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { CurrentLangT } from "~/types/types";
import translationEN from "./langs/en/translation.json";
import translationRU from "./langs/ru/translation.json";
import translationES from "./langs/es/translation.json";
import translationUA from "./langs/ua/translation.json";

function getLanguage(lang: CurrentLangT) {
  switch (lang) {
    case 'en':
      return translationEN;
    case 'ru':
      return translationRU;
    case 'es':
      return translationES;
    case 'ua':
      return translationUA;
    default:
      return translationEN;
  }
}

function initI18n(defaultLang: CurrentLangT) {
  i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    lng: defaultLang,
    fallbackLng: defaultLang,
    debug: false,
    resources: {
      'en': {
        translation: getLanguage('en'),
      },
      'ru': {
        translation: getLanguage('ru'),
      },
      'es': {
        translation: getLanguage('es'),
      },
      'ua': {
        translation: getLanguage('ua'),
      }
    },
  });
}

function changeLanguage(lang: CurrentLangT) {
  i18n.changeLanguage(lang);
}

export { initI18n, changeLanguage };