import { showToast } from "./alertService";
import i18next from "i18next";

export const handleOpenLink = (link: string) => {
  window.open(link, '_blank', 'noreferrer')
};

export const copyToClipboard = (s: string) => {
  navigator.clipboard.writeText(s);
  showToast(i18next.t("Toast.copiedToClipboard"), "success");
}