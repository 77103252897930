import React, { memo, useMemo } from 'react';
import { useStyles } from './InstructionItemStyles';
import { Box, IconButton } from '@material-ui/core';
import { InstructionTypeIndicator } from '~/components/InstructionTypeIndicator';
import { EventExecutionProgressBar } from '~/components/EventExecutionProgressBar';
import { InstructionPrototype as InstructionPrototypeIndicator } from '../InstructionPrototype';
import { InstructionPrototype, InstructionType } from '~/types/api';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import PlayIcon from './assets/play';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';

const MAX_DESCRIPTION_LENGTH = 144;

export type InstructionItemProps = {
  isDragging: boolean;
  inStructionId: string;
  instructionPrototype?: InstructionPrototype;
  instructionType: InstructionType;
  description?: string;
  groupId?: string;
  groupDescription?: string;
  groupColor?: string;
  isLastItem?: boolean;
  withEventExecutionProgressBar?: boolean;
  onPressSend?: () => void;
};

const InstructionItem: React.FC<InstructionItemProps> = ({
  isDragging,
  inStructionId,
  instructionPrototype,
  instructionType,
  description,
  groupId,
  groupDescription,
  groupColor,
  isLastItem,
  withEventExecutionProgressBar,
  onPressSend
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    lastSentEvent
  } = useSelector(userSelector);
  
  const descriptionClamp = useMemo(() => {
    if (!description)
      description = t("Common.noDescriptionProvided");

    if (description?.length > MAX_DESCRIPTION_LENGTH)
      return description.slice(0, MAX_DESCRIPTION_LENGTH) + '...';

    return description;
  }, [description, t]);

  return (
    <Box 
      className={cn(s.container)} 
      style={{
        backgroundColor: isDragging? theme.colors.instructionItemBgDragging: theme.colors.instructionItemBg,
        border: isDragging? `1px dashed ${theme.colors.draggableItemBorderActive}`: `1px dashed ${theme.colors.draggableItemBorderUnactive}`,
        transitionDuration: '0.4s'
      }}
    >
      <Box className={cn(s.rowBetween)}>
        <Box className={cn(s.rowCentered)}>
          <p className={cn(s.instructionIdText)}>
            {inStructionId}
          </p>
          <InstructionPrototypeIndicator prototype={instructionPrototype}/>
        </Box>

        <Box className={cn(s.rowCentered)}>
          <InstructionTypeIndicator type={instructionType} marginRight={theme.metrics.x}/>
          {onPressSend &&
            <IconButton 
              onClick={(e) => onPressSend()} 
              size='small'
              className={cn(s.sendButton)}
              title={t("Other.sendEvent")}
            >
              <PlayIcon color={theme.colors.instructionItemTextMain}/>
            </IconButton>
          }
        </Box>
      </Box>
      <p className={cn(s.descriptionText)}>
        {descriptionClamp}
      </p>

      {(withEventExecutionProgressBar && lastSentEvent && lastSentEvent.instructionId === inStructionId) &&
        <EventExecutionProgressBar 
          event={lastSentEvent}
          prevEventOfScenario={undefined}
          absoluteLeft={0}
        />
      }

      {groupId &&
        <Box 
          className={cn(s.groupIndicator)}
          style={{backgroundColor: groupColor}}
          title={`${t("Common.group")}: ${groupId} - ${groupDescription}`}
        />
      }
    </Box>
  );
};

InstructionItem.defaultProps={
  isLastItem: false,
  withEventExecutionProgressBar: false
}

const MemorizedComponent = memo(InstructionItem);
export { MemorizedComponent as InstructionItem };