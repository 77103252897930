import { FC, createElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AvailableInstructions } from "~/blocks/AvailableInstructions";
import { NetworkTimeline } from "~/blocks/NetworkTimeline";
import { ReduxInspector } from "~/blocks/ReduxInspector";
import { ToolsPanel } from "~/blocks/ToolsPanel";
import { NoData } from "~/components/NoData";
import { FloatingViewContext } from "~/providers";
import { FloatingViewContent } from "~/providers/FloatingViewContextProvider/types";
import { NavigationTab } from "~/types/types";

export const FloatingViewContentToBlockTable: {[k in FloatingViewContent]: {
  Component: FC;
  navigationTab: NavigationTab;
}} = {
  NetworkTimeline: {
    Component: NetworkTimeline,
    navigationTab: "NETWORK_TAB"
  },
  NetworkAvailableInstructions: {
    Component: AvailableInstructions,
    navigationTab: "NETWORK_TAB"
  },
  ReduxInspector: {
    Component: ReduxInspector,
    navigationTab: "REDUX_INSPECTOR_TAB"
  },
  ToolsPanel: {
    Component: ToolsPanel,
    navigationTab: "MAIN_TAB"
  }
} as const;


export type FloatingViewCompatibleContentOrPlaceholderProps = {
  content: FloatingViewContent
};

export const FloatingViewCompatibleContentOrPlaceholder: React.FC<FloatingViewCompatibleContentOrPlaceholderProps> = ({
  content
}) => {
  const { t } = useTranslation();
  
  const {
    floatingViewVisible,
    currentFloatingViewContent
  } = useContext(FloatingViewContext);

  if (floatingViewVisible && currentFloatingViewContent === content)
    return (<NoData text={t("Common.openedInFloatingWindow")} />);

  return createElement(FloatingViewContentToBlockTable[content].Component);
};