import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useStyles } from './OptionsMenuStyles';
import { Select, MenuItem } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { ObjectT, OptionItem } from '~/types/types';
import cn from 'classnames';

export type OptionsMenuProps = {
  ref: any;
  id: string;
  optionItems: OptionItem[];
  optionHandlers: ObjectT<(() => void)>;
};

export type OptionsMenuMethods = {
  open: () => void;
  close: () => void;
};

const OptionsMenu: React.FC<OptionsMenuProps> = forwardRef(({
  id,
  optionItems,
  optionHandlers
}, ref) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const [opened, setOpened] = useState(false);

  useImperativeHandle(
    ref,
    (): OptionsMenuMethods => ({
      open: () => setOpened(true),
      close: () => setOpened(false)
    })
  );

  return (
    <Select
      labelId={`${id}-label`}
      id={id}
      classes={{root: cn(s.select), icon: cn(s.selectIcon)}}
      value={""}
      label={""}
      native={false}
      open={opened}
      onClose={() => setOpened(false)}
      title={""}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null,
        PaperProps: {
          className: cn(s.paper)
        }
      }}
      color={"secondary"}
      disableUnderline={true}
      onClick={(event) => event.stopPropagation()}
      onChange={(event: any) => optionHandlers[event.target.value]?.()}
    >
      {optionItems.map((item) => (
        <MenuItem   
          key={`OM_${id}_ITEM_${item.id}`} 
          value={item.id}
        >
          {item.label}
        </MenuItem>
      ))}
    </Select>
  );
});

OptionsMenu.defaultProps={}

export { OptionsMenu };