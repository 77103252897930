import React, { memo, useState } from 'react';
import { useStyles } from './MembersModalStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CustomModal, CustomModalProps } from "~/components/CustomModal";
import { Project } from '~/types/types';
import { TextInput } from '~/components/TextInput';
import { CustomButton } from '~/components/CustomButton';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { remoteSettingsSelector } from '~/logic/remoteSettings/RemoteSettingsSelectors';
import { RemoteSettingsActions } from '~/logic/remoteSettings/RemoteSettingsSlice';

export type MembersModalMode = "add" | "remove";

export type MembersModalProps = CustomModalProps & {
  mode: MembersModalMode;
  project?: Project | null;
};

const MembersModal: React.FC<MembersModalProps> = ({
  mode,
  project,
  ...restProps
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();

  const {
    addProjectMember: {
      fetching: fetchingAddMember,
    },
    removeProjectMember: {
      fetching: fetchingRemoveMember
    }
  } = useSelector(remoteSettingsSelector);

  const [apiKey, setApiKey] = useState("");

  const onPressOk = () => {
    if (mode === "add") {
      dispatch(RemoteSettingsActions.addProjectMember.request({
        projectId: project!._id,
        apiKey,
        successCallback: restProps.onClose
      }));
    } else {
      dispatch(RemoteSettingsActions.removeProjectMember.request({
        projectId: project!._id,
        apiKey,
        successCallback: restProps.onClose
      }));
    }
  }

  if (!project)
    return null;

  return (
    <CustomModal 
      {...restProps} 
      classname={cn(s.modal)} 
      title={mode === "add" ? `Add member to: ${project.name}` : `Remove member from: ${project.name}`}
    >
      <Box className={cn(s.container)}>
        <Box className={cn(ds.inputContainterMarginBottom)}>
          <TextInput 
            placeholder={"0123456789abcdf"}
            label={"ApiKey"}
            labelColor={theme.colors.textMain}
            value={apiKey}
            onChangeText={(text) => setApiKey(text)}
          />
        </Box>
        
        <Box className={cn(s.bottomButton)}>
          <CustomButton 
            title={'Ok'}
            onPress={onPressOk}
            spinner={mode === "add" ? fetchingAddMember : fetchingRemoveMember}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

MembersModal.defaultProps={}

const MemorizedComponent = memo(MembersModal);
export { MemorizedComponent as MembersModal };