import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  topCont: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    padding: 12,
    // backgroundColor: 'green'
  },
  groupLabelText: {
    color: theme.colors.textMain,
    margin: 0,
    marginBottom: 4,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 22
  },
  labelRow: {
    display: "flex",
    width: "100%",
    justifyContent: 'space-between'
  },
  group: {
    display: "flex",
    position: "relative",
    width: "100%",
    padding: theme.metrics.x2,
    flexDirection: "column",
    backgroundColor: theme.colors.bgBackdrop,
    borderRadius: 8,
    marginBottom: 20
  },
  groupRow: {
    display: "flex",
    width: "100%",
    padding: 8,
    flexDirection: "row",
    backgroundColor: theme.colors.bgBackdrop,
    borderRadius: 8,
    marginBottom: 20
  },
  contentBetween: {
    justifyContent: "space-between"
  },
  syntaxHighlighterPreviewContainer: {
    display: "flex",
    width: "50%",
    marginLeft: theme.metrics.x4
  },
  noDataCenterAbsolute: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: theme.metrics.x3
    // backgroundColor: 'red'
  },
  bottomCont: {
    display: "flex",
    alignItems: 'flex-end',
    justifyContent: 'center',
    width: "100%",
    // height: "10%",
    // backgroundColor: 'red',
    paddingBottom: theme.metrics.x8
  },
  appKlaarSmallLogoCont: {
    display: "flex",
    width: 20,
    height: 20,
    marginBottom: theme.metrics.x025,
    marginRight: theme.metrics.x2
  },
  whiteText: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f400.fontFamily
  },
  linkText: {
    color: theme.colors.textBright,
    margin: 0,
    fontSize: 18,
    fontFamily: theme.fonts.f600.fontFamily,
    userSelect: 'none',
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: "100%"
  },
  bottomBorder: {
    borderBottom: `0.5px solid ${theme.colors.borderMain}`
  },
  apiKeyCont: {
    display: 'flex',
    alignItems: 'center',
    width: "100%",
  },
  copyApiKey: {
    display: 'flex',
    width: 30,
    height: 30
  },
  getNewApiKey: {
    display: 'flex',
    width: 32,
    height: 32
  },
  largeBtn: {
    display: 'flex',
    width: 400,
    // backgroundColor: 'blue'
  },
  orangeText: {
    color: theme.colors.textBright,
    margin: 0,
    marginRight: 8,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 18
  },
  whiteTextBold: {
    color: theme.colors.textMain,
    margin: 0,
    marginRight: 8,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 18
  }
});