import { CustomCommand } from "reactotron-core-client";
import { AppSettingsActions } from "~/logic/appSettings/AppSettingsSlice";
import { UserActions } from "~/logic/user/UserSlice";
import { store } from "~/store/store";

const Logout: CustomCommand = {
  command: "Logout",
  handler: () => {
    store.dispatch(UserActions.logout.request());
  },
};

const Profile: CustomCommand = {
  command: "Profile",
  description: "Get user profile (requeres userToken)",
  handler: () => {
    store.dispatch(UserActions.profile.request({}));
  }
}

const EditProfile: CustomCommand = {
  command: "Edit Profile",
  description: "Edit user profile (editable field)",
  handler: () => {
    store.dispatch(UserActions.editProfile.request({
      projectsSetting: {
        "653ae4e8871bd957df630a18": {
          remoteSettingsEnabled: true
        }
      }
    }));
  }
};

const GetPersonalSetting: CustomCommand = {
  command: "Get Personal Setting",
  description: "Получение персональных настроек пользователя (примечание: со Стасом там и не договорились, с точки зрения сервера это публичная информация о клиенте)",
  handler: () => {
    store.dispatch(UserActions.personalSetting.request({
      apiKey: "0qfo4483lgjvru15"
    }))
  }
};

const CreateTestAccount: CustomCommand = {
  command: "CreateTestAccount",
  handler: () => {
    store.dispatch(UserActions.createTestAccount.request({}))
  }
}

const CheckMailBox: CustomCommand = {
  command: "CheckMailBox",
  handler: () => {
    store.dispatch(UserActions.checkMailBox.request({
      id: "63f8fde3d9eb47eeb0bdccbc"
    }))
  }
}

const TestAccountsList: CustomCommand = {
  command: "TestAccountsList",
  description: "Get list of test accounts available for this apiKey",
  handler: () => {
    store.dispatch(UserActions.testAccountsList.request({
      index: 0
    }))
  }
}

const SelectRandomTheme: CustomCommand = {
  command: "SelectRandomTheme",
  handler: () => {
    store.dispatch(AppSettingsActions.selectRandomTheme())
  }
};

const GetClientsQueue: CustomCommand = {
  command: "getClientsQueue",
  handler: () => {
    store.dispatch(UserActions.clientsQueue.request());
  }
};

const commands = [
  Logout, 
  Profile,
  EditProfile,
  GetPersonalSetting,
  CreateTestAccount,
  CheckMailBox,
  TestAccountsList,
  SelectRandomTheme,
  GetClientsQueue
];

export { commands };