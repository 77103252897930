import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './ThemeItemStyles';
import { useThemeContext } from '~/theme';
import cn from 'classnames';

export type ThemeItemProps = {
  selected: boolean;
  themeName: string;
  marginRight?: number;
  title: string;
  onPress: () => void;
};

const ThemeItem: React.FC<ThemeItemProps> = ({
  selected,
  themeName,
  marginRight,
  title,
  onPress
}) => {
  const s = useStyles();
  const { theme: { colors } } = useThemeContext();

  const previewBgColorTable = useMemo(() => ({
    blueBayoux: colors.blueBayoux,
    balticSea: colors.balticSea,
    wildSand: colors.jetStream,
    white: colors.white
  }), [colors]);

  return (
    <Box 
      className={cn(s.container)}
      title={title}
      style={{
        // @ts-ignore
        backgroundColor: previewBgColorTable[themeName], 
        marginRight,
        border: `1px solid ${selected ? colors.textBright : colors.greySubText}`
      }}
      onClick={onPress}
    />
  );
};

ThemeItem.defaultProps={}

export { ThemeItem };