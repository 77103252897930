import React, { useState, useLayoutEffect, useRef } from 'react';
import { useStyles } from './NetworkTabStyles';
import { useNetworkTabAnalytics } from './useNetworkTabAnalytics';
import { Rnd as ResizeableView } from "react-rnd";
import { ResizeHandle } from '~/components/ResizeHandle';
import { FloatingViewCompatibleContentOrPlaceholder } from '~/blocks/FloatingViewCompatibleHelpers';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { useWindowSize, useRndSizeState } from '~/hooks';
import { resizeHandleRightStyle } from '~/constants/resizeHandleStyles';
import { useThemeContext } from '~/theme';
import cn from 'classnames';

const RND_LEFT_ID = "NETWORK_TAB_LEFT_RND";
const RND_RIGHT_ID = "NETWORK_TAB_RIGHT_RND";

export type NetworkTabProps = {};

const NetworkTab: React.FC<NetworkTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();

  useNetworkTabAnalytics();

  const { windowWidth } = useWindowSize();
  const [w, setW] = useState(windowWidth);
  const [lw, setLw] = useRndSizeState(RND_LEFT_ID, w, 0.3);
  const [rw, setRw] = useRndSizeState(RND_RIGHT_ID, w, 0.7);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setW(containerRef?.current?.offsetWidth ?? windowWidth);
  }, [windowWidth]);

  return (
    <DragDropContext onDragEnd={() => {}}>
      <div className={cn(ds.fullSpaceRelative)} ref={containerRef}>
        <ResizeableView
          disableDragging={true}
          enableResizing={{"right": true}}
          size={{ width: lw,  height: "100%" }}
          minWidth={windowWidth * 0.19}
          maxWidth={windowWidth * 0.4}
          onResize={(e, direction, ref) => {
            setLw(ref.offsetWidth);
            setRw(w - ref.offsetWidth);
          }}
          onResizeStop={(e, direction, ref) => dispatch(UserActions.setRndDefaultSizeSettings([{key: RND_LEFT_ID, value: ref.offsetWidth / w}, {key: RND_RIGHT_ID, value: (w - ref.offsetWidth) / w}]))}
          resizeHandleStyles={{"right": resizeHandleRightStyle}}
        >
          <div className={cn(s.left)}>
            <FloatingViewCompatibleContentOrPlaceholder content={"NetworkAvailableInstructions"} />
            
            <ResizeHandle height={"calc(100% - 48px)"} top={24} right={4}/>
          </div>
        </ResizeableView>

        <div style={{width: rw}} className={cn(s.rightContainer)}>
          <FloatingViewCompatibleContentOrPlaceholder content={"NetworkTimeline"} />
        </div>
      </div>
    </DragDropContext>
  );
};

NetworkTab.defaultProps={}

export { NetworkTab };