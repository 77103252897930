import React, { useState } from 'react';
import { useStyles } from './ImportScenarioModalStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../CustomButton';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { TextInput } from '../TextInput';
import { Checkbox } from '~/components/Checkbox';
import { ExportableScenarioParseResult, parseExportableScenario, parseExportableScenarioFromString } from '~/helpers/draggableHelpers';
import { PickedMedia } from '../PickedMedia';
import { readJsonFile } from '~/helpers/jsonHelpers';
import { showToast } from '~/helpers/alertService';
import { handleOpenLink } from '~/helpers/windowHelpers';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { CONFIG } from '~/config';
import { useScenarioCollection } from '~/hooks/useScenarioCollection';
import cn from 'classnames';

export type ImportScenarioModalProps = CustomModalProps & {};

const ImportScenarioModal: React.FC<ImportScenarioModalProps> = ({
  ...restProps
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    connectedClientProjectId
  } = useSelector(userSelector);

  const [scenarioAsString, setScenarioAsString] = useState("");
  const [scenarioFile, setScenarioFile] = useState<any>(null);
  const [fillConstructorWithImportedScenario, setFillConstructorWithImportedScenario] = useState(true);
  const [saveToScenarioCollection, setSaveToScenarioCollection] = useState(false);

  const { 
    saveScenarioToScenarioCollection,
    fillConstructorWithScenario
  } = useScenarioCollection();

  const innerOnClose = () => {
    setScenarioAsString("");
    setScenarioFile(null);
    restProps.onClose();
  }

  const handleImport = async () => {
    try {
      let parseResult: ExportableScenarioParseResult;
      if (scenarioAsString) {
        parseResult = parseExportableScenarioFromString(scenarioAsString);
      } else {
        const scenario = await readJsonFile(scenarioFile);
        parseResult = parseExportableScenario(scenario as any);
      }

      // console.log('parseResult', parseResult);

      if (!parseResult.valid || !parseResult.parsedScenario)
        throw new Error("Invalid scenario");

      if (fillConstructorWithImportedScenario)
        fillConstructorWithScenario(parseResult.parsedScenario);

      if (saveToScenarioCollection)
        saveScenarioToScenarioCollection(parseResult.parsedScenario);

      restProps.onClose();
    } catch (e) {
      showToast(t("Toast.invalidScenario"), "warning");
    }
  }

  return (
    <CustomModal
      {...restProps}
      onClose={innerOnClose}
      classname={cn(s.importScenarioModal)}
      title={t("Other.importScenario")}
      enableBlur={true}
    >
      <>
        <Box className={cn(s.mainBlock)}>
          <Box className={cn(s.inputCont)}>
            <TextInput 
              label={`${t("Common.text")}:`}
              labelColor={theme.colors.textMain}
              value={scenarioAsString}
              onChangeText={(text) => setScenarioAsString(text)}
              placeholder={t("Other.pasteScenarioHere")}
              editable={!scenarioFile}
            />
          </Box>

          <Box className={cn(s.mediaPickerCont)}>
            <p className={cn(s.label, s.labelActive)}>
              {`${t("Other.selectFile")}:`}
            </p>
            <Box className={cn(ds.fullWidthCenteredRow)}>
              <PickedMedia
                onFileChanged={(data: any) => {
                  setScenarioAsString("");
                  setScenarioFile(data);
                }}
                source={scenarioFile}
                empty={!scenarioFile}
              />
            </Box>
          </Box>

          <Box className={cn(s.checkboxesCont)}>
            <Box className={cn(s.checkboxRow)}>
              <Checkbox 
                mark={fillConstructorWithImportedScenario}
                onPress={() => setFillConstructorWithImportedScenario((v) => !v)}
                marginRight={theme.metrics.x2}
              />
              <p className={cn(s.label, s.labelActive)}>
                {t("Other.fillConstructorWithImportedScenario")}
              </p>
            </Box>
            <Box 
              className={cn(s.checkboxRow)} 
              title={`${t("Other.thisOptionAvailableIfSetUpProjectId")} - ${CONFIG.LINKS.SETUP_CODEBUD_PROJECT}`}
              onClick={() => !connectedClientProjectId && handleOpenLink(CONFIG.LINKS.SETUP_CODEBUD_PROJECT)}
            >
              <Checkbox 
                mark={saveToScenarioCollection}
                onPress={() => setSaveToScenarioCollection((v) => !v)}
                marginRight={theme.metrics.x2}
                disabled={!connectedClientProjectId}
              />
              <p className={cn(s.label, connectedClientProjectId ? s.labelActive : s.labelUnactive)}>
                {t("Other.saveToMyScenarioCollection")}
              </p>
            </Box>
          </Box>
        </Box>

        <Box className={cn(s.bottomButton)} title={t("Other.importScenario")}>
          <CustomButton 
            title={t("Common.import")}
            bgColorActive={theme.colors.black}
            colorActive={theme.colors.white}
            disabled={scenarioAsString.length === 0 && !scenarioFile || !fillConstructorWithImportedScenario && !saveToScenarioCollection}
            onPress={handleImport}
          />
        </Box>
      </>
    </CustomModal>
  );
};

ImportScenarioModal.defaultProps={}

export { ImportScenarioModal };