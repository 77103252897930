import { useEffect, useState } from "react";

// Хук для использования "зависимого" значения - комбинация useEffect и useState
// Возвращает актуальное значение переменной состояния - зависимого от deps значения по правилу processor
export const useDependentValue = <DependentValueType = any>(
  processor: () => any,
  deps: ReadonlyArray<any>,
): DependentValueType => {
  const [value, setValue] = useState(processor());

  useEffect(() => {
    const updateValue = () => {
      setValue(processor());
    };

    updateValue();
  }, deps);

  return value;
};