import { useSelector, useDispatch } from "react-redux";
import { showToast } from "~/helpers/alertService";
import { ExportableScenario } from "~/helpers/draggableHelpers";
import { UserActions } from "~/logic/user/UserSlice";
import { userSelector } from "~/logic/user/UserSelectors";
import { useTranslation } from "react-i18next";

export const useScenarioCollection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const {
    scenariosCollection,
    draggableInstuctionsState
  } = useSelector(userSelector);
  
  const saveScenarioToScenarioCollection = (scenario: ExportableScenario) => {
    if (scenariosCollection.find((el) => el.id === scenario.id)) {
      showToast(t("Toast.scenarioSuchIdAlreadyExistsInCollection"), "info");
    } else {
      dispatch(UserActions.addScenarioToCollection(scenario));
      showToast(t("Toast.scenarioAddedToCollection"), "success");
    }
  }

  const fillConstructorWithScenario = (exportableScenario: ExportableScenario) => {
    dispatch(UserActions.setDraggableInstuctionsState({[Object.keys(draggableInstuctionsState)[0]]: exportableScenario.scenario}));
  }

  const deleteScenarioFromCollection = (id: string) => {
    dispatch(UserActions.deleteScenarioFromCollection(id));
  }

  return {
    saveScenarioToScenarioCollection,
    fillConstructorWithScenario,
    deleteScenarioFromCollection
  };
}