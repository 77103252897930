import { create } from "apisauce";
import { CONFIG } from "~/config";
import { reactotron } from '~/ReactotronConfig';
import * as T from "~/types/api";

const PAGINATION_CONFIG = {
  // Number of results to return per page
  limit: 15
};

const SOCKET_EVENTS_LISTEN = {
  CONNECT: "connect",
  ERROR: "error",
  CONNECT_ERROR: "connect_error",
  CLIENTS_QUEUE_UPDATED: "CLIENTS_QUEUE_UPDATED",
  CLIENT_CONNECTED: "CLIENT_CONNECTED",
  DISCONNECT: "disconnect",
  EXECUTING_EVENT: "executingEvent",
  SAVE_EVENT_LOG: "saveEventLog",
  EXECUTING_SCENARIO: "executingScenario",
  SAVE_SCENARIO_LOG: "saveScenarioLog",
  SAVE_REDUX_STATE_COPY: "saveReduxStateCopy",
  SAVE_REDUX_ACTIONS_BATCH: "saveReduxActionsBatch",
  SAVE_ZUSTAND_STATE_COPY: "saveZustandStateCopy",
  NEW_MAIL: "newMail",
  SAVE_INTERCEPTED_REQUEST: "saveInterceptedRequest",
  SAVE_INTERCEPTED_RESPONSE: "saveInterceptedResponse",
  SAVE_MOBILE_APP_STATE: "saveMobileAppState",
  SAVE_INTERCEPTED_STORAGE_ACTIONS_BATCH: "saveInterceptedStorageActionsBatch",
  SAVE_FULL_STORAGE_SNAPSHOT: "saveFullStorageSnapshot",
  CAPTURE_EVENT: "captureEvent",
  CAPTURE_CRASH_REPORT : "captureCrashReport",
  SAVE_TANSTACK_QUERIES_DATA_COPY: "saveTanStackQueriesDataCopy",
  SAVE_TANSTACK_QUERY_EVENTS_BATCH: "saveTanStackQueryEventsBatch",
  SAVE_CONTEXT_VALUE_COPY: "saveContextValueCopy",
  SAVE_MOBX_STATE_COPY: "saveMobXStateCopy",
  SAVE_MOBX_EVENTS_BATCH: "saveMobXEventsBatch"
};

const SOCKET_EVENTS_EMIT = {
  SET_CONNECTION_INFO: "setConnectionInfo",
  SWITCH_CURRENT_CLIENT: "switchCurrentClient",
  EVENT: "event",
  SCENARIO: "scenario",
  SAVE_NEW_REMOTE_SETTINGS: "saveNewRemoteSettings",
  STOP_SCENARIO_EXECUTION: "stopScenarioExecution",
  FORCE_SINGLE_CLIENT_DISCONNECT: "forceSingleClientDisconnect",
  FORCE_ALL_CLIENTS_FROM_QUEUE_DISCONNECT: "forceAllClientsFromQueueDisconnect",
  FORCE_REFRESH: "forceRefresh"
};

const sauce = create({
  baseURL: CONFIG.BASE_URL,
  headers: { Accept: "application/json" },
});

// @ts-ignore
sauce.addMonitor(reactotron.apisauce);

const sauceAuthorizedApiKey = create({
  baseURL: CONFIG.BASE_URL,
  headers: {
    Accept: 'application/json'
  },
});

// @ts-ignore
sauceAuthorizedApiKey.addMonitor(reactotron.apisauce);

// Updates sauceAuthorizedApiKey instance (puts new ApiKey into headers)
const updateAuthorizationHeaderWithApiKey = (apiKey: string) => {
  sauceAuthorizedApiKey.setHeader('Authorization', apiKey);
};

const sauceAuthorizedToken = create({
  baseURL: CONFIG.BASE_URL,
  headers: {
    Accept: 'application/json'
  },
});

// @ts-ignore
sauceAuthorizedToken.addMonitor(reactotron.apisauce);

// Updates sauceAuthorized instance (puts new token into headers)
const updateAuthorizationHeaderWithToken = (token: string) => {
  sauceAuthorizedToken.setHeader('Authorization', `Bearer ${token}`);
};

const api = {
  sauce,
  authPost: (params: T.AuthRequest) => sauce.post<T.AuthResponse>(`/auth/login`, params),
  otpPost: (params: T.OtpRequest) => sauceAuthorizedToken.post<T.OtpResponse>(`/auth/activate`, params),
  resendOtpPost: (params: T.ResendOtpRequest) => sauce.post<T.ResendOtpResponse>(`/auth/otp`, params),
  forgotPasswordPost: (params: T.ForgotPasswordRequest) => sauce.post<T.ForgotPasswordResponse>(`/auth/forgot `, params),
  profileGet: () => sauceAuthorizedToken.get<T.ProfileResponse>(`/profile/`),
  editProfilePost: (params: T.EditProfileRequest) => sauceAuthorizedToken.post<T.EditProfileResponse>(`/profile/edit`, params),
  personalSettingGet: (params: T.PersonalSettingRequest) => sauce.get<T.PersonalSettingResponse>(`/client/one/${params.apiKey}`),
  generateApiKeyGet: () => sauceAuthorizedToken.get<T.ProfileResponse>(`/profile/apikey/generate`),
  createTestAccountPost: (params: T.CreateTestAccountRequest) => sauceAuthorizedApiKey.post<T.CreateTestAccountResponse>(`/account/create`, params),
  checkMailBoxGet: (params: T.CheckMailBoxRequest) => sauceAuthorizedApiKey.get<T.CheckMailBoxResponse>(`/account/one/${params.id}`),
  testAccountsListGet: (params: T.TestAccountsListRequest) => sauceAuthorizedApiKey.get<T.TestAccountsListResponse>(`/account/list/${params.index}`),
  getRemoteSettingsGet: (params: T.GetRemoteSettingsRequest) => sauceAuthorizedApiKey.get<T.GetRemoteSettingsResponse>(`/project/${params.projectId}/remotesettings`),
  setRemoteSettingsPost: (params: T.SetRemoteSettingsRequest) => sauceAuthorizedApiKey.post<T.SetRemoteSettingsResponse>(`/project/${params.projectId}/remotesettings`, {...params.remoteSettings}),
  clearMailBoxDelete: (params: T.ClearMailBoxRequest) => sauceAuthorizedApiKey.delete<T.ClearMailBoxResponse>(`/account/${params.id}/mailbox`),
  deleteTestAccountDelete: (params: T.DeleteTestAccountRequest) => sauceAuthorizedApiKey.delete<T.DeleteTestAccountResponse>(`/account/${params.id}`),
  editTestAccountPost: (params: T.EditTestAccountRequest) => sauceAuthorizedApiKey.post<T.EditTestAccountResponse>(`/account/edit/${params.id}`, params),
  createProjectPost: (params: T.CreateProjectRequest) => sauceAuthorizedToken.post<T.CreateProjectResponse>(`/project/create`, params),
  projectListGet: (params: T.ProjectListRequest) => sauceAuthorizedToken.get<T.ProjectListResponse>(`/project/list/${params.index}`),
  projectByIdGet: (params: T.ProjectByIdRequest) => sauceAuthorizedToken.get<T.ProjectByIdResponse>(`/project/one/${params.id}`),
  deleteProjectDelete: (params: T.DeleteProjectRequest) => sauceAuthorizedToken.delete<T.DeleteProjectResponse>(`/project/remove/${params.id}`),
  editProjectPost: (params: T.EditProjectRequest) => sauceAuthorizedToken.post<T.EditProjectResponse>(`/project/edit/${params.id}`, params),
  addProjectMemberPost: (params: T.AddProjectMemberRequest) => sauceAuthorizedToken.post<T.AddProjectMemberResponse>(`/project/edit/${params.projectId}/members/add`, params),
  removeProjectMemberDelete: (params: T.DeleteProjectMemberRequest) => sauceAuthorizedToken.delete<T.DeleteProjectMemberResponse>(`/project/edit/${params.projectId}/members/${params.apiKey}`),
  clientsQueueGet: () => sauceAuthorizedApiKey.get<T.ClientsQueueResponse>(`/queue/`)
};

export { 
  api, 
  updateAuthorizationHeaderWithApiKey,
  updateAuthorizationHeaderWithToken,
  PAGINATION_CONFIG, 
  SOCKET_EVENTS_EMIT, 
  SOCKET_EVENTS_LISTEN,  
};