import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useRemoteSettingsTabAnalytics = () => {
  const trackRemoteSettingsUpdated = (numOfKeyValuePairs: number) => {
    segmentAnalytics.track("Remote settings updated", {numOfKeyValuePairs});
  }

  useEffect(() => {
    segmentAnalytics.page("MainPage", "RemoteSettingsTab");
  }, []);

  return {
    trackRemoteSettingsUpdated
  };
};