import React, { memo, useState } from 'react';
import { useStyles } from './NavigationBarTabStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { NavigationBarTabCursor, NavigationBarTabT } from './types';
import { NAVIGATION_BAR_TABS_ICON_TABLE, NAVIGATION_BAR_TABS_TITLE_TABLE, NAVIGATION_BAR_TABS_STYLE_TABLE } from '../NavigationBar';
import { SomethingNewIndicator } from "~/components/SomethingNewIndicator";
import cn from 'classnames';

export type NavigationBarTabProps = {
  tab: NavigationBarTabT;
  newIndicator?: boolean;
  isSelected?: boolean;
  cursor?: NavigationBarTabCursor;
  justifyContent?: "center" | "flex-end";
  onPress?: () => void;
  onPressDisabled?: boolean;
  isDragging?: boolean;
  withHoverEffect?: boolean;
};

const NavigationBarTab: React.FC<NavigationBarTabProps> = ({
  tab,
  newIndicator,
  isSelected,
  cursor,
  justifyContent,
  onPress,
  onPressDisabled,
  isDragging,
  withHoverEffect
}) => {
  const Icon = NAVIGATION_BAR_TABS_ICON_TABLE[tab.id];
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const [hovered, setHovered] = useState(false);

  return (
    <Box className={cn(s.tab)}>
      {newIndicator &&
        <Box className={cn(s.somethingNewIndicatorContainer)}>
          <SomethingNewIndicator />
        </Box>
      }

      <Box 
        className={cn(s.tabButton, {[s.tabButtonHovered]: withHoverEffect && hovered || isDragging})}
        style={{cursor, justifyContent}}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onPressDisabled ? undefined : onPress}
      >
        {/*@ts-ignore*/}
        <Box className={cn(s[NAVIGATION_BAR_TABS_STYLE_TABLE[tab.id]])}>
          <Icon fill={isSelected ? theme.colors.navBarIconActive: theme.colors.navBarIconUnactive}/>
        </Box>
        <p 
          className={cn(s.tabTitle)}
          style={{color: isSelected ? theme.colors.navBarTextActive : theme.colors.navBarTextUnactive}}
        >
          {NAVIGATION_BAR_TABS_TITLE_TABLE[tab.id]}
        </p>
      </Box>  
    </Box>
  );
};

NavigationBarTab.defaultProps={
  newIndicator: false,
  isSelected: false,
  cursor: "grab",
  justifyContent: "center",
  onPressDisabled: false,
  withHoverEffect: true
}

const MemorizedComponent = memo(NavigationBarTab);
export { MemorizedComponent as NavigationBarTab };