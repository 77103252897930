import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M.704 13.167C1.215 6.27 6.974.833 14 .833c-1.107 0-2.094.486-2.905 1.229-.808.738-1.49 1.77-2.042 2.989-.555 1.226-.986 2.66-1.278 4.217a26.34 26.34 0 0 0-.423 3.899H.704Zm0 2h6.648c.047 1.335.19 2.65.423 3.898.292 1.557.723 2.991 1.278 4.217.552 1.22 1.234 2.251 2.042 2.99.811.742 1.798 1.228 2.905 1.228-7.027 0-12.785-5.437-13.296-12.333Z"
      clipRule="evenodd"
    />
    <path
     fill={props.fill}
      d="M14 2.694c-.366 0-.84.156-1.39.66-.556.508-1.111 1.304-1.602 2.388-.487 1.077-.883 2.377-1.156 3.83a24.658 24.658 0 0 0-.394 3.595h9.085a24.648 24.648 0 0 0-.394-3.595c-.273-1.453-.669-2.753-1.156-3.83-.49-1.084-1.046-1.88-1.601-2.388-.551-.504-1.025-.66-1.391-.66ZM9.852 18.761c.273 1.453.669 2.753 1.156 3.83.49 1.085 1.046 1.88 1.601 2.389.551.504 1.025.66 1.392.66.366 0 .84-.156 1.39-.66.556-.508 1.111-1.304 1.602-2.389.487-1.077.883-2.377 1.156-3.83.214-1.144.347-2.357.394-3.594H9.458c.047 1.237.18 2.45.394 3.594Z"
    />
    <path
     fill={props.fill}
      d="M14 .833c1.108 0 2.095.486 2.906 1.229.808.738 1.49 1.77 2.042 2.989.555 1.226.986 2.66 1.278 4.217.234 1.248.376 2.563.423 3.899h6.648C26.785 6.27 21.027.833 14 .833ZM20.226 19.065c-.292 1.557-.723 2.991-1.278 4.217-.552 1.22-1.235 2.251-2.042 2.99-.811.742-1.798 1.228-2.905 1.228 7.027 0 12.784-5.437 13.296-12.333h-6.648a26.338 26.338 0 0 1-.423 3.898Z"
    />
  </svg>
)
export { SvgComponent as NetworkIconV2 }
