import React from 'react';
import { IconButton } from '@material-ui/core';
import PinIcon from './assets/pin';

export type PinProps = {
  left: number;
  pinned: boolean | undefined;
  className: string;
  defaultColor?: string;
  title?: string;
  onChangePin: (newPinned: boolean) => void;
};

const Pin: React.FC<PinProps> = ({
  left,
  pinned,
  className,
  defaultColor,
  title,
  onChangePin
}) => {
  return (
    <IconButton 
      onClick={(e) => onChangePin(!pinned)} 
      size='small'
      title={title}
      className={className}
      style={{left}}
    >
      <PinIcon color={pinned ? 'red' : defaultColor}/>
    </IconButton>
  );
};

Pin.defaultProps={
  defaultColor: "white"
}

export { Pin };