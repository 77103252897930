import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  left: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.metrics.x3,
    position: 'relative'
  },
  rightContainer: {
    display: 'flex', 
    position: 'absolute', 
    right: 0, 
    height: '100%', 
    padding: theme.metrics.x3
  },
});