import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  actionModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    height: '30vh',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  "&__content": {outline: "none"},
  title: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily,
    fontSize: 26,
    margin: 0
  },
  text: {
    color: theme.colors.textMain,
    fontSize: 20,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0
  },
  bottomRow: {
    display: "flex",
    width: "100%",
  },
  btn: {
    display: "flex",
    height: 50,
    width: "47%"
  }
});