import React from 'react';
import { useStyles } from './CustomLoaderStyles';
import { useThemeContext } from '~/theme';
import cn from 'classnames';

export type CustomLoaderProps = {
  size?: number;
  colorBg?: string;
  color?: string
  width?: number;
};

const CustomLoader: React.FC<CustomLoaderProps> = ({
  size,
  colorBg,
  color,
  width
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <div 
      style={{
        width: size,
        height: size,
        border: `${width}px solid ${colorBg ?? theme.colors.bgSecondary}`,
        borderBottomColor: color ?? theme.colors.blueRibbon,
      }}
      className={cn(s.loader)}
    >

    </div>
  );
};

CustomLoader.defaultProps={
  width: 3,
  size: 16
}

export { CustomLoader };