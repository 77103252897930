import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { InstructionPrototype as InstructionPrototypeT } from '~/types/api';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';

export type InstructionPrototypeProps = {
  prototype?: InstructionPrototypeT;
};

const InstructionPrototype: React.FC<InstructionPrototypeProps> = ({
  prototype
}) => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const PROTOTYPE_DESCRIPTION = useMemo(() => ({
    login: t("Other.prototypeLoginDescription"),
    logout: t("Other.prototypeLogoutDescription")
  }), [t]);

  if (!prototype)
    return null;
  
  return (
    <Box style={{marginLeft: 6}} title={PROTOTYPE_DESCRIPTION[prototype]}>
      <p style={{
        color: theme.colors.dodgerBlue,
        margin: 0,
        pointerEvents: 'none',
        fontFamily: theme.fonts.f700.fontFamily,
        fontSize: 18
      }}>
        {`[${prototype}]`}
      </p>
    </Box>
  );
};

InstructionPrototype.defaultProps={}

export { InstructionPrototype };