import React, { memo } from 'react';
import { useStyles } from './StringValueItemStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { stringWithMaxLen } from '~/helpers/strings';
import cn from 'classnames';

export type StringValueItemProps = {
  value: string;
  label: string;
  valueColor?: string;
  labelColor?: string;
  labelBold?: boolean;
  customHeight?: number;
  borderColor?: string;
};

const StringValueItem: React.FC<StringValueItemProps> = ({
  label,
  value,
  customHeight,
  borderColor,
  labelColor,
  labelBold,
  valueColor
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <Box className={cn(s.container)} style={{height: customHeight, borderBottom: `1px solid ${borderColor ?? theme.colors.borderMain}`}}>
      <p 
        className={cn(s.label)} 
        style={{
          color: labelColor ?? theme.colors.textMain, 
          fontSize: label.length < 20 ? 17 : 14,
          fontFamily: labelBold ? theme.fonts.f800.fontFamily : theme.fonts.f400.fontFamily,
        }}>
        {label}
      </p>

      <p className={cn(s.value)} style={{color: valueColor ?? theme.colors.textBright, fontSize: value.length < 30 ? 17 : value.length < 80 ? 14 : 12}}>
        {stringWithMaxLen(value, 150)}
      </p>
    </Box>
  );
};

StringValueItem.defaultProps={
  customHeight: 44
}

const MemorizedComponent = memo(StringValueItem);
export { MemorizedComponent as StringValueItem };