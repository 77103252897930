import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.166 10.153A2 2 0 0 1 7.16 8h9.68a2 2 0 0 1 1.994 2.153l-.692 9A2 2 0 0 1 16.148 21H7.852a2 2 0 0 1-1.994-1.847l-.692-9Z"
      stroke={props.color}
      strokeWidth={2}
    />
    <path d="M19.5 5h-15" stroke={props.color} strokeWidth={2} strokeLinecap="round" />
    <path
      d="M10 3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2h-4V3Z"
      stroke={props.color}
      strokeWidth={2}
    />
    <path
      d="M14 12v5M10 12v5"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
)

export { SvgComponent as TrashIcon }