import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  pinnedContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.bgSenary
  },
  pinnedScrollable: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "scroll"
  },
  whiteText: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily
  },
  icon: {
    display: "flex",
    height: 36,
    width: 41,
    marginBottom: theme.metrics.x3
  }
});