import { call, spawn, put, take } from 'redux-saga/effects';
import { AppSettingsSaga } from '../logic/appSettings/AppSettingsSagas';
import { UserSaga } from '../logic/user/UserSagas';
import { OurReduxInspectorSaga } from '../logic/ourReduxInspector/OurReduxInspectorSagas';
import { RemoteSettingsSaga } from '../logic/remoteSettings/RemoteSettingsSagas';
import { NetworkSaga } from '../logic/network/NetworkSagas';
import { OurZustandInspectorSaga } from '../logic/ourZustandInspector/OurZustandInspectorSagas';
import { OurTanStackQueryInspectorSaga } from '../logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSagas';
import { OurContextsInspectorSaga } from '../logic/ourContextsInspector/OurContextsInspectorSagas';
import { OurMobxInspectorSaga } from '../logic/ourMobxInspector/OurMobxInspectorSagas';
import { UserActions } from '../logic/user/UserSlice';
import { AppSettingsActions } from '../logic/appSettings/AppSettingsSlice';

function* applicationStart() {
  console.log("Running applicationStart saga...");

  yield put(AppSettingsActions.getAppSettingsFromLocalStorage());
  yield take(AppSettingsActions.appStartSettingsSetupDone);

  yield put(UserActions.initialSaga.request({}));
}

function* rootSaga() {
  const sagas = [
    applicationStart, 
    AppSettingsSaga, 
    UserSaga, 
    OurReduxInspectorSaga, 
    RemoteSettingsSaga, 
    NetworkSaga, 
    OurZustandInspectorSaga, 
    OurTanStackQueryInspectorSaga, 
    OurContextsInspectorSaga,
    OurMobxInspectorSaga
  ];

  yield* sagas.map((saga) =>
    spawn(function* sagaGuard() {
      while (true) {
        try {
          yield call(saga);
          break;
        } catch (e) {
          console.log("rootSaga error:", e);
        }
      }
    }),
  );
}

export { rootSaga };