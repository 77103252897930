import Russia from "./russia.png";
import RussiaSmall from "./russia_small.png";
import UnitedKingdom from "./united-kingdom.png";
import UnitedKingdomSmall from "./united-kingdom_small.png";
import Spain from "./spain.png";
import SpainSmall from "./spain_small.png";
import Ukraine from "./ukraine.png";
import UkraineSmall from "./ukraine_small.png";

export const FlagsImages = {
  Russia,
  UnitedKingdom,
  Spain,
  Ukraine
};

export const FlagsImagesSmall = {
  Russia: RussiaSmall,
  UnitedKingdom: UnitedKingdomSmall,
  Spain: SpainSmall,
  Ukraine: UkraineSmall
};