import { makeStyles } from '@material-ui/core/styles';
import { theme } from './theme';

export const useStyles = makeStyles({
  fullWidth: {
    display: "flex",
    width: '100%'
  },
  fullSpace: {
    display: "flex",
    width: '100%',
    height: '100%',
  },
  fullSpaceImg: {
    display: "flex",
    width: '100%',
    height: '100%',
    objectFit: "contain"
  },
  fullSpaceCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  fullSpaceColRelative: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative"
  },
  fullSpaceCentered: {
    display: "flex",
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullSpaceCenteredCol: {
    display: "flex",
    flexDirection: "column",
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullSpaceCenteredColRelative: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative"
  },
  fullWidthCenteredCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  fullWidthCenteredRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  rowCentered: {
    display: 'flex',
    justifyContent: 'center'
  },
  fullSpaceRelative: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: 'relative',
  },
  inputContainterMarginTop: {
    width: "100%",
    marginTop: theme.metrics.x4
  },
  inputContainterMarginBottom: {
    width: "100%",
    marginBottom: theme.metrics.x4
  },
  whiteTextBolder: {
    color: 'white',
    fontFamily: theme.fonts.f600.fontFamily
  },
  grayTextBolder: {
    color: 'gray',
    fontFamily: theme.fonts.f600.fontFamily
  },
  openInFloatingWindowButton: {
    display: "flex",
    width: 20, 
    height: 20,
    // backgroundColor: 'blue',
    justifyContent: 'center',
    alignItems: 'center'
  },
});