import React from 'react';
import { useStyles } from './ScrollToButtonStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from "~/assets/icons";
import Animated, { useSharedValue, useDerivedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';
import cn from 'classnames';

const MIN_OPACITY = 0.4;
const MAX_OPACITY = 1;

export type ScrollToButtonProps = {
  type: "toTop" | "toBottom";
  size?: number;
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  onPress: () => void;
};

const ScrollToButton: React.FC<ScrollToButtonProps> = ({
  type,
  size,
  left,
  top,
  right,
  bottom,
  onPress
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const hovered = useSharedValue(false);

  const opacity = useDerivedValue(() => {
    if (hovered.value)
      return withTiming(MAX_OPACITY);

    return withTiming(MIN_OPACITY);
  }, [hovered]);

  const onMouseEnter = () => {
    hovered.value = true;
  }

  const onMouseLeave = () => {
    hovered.value = false;
  }

  const containerStyle = useAnimatedStyle(() => ({
    opacity: opacity.value
  }), [opacity]);

  return (
    <Animated.View 
      style={[containerStyle, {
        display: "flex",
        width: size,
        height: size,
        borderRadius: size! * 0.5,
        backgroundColor: theme.colors.black50,
        position: "absolute",
        left,
        top,
        right,
        bottom,
        zIndex: 4
      }]}
    >
      <IconButton 
        size={"small"} 
        className={cn(s.container)} 
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onPress}
        title={type === "toTop" ? t("Other.scrollToTop") : t("Other.scrollToBottom")}
      >
        <Box className={cn(s.arrowContainer, {[s.rotate]: type === "toTop"})}>
          <ArrowDownIcon color={theme.colors.iconsMain}/>
        </Box>
      </IconButton>
    </Animated.View>
  );
};

ScrollToButton.defaultProps={
  size: 22
}

export { ScrollToButton };