import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    userSelect: 'none',
    width: '100%',
    height: 84,
    justifyContent: 'space-between',
    borderRadius: 8,
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x,
    paddingTop: theme.metrics.x,
    paddingBottom: theme.metrics.x
  },
  instructionIdText: {
    margin: 0,
    color: theme.colors.instructionItemTextMain,
    fontFamily: theme.fonts.f700.fontFamily,
    fontSize: 18
  },
  descriptionText: {
    margin: 0,
    color: theme.colors.textSecondary,
    fontSize: 14,
    fontFamily: theme.fonts.f300.fontFamily,
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    // overflowWrap: 'break-word',
    // textOverflow: 'ellipsis',
  },
  rowBetween: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: 'center',
    position: 'relative',
  },
  rowCentered: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  sendButton: {
    width: 26,
    height: 24,
    marginLeft: -theme.metrics.x,
    marginRight: -theme.metrics.x,
  },
  groupIndicator: {
    position: "absolute",
    right: -17,
    bottom: -17,
    width: 32,
    height: 32,
    transform: "rotate(45deg)",
    zIndex: 1
    // opacity: 1
  }
});