import { useEffect } from "react";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useLoginPageAnalytics = () => {
  const trackUserAuthAttempt = (email: string) => {
    segmentAnalytics.track("Auth attempt", {email});
  };

  useEffect(() => {
    segmentAnalytics.page("LoginPage", "LoginPage");
  }, []);

  return {
    trackUserAuthAttempt
  };
};