import React from 'react';
import { useStyles } from './ReduxTabStyles';
import { useThemeContext } from '~/theme';
import { FloatingViewCompatibleContentOrPlaceholder } from '~/blocks/FloatingViewCompatibleHelpers';
import cn from 'classnames';

export type ReduxTabProps = {};

const ReduxTab: React.FC<ReduxTabProps> = ({}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  return (
    <div className={cn(s.container)}>
      <FloatingViewCompatibleContentOrPlaceholder content={"ReduxInspector"} />
    </div>
  );
};

ReduxTab.defaultProps={}

export { ReduxTab };