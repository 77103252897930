import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    fill={props.color}
    width="100%"
    height="100%"
    viewBox="0 0 1024 1024"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m665.5 256-76.8 76.8v122.9a153.6 153.6 0 0 1 76.8 133h-128v153.6l-25.6 25.6-25.6-25.6V588.7h-128a153.47 153.47 0 0 1 76.8-133V332.8L358.3 256" />
  </svg>
)

export default SvgComponent
