import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  availableInstructionsContainer: {
    backgroundColor: theme.colors.bgQuaternary,
    borderRadius: theme.metrics.x2,
  },
  searchInputContainer: {
    display: "flex",
    width: '100%',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.metrics.x2,
    paddingRight: theme.metrics.x2,
    marginTop: theme.metrics.x2,
    marginBottom: theme.metrics.x2,
  }
});