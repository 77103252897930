import { segmentAnalytics } from "~/services/segmentAnalyticsService";

export const useTrackUserSentScenario = () => {
  const trackUserSentScenario = (numOfEvents: number, numOfParams: number) => {
    segmentAnalytics.track("Scenario sent", {numOfEvents, numOfParams});
  }

  return {
    trackUserSentScenario
  };
};