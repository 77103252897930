import React, { memo, useMemo } from 'react';
import { useStyles } from './StorageSnapshotModalStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CustomModal, CustomModalProps } from '../CustomModal';
import { SyntaxHighlighter } from './../SyntaxHighlighter';
import { NoData } from '../NoData';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { useTranslation } from 'react-i18next';
import { useStorageSnapshot, useTimeToShow } from '~/hooks';
import { StorageSnapshot } from '~/types/types';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { RefreshIcon } from '~/assets/icons';
import { handleOpenLink } from '~/helpers/windowHelpers';
import { CONFIG } from '~/config';
import cn from 'classnames';

export type StorageSnapshotModalProps = CustomModalProps & {
  snapshot: StorageSnapshot | null;
};

const StorageSnapshotModal: React.FC<StorageSnapshotModalProps> = ({
  snapshot,
  ...restProps
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const { refreshEntireStorageSnapshot } = useStorageSnapshot();

  const timeToShow = useTimeToShow(snapshot?.timestamp);

  const dataToShow = useMemo(() => {
    return snapshot ? jsonStringifyObjectFormatted(snapshot.storageAsObject) : null
  }, [snapshot]);

  return (
    <CustomModal
      {...restProps} 
      onClose={restProps.onClose}
      classname={cn(s.modal)} 
      title={`${snapshot ? snapshot.storageType : "Storage"} ${t("Common.snapshot")}`}
      enableBlur={true}
      headerLeft={
        <Box className={s.headerLeft}>
          <IconButton 
            onClick={refreshEntireStorageSnapshot} 
            size='small'
            className={cn(s.refreshButton)}
            title={t("Common.refresh")}
          >
            <RefreshIcon color={theme.colors.iconsMain}/>
          </IconButton>
        </Box>
      }
    >
      <Box className={cn(s.content)}>
        {dataToShow ?
          <>
            <SyntaxHighlighter 
              code={dataToShow}
              enableFolding={true}
              foldingController={"inner"}
              customStyle={{...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
              borderRadius={0}
            />
            <Box className={cn(s.footer)}>
              {snapshot !== undefined &&
                <p 
                  className={cn(s.timestampText)}
                >
                  {`${t("Other.lastUpdate")}: ${timeToShow}`}
                </p>
              }
            </Box>
          </>
        :
          <NoData 
            bottomChildren={
              <p className={cn(s.linkText)} onClick={() => handleOpenLink(CONFIG.LINKS.STORAGE_MONITOR)}>
                {t("Common.learnMore")}
              </p>
            }
          />
        }
      </Box>
    </CustomModal>
  );
};

StorageSnapshotModal.defaultProps={}

const MemorizedComponent = memo(StorageSnapshotModal);
export { MemorizedComponent as StorageSnapshotModal };