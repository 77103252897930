import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: theme.metrics.x3,
  },
});