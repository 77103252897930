import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    className="icon flat-line"
    data-name="Flat Line"
    viewBox="0 0 24 24"
  >
    <path
      d="M10 10 3.29 3.29m17.42 17.42L14 14"
      style={{
        fill: "none",
        stroke: props.color,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
    <path
      d="M10 3H4a1 1 0 0 0-1 1v6M21 14v6a1 1 0 0 1-1 1h-6"
      data-name="primary"
      style={{
        fill: "none",
        stroke: props.color,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2,
      }}
    />
  </svg>
)
export { SvgComponent as MaximizeWindowIcon };
