import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.284 11.134a1 1 0 0 1 0 1.732L6.716 18.967a1 1 0 0 1-1.5-.866V5.9a1 1 0 0 1 1.5-.866l10.568 6.101Z"
      fill={props.color}
    />
  </svg>
)

export default SvgComponent
