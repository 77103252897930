import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  privateText: {
    color: theme.colors.textBright,
    margin: 0,
    marginRight: 8,
    // fontFamily: theme.fonts.f600.fontFamily,
    fontFamily: "monospace",
    fontWeight: "bolder",
    fontSize: 18
  },
  iconCont: {
    display: 'flex',
    // width: 24,
    // height: 24,
    width: 30,
    height: 30
  }
});