import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
  >
    <circle cx={24} cy={12} r={8} fill={props.color1} />
    <path
      fill={props.color2}
      d="M40 35c0 4.97 0 9-16 9S8 39.97 8 35s7.163-9 16-9 16 4.03 16 9Z"
    />
  </svg>
)
export { SvgComponent as UserIcon }
