import { restActionCreatorHelper } from '~/helpers/restActionCreatorHelper';

const remoteSettingsRestAction = restActionCreatorHelper(`remoteSettings`);

export const GetRemoteSettingsRestActions = remoteSettingsRestAction('getRemoteSettings');

export const SetRemoteSettingsRestActions = remoteSettingsRestAction('setRemoteSettings');

export const CreateProjectRestActions = remoteSettingsRestAction('createProject');

export const EditProjectRestActions = remoteSettingsRestAction('editProject');

export const DeleteProjectRestActions = remoteSettingsRestAction('deleteProject');

export const ProjectListRestActions = remoteSettingsRestAction('projectList');

export const ProjectByIdRestActions = remoteSettingsRestAction('projectById');

export const AddProjectMemberRestActions = remoteSettingsRestAction('addProjectMember');

export const RemoveProjectMemberRestActions = remoteSettingsRestAction('removeProjectMember');