import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  importScenarioModal: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    height: '75vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.globalBg,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 16,
    paddingRight: 16
  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    // backgroundColor: 'green',
    overflow: 'scroll'
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 12
  },
  inputCont: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.metrics.x4
  },
  mediaPickerCont: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: theme.metrics.x4,
  },
  label: {
    fontSize: 18,
    fontFamily: theme.fonts.f600.fontFamily,
    margin: 0,
    pointerEvents: "none"
  },
  labelActive: {
    color: theme.colors.textMain,
  },
  labelUnactive: {
    color: theme.colors.textTetriary
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.metrics.x2
  },
  checkboxesCont: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.metrics.x4
  }
});