export const resizeHandleRightStyle = {
  width: 16,
  height: "calc(100% - 24px)",
  top: 12,
  // backgroundColor: 'red'
  // backgroundColor: "#ffffff20"
};

export const resizeHandleTopStyle = {
  height: 16,
  width: "calc(100% - 24px)",
  left: 12,
  top: 8,
  // backgroundColor: 'red'
};


export const resizeHandleBottomStyle = {
  height: 16,
  width: "calc(100% - 24px)",
  left: 12,
  bottom: -4,
  // backgroundColor: 'red'
};