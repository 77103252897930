import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 478.53 478.53"
    style={{
      enableBackground: "new 0 0 478.53 478.53",
    }}
    xmlSpace="preserve"
  >
    <path d="M477.795 184.279a14.997 14.997 0 0 0-12.108-10.209l-147.159-21.384-65.812-133.35a15 15 0 0 0-26.902 0l-65.812 133.35L12.843 174.07A14.996 14.996 0 0 0 .735 184.279a14.997 14.997 0 0 0 3.796 15.376l106.484 103.798-25.138 146.565a15.002 15.002 0 0 0 21.765 15.813l131.623-69.199 131.623 69.199a14.998 14.998 0 0 0 15.797-1.141 15.002 15.002 0 0 0 5.968-14.671l-25.138-146.565 106.484-103.798a15 15 0 0 0 3.796-15.377z" />
  </svg>
)

export default SvgComponent