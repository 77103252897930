import { useDispatch } from "react-redux";
import { UserActions } from "~/logic/user/UserSlice";
import { socketService } from "~/services/socketService";

export const useStorageSnapshot = () => {
  const dispatch = useDispatch();

  const refreshEntireStorageSnapshot = () => {
    socketService.forceRefresh({type: "storage"});
  };

  const onPressViewEntireStorage = () => {
    refreshEntireStorageSnapshot();
    dispatch(UserActions.setStorageSnapshotModalVisible(true));
  };

  return {
    refreshEntireStorageSnapshot,
    onPressViewEntireStorage
  };
};