import React, { useMemo } from 'react';
import { CircularProgress as MuiCircularProgress, CircularProgressProps, withStyles, createStyles, Theme } from '@material-ui/core';
import { useThemeContext } from '~/theme';

interface CircularProgressColoredProps extends Omit<CircularProgressProps, 'color'> {
  color?: string;
}

const CircularProgressColored: React.FC<CircularProgressColoredProps> = ({
  color,
  ...restProps
}) => {
  const { theme: { colors } } = useThemeContext();

  const CircularProgress = useMemo(() => withStyles((theme: Theme) =>
    createStyles({
      colorPrimary: {
        color: color ?? colors.loaderColor
      },
    })
  )(MuiCircularProgress), [color, colors]);

  return (
    <CircularProgress {...restProps}/>
  );
};

CircularProgressColored.defaultProps={}

export { CircularProgressColored };