import React, { ChangeEvent, memo } from "react";
import { useStyles } from "./PickedMediaStyles";
import { useThemeContext } from "../../theme";
import { JsonIcon } from "~/assets/icons";
import { stringWithMaxLen } from "~/helpers/strings";
import cn from "classnames";
import AddCircle from "./assets/add";
import TrashIcon from "./assets/trash";

export type PickedMediaProps = {
  source?: any;
  empty?: boolean;
  fileType?: "json" | "image";
  onFileChanged: (data: any) => void;
};

const PickedMedia: React.FC<PickedMediaProps> = ({
  source,
  empty,
  fileType,
  onFileChanged,
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    onFileChanged(file);
  };

  return (
    <div className={cn(s.container)}>
      {empty && (
        <div className={cn(s.emptyCont)}>
          <AddCircle color={theme.colors.iconsSecondary} />
        </div>
      )}
      {!empty && (
        <div className={cn(s.deleteBtn)} onClick={() => onFileChanged(null)}>
          <TrashIcon color={theme.colors.red} />
        </div>
      )}
      {!!source && (
        <div className={cn(s.imageCont)}>
          {fileType === "json" ?
            <>
              <JsonIcon color={theme.colors.iconsMain}/>
              <p className={s?.nameText}>
                {stringWithMaxLen(source.name, 22)}
              </p>
            </>
          :
            <img className={cn(s.image)} src={URL.createObjectURL(source)} />
          }
        </div>
      )}
      <input
        type="file"
        onChange={handleFileChange}
        className={cn(s.input)}
        onClick={(e) => {
          // @ts-ignore
          e.target.value = null;
        }}
      />
    </div>
  );
};

PickedMedia.defaultProps = {
  fileType: "json"
};

const MemorizedComponent = memo(PickedMedia);
export { MemorizedComponent as PickedMedia };
