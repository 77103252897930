import React, { useState, useMemo } from 'react';
import { useStyles } from './StorageActionItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/components/TimelineItem';
import { InterceptedStorageAction } from '~/types/types';
import { Box, Button, IconButton } from '@material-ui/core';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { jsonStringifyInterceptedStorageValue, jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { useStorageSnapshot } from '~/hooks';
import { ViewMoreIcon } from '~/assets/icons/viewMore';
import cn from 'classnames';
import 'animate.css';

export type StorageActionItemProps = TimelineItemExternalProps & {
  interceptedStorageAction: InterceptedStorageAction;
};

const StorageActionItem: React.FC<StorageActionItemProps> = ({
  interceptedStorageAction,
  showBorderBottom,
  timeSincePrevEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();

  const { onPressViewEntireStorage } = useStorageSnapshot();

  const [opened, setOpened] = useState(false);
  const [dataToShow, setDataToShow] = useState("");

  const timeToShow = useTimeToShow(interceptedStorageAction.timestamp);
  const actionLabel = useMemo(() => {
    let showArgs = "";

    if (interceptedStorageAction.data?.key)
      showArgs = `(${interceptedStorageAction.data.key})`;
    else if (interceptedStorageAction.data?.keys)
      showArgs = `(${interceptedStorageAction.data.keys.join(", ")})`;
    else if (interceptedStorageAction.data?.pairs)
      showArgs = `(...)`;

    return interceptedStorageAction.action + showArgs;
  }, [interceptedStorageAction]);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      timeSincePrevEvent={timeSincePrevEvent}
      showBorderBottom={showBorderBottom}
      onChangeOpened={(v) => setOpened(v)}
      leftChildren={
        <p className={cn(s.greenText)} title={`${t("Common.intercepted")} storage interaction`}>
          {"STORAGE ACTION"} 
        </p>
      }
      rightChildren={
        opened ?
          <Box className={cn(s.row)}>
            <IconButton 
              onClick={(e) => {
                e.stopPropagation();
                onPressViewEntireStorage();
              }} 
              size='small'
              className={cn(s.viewMore)}
              title={t("Other.viewEntireStorage")}
            >
              <ViewMoreIcon color={theme.colors.mobster}/>
            </IconButton>
          </Box>
        :
          <p className={cn(tds.grayText, tds.filledText)}>
            {actionLabel}
          </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {`Action: ${interceptedStorageAction.action}`}
              </p>
            </Box>
            {interceptedStorageAction.data?.key &&
              <Box className={cn(tds.wrapText)}>
                <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                  {`Key: ${interceptedStorageAction.data.key}`}
                </p>
              </Box>
            }
            {interceptedStorageAction.data?.keys &&
              <Box className={cn(tds.wrapText)}>
                <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                  {`Keys: [${interceptedStorageAction.data.keys.join(", ")}]`}
                </p>
              </Box>
            }
            {interceptedStorageAction.data?.pairs &&
              <Box className={cn(tds.wrapText)}>
                <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                  {`Key-Value pairs: ${JSON.stringify(interceptedStorageAction.data.pairs)}`}
                </p>
              </Box>
            }

            <Box className={cn(tds.infoCol)}>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  ID:
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedStorageAction.storageActionId}
                </p>
              </Box>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {`${t("Other.timestamp")}:`}
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedStorageAction.timestamp}
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            {interceptedStorageAction.data?.value &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyInterceptedStorageValue(interceptedStorageAction.data.value))}
              >
                Value
              </Button>
            }
            {interceptedStorageAction._stackTraceData &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedStorageAction._stackTraceData))}
              >
                TRACE
              </Button>
            }
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

StorageActionItem.defaultProps={}

export { StorageActionItem };