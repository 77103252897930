import { EventType } from '~/types/api';
import { RemoteEvent } from '~/types/types';

// export class Event {
//   private static _currentId = 0;
//   public id: number;
//   public localId: string;
//   public eventType: EventType;
//   public instructionId: string;
//   public args?: any[];

//   constructor(eventType: EventType, instructionId: string, localId: string, args?: any[]) {
//     this.id = Event._currentId++;
//     this.localId = localId;
//     this.eventType = eventType;
//     this.instructionId = instructionId;
//     this.args = args;
//   }
// }

let _currentId = 0;
// Serializable analog
export const createSerializeableEvent = (eventType: EventType, instructionId: string, localId: string, args?: any[]): RemoteEvent => {
  return {
    id: _currentId++,
    localId,
    eventType,
    instructionId,
    args
  };
}