import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    alignItems: 'center',
    height: 104,
    borderRadius: theme.metrics.x2,
    padding: theme.metrics.x2,
    cursor: "pointer",
    marginBottom: theme.metrics.x2
  },
  selectedIndicator: {
    position: "absolute",
    left: -17,
    top: -17,
    width: 32,
    height: 32,
    transform: "rotate(45deg)",
    backgroundColor: theme.colors.bgContrastColor
    // opacity: 1
  },
  left: {
    display: "flex",
    width: 75,
    height: 75,
    marginRight: theme.metrics.x2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.metrics.x2,
    // backgroundColor: 'blue'
  },
  leftBg: {
    backgroundColor: theme.colors.black20,
  },
  giantLetter: {
    fontSize: 44,
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.textMain,
    margin: 0
  },
  right: {
    height: '100%',
    width: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: 'relative',
    paddingRight: theme.metrics.x6
  },
  buttonsCol: {
    display: "flex",
    position: 'absolute',
    right: 0,
    height: '100%',
    flexDirection: "column",
    justifyContent: "space-between",
  },
  icon: {
    width: 20,
    height: 20
  },
  projectNameText: {
    fontFamily: theme.fonts.f700.fontFamily,
    color: theme.colors.textMain,
    margin: 0
  },
  descriptionText: {
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.textTetriary,
    margin: 0
  },
  membersText: {
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.textBrightSecondary,
    margin: 0
  }
});