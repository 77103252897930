import React, { createElement, memo, useContext, useState } from 'react';
import { useStyles } from './FloatingViewStyles';
import { Rnd as ResizeableView } from "react-rnd";
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CloseIcon, MinimizeWindowIcon } from '~/assets/icons';
import { useDependentState, useWindowSize } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '~/config';
import { FloatingViewContext, FLOATING_VIEW_SHORTCUT_POSITION } from '~/providers';
import { FloatingViewContentToBlockTable } from '~/blocks/FloatingViewCompatibleHelpers';
import cn from 'classnames';

export type FloatingViewProps = {
  withGrab?: boolean;
  resizeEnabled?: boolean;
};

const FloatingView: React.FC<FloatingViewProps> = ({
  withGrab = true,
  resizeEnabled = true
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    resizeableViewRef,
    floatingViewVisible,
    floatingViewMinimized,
    floatingViewMinimizedWithWindowAnimationDelay,
    minimizeFloatingWindow,
    currentFloatingViewContent,
    closeFloatingWindow,
    defaultWidth,
    defaultHeight
  } = useContext(FloatingViewContext);

  const { windowWidth, windowHeight } = useWindowSize();

  const [w, setW] = useDependentState(() => defaultWidth, [defaultWidth]);
  const [h, setH] = useDependentState(() => defaultHeight, [defaultHeight]);
  const [position, setPosition] = useState({
    x: windowWidth - defaultWidth - CONFIG.NAV_BAR_WIDTH,
    y: (windowHeight - defaultHeight) * 0.5
  });

  if (!floatingViewVisible)
    return null;

  return (
    <ResizeableView
      ref={resizeableViewRef}
      enableResizing={{bottomLeft: resizeEnabled, topLeft: resizeEnabled, topRight: resizeEnabled, bottomRight: resizeEnabled}}
      dragAxis={"both"}
      bounds={"parent"}
      className={cn(s.draggable, {[s.hidden]: floatingViewMinimizedWithWindowAnimationDelay})}
      size={{ width: w, height: h }}
      position={position}
      minWidth={defaultWidth}
      minHeight={defaultHeight}
      onResize={(e, direction, ref) => {
        setW(ref.offsetWidth);
        setH(ref.offsetHeight);
      }}
      onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => setPosition(position)}
      default={{
        x: windowWidth - defaultWidth - CONFIG.NAV_BAR_WIDTH,
        y: (windowHeight - defaultHeight) * 0.5,
        width: defaultWidth,
        height: defaultHeight
      }}
    >
      <Box 
        className={cn(s.container, s.blurFilter)}
        style={floatingViewMinimized ? {
          transform: `translate(${windowWidth - w - position.x - CONFIG.NAV_BAR_WIDTH - FLOATING_VIEW_SHORTCUT_POSITION.RIGHT}px, ${windowHeight - h - position.y - FLOATING_VIEW_SHORTCUT_POSITION.BOTTOM}px) scale(0)`,
        } : undefined}
      >
        <Box 
          className={cn(s.headerCont)}
          style={{cursor: withGrab ? "grab" : undefined}}
        >
          <Box className={cn(s.headerBtn)} onClick={minimizeFloatingWindow} title={t("Common.minimize")}>
            <MinimizeWindowIcon color={theme.colors.iconsSecondary}/>
          </Box>
          <Box className={cn(s.headerBtn)} onClick={closeFloatingWindow} title={t("Common.hide")}>
            <CloseIcon color={theme.colors.iconsSecondary}/>
          </Box>
        </Box>
        <div className={cn(s.contentContainer)}>
          {floatingViewVisible && currentFloatingViewContent &&
            createElement(FloatingViewContentToBlockTable[currentFloatingViewContent].Component)
          }
        </div>
      </Box>
    </ResizeableView>
  );
};

const MemorizedComponent = memo(FloatingView);
export { MemorizedComponent as FloatingView };