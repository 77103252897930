import { useEffect } from "react";
import { useSelector } from "react-redux";
import { appSettingsSelector } from "~/logic/appSettings/AppSettingsSelectors";
import { userSelector } from "~/logic/user/UserSelectors";
import { segmentAnalytics } from "~/services/segmentAnalyticsService";
import _ from "lodash";

export const useOtherTabAnalytics = () => {
  const {
    colorTheme
  } = useSelector(appSettingsSelector);

  const {
    chosenNavBarTabs
  } = useSelector(userSelector);

  const trackUserSelectedTheme = _.throttle(() => {
    segmentAnalytics.track("User selected theme", {colorTheme});
  }, 500, {leading: false, trailing: true});

  const trackUserSelectedNavBarTabs = _.throttle(() => {
    segmentAnalytics.track("User selected nav bar tabs", {chosenNavBarTabs});
  }, 500, {leading: false, trailing: true});

  useEffect(() => {
    segmentAnalytics.page("MainPage", "OtherTab");
  }, []);

  return {
    trackUserSelectedTheme,
    trackUserSelectedNavBarTabs
  };
};