import React, { useState } from 'react';
import { useStyles } from './MobxEventItemStyles';
import { TimelineItem, TimelineItemExternalProps, useTimelineDefaultStyles, TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/components/TimelineItem';
import { InterceptedMobxEvent } from '~/types/types';
import { Box, Button } from '@material-ui/core';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { jsonStringifyObjectFormatted } from '~/helpers/jsonHelpers';
import { useTimeToShow } from '~/hooks/useTimeToShow';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import 'animate.css';

export type MobxEventItemProps = TimelineItemExternalProps & {
  interceptedMobxEvent: InterceptedMobxEvent;
};

const MobxEventItem: React.FC<MobxEventItemProps> = ({
  interceptedMobxEvent,
  showBorderBottom,
  timeSincePrevEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const tds = useTimelineDefaultStyles(theme)();
  const { t } = useTranslation();

  const [dataToShow, setDataToShow] = useState("");

  const timeToShow = useTimeToShow(interceptedMobxEvent.timestamp);

  return (
    <TimelineItem 
      timeToShow={timeToShow}
      timeSincePrevEvent={timeSincePrevEvent}
      showBorderBottom={showBorderBottom}
      leftChildren={
        <p className={cn(s.blueText)} title={`${t("Common.intercepted")} mobx event`}>
          {"MOBX EVENT"} 
        </p>
      }
      rightChildren={
        <p className={cn(tds.grayText, tds.filledText)}>
          {`${interceptedMobxEvent.event.type} (${interceptedMobxEvent.event.name})`}
        </p>
      }
      openedChildren={
        <Box className={cn(tds.detailsCont, "animate__animated animate__fadeIn")}>
          <Box className={cn(tds.detailsColumn)}>
            <Box className={cn(tds.wrapText)}>
              <p className={cn(tds.yellowText)} style={{maxWidth: '100%'}}>
                {`TYPE: ${interceptedMobxEvent.event.type}`}
              </p>
            </Box>

            <Box className={cn(tds.infoCol)}>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  ID:
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedMobxEvent.mobxEventId}
                </p>
              </Box>
              <Box className={cn(tds.rowDescBetween)}>
                <p className={cn(tds.grayTextSmall)}>
                  {`${t("Other.timestamp")}:`}
                </p>
                <p className={cn(tds.yellowText)}>
                  {interceptedMobxEvent.timestamp}
                </p>
              </Box>
            </Box>
          </Box>

          <Box className={cn(tds.buttonsRow)}>
            {Object.keys(interceptedMobxEvent.event).map((key) => {
              // @ts-ignore
              const value = interceptedMobxEvent.event[key];

              if (typeof value !== "object")
                return null;

              return (
                <Button 
                  key={`ex_val_${key}`}
                  variant="contained" 
                  className={cn(tds.bottomButton)}
                  onClick={() => setDataToShow(jsonStringifyObjectFormatted(value, "payload"))}
                >
                  {key}
                </Button>
              );
            })}
            {interceptedMobxEvent._stackTraceData &&
              <Button 
                variant="contained" 
                className={cn(tds.bottomButton)}
                onClick={() => setDataToShow(jsonStringifyObjectFormatted(interceptedMobxEvent._stackTraceData))}
              >
                TRACE
              </Button>
            }
          </Box>

          {dataToShow &&
            <Box className={cn(tds.syntaxHighligherCont)}>
              <SyntaxHighlighter 
                code={dataToShow}
                customStyle={TIMELINE_ITEM_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}
                enableFolding={true}
                foldingController={"inner"}
                fallbackDataKey={"payload"}
              />
            </Box>
          }
        </Box>
      }
    />
  );
};

MobxEventItem.defaultProps={}

export { MobxEventItem };