import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const STATE_INSPECTOR_HEADER_HEIGHT = 40;

export const useStyles = (theme: Theme) => makeStyles({
  headerText: {
    color: theme.colors.bgContrastColorText,
    fontFamily: theme.fonts.f600.fontFamily,
    margin: 0,
    paddingTop: theme.metrics.x
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    height: STATE_INSPECTOR_HEADER_HEIGHT,
    position: 'relative',
    backgroundColor: theme.colors.bgContrastColor,
    cursor: "pointer",
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.metrics.x5,
    paddingRight: theme.metrics.x5,
    userSelect: 'none'
  },
  headerLeft: {
    display: "flex",
    position: 'absolute',
    left: theme.metrics.x5,
    alignItems: 'center'
  },
  selectContainer: {
    marginRight: theme.metrics.x4,
    paddingTop: theme.metrics.x
  },
  select: {
    '&:focus':{
      backgroundColor: theme.colors.transparent
    },
    color: theme.colors.bgContrastColorText,
    fontFamily: theme.fonts.f500.fontFamily,
    // margin: 0,
    fontSize: 14
  },
  selectIcon: {
    color: theme.colors.bgContrastColorText
  },
  paper: {
    backgroundColor: theme.colors.bgQuinary,
    color: theme.colors.textMain
  },
  timestampText: {
    fontFamily: theme.fonts.f500.fontFamily,
    color: theme.colors.bgContrastColorText,
    margin: 0,
    paddingTop: theme.metrics.x,
    fontSize: 14,
    // '@media (max-width: 1150px)': {
    //   display: 'none'
    // }
  },
  headerRight: {
    display: 'flex',
    position: 'absolute',
    right: theme.metrics.x5,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  headerRightButton: {
    display: "flex",
    width: 24,
    height: 24
    // backgroundColor: 'red'
  },
  openInFloatingWindowButton: {
    display: "flex",
    width: 22,
    height: 22
  }
});