import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import { handleFoldChange, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { deleteZustandPinnedPathsFromLocalStorage, saveZustandPinnedPathsToLocalStorage } from "~/helpers/localStorage";
import { showToast } from "~/helpers/alertService";
import { StateManagerStateCopy, StateManagerStateCopyWithTimestamp } from '~/types/types';
import i18next from 'i18next';

const MAX_PINNED_PATHS = 20;

type HandleFoldChangePayload = {
  lineIndex: number;
  newOpened: boolean;
};

type PinPathPayload = {
  path: string;
  apiKey: string;
}

type UnpinPathPayload = {
  path: string;
  apiKey: string;
}

type ClearPinnedPathsPayload = {
  apiKey: string;
}

type OurZustandInspectorState = {
  zustandStateCopy: StateManagerStateCopy | null;
  zustandStateCopyNumOfLines: number;
  zustandStateCopyTimestamp: number | undefined;
  pinnedPaths: string[];
  linesWithMarkup: LinesWithMarkup[];
  gotSomethingNewIndicator: boolean;
};

const initialOurZustandInspectorState: OurZustandInspectorState = {
  zustandStateCopy: null,
  zustandStateCopyNumOfLines: 0,
  zustandStateCopyTimestamp: undefined,
  pinnedPaths: [],
  linesWithMarkup: [],
  gotSomethingNewIndicator: false
};

const ourZustandInspectorSlice = createSlice({
  name: 'ourZustandInspector',
  initialState: initialOurZustandInspectorState,
  reducers: {
    setZustandStateCopy: {
      reducer(state, action: PayloadAction<StateManagerStateCopyWithTimestamp>) {
        const zustandStateCopy = {state: action.payload.state};
        const s = JSON.stringify(zustandStateCopy, null, 2);
        let prevData;
        if (state.zustandStateCopy && state.linesWithMarkup.length > 0) {
          prevData = {
            linesWithMarkup: state.linesWithMarkup
          }
        }
  
        const newLinesWithMarkup = markupJSON(s, zustandStateCopy, prevData);
        state.linesWithMarkup = newLinesWithMarkup;
        state.zustandStateCopy = zustandStateCopy;
        state.zustandStateCopyNumOfLines = newLinesWithMarkup.length;
        state.zustandStateCopyTimestamp = action.payload.timestamp;
      },
      prepare: prepareAutoBatched<StateManagerStateCopyWithTimestamp>(),
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    handleInspectorFoldChange(state, action: PayloadAction<HandleFoldChangePayload>) {
      const { lineIndex, newOpened } = action.payload;

      state.linesWithMarkup = handleFoldChange(state.linesWithMarkup, lineIndex, newOpened);
    },
    setPinnedPaths(state, action: PayloadAction<string[]>) {
      state.pinnedPaths = action.payload;
    },
    pinPath(state, action: PayloadAction<PinPathPayload>) {
      if (state.pinnedPaths.length === MAX_PINNED_PATHS) {
        showToast(`${i18next.t("Toast.pinsLimitReached")} ${i18next.t("Toast.youCannotPinMoreThan")} ${MAX_PINNED_PATHS} ${i18next.t("Common.objects")}.`, "warning");
        return;
      }

      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.add(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveZustandPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    unpinPath(state, action: PayloadAction<UnpinPathPayload>) {
      const pinnedPathsSet = new Set(state.pinnedPaths);
      pinnedPathsSet.delete(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPaths = pinnedPaths;
      saveZustandPinnedPathsToLocalStorage(action.payload.apiKey, pinnedPaths);
    },
    clearPinnedPaths(state, action: PayloadAction<ClearPinnedPathsPayload>) {
      state.pinnedPaths = [];
      deleteZustandPinnedPathsFromLocalStorage(action.payload.apiKey);
    },
    clearAllState: {
      reducer() {
        return {
          ...initialOurZustandInspectorState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearZustandInspector: {
      reducer(state) {
        state.zustandStateCopy = initialOurZustandInspectorState.zustandStateCopy;
        state.zustandStateCopyNumOfLines = initialOurZustandInspectorState.zustandStateCopyNumOfLines;
        state.zustandStateCopyTimestamp = initialOurZustandInspectorState.zustandStateCopyTimestamp;
        state.linesWithMarkup = initialOurZustandInspectorState.linesWithMarkup;
        state.gotSomethingNewIndicator = initialOurZustandInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<void>(),
    }
  }
})

const ourZustandInspectorReducer = ourZustandInspectorSlice.reducer;
const OurZustandInspectorActions = ourZustandInspectorSlice.actions;

export { ourZustandInspectorReducer, OurZustandInspectorActions };