import React from 'react';
import { useStyles } from './MainStyles';
import { EventItem } from '~/components/EventItem';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { userSelector } from '~/logic/user/UserSelectors';
import { useSelector } from 'react-redux';
import { EventStateData } from '~/helpers/draggableHelpers';
import { useDispatch } from 'react-redux';
import { UserActions } from '~/logic/user/UserSlice';
import { Typography, Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { DropIcon } from "~/assets/icons";
import cn from 'classnames';

export type DroppableConstructorProps = {
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
};

const DroppableConstructor: React.FC<DroppableConstructorProps> = ({
  isDropDisabled,
  isDragDisabled
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    draggableInstuctionsState
  } = useSelector(userSelector);

  const handleDeleteEvent = (list: string, id: string) => {
    dispatch(UserActions.removeDraggableEvent({
      list,
      id
    }));
  };

  const handlePressFillWithAccountData = (list: string, index: number) => {
    dispatch(UserActions.fillEventWithSelectedAccountData({list, index}));
  };

  const handlePressFillWithLastParams = (list: string, index: number) => {
    dispatch(UserActions.fillEventWithLastParams({list, index}));
  };

  return (
    <Box style={{width: '100%', overflowX: "hidden", zIndex: 2}}>
      {Object.keys(draggableInstuctionsState).map((list) => {
        return (
          <Droppable key={list} droppableId={list} isDropDisabled={isDropDisabled}>
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                className={cn(s.dashedContainer)}
                style={{borderColor: droppableSnapshot.isDraggingOver ? theme.colors.droppableAreaBorderActive : theme.colors.droppableAreaBorderUnactive}}
              >
                {
                  draggableInstuctionsState[list].length ? 
                    draggableInstuctionsState[list].map(
                      (item: EventStateData, index: number) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => {
                            const inheritanceType = draggableInstuctionsState[list][index - 1]?.instructionId === "forwardData" ? "INHERITS": draggableInstuctionsState[list][index + 1]?.instructionId === "forwardData" ? "PASSES" : undefined;
                            
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{...provided.draggableProps.style, marginBottom: theme.metrics.x05}}
                              >
                                <EventItem 
                                  stateKey={list}
                                  eventIndex={index}
                                  event={item}
                                  prevEventOfScenario={index > 0 ? draggableInstuctionsState[list][index - 1] : undefined}
                                  isDragging={snapshot.isDragging}
                                  dragHandleProps={provided.dragHandleProps}
                                  onPressDelete={() => handleDeleteEvent(list, item.id)}
                                  onPressFillWithAccountData={() => handlePressFillWithAccountData(list, index)}
                                  onPressFillWithLastParams={() => handlePressFillWithLastParams(list, index)}
                                  inheritanceType={inheritanceType}
                                  insideOfCondition={draggableInstuctionsState[list][index - 1]?.instructionId === "condition"}
                                />
                              </div>
                            )
                          }}
                        </Draggable>
                      )
                    )
                  : 
                    (
                      <Box className={cn(s.droppableConstructorPlaceholderContainer)}>
                        <DropIcon color={theme.colors.iconsMain}/>
                        <Typography variant="h4" className={cn(s.whiteTextBolder)}>
                          {t("MainPage.MainTab.dropInstructionsHere")}
                        </Typography>
                        <Typography variant="h6" className={cn(s.grayText)}>
                          {t("MainPage.MainTab.thisIsDroppableConstructor")}
                        </Typography>
                      </Box>
                    )
                }
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        );
      })}
    </Box>
  );
};

DroppableConstructor.defaultProps={
  isDropDisabled: false,
  isDragDisabled: false
}

export { DroppableConstructor };