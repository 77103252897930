import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <style>
        {
          `.cls-1{fill:none;stroke:${props.fill};stroke-miterlimit:10;stroke-width:1.91px}`
        }
      </style>
    </defs>
    <circle className="cls-1" cx={3.41} cy={12} r={1.91} />
    <circle className="cls-1" cx={12} cy={12} r={1.91} />
    <circle className="cls-1" cx={20.59} cy={12} r={1.91} />
  </svg>
)

export { SvgComponent as OtherIcon }
