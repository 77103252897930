import React, { memo } from 'react';
import { useStyles } from './ActionModalStyles';
import { Box } from '@material-ui/core';
import { CustomButton } from "~/components/CustomButton";
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { theme } from '~/theme/default/theme';
import Modal from 'react-modal';
import cn from 'classnames';

Modal.setAppElement('#root');

// Global constants
const MODAL_CUSTOM_STYLE = {
  "content": {
    display: 'flex',
  },
  overlay: {
    zIndex: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.modalBgColor,
    backdropFilter: `blur(${theme.metrics.modalBackdropBlurRadius}px)`
  },
}

export type ActionModalProps = {
  visible: boolean;
  title?: string;
  text?: string;
  yesBtnText?: string;
  noBtnText?: string;
  onClose: () => void;
  onPressYes: () => void;
  onPressNo?: () => void;
};

const ActionModal: React.FC<ActionModalProps> = ({
  visible,
  title,
  text,
  yesBtnText,
  noBtnText,
  onPressNo,
  onClose,
  onPressYes
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => onClose()}
      contentLabel="Action modal"
      className={cn(s.actionModal, s["&__content"])}
      style={MODAL_CUSTOM_STYLE as any}
    >
      {title &&
        <p className={cn(s.title)}>
          {title}
        </p>
      }
      {text &&
        <p className={cn(s.text)}>
          {text}
        </p>
      }

      <Box className={cn(s.bottomRow)} style={{justifyContent: onPressNo ? "space-between" : "center"}}>
        <Box className={cn(s.btn)}>
          <CustomButton 
            bgColorActive={theme.colors.dodgerBlue}
            colorActive={theme.colors.white}
            title={yesBtnText ?? t("Common.yes")}
            onPress={() => {
              onPressYes();
              onClose();
            }}
          />
        </Box>

        {onPressNo &&
          <Box className={cn(s.btn)}>
            <CustomButton 
              bgColorActive={theme.colors.mandy}
              colorActive={theme.colors.white}
              title={noBtnText ?? t("Common.no")}
              onPress={() => {
                onPressNo();
                onClose();
              }}
            />
          </Box>
        }
      </Box>
    </Modal>
  );
};

ActionModal.defaultProps={}

const MemorizedComponent = memo(ActionModal);
export { MemorizedComponent as ActionModal };