import { DraggableLocation } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import { InstructionPublicFields, ParamType } from '../types/api';
import { getEventLastParamsFromLocalStorage } from './localStorage';
import { EventExecutionStatus, TestAccount } from '../types/types';
import { showToast } from './alertService';
import { validateExportableScenario } from './ajvValidators';
import i18next from 'i18next';

export type EventStateData = InstructionPublicFields & {
  params: {[key: string]: string};
  externalId?: number;
  touched?: boolean;
};

export type MailSub = {
  text: string;
  html: string;
  value: any[];
};

export type Mail = {
  attachments: any[];
  headerLines: any[];
  html: string;
  text: string;
  textAsHtml: string;
  subject: string;
  date: string;
  messageId: string;
  to: MailSub;
  from: MailSub;
};

export type DraggableInstuctionsState = {
  [key: string]: EventStateData[];
};

export type SentEventsExecutionStatusTable = {[key: string]: EventExecutionStatus};

export type ExportableScenario = {
  id: string;
  scenario: EventStateData[];
  title: string;
  description: string;
};

export type ExportableScenarioParseResult = {
  valid: boolean;
  parsedScenario: ExportableScenario | null;
}

export const remove = (list: any[], indexToRemove: number) => {
  const result = Array.from(list);
  result.splice(indexToRemove, 1);

  return result;
}

export const add = (destination: any[], indexToAdd: number, element: any) => {
  const result = Array.from(destination);
  result.splice(indexToAdd, 0, element);

  return result;
}

// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const copy = (source: any[], destination: any[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
  // console.log('==> dest', destination);

  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
};

export const move = (source: any[], destination: any[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation): DraggableInstuctionsState => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: DraggableInstuctionsState = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const prepareNewEventIfNeeded = (allInstructions: InstructionPublicFields[], droppableSource: DraggableLocation, currentTestAccount: TestAccount | null) => {
  // if (!currentTestAccount || allInstructions[droppableSource.index].prototype !== "login")
  //   return allInstructions;

  const allInstructionsCopy: InstructionPublicFields[] = JSON.parse(JSON.stringify(allInstructions));
  const newParams = {...allInstructionsCopy[droppableSource.index].params};

  if (allInstructions[droppableSource.index].prototype === "login" && currentTestAccount && allInstructionsCopy[droppableSource.index].parametersDescription) {
    for (const key of Object.keys(allInstructionsCopy[droppableSource.index].parametersDescription as {[key: string]: ParamType})) {
      // @ts-ignore
      if (currentTestAccount[key]) {
        // @ts-ignore
        newParams[key] = currentTestAccount[key];
        allInstructionsCopy[droppableSource.index].touched = true;
      } else if (currentTestAccount.additionalData?.[key]) {
        newParams[key] = currentTestAccount.additionalData[key];
        allInstructionsCopy[droppableSource.index].touched = true;
      }
    }

    // if (allInstructionsCopy[droppableSource.index].parametersDescription?.email) {
    //   newParams.email = currentTestAccount.email;
    //   allInstructionsCopy[droppableSource.index].touched = true;
    // }
    // if (allInstructions[droppableSource.index].parametersDescription?.pass) {
    //   newParams.pass = currentTestAccount.pass;
    //   allInstructionsCopy[droppableSource.index].touched = true;
    // }
  } else if (allInstructions[droppableSource.index].instructionId === "delay") {
    newParams.delayInMs = "1000";
    allInstructionsCopy[droppableSource.index].touched = true;
  }

  if (allInstructionsCopy[droppableSource.index].numOfArgs > 0) {
    const lastParams = getEventLastParamsFromLocalStorage(allInstructionsCopy[droppableSource.index].instructionId);
    allInstructionsCopy[droppableSource.index].lastParamsSaved = lastParams ? JSON.parse(lastParams) : undefined;
  }

  allInstructionsCopy[droppableSource.index] = {...allInstructionsCopy[droppableSource.index], params: newParams};

  return allInstructionsCopy;
};

export const prepareExportableScenario = (draggableInstuctionsState: DraggableInstuctionsState, title: string = "", description: string = ""): ExportableScenario => {
  const keys = Object.keys(draggableInstuctionsState);
  const scenario = draggableInstuctionsState[keys[0]];

  return {
    id: uuid(),
    scenario,
    title,
    description
  };
};

export const parseExportableScenario = (exportableScenario: ExportableScenario): ExportableScenarioParseResult => {
  try {
    const ok = validateExportableScenario(exportableScenario);

    if (!ok)
      throw new Error(i18next.t("Toast.invalidExportableScenario"));

    return {
      valid: true,
      parsedScenario: exportableScenario
    };
  } catch (e) {
    showToast(i18next.t("Toast.unableToParseScenario"), "warning");
    return {
      valid: false,
      parsedScenario: null
    };
  }
}

export const parseExportableScenarioFromString = (exportableScenarioString: string): ExportableScenarioParseResult => {
  try {
    return parseExportableScenario(JSON.parse(exportableScenarioString));
  } catch (e) {
    showToast("Unable to parse scenario.", "warning");
    return {
      valid: false,
      parsedScenario: null
    };
  }
}