import React from 'react';
import { Box } from '@material-ui/core';
import { metrics } from '~/theme/default/metrics';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';

export type HttpResponseStatusCodeIndicatorProps = {
  statusCode: number;
  size?: number;
  marginLeft?: number;
  marginBottom?: number;
};

const HttpResponseStatusCodeIndicator: React.FC<HttpResponseStatusCodeIndicatorProps> = ({
  statusCode,
  size,
  marginLeft,
  marginBottom
}) => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  return (
    <Box 
      style={{
        width: size,
        height: size,
        borderRadius: size! * 0.5,
        marginLeft,
        marginBottom,
        backgroundColor: statusCode >= 400 ? theme.colors.errorColor : theme.colors.successColor
      }}
      title={`${t("Other.statusCode")}: ${statusCode}`}
    />
  );
};

HttpResponseStatusCodeIndicator.defaultProps={
  size: 6,
  marginLeft: metrics.x2
}

export { HttpResponseStatusCodeIndicator };