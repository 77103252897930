import React from 'react';
import { useStyles } from './DraggableNavigationBarTabsStyles';
import { NavigationBarTab, NavigationBarTabCursor, NavigationBarTabT } from '../NavigationBarTab';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useThemeContext } from '~/theme';
import { NavigationTab } from '~/types/types';
import { useSelector } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import cn from 'classnames';

export type DraggableNavigationBarTabsProps = {
  droppableId: string;
  isDropDisabled?: boolean;
  isDragDisabled?: boolean;
  flexDirection?: "row" | "column";
  tabJustifyContent?: "center" | "flex-end";
  shouldDisplayNewIndicator?: (tabId: NavigationTab) => boolean;
  onPressTab?: (tabId: NavigationTab) => void;
  isDragDisabledForTab?: (tabId: NavigationTab) => boolean;
  tabs: NavigationBarTabT[];
  withHoverEffect?: boolean;
};

const DraggableNavigationBarTabs: React.FC<DraggableNavigationBarTabsProps> = ({
  droppableId,
  isDropDisabled,
  isDragDisabled,
  flexDirection,
  tabJustifyContent,
  shouldDisplayNewIndicator,
  onPressTab,
  isDragDisabledForTab,
  tabs,
  withHoverEffect
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const {
    navigationTab,
    editingNavBarTabs
  } = useSelector(userSelector);

  const getCursorType = (tabId: NavigationTab): NavigationBarTabCursor => {
    if (editingNavBarTabs) {
      if (isDragDisabledForTab?.(tabId))
        return "not-allowed";
      return "grab";
    }

    return !onPressTab ? "unset" : "pointer";
  }

  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled} direction={flexDirection === "row" ? "horizontal" : "vertical"}>
      {(droppableProvided, droppableSnapshot) => (
        <div 
          className={cn(s.container)} 
          style={{
            flexDirection,
            borderColor: droppableSnapshot.isDraggingOver ? theme.colors.droppableAreaBorderActive : theme.colors.droppableAreaBorderUnactive
          }}
          ref={droppableProvided.innerRef}
        >
          {tabs.map((item: NavigationBarTabT, index: number) => 
            (
              <Draggable
                key={`${droppableId}_${item.id}`}
                draggableId={`${droppableId}_${item.id}`}
                index={index}
                isDragDisabled={isDragDisabled || isDragDisabledForTab?.(item.id)}
              >
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={provided.draggableProps.style}
                      // className={cn({[s.marginBottom]: !isLastItem})}
                    >
                      <NavigationBarTab 
                        key={item.id} 
                        tab={item}
                        justifyContent={tabJustifyContent}
                        newIndicator={shouldDisplayNewIndicator ? shouldDisplayNewIndicator(item.id) : false}
                        isSelected={navigationTab === item.id}
                        onPress={onPressTab ? () => onPressTab(item.id) : undefined}
                        onPressDisabled={editingNavBarTabs}
                        cursor={getCursorType(item.id)}
                        isDragging={snapshot.isDragging}
                        withHoverEffect={withHoverEffect}
                      />
                    </div>
                  )
                }}
              </Draggable>
            )
          )}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

DraggableNavigationBarTabs.defaultProps={
  isDropDisabled: false,
  isDragDisabled: false,
  flexDirection: "row",
  tabJustifyContent: "center",
  withHoverEffect: true
}

export { DraggableNavigationBarTabs };