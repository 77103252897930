import React, { useState, useMemo } from 'react';
import { useStyles } from './PrivateTextStyles';
import { Box, IconButton } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ShowIcon, HideIcon } from '~/assets/icons';
import cn from 'classnames';

export type PrivateTextProps = {
  text: string;
  censorSymbol?: string; // • / ✱
  iconColor?: string;
};

const PrivateText: React.FC<PrivateTextProps> = ({
  text,
  censorSymbol,
  iconColor
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const censoredText = useMemo(() => {
    return censorSymbol!.repeat(text.length);
  }, [text, censorSymbol]);

  return (
    <Box className={cn(s.container)}>
      <p className={cn(s.privateText)}>
        {visible ? text : censoredText}
      </p>
      <IconButton 
        onClick={(e) => setVisible((v) => !v)} 
        size='small'
        className={cn(s.iconCont)}
        title={visible ? t("Common.hide") : t("Common.show")}
      >
        {visible ?
          <HideIcon color={iconColor ?? theme.colors.white}/>
        :
          <ShowIcon color={iconColor ?? theme.colors.white}/>
        }
      </IconButton>
    </Box>
  );
};

PrivateText.defaultProps={
  censorSymbol: "•"
}

export { PrivateText };