import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 8,
    paddingLeft: 8
  },
  titleText: {
    pointerEvents: 'none',
    fontFamily: theme.fonts.f600.fontFamily,
    paddingTop: theme.metrics.x
  }
});