import moment from "moment";
import { TimelineItem } from "~/types/types";

export const computeItemKey = (index: number, item: TimelineItem) => {
  if ("actionId" in item)
    return `ntl_${item.actionId}`;
  if ("requestId" in item)
    return `ntl_${item.requestId}`;
  if ("storageActionId" in item)
    return `ntl_${item.storageActionId}`;
  if ("capturedEventId" in item)
    return `ntl_${item.capturedEventId}`;
  if ("tanStackQueryEventId" in item)
    return `ntl_${item.tanStackQueryEventId}`;
  if ("mobxEventId" in item)
    return `ntl_${item.mobxEventId}`;
  if ("crashReportId" in item)
    return `ntl_${item.crashReportId}`;

  return `ntl_unknown_${index}`;
}

export const getTimelineItemMainTimestamp = (item: TimelineItem): number => {
  if ("actionId" in item || "storageActionId" in item || "capturedEventId" in item || "tanStackQueryEventId" in item || "mobxEventId" in item || "crashReportId" in item)
    return item.timestamp;
  if ("requestId" in item)
    return (item.timestampRequest ?? item.timestampResponse) as number;

  return moment().valueOf();
};

export const getTimeSincePrevEvent = (item: TimelineItem, prevItem?: TimelineItem): string | undefined => {
  if (!prevItem)
    return undefined;

  const itemTimestamp = getTimelineItemMainTimestamp(item);
  const prevItemTimestamp = getTimelineItemMainTimestamp(prevItem);

  const timeGapMs = itemTimestamp - prevItemTimestamp;

  if (timeGapMs < 0)
    return undefined;

  return `+${timeGapMs}ms`;
}