import { 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import { handleFoldChange, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { deleteContextsPinnedPathsFromLocalStorage, saveContextsPinnedPathsToLocalStorage } from "~/helpers/localStorage";
import { showToast } from "~/helpers/alertService";
import { ContextValueCopy, ObjectT } from '~/types/types';
import i18next from 'i18next';
import _ from "lodash";

const MAX_PINNED_PATHS = 20;

type HandleFoldChangePayload = {
  contextId: string;
  lineIndex: number;
  newOpened: boolean;
};

type PinPathPayload = {
  contextId: string;
  path: string;
  apiKey: string;
}

type UnpinPathPayload = {
  contextId: string;
  path: string;
  apiKey: string;
}

type ClearPinnedPathsPayload = {
  apiKey: string;
}

export type ContextValueByContextIdTable = ObjectT<ContextValueCopy | undefined>;

type UpdateContextValueByContextIdPayload = {
  contextId: string;
  value: ObjectT<any>;
  timestamp: number;
};

type OurContextsInspectorState = {
  viewingContextId: string | undefined;
  contextValueByContextIdTable: ContextValueByContextIdTable;
  contextValueCopyNumOfLinesByContextIdTable: ObjectT<number | undefined>;
  contextValueCopyTimestampByContextIdTable: ObjectT<number | undefined>;
  pinnedPathsByContextIdTable: ObjectT<string[] | undefined>;
  linesWithMarkupByContextIdTable: ObjectT<LinesWithMarkup[] | undefined>;
  gotSomethingNewIndicator: boolean;
};

const initialOurContextsInspectorState: OurContextsInspectorState = {
  viewingContextId: undefined,
  contextValueByContextIdTable: {},
  contextValueCopyNumOfLinesByContextIdTable: {},
  contextValueCopyTimestampByContextIdTable: {},
  pinnedPathsByContextIdTable: {},
  linesWithMarkupByContextIdTable: {},
  gotSomethingNewIndicator: false
};

const ourContextsInspectorSlice = createSlice({
  name: 'ourContextsInspector',
  initialState: initialOurContextsInspectorState,
  reducers: {
    setViewingContextId: {
      reducer(state, action: PayloadAction<string | undefined>) {
        state.viewingContextId = action.payload;
      },
      prepare: prepareAutoBatched<string | undefined>()
    },
    updateContextValueByContextId: {
      reducer(state, action: PayloadAction<UpdateContextValueByContextIdPayload>) {
        const { contextId, value, timestamp } = action.payload;

        const contextValueCopy = {value};
        const s = JSON.stringify(contextValueCopy, null, 2);
        let prevData;
        if (state.contextValueByContextIdTable[contextId] && state.linesWithMarkupByContextIdTable[contextId]?.length) {
          prevData = {
            linesWithMarkup: state.linesWithMarkupByContextIdTable[contextId] as LinesWithMarkup[]
          }
        }

        const newLinesWithMarkup = markupJSON(s, contextValueCopy, prevData);
        state.linesWithMarkupByContextIdTable[contextId] = newLinesWithMarkup;
        state.contextValueByContextIdTable[contextId] = contextValueCopy;
        state.contextValueCopyNumOfLinesByContextIdTable[contextId] = newLinesWithMarkup.length;
        state.contextValueCopyTimestampByContextIdTable[contextId] = timestamp;

        if (!state.viewingContextId)
          state.viewingContextId = contextId;
      },
      prepare: prepareAutoBatched<UpdateContextValueByContextIdPayload>()
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    handleInspectorFoldChange(state, action: PayloadAction<HandleFoldChangePayload>) {
      const { contextId, lineIndex, newOpened } = action.payload;

      if (state.linesWithMarkupByContextIdTable[contextId]) {
        state.linesWithMarkupByContextIdTable[contextId] = handleFoldChange(state.linesWithMarkupByContextIdTable[contextId]!, lineIndex, newOpened);
      }
    },
    setPinnedPathsByContextIdTable(state, action: PayloadAction<ObjectT<string[]>>) {
      state.pinnedPathsByContextIdTable = action.payload;
    },
    pinPath(state, action: PayloadAction<PinPathPayload>) {
      const { contextId } = action.payload;

      if (state.pinnedPathsByContextIdTable[contextId] === undefined)
        state.pinnedPathsByContextIdTable[contextId] = [];

      if (state.pinnedPathsByContextIdTable[contextId]!.length === MAX_PINNED_PATHS) {
        showToast(`${i18next.t("Toast.pinsLimitReached")} ${i18next.t("Toast.youCannotPinMoreThan")} ${MAX_PINNED_PATHS} ${i18next.t("Common.objects")}.`, "warning");
        return;
      }

      const pinnedPathsSet = new Set(state.pinnedPathsByContextIdTable[contextId]);
      pinnedPathsSet.add(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPathsByContextIdTable[contextId] = pinnedPaths;
      saveContextsPinnedPathsToLocalStorage(action.payload.apiKey, state.pinnedPathsByContextIdTable);
    },
    unpinPath(state, action: PayloadAction<UnpinPathPayload>) {
      const { contextId } = action.payload;

      const pinnedPathsSet = new Set(state.pinnedPathsByContextIdTable[contextId] ?? []);
      pinnedPathsSet.delete(action.payload.path);

      const pinnedPaths = Array.from(pinnedPathsSet);
      state.pinnedPathsByContextIdTable[contextId] = pinnedPaths;
      saveContextsPinnedPathsToLocalStorage(action.payload.apiKey, state.pinnedPathsByContextIdTable);
    },
    clearPinnedPaths(state, action: PayloadAction<ClearPinnedPathsPayload>) {
      state.pinnedPathsByContextIdTable = {};
      deleteContextsPinnedPathsFromLocalStorage(action.payload.apiKey);
    },
    clearAllState: {
      reducer() {
        return {
          ...initialOurContextsInspectorState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearContextsInspector: {
      reducer(state) {
        state.viewingContextId = initialOurContextsInspectorState.viewingContextId;
        state.contextValueByContextIdTable = initialOurContextsInspectorState.contextValueByContextIdTable;
        state.contextValueCopyNumOfLinesByContextIdTable = initialOurContextsInspectorState.contextValueCopyNumOfLinesByContextIdTable;
        state.contextValueCopyTimestampByContextIdTable = initialOurContextsInspectorState.contextValueCopyTimestampByContextIdTable;
        state.linesWithMarkupByContextIdTable = initialOurContextsInspectorState.linesWithMarkupByContextIdTable;
        state.gotSomethingNewIndicator = initialOurContextsInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearSingleContextInspectorById: {
      reducer(state, action: PayloadAction<string>) {
        const contextIds = Object.keys(state.contextValueByContextIdTable);

        state.viewingContextId = contextIds.find((id) => id !== action.payload);

        state.contextValueByContextIdTable = _.omit(state.contextValueByContextIdTable, action.payload);
        state.contextValueCopyNumOfLinesByContextIdTable = _.omit(state.contextValueCopyNumOfLinesByContextIdTable, action.payload);
        state.contextValueCopyTimestampByContextIdTable = _.omit(state.contextValueCopyTimestampByContextIdTable, action.payload);
        state.linesWithMarkupByContextIdTable = _.omit(state.linesWithMarkupByContextIdTable, action.payload);
        state.gotSomethingNewIndicator = initialOurContextsInspectorState.gotSomethingNewIndicator;
      },
      prepare: prepareAutoBatched<string>()
    }
  }
})

const ourContextsInspectorReducer = ourContextsInspectorSlice.reducer;
const OurContextsInspectorActions = ourContextsInspectorSlice.actions;

export { ourContextsInspectorReducer, OurContextsInspectorActions };