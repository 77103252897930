import { makeStyles } from '@material-ui/core/styles';
import { STATE_INSPECTOR_HEADER_HEIGHT } from '~/components/StateInspectorHeader';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    padding: 12,
  },
  syntaxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    borderRadius: 8,
    overflow: "hidden",
  },
  dataContainer: {
    display: "flex",
    width: '100%',
    height: `calc(100% - ${STATE_INSPECTOR_HEADER_HEIGHT}px)`,
  },
  whiteText: {
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f700.fontFamily
  },
  tanStackQueryIcon: {
    display: "flex",
    height: 36,
    width: 41,
    marginBottom: theme.metrics.x3
  }
});