import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill={props.color}
  >
    <path d="M4 0v3H1v12h12v-3h3V0Zm7.2 13.2H2.8V4.8H4V12h7.2Zm3.4-2.6H6.39l5.11-5.1L13 7V3H9l1.5 1.5-5.1 5.11V1.4h9.2Z" />
  </svg>
)
export { SvgComponent as NewWindowIcon }
