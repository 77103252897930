import { NavigationBarTabT } from "../NavigationBarTab";
import { ALL_NAVIGATION_BAR_TABS } from "./constants";

export const validateSavedNavBarTabs = (saved: NavigationBarTabT[]) => {
  for (const tab of saved) {
    const found = ALL_NAVIGATION_BAR_TABS.find((t) => t.id === tab.id);
    if (!found || found.defaultIndex !== tab.defaultIndex)
      return false;
  }

  return true;
};