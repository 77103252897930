import React, { Fragment, useState } from 'react';
import { useStyles } from './ReduxInspectorStyles';
import { useReduxInspectorAnalytics } from './useReduxInspectorAnalytics';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { Box, Typography } from '@material-ui/core';
import { StateInspectorHeader } from '~/components/StateInspectorHeader';
import { PinnedPathsContainer } from '~/components/PinnedPathsContainer';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { ReduxIcon } from '~/assets/icons';
import { useThemeContext } from '~/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourReduxInspectorSelector } from '~/logic/ourReduxInspector/OurReduxInspectorSelectors';
import { OurReduxInspectorActions } from '~/logic/ourReduxInspector/OurReduxInspectorSlice';
import cn from 'classnames';

export type ReduxInspectorProps = {};

const ReduxInspector: React.FC<ReduxInspectorProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey
  } = useSelector(userSelector);

  const {
    reduxStateCopy,
    linesWithMarkup,
    reduxStateCopyNumOfLines,
    reduxStateCopyTimestamp,
    pinnedPaths
  } = useSelector(ourReduxInspectorSelector);

  const {
    trackUserChangedViewMode
  } = useReduxInspectorAnalytics();

  const [pinnedMode, setPinnedMode] = useState(false);

  const onPressUnpin = (path: string) => {
    dispatch(OurReduxInspectorActions.unpinPath({path, apiKey}));
  }

  return (
    <Fragment>
      {reduxStateCopy ?
        <Box className={s?.syntaxContainer}>
          <StateInspectorHeader 
            pinnedMode={pinnedMode}
            stateCopyNumOfLines={reduxStateCopyNumOfLines}
            stateCopyTimestamp={reduxStateCopyTimestamp}
            stateManagerName={"redux"}
            onPress={() => {
              trackUserChangedViewMode(pinnedMode ? "default" : "pinned");
              setPinnedMode((v) => !v)
            }}
            onPressClear={() => dispatch(OurReduxInspectorActions.clearReduxInspector())}
            floatingViewContent={"ReduxInspector"}
          />
          <Box className={cn(s.dataContainer)}>
            {pinnedMode ?
              <PinnedPathsContainer 
                stateCopy={reduxStateCopy}
                pinnedPaths={pinnedPaths}
                onPressUnpin={onPressUnpin}
                icon={<ReduxIcon fill={theme.colors.textBright} />}
              />
            :
              <SyntaxHighlighter 
                code={JSON.stringify(reduxStateCopy, null, 2)}
                linesWithMarkup={linesWithMarkup}
                customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
                enableFolding={true}
                virtualize={true}
                withPins={true}
                pinnedPaths={pinnedPaths}
                borderRadius={0}
                onInspectorFoldChange={(lineIndex, newOpened) => dispatch(OurReduxInspectorActions.handleInspectorFoldChange({lineIndex, newOpened}))}
                onPinPath={(path) => dispatch(OurReduxInspectorActions.pinPath({path, apiKey}))}
                onUnpinPath={(path) => dispatch(OurReduxInspectorActions.unpinPath({path, apiKey}))}
              />
            }
          </Box>
        </Box>
      :
        <Box className={s?.syntaxContainer}>
          <Box className={ds.fullSpaceCenteredCol}>
            <Box className={cn(s.reduxIcon)}>
              <ReduxIcon fill={theme.colors.textBright}/>
            </Box>
            <Typography variant="h5" className={cn(s.whiteText)}>
              {`Redux state ${t("MainPage.StateManagerTab.willBeDisplayedHere")}`}
            </Typography>
          </Box>
        </Box>
      }
    </Fragment>
  );
};

ReduxInspector.defaultProps={}

export { ReduxInspector };