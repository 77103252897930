import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
  >
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M1.359 8.597c-.692 1.265-.692 2.79-.692 5.842v2.028c0 5.2 0 7.801 1.562 9.417C3.79 27.5 6.305 27.5 11.333 27.5h5.334c5.028 0 7.542 0 9.104-1.616 1.562-1.616 1.562-4.216 1.562-9.417v-2.028c0-3.052 0-4.577-.692-5.842-.692-1.265-1.957-2.05-4.486-3.62l-2.667-1.654C16.814 1.663 15.478.833 14 .833c-1.477 0-2.814.83-5.488 2.49L5.845 4.978C3.316 6.548 2.051 7.332 1.36 8.597Z"
      clipRule="evenodd"
    />
  </svg>
)

export { SvgComponent as MainIconV3 };