import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const TEST_ACCOUNT_HEIGHT = 56;

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.metrics.x3,
    height: TEST_ACCOUNT_HEIGHT,
    minWidth: 150,
    backgroundColor: theme.colors.bgQuaternary,
    cursor: 'pointer',
    borderRadius: 8
  },
  whiteText: {
    margin: 0,
    color: theme.colors.textMain,
    fontSize: 12,
    fontFamily: theme.fonts.f400.fontFamily
  },
  whiteTextBold: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily
  }
});