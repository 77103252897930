import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const CLIENT_SELECTOR_MAIN_HEIGHT = 64;
export const CLIENT_SELECTOR_HANDLE_HEIGHT = 20;
export const CLIENT_SELECTOR_HANDLE_WIDTH = 260;
export const CLIENT_SELECTOR_HANDLE_BORDER_RADIUS = 12;
export const CLIENT_SELECTOR_BORDER_WIDTH = 1;

export const useStyles = (theme: Theme) => makeStyles({
  handle: {
    display: 'flex',
    width: CLIENT_SELECTOR_HANDLE_WIDTH,
    height: CLIENT_SELECTOR_HANDLE_HEIGHT,
    backgroundColor: theme.colors.bgTetriary,
    // backgroundColor: 'red',
    position: 'absolute',
    top: -CLIENT_SELECTOR_HANDLE_HEIGHT,
    borderTopLeftRadius: CLIENT_SELECTOR_HANDLE_BORDER_RADIUS,
    borderTopRightRadius: CLIENT_SELECTOR_HANDLE_BORDER_RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    cursor: 'pointer',
    border: `${CLIENT_SELECTOR_BORDER_WIDTH}px solid ${theme.colors.borderSecondary}`,
    borderBottomWidth: 0
  },
  handleCircle: {
    display: 'flex',
    position: 'absolute',
    width: CLIENT_SELECTOR_HANDLE_HEIGHT,
    height: CLIENT_SELECTOR_HANDLE_HEIGHT * 0.5,
  },
  handleCircleLeft: {
    bottom: 0,
    left: -CLIENT_SELECTOR_HANDLE_HEIGHT,
    borderRadius: `0 0 ${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0`,
    WebkitBorderRadius: `0 0 ${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0`,
    WebkitBoxShadow: `${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0 0 0 ${theme.colors.bgTetriary}`,
    boxShadow: `${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0 0 0 ${theme.colors.bgTetriary}`,
    border: `${CLIENT_SELECTOR_BORDER_WIDTH}px solid ${theme.colors.borderSecondary}`,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  handleCircleRight: {
    bottom: 0,
    right: -CLIENT_SELECTOR_HANDLE_HEIGHT,
    borderRadius: `0 0 0 ${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px`,
    WebkitBorderRadius: `0 0 0 ${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px`,
    WebkitBoxShadow: `-${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0 0 0 ${theme.colors.bgTetriary}`,
    boxShadow: `-${CLIENT_SELECTOR_HANDLE_BORDER_RADIUS}px 0 0 0 ${theme.colors.bgTetriary}`,
    border: `${CLIENT_SELECTOR_BORDER_WIDTH}px solid ${theme.colors.borderSecondary}`,
    borderRightWidth: 0,
    borderTopWidth: 0
  },
  handleLeftButtonsRow: {
    display: 'flex',
    position: 'absolute',
    left: theme.metrics.x,
    alignItems: 'center'
    // backgroundColor: 'blue'
  },
  refreshIcon: {
    width: 20,
    height: 20
  },
  trashIcon: {
    width: 18,
    height: 18,
  },
  clientsText: {
    color: theme.colors.textMain,
    fontSize: 12,
    userSelect: "none",
    pointerEvents: "none"
  },
  statusIndicatorContainer: {
    display: 'flex',
    position: 'absolute',
    right: theme.metrics.x2
  },
  clientsContainer: {
    display: 'flex',
    height: '100%',
    minWidth: '100%',
    overflowX: "scroll",
    // backgroundColor: 'red',
    borderRadius: theme.metrics.x2
  }
});