import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: 'blue'
  },
  fullTextCont: {
    display: "flex",
    marginLeft: theme.metrics.x,
    paddingRight: theme.metrics.x4,
    cursor: "pointer",
    height: '100%',
    alignItems: "center"
  },
  fullText: {
    color: theme.colors.bgContrastColorText,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0,
    userSelect: "none",
    fontSize: 14
  }
});