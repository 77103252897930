import * as React from "react"

const SvgComponent = (props) => (
  <svg
    {...props}
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12h16M10 6l-5.938 5.938v0a.088.088 0 0 0 0 .124v0L10 18"
      stroke={props.color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
