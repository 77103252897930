import { ClientConnectedData } from "~/types/api";
import { DeviceType } from "./types";
import { capitalizeString } from "~/helpers/strings";

export const prepareClientPreviewItemInfo = (connectionInfoPacket: ClientConnectedData): {
  type: DeviceType,
  title: string,
  description: string
} => {
  let type: DeviceType = "unknown";
  let title = connectionInfoPacket.socketId.slice(0, 8);
  let description = "No description";

  if (connectionInfoPacket.environmentInfo.browserName) {
    type = connectionInfoPacket.environmentInfo.browserName.toLowerCase().includes("chrome") ? "chrome" : "web";
  } else if (connectionInfoPacket.environmentInfo.OS) {
    switch (connectionInfoPacket.environmentInfo.OS.toLowerCase()) {
      case "ios":
        type = "ios";
        break;
      case "android":
        type = "android";
        break;
      case "macos":
        type = "apple";
        break;
      case "windows":
        type = "windows";
        break;
      case "linux":
        type = "linux";
        break;
    
      default:
        break;
    }
  }

  if (connectionInfoPacket.environmentInfo.browserName) {
    title = connectionInfoPacket.environmentInfo.browserName;
  } else {
    if (connectionInfoPacket.environmentInfo.Model)
      title = connectionInfoPacket.environmentInfo.Model;
    else if (connectionInfoPacket.environmentInfo.interfaceIdiom)
      title = capitalizeString(connectionInfoPacket.environmentInfo.interfaceIdiom);
    else if (connectionInfoPacket.environmentInfo.USER)
      title = capitalizeString(connectionInfoPacket.environmentInfo.USER);
  }

  if (connectionInfoPacket.environmentInfo.OS)
    description = connectionInfoPacket.environmentInfo.OS;

  if (connectionInfoPacket.environmentInfo.Version)
    description += ` ${connectionInfoPacket.environmentInfo.Version}`;
  else if (connectionInfoPacket.environmentInfo.osVersion)
    description += ` ${connectionInfoPacket.environmentInfo.osVersion}`;

  return {
    type,
    title,
    description
  };
};