import React, { memo, useState, useEffect } from 'react';
import { useStyles } from './CreateProjectModalStyles';
import { Box, Button } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import { CustomModal, CustomModalProps } from "~/components/CustomModal";
import { useDispatch, useSelector } from 'react-redux';
import { RemoteSettingsActions } from '~/logic/remoteSettings/RemoteSettingsSlice';
import { TextInput } from '~/components/TextInput';
import { CustomButton } from '~/components/CustomButton';
import { StringValueItem } from '~/components/StringValueItem';
import { Project } from '~/types/types';
import { remoteSettingsSelector } from '~/logic/remoteSettings/RemoteSettingsSelectors';
import cn from 'classnames';

export type CreateProjectModalProps = CustomModalProps & {
  project?: Project | null;
  onPressAddMember: () => void;
  onPressRemoveMember: () => void;
};

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  project,
  onPressAddMember,
  onPressRemoveMember,
  ...restProps
}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();

  const {
    createProject: {
      fetching: fetchingCreateProject
    },
    editProject: {
      fetching: fetchingEditProject
    }
  } = useSelector(remoteSettingsSelector);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const handleUpdateProject = () => {
    if (project) {
      if (name !== project.name || description !== project.description || image !== project.image) {
        dispatch(RemoteSettingsActions.editProject.request({
          id: project._id,
          name,
          description,
          image,
          successCallback: restProps.onClose
        }));
      }
    } else {
      dispatch(RemoteSettingsActions.createProject.request({
        name,
        description,
        image: image.length ? image : undefined,
        successCallback: restProps.onClose
      }));
    }
  }

  useEffect(() => {
    setName(project?.name ?? "");
    setDescription(project?.description ?? "");
    setImage(project?.image ?? "");
  }, [project]);

  return (
    <CustomModal 
      {...restProps} 
      classname={cn(s.modal)} 
      title={project ? `Edit project: ${project.name}` : "Create new project"}
    >
      <Box className={cn(s.container)}>
        <Box className={cn(ds.fullWidthCenteredCol)}>
          <Box className={cn(ds.inputContainterMarginBottom)}>
            <TextInput 
              placeholder={"My awesome project"}
              label={"Project name"}
              labelColor={theme.colors.textMain}
              value={name}
              onChangeText={(text) => setName(text)}
            />
          </Box>
          <Box className={cn(ds.inputContainterMarginBottom)}>
            <TextInput 
              placeholder={"Some useful description"}
              label={"Project description"}
              labelColor={theme.colors.textMain}
              value={description}
              onChangeText={(text) => setDescription(text)}
            />
          </Box>
          <Box className={cn(ds.inputContainterMarginBottom)}>
            <TextInput 
              placeholder={"http://mySite.com/images/0"}
              label={"Logo"}
              labelColor={theme.colors.textMain}
              value={image}
              onChangeText={(text) => setImage(text)}
            />
          </Box>
          {(!!project && project.members.length > 0) &&
            <Box className={cn(s.membersCont)}>
              <p className={cn(s.brightText)}>Members:</p>
              {project.members.map((member) => (
                <StringValueItem 
                  key={member._id}
                  label={member.email}
                  value={member.apiKey}
                />
              ))}
            </Box>
          }
          {!!project &&
            <Box className={cn(ds.fullWidthCenteredRow)}>
              <Button 
                variant="contained" 
                className={cn(s.memberButton)}
                onClick={onPressAddMember}
              >
                Add member
              </Button>
              <Button 
                variant="contained" 
                className={cn(s.memberButton)}
                onClick={onPressRemoveMember}
              >
                Remove member
              </Button>
            </Box>
          }
        </Box>

        <Box className={cn(s.bottomButton)}>
          <CustomButton 
            title={project ? 'Save' : 'Create'}
            onPress={handleUpdateProject}
            spinner={project ? fetchingEditProject : fetchingCreateProject}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

CreateProjectModal.defaultProps={}

const MemorizedComponent = memo(CreateProjectModal);
export { MemorizedComponent as CreateProjectModal };