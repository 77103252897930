import React, { useState } from 'react';
import { useStyles } from './ZustandTabStyles';
import { STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE } from '~/constants/syntaxHighlighterStyles';
import { useZustandTabAnalytics } from './useZustandTabAnalytics';
import { Box, Typography } from '@material-ui/core';
import { StateInspectorHeader } from '~/components/StateInspectorHeader';
import { PinnedPathsContainer } from '~/components/PinnedPathsContainer';
import { SyntaxHighlighter } from '~/components/SyntaxHighlighter';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '~/logic/user/UserSelectors';
import { ourZustandInspectorSelector } from '~/logic/ourZustandInspector/OurZustandInspectorSelectors';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { ZustandIcon } from '~/assets/icons';
import { OurZustandInspectorActions } from '~/logic/ourZustandInspector/OurZustandInspectorSlice';
import cn from 'classnames';

export type ZustandTabProps = {};

const ZustandTab: React.FC<ZustandTabProps> = ({}) => {
  const { theme, ds } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    apiKey
  } = useSelector(userSelector);

  const {
    zustandStateCopy,
    linesWithMarkup,
    zustandStateCopyNumOfLines,
    zustandStateCopyTimestamp,
    pinnedPaths
  } = useSelector(ourZustandInspectorSelector);

  const {
    trackUserChangedViewMode
  } = useZustandTabAnalytics();

  const [pinnedMode, setPinnedMode] = useState(false);

  const onPressUnpin = (path: string) => {
    dispatch(OurZustandInspectorActions.unpinPath({path, apiKey}));
  }

  return (
    <Box className={cn(s.container)}>
      {zustandStateCopy ?
        <Box className={s?.syntaxContainer}>
          <StateInspectorHeader 
            pinnedMode={pinnedMode}
            stateCopyNumOfLines={zustandStateCopyNumOfLines}
            stateCopyTimestamp={zustandStateCopyTimestamp}
            stateManagerName={"zustand"}
            onPress={() => {
              trackUserChangedViewMode(pinnedMode ? "default" : "pinned");
              setPinnedMode((v) => !v);
            }}
            onPressClear={() => dispatch(OurZustandInspectorActions.clearZustandInspector())}
          />
          <Box className={cn(s.dataContainer)}>
            {pinnedMode ?
              <PinnedPathsContainer 
                stateCopy={zustandStateCopy}
                pinnedPaths={pinnedPaths}
                onPressUnpin={onPressUnpin}
                icon={<ZustandIcon fill={theme.colors.textBright} />}
              />
            :
              <SyntaxHighlighter 
                code={JSON.stringify(zustandStateCopy, null, 2)}
                linesWithMarkup={linesWithMarkup}
                customStyle={{backgroundColor: theme.colors.bgSenary, ...STATE_INSPECTOR_DEFAULT_SYNTAX_HIGHLIGHTER_STYLE}}
                enableFolding={true}
                virtualize={true}
                withPins={true}
                pinnedPaths={pinnedPaths}
                borderRadius={0}
                onInspectorFoldChange={(lineIndex, newOpened) => dispatch(OurZustandInspectorActions.handleInspectorFoldChange({lineIndex, newOpened}))}
                onPinPath={(path) => dispatch(OurZustandInspectorActions.pinPath({path, apiKey}))}
                onUnpinPath={(path) => dispatch(OurZustandInspectorActions.unpinPath({path, apiKey}))}
              />
            }
          </Box>
        </Box>
      :
        <Box className={s?.syntaxContainer}>
          <Box className={ds.fullSpaceCenteredCol}>
            <Box className={cn(s.zustandIcon)}>
              <ZustandIcon fill={theme.colors.textBright}/>
            </Box>
            <Typography variant="h5" className={cn(s.whiteText)}>
              {`Zustand state ${t("MainPage.StateManagerTab.willBeDisplayedHere")}`}
            </Typography>
          </Box>
        </Box>
      }
    </Box>
  );
};

ZustandTab.defaultProps={}

export { ZustandTab };