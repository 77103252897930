import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    width: '40vw',
    height: '60vh',
    paddingLeft: theme.metrics.x4,
    paddingRight: theme.metrics.x4,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    // width: 200,
    // height: 200,
    backgroundColor: theme.colors.globalBg,
    overflow: 'scroll'
  },
  bottomButton: {
    display: 'flex',
    width: '100%',
    paddingLeft: theme.metrics.x6,
    paddingRight: theme.metrics.x6,
    marginTop: theme.metrics.x3,
  },
  memberButton: {
    marginRight: theme.metrics.x3,
    marginLeft: theme.metrics.x3,
    fontFamily: theme.fonts.f600.fontFamily
  },
  membersCont: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.metrics.x,
    marginBottom: theme.metrics.x4
  },
  brightText: {
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.textBright,
    margin: 0
  },
  mainText: {
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.textMain,
    margin: 0
  }
});