import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';
import { FLOATING_VIEW_ANIMATIONS_DURATIONS, FLOATING_VIEW_SHORTCUT_POSITION } from '~/providers';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: theme.metrics.x12,
    height: theme.metrics.x12,
    borderRadius: theme.metrics.x2,
    position: "absolute",
    bottom: FLOATING_VIEW_SHORTCUT_POSITION.BOTTOM,
    right: FLOATING_VIEW_SHORTCUT_POSITION.RIGHT,
    backgroundColor: theme.colors.bgQuaternary,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    // WebkitBoxShadow: `${20}px 0 0 0 ${theme.colors.bgTetriary}`,
    // boxShadow: `${20}px 0 0 0 ${theme.colors.bgTetriary}`,
    transformOrigin: "bottom right",
    transition: `transform ${FLOATING_VIEW_ANIMATIONS_DURATIONS.SHORTCUT_ENTRY.CSS} linear`,
    transform: "scale(0)"
  },
  appeared: {
    transform: "scale(1)"
  }
});