import { io, Socket } from "socket.io-client";
import { CONFIG } from '~/config';
import { singletonClass } from '~/decorators';
import { NavigateFunction } from "react-router-dom";
import { SOCKET_EVENTS_EMIT, SOCKET_EVENTS_LISTEN } from '~/api/api';
import { ClientConnectedData, ConnectionInfoPacket, InstructionPublicFields } from '~/types/api';
import { createSerializeableEvent as createSerializeableRemoteEvent } from '~/classes/Event';
import { Scenario as RemoteScenario } from '~/classes/Scenario';
import { store } from '~/store/store';
import { UserActions } from "../logic/user/UserSlice";
import { DraggableInstuctionsState, EventStateData, Mail } from "../helpers/draggableHelpers";
import { prepareParams, prettifyTanStackQueriesData } from "../helpers/strings";
import { validateApiKey } from "../helpers/validationHelper";
import { getReduxPinnedPathsFromLocalStorage, getZustandPinnedPathsFromLocalStorage, getTanStackQueryPinnedPathsFromLocalStorage, getContextsPinnedPathsFromLocalStorage, getMobxPinnedPathsFromLocalStorage, getScenariosCollectionFromLocalStorage, saveEventLastParamsToLocalStorage } from "../helpers/localStorage";
import { OurReduxInspectorActions } from "../logic/ourReduxInspector/OurReduxInspectorSlice";
import { NetworkActions } from "../logic/network/NetworkSlice";
import { RemoteSettingsActions } from "~/logic/remoteSettings/RemoteSettingsSlice";
import * as T from "../types/types";
import { box } from 'tweetnacl';
import { encodeUTF8, decodeBase64 } from 'tweetnacl-util';
import { OurZustandInspectorActions } from "~/logic/ourZustandInspector/OurZustandInspectorSlice";
import { OurTanStackQueryInspectorActions } from "~/logic/ourTanStackQueryInspector/ourTanStackQueryInspectorSlice";
import { OurContextsInspectorActions } from "~/logic/ourContextsInspector/OurContextsInspectorSlice";
import { OurMobxInspectorActions } from "~/logic/ourMobxInspector/OurMobxInspectorSlice";
import { ERRORS_500_PROBLEMS } from "~/constants/criticalProblems";

@singletonClass
class SocketService {
  private _initCallsCounter: number = 0;
  private _navigateFn: NavigateFunction | undefined;
  private _apiKey: string = "";
  private _socket: Socket | undefined;
  private _connectionInfoPacket?: ConnectionInfoPacket;
  private _availableInstructions?: InstructionPublicFields[];
  private _specialInstructions?: InstructionPublicFields[];
  private _keyPair: nacl.BoxKeyPair;
  private _enableEncryption: boolean = false;
  private _clientPublicKey: Uint8Array | null = null;
  private _sharedKey: Uint8Array | null = null;
  private _reconnectionAttemptsCounter: number = 0;
  private _reconnectionDelay: number = CONFIG.SOCKET_RECONNECTION_DELAY;
  private _connectErrorsCounter: number = 0;
  private _onSuccessConnectCallback: (() => void) | undefined;

  private _decryptData(messageWithNonce: string) {
    try {
      if (!this._enableEncryption)
        return JSON.parse(messageWithNonce);

      if (!this._sharedKey) {
        try {
          // If data is actually not encrypted (for example, quickly changed for another client which doesnt use encryption)
          const parsedData = JSON.parse(messageWithNonce);
          return parsedData;
        } catch (e) {
          throw new Error('Shared key not generated');
        }
      }

      const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
      const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);

      const message = messageWithNonceAsUint8Array.slice(box.nonceLength, messageWithNonce.length);
      const decrypted = box.open.after(message, nonce, this._sharedKey);

      if (!decrypted) {
        throw new Error('Could not decrypt message');
      }

      const base64DecryptedMessage = encodeUTF8(decrypted);
      return JSON.parse(base64DecryptedMessage);
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  private clearConnectionData(clearRedux: boolean = false) {
    this._availableInstructions = undefined;
    this._specialInstructions = undefined;
    this._enableEncryption = false;
    this._clientPublicKey = null;
    this._sharedKey = null;
    store.dispatch(UserActions.clearAllInstructions());

    if (clearRedux) {
      // Clear User Redux
      store.dispatch(UserActions.setBothSidesConnected(false));
      store.dispatch(UserActions.setConnectedClientProjectId(undefined));
      store.dispatch(UserActions.setConnectedClientEnvironmentInfo({}));
      store.dispatch(UserActions.setConnectedClientSocketId(undefined));
      store.dispatch(UserActions.setConnectedClientSocketRouteId(undefined));
      // store.dispatch(UserActions.setGotSomethingNewIndicator(false)); Следует прятать индикатор если мы также будем чистить логи main tab(а)
      store.dispatch(UserActions.clearConstructorState());
      store.dispatch(UserActions.setViewingStorageSnapshot(null));

      // Clear OurReduxInspector Redux
      store.dispatch(OurReduxInspectorActions.clearReduxInspector());

      // Clear OurZustandInspector Redux
      store.dispatch(OurZustandInspectorActions.clearZustandInspector());

      // Clear OurTanStackQueryInspector Redux
      store.dispatch(OurTanStackQueryInspectorActions.clearTanStackQueryInspector());

      // Clear OurContextsInspector Redux
      store.dispatch(OurContextsInspectorActions.clearContextsInspector());

      // Clear Network Redux
      store.dispatch(NetworkActions.clearAllState());
    }
  }

  private _getReconnectionDelay() {
    this._reconnectionAttemptsCounter++;
    this._reconnectionDelay += 100;
    return this._reconnectionDelay;
  }

  public clearOnSuccessConnectCallback = () => {
    this._onSuccessConnectCallback = undefined;
  }

  public init(apiKey: string, navigateFn?: NavigateFunction) {
    // console.log('socketService initiation');

    this._initCallsCounter++;

    this._connectErrorsCounter = 0;

    if (navigateFn)
      this._navigateFn = navigateFn;

    if (!validateApiKey(apiKey)) {
      if (this._initCallsCounter > 1)
        console.warn(`Wrong API_KEY format!`);

      return;
    }
    this._apiKey = apiKey;
    store.dispatch(UserActions.handleGotApiKey(apiKey));

    this._connectionInfoPacket = {
      apiKey: this._apiKey,
      clientType: "ADMIN_PANEL",
      publicKey: this._keyPair.publicKey
    };

    this._socket = io(CONFIG.MAIN_URL, {
      withCredentials: true,
      path: CONFIG.SOCKET_PATH, 
      transports: ['websocket'],
      reconnectionDelay: CONFIG.SOCKET_RECONNECTION_DELAY,
      reconnectionDelayMax: 2 * CONFIG.SOCKET_RECONNECTION_DELAY,
      // transports: ['polling'],
      query: {apiKey: this._apiKey}
    });

    const savedReduxPinnedPathsForThisApiKey = getReduxPinnedPathsFromLocalStorage(apiKey);
    if (savedReduxPinnedPathsForThisApiKey)
      store.dispatch(OurReduxInspectorActions.setPinnedPaths(JSON.parse(savedReduxPinnedPathsForThisApiKey)));

    const savedZustandPinnedPathsForThisApiKey = getZustandPinnedPathsFromLocalStorage(apiKey);
    if (savedZustandPinnedPathsForThisApiKey)
      store.dispatch(OurZustandInspectorActions.setPinnedPaths(JSON.parse(savedZustandPinnedPathsForThisApiKey)));

    const savedTanStackQueryPinnedPathsForThisApiKey = getTanStackQueryPinnedPathsFromLocalStorage(apiKey);
    if (savedTanStackQueryPinnedPathsForThisApiKey)
      store.dispatch(OurTanStackQueryInspectorActions.setPinnedPaths(JSON.parse(savedTanStackQueryPinnedPathsForThisApiKey)));

    const savedContextsPinnedPathsForThisApiKey = getContextsPinnedPathsFromLocalStorage(apiKey);
    if (savedContextsPinnedPathsForThisApiKey)
      store.dispatch(OurContextsInspectorActions.setPinnedPathsByContextIdTable(JSON.parse(savedContextsPinnedPathsForThisApiKey)));

    const savedMobxPinnedPathsForThisApiKey = getMobxPinnedPathsFromLocalStorage(apiKey);
    if (savedMobxPinnedPathsForThisApiKey)
      store.dispatch(OurMobxInspectorActions.setPinnedPaths(JSON.parse(savedMobxPinnedPathsForThisApiKey)));

    this._socket.on(SOCKET_EVENTS_LISTEN.CONNECT, () => {
      // console.log('Socket connected:', this._socket.connected);
      this._connectErrorsCounter = 0;
      this._onSuccessConnectCallback?.();
      this.clearOnSuccessConnectCallback();
      this._socket?.emit(SOCKET_EVENTS_EMIT.SET_CONNECTION_INFO, this._connectionInfoPacket);
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.CLIENTS_QUEUE_UPDATED, (queueTable: T.ClientsQueueTable) => {
      // console.log('Clients queue updated:', queueTable);
      store.dispatch(UserActions.setClientsQueueTable(queueTable));
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.CLIENT_CONNECTED, (data: ClientConnectedData) => {
      // console.log('Client connected', data);
      if (data.isAdmin) {
        console.warn('Warning: admin connected');
        return;
      }

      // We should clear data in case if reconnected user is not the same user it was before
      this.clearConnectionData(true);
      this._reconnectionAttemptsCounter = 0;
      this._reconnectionDelay = CONFIG.SOCKET_RECONNECTION_DELAY;

      if (data.publicKey) {
        this._enableEncryption = true;
        this._clientPublicKey = new Uint8Array(data.publicKey.data);
        this._sharedKey = box.before(this._clientPublicKey, this._keyPair.secretKey);
      }

      this._availableInstructions = data.availableInstructions.map((el: InstructionPublicFields) => ({...el, instructionType: "default"}));
      this._specialInstructions = data.specialInstructions.map((el: InstructionPublicFields) => ({...el, instructionType: "special"}));

      const allInstructions = this._specialInstructions.concat(this._availableInstructions).map((el: InstructionPublicFields) => {
        const params: {[key: string]: string} = {...(el.parametersDescription ?? {})} ;
        Object.keys(params).forEach((key) => {
          params[key] = "";
        });

        return {
          ...el,
          numOfArgs: el.parametersDescription ? Object.keys(el.parametersDescription).length : 0,
          instructionId: el.id,
          params,
          sQuery: `${el.id} ${el._groupId ?? ""}`
        }
      });

      // console.log("CONNECTED CLIENT:", data);
      store.dispatch(UserActions.setAllInstructions(allInstructions));
      store.dispatch(UserActions.setConnectedClientProjectId(data.projectId));
      store.dispatch(UserActions.setConnectedClientEnvironmentInfo(data.environmentInfo));
      store.dispatch(UserActions.setConnectedClientSocketId(data.socketId));
      store.dispatch(UserActions.setConnectedClientSocketRouteId(data.socketRouteId));
      store.dispatch(UserActions.setBothSidesConnected(true));
      store.dispatch(RemoteSettingsActions.projectList.request({index: 0}));

      if (data.projectId) {
        const scenariosCollection = getScenariosCollectionFromLocalStorage(this._apiKey, data.projectId);
        if (scenariosCollection)
          store.dispatch(UserActions.setScenariosCollection(JSON.parse(scenariosCollection)));
      }

      // // TEST CODE !
      // setTimeout(() => {
      //   // console.log('Emitting event');
      //   // this.testEmitEvent();
      //   console.log('Emitting scenario');
      //   this.testEmitScenario();
      // }, 5000);
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.EXECUTING_EVENT, (eventId: string) => {
      // console.log('Client started execution of event', eventId);
      store.dispatch(UserActions.updateSentEventsExecutionStatusTable({
        eventId: Number(eventId),
        executionStarted: true
      }));
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_EVENT_LOG, (encryptedData: string) => {
      try {
        const eventLog: T.EventLog | null = this._decryptData(encryptedData);

        if (eventLog) {
          store.dispatch(UserActions.updateSentEventsExecutionStatusTable({
            eventId: eventLog.event.id,
            executionCompleted: true,
            ok: eventLog.ok
          }));
          store.dispatch(UserActions.pushLog(eventLog));
          store.dispatch(UserActions.setToolsPanelMode("LOGS"));
        }
      } catch (e) {}
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.EXECUTING_SCENARIO, (scenarioId: string) => {
      // console.log('Client started execution of scenario', scenarioId);
      store.dispatch(UserActions.setScenarioIsExecuting(true));
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_SCENARIO_LOG, (encryptedData: string) => {
      try {
        store.dispatch(UserActions.setScenarioIsExecuting(false));

        const scenarioLog: T.ScenarioLog | null = this._decryptData(encryptedData);

        if (scenarioLog) {
          // console.log('Scenario log', data);
          if (!scenarioLog.ok && scenarioLog.error?.eventCausedError) {
            store.dispatch(UserActions.updateSentEventsExecutionStatusTable({
              eventId: scenarioLog.error.eventCausedError.id,
              executionCompleted: true,
              ok: scenarioLog.ok
            }));
          }

          store.dispatch(UserActions.pushLog(scenarioLog));
          store.dispatch(UserActions.setToolsPanelMode("LOGS"));
        }
      } catch (e) {}
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.NEW_MAIL, (data: Mail) => {
      // console.log("Got new email from socket:", data);
      store.dispatch(UserActions.handleNewEmailFromSocket(data));
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_REDUX_STATE_COPY, (encryptedData: string) => {
      // console.log("Got new redux state here", encryptedData);
      const stateCopy: T.StateManagerStateCopyWithTimestamp | null = this._decryptData(encryptedData);

      if (stateCopy) {
        store.dispatch(OurReduxInspectorActions.setReduxStateCopy(stateCopy));
        store.dispatch(OurReduxInspectorActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_REDUX_ACTIONS_BATCH, (encryptedData: string) => {
      const decryptedData: {actions: T.InterceptedReduxAction[]} = this._decryptData(encryptedData);
      
      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedReduxActions(decryptedData.actions));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_ZUSTAND_STATE_COPY, (encryptedData: string) => {
      // console.log("Got new zustand state here", encryptedData);
      const stateCopy: T.StateManagerStateCopyWithTimestamp | null = this._decryptData(encryptedData);

      if (stateCopy) {
        store.dispatch(OurZustandInspectorActions.setZustandStateCopy(stateCopy));
        store.dispatch(OurZustandInspectorActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_INTERCEPTED_REQUEST, (encryptedData: string) => {
      // console.log("Got intercepted request data", encryptedData);
      const decryptedData = this._decryptData(encryptedData);
      
      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedRequest(decryptedData));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_INTERCEPTED_RESPONSE, (encryptedData: string) => {
      // console.log("Got intercepted response data", encryptedData);
      const decryptedData = this._decryptData(encryptedData);

      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedResponse(decryptedData));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_INTERCEPTED_STORAGE_ACTIONS_BATCH, (encryptedData: string) => {
      const decryptedData: {storageActions: T.InterceptedStorageAction[]} = this._decryptData(encryptedData);

      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedStorageActions(decryptedData.storageActions));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.CAPTURE_EVENT, (encryptedData: string) => {
      const decryptedData: T.CapturedEvent = this._decryptData(encryptedData);

      if (decryptedData) {
        store.dispatch(NetworkActions.addCapturedEvent(decryptedData));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.CAPTURE_CRASH_REPORT, (encryptedData: string) => {
      const decryptedData: T.CapturedCrashReport = this._decryptData(encryptedData);

      if (decryptedData) {
        store.dispatch(NetworkActions.addCapturedCrashReport(decryptedData));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_TANSTACK_QUERIES_DATA_COPY, (encryptedData: string) => {
      const decryptedData: T.TanStackQueriesDataCopyWithTimestamp | null = this._decryptData(encryptedData);

      if (decryptedData) {
        const queriesDataPretty = prettifyTanStackQueriesData(decryptedData.queriesData);
        store.dispatch(OurTanStackQueryInspectorActions.setTanStackQueriesDataCopy({timestamp: decryptedData.timestamp, queriesData: queriesDataPretty}));
        store.dispatch(OurTanStackQueryInspectorActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_TANSTACK_QUERY_EVENTS_BATCH, (encryptedData: string) => {
      const decryptedData: {tanStackQueryEvents: T.InterceptedTanStackQueryEvent[]} = this._decryptData(encryptedData);

      // console.log("here tanstack events", decryptedData.tanStackQueryEvents);
      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedTanStackQueryEvents(decryptedData.tanStackQueryEvents));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_CONTEXT_VALUE_COPY, (encryptedData: string) => {
      const decryptedData: {contextId: string; value: T.ObjectT<any>; timestamp: number} = this._decryptData(encryptedData);
      // console.log("HERE, context data", decryptedData);
      if (decryptedData) {
        store.dispatch(OurContextsInspectorActions.updateContextValueByContextId(decryptedData));
        store.dispatch(OurContextsInspectorActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_MOBX_STATE_COPY, (encryptedData: string) => {
      const stateCopy: T.StateManagerStateCopyWithTimestamp | null = this._decryptData(encryptedData);
      // console.log("Got new MobX state here", stateCopy);

      if (stateCopy) {
        store.dispatch(OurMobxInspectorActions.setMobxStateCopy(stateCopy));
        store.dispatch(OurMobxInspectorActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_MOBX_EVENTS_BATCH, (encryptedData: string) => {
      const decryptedData: {events: T.InterceptedMobxEvent[]} = this._decryptData(encryptedData);

      // console.log("here mobx events", decryptedData.events);
      if (decryptedData) {
        store.dispatch(NetworkActions.addInterceptedMobxEvents(decryptedData.events));
        store.dispatch(NetworkActions.setGotSomethingNewIndicator(true));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_FULL_STORAGE_SNAPSHOT, (encryptedData: string) => {
      const decryptedData: T.StorageSnapshot = this._decryptData(encryptedData);

      // console.log("Entire storage here", decryptedData);
      if (decryptedData) {
        store.dispatch(UserActions.setViewingStorageSnapshot(decryptedData));
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.SAVE_MOBILE_APP_STATE, (data: T.MobileAppState) => {
      // console.log("Mobile app state:", data);
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.CONNECT_ERROR, (err) => {
      console.warn(`Socket connect_error: ${err.message}`);

      if (this._connectErrorsCounter++ > 2) {
        this._navigateFn?.("/internal_error", {state: {problem: ERRORS_500_PROBLEMS.SOCKET_SERVER_CONNECTION_ERROR}});
        this._onSuccessConnectCallback = () => this._navigateFn?.("/main");
      }
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.ERROR, (error) => {
      console.log('Socket send error:', error);
    });

    this._socket.on(SOCKET_EVENTS_LISTEN.DISCONNECT, async (reason, details) => {
      console.log('Socket disconnected. Clearing data...');
      if (CONFIG.RUN_MODE === "DEVELOPMENT") {
        console.log('Disconnect reason:', reason);
        console.log('Disconnect details:', details);
      }
      
      this.clearConnectionData(true);

      setTimeout(() => {
        this._socket?.connect();
      }, this._getReconnectionDelay());
    });
  }

  constructor() {
    this._keyPair = box.keyPair();
  }

  private testEmitEvent() {
    this._socket?.emit(SOCKET_EVENTS_EMIT.EVENT, createSerializeableRemoteEvent("default", "sayHi", "testEmitedEvent", [{"name": 'Sasha'}]));
  }

  private testEmitScenario() {
    const events = [
      createSerializeableRemoteEvent("default", "helloWorld", "testEmitedEvent_0"),
      createSerializeableRemoteEvent("special", "delay", "testEmitedEvent_1", [{"delayInMs": 2000}]),
      createSerializeableRemoteEvent("default", "asyncTest", "testEmitedEvent_2"),
      createSerializeableRemoteEvent("special", "delay", "testEmitedEvent_3", [{"delayInMs": 2000}]),
      createSerializeableRemoteEvent("default", "pow", "testEmitedEvent_4", [{"x": 2, "y": 5}]),
      // createSerializeableRemoteEvent("default", "nonExistingStuff"),
    ];

    this._socket?.emit(SOCKET_EVENTS_EMIT.SCENARIO, new RemoteScenario(events));
  }

  public switchToOtherClient(socketId: string) {
    // console.log("Switching to:", socketId);

    this.clearConnectionData(true);

    setTimeout(() => {
      this._socket?.emit(SOCKET_EVENTS_EMIT.SWITCH_CURRENT_CLIENT, socketId);
    }, 50);
  };

  private prepareRemoteEventFromEventStateData(eventInConstructor: EventStateData): T.RemoteEvent {
    if (eventInConstructor.numOfArgs > 0)
      saveEventLastParamsToLocalStorage(eventInConstructor);

    const eventType = eventInConstructor.instructionType;
    const instructionId = eventInConstructor.instructionId;
    const args = eventInConstructor.numOfArgs ? 
      [prepareParams(eventInConstructor.params, eventInConstructor.parametersDescription as any)]
      : undefined;

    return createSerializeableRemoteEvent(eventType, instructionId, eventInConstructor.id, args);
  }

  public emitEventFromConstructor(constructor: DraggableInstuctionsState) {
    const lists = Object.keys(constructor);
    const eventInConstructor: EventStateData = constructor[lists[0]][0];
    const event = this.prepareRemoteEventFromEventStateData(eventInConstructor);
    store.dispatch(UserActions.createSentEventsExecutionStatusTable({eventsSent: [event]}));
    store.dispatch(UserActions.addExternalIdsToDraggableInstuctionsState({key: lists[0], remoteEvents: [event]}));
    store.dispatch(UserActions.setLastSentEvent({...eventInConstructor, externalId: event.id}));

    this._socket?.emit(SOCKET_EVENTS_EMIT.EVENT, event);
  }

  public emitScenarioFromConstructor(constructor: DraggableInstuctionsState) {
    try {
    const lists = Object.keys(constructor);
    const scenarioInConstructor: EventStateData[] = constructor[lists[0]];

    const events = [];
    for (const ev of scenarioInConstructor)
      events.push(this.prepareRemoteEventFromEventStateData(ev));

    // console.log('events', events)
    store.dispatch(UserActions.createSentEventsExecutionStatusTable({eventsSent: events}));
    store.dispatch(UserActions.addExternalIdsToDraggableInstuctionsState({key: lists[0], remoteEvents: events}));

    this._socket?.emit(SOCKET_EVENTS_EMIT.SCENARIO, new RemoteScenario(events));
    } catch (e) {
      console.log("error", e)
    }
  }

  public emitLogoutAndLogin(account: T.TestAccount) {
    if (!this._apiKey)
      return;
      
    try {
      if (this._availableInstructions) {
        const loginInstruction = this._availableInstructions.find((el) => el.prototype === "login");
        if (!loginInstruction)
          throw new Error("No login prototyped instruction found.");

        const logoutInstruction = this._availableInstructions.find((el) => el.prototype === "logout");
        if (!logoutInstruction)
          throw new Error("No logout prototyped instruction found.");

        const loginInstructionParams: {[key: string]: any} = {};

        for (const key of Object.keys(loginInstruction.parametersDescription ?? {})) {
          // @ts-ignore
          if (account[key]) {
            // @ts-ignore
            loginInstructionParams[key] = account[key];
          } else if (account.additionalData?.[key]) {
            loginInstructionParams[key] = account.additionalData[key];
          }
        }

        const events = [
          createSerializeableRemoteEvent("default", logoutInstruction.id, "logoutEmitedAutomatically"),
          createSerializeableRemoteEvent("default", loginInstruction.id, "loginEmitedAutomatically", [loginInstructionParams])
        ];

        this._socket?.emit(SOCKET_EVENTS_EMIT.SCENARIO, new RemoteScenario(events));
      } else {
        throw new Error("No instructions available.");
      }
    } catch (e) {
      console.log('Error on auto logout+login', e);
    }
  }

  public emitEvent(eventInConstructor: EventStateData) {
    const event = this.prepareRemoteEventFromEventStateData(eventInConstructor);
    store.dispatch(UserActions.createSentEventsExecutionStatusTable({eventsSent: [event]}));
    store.dispatch(UserActions.setLastSentEvent({...eventInConstructor, externalId: event.id}));

    this._socket?.emit(SOCKET_EVENTS_EMIT.EVENT, event);
  }

  public sendNewRemoteSettings(r: T.ObjectT<any>) {
    try {
      this._socket?.emit(SOCKET_EVENTS_EMIT.SAVE_NEW_REMOTE_SETTINGS, r);
    } catch (e) {
      console.warn("Cannot sync remote settings through socket", e);
    }
  }

  public tryToStopScenarioExecution() {
    store.dispatch(UserActions.setStopScenarioExecutionFetching(true));
    this._socket?.emit(SOCKET_EVENTS_EMIT.STOP_SCENARIO_EXECUTION);
  }

  public get connectedToServer(): boolean {
    return !!this._socket?.connected;
  }

  public forceSingleClientDisconnect(socketId: string, isCurrent: boolean) {
    this._socket?.emit(SOCKET_EVENTS_EMIT.FORCE_SINGLE_CLIENT_DISCONNECT, socketId);
   
    if (isCurrent)
      this.clearConnectionData(true);
  }

  public forceAllClientsFromQueueDisconnect() {
    this._socket?.emit(SOCKET_EVENTS_EMIT.FORCE_ALL_CLIENTS_FROM_QUEUE_DISCONNECT);
    this.clearConnectionData(true);
  }

  public forceRefresh(payload: T.ForceRefreshPayload) {
    this._socket?.emit(SOCKET_EVENTS_EMIT.FORCE_REFRESH, payload);
  }

  public disconnect() {
    this._initCallsCounter = 0;
    this._navigateFn = undefined;
    this._apiKey = "";

    this._socket?.disconnect();
    this._socket = undefined;

    this._connectionInfoPacket = undefined;

    this.clearConnectionData(true);

    this._reconnectionAttemptsCounter = 0;
    this._reconnectionDelay = CONFIG.SOCKET_RECONNECTION_DELAY;
    this._connectErrorsCounter = 0;
    this._onSuccessConnectCallback = undefined;
  }
}

const socketService = new SocketService();

export { socketService };