import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './MainStyles';
import { InstructionItem } from '~/components/InstructionItem';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { InstructionPublicFields } from '~/types/api';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

export type DroppableInstructionsProps = {
  bothSidesConnected: boolean;
  instructions: InstructionPublicFields[];
  draggingDisabled?: boolean;
  showSpecialInstructions?: boolean;
  withEventExecutionProgressBar?: boolean;
  onPressSendEvent?: (instructionId: string) => void;
};

const DroppableInstructions: React.FC<DroppableInstructionsProps> = ({
  bothSidesConnected,
  instructions,
  draggingDisabled,
  showSpecialInstructions,
  withEventExecutionProgressBar,
  onPressSendEvent
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { ds } = useThemeContext();
  const { t } = useTranslation();

  if (instructions.length === 0)
    return (
      <div className={cn(s.kiosk)}>
        <Box className={cn(ds.fullWidthCenteredRow)}>
          <p className={cn(ds.grayTextBolder)}>
            {bothSidesConnected ? t("Common.noInstructions") : t("Common.waitingForClientConnect")}
          </p>
        </Box>
      </div>
    );

  return (
    <Droppable droppableId="ITEMS" isDropDisabled={true}>
      {(droppableProvided, droppableSnapshot) => (
        <div className={cn(s.kiosk)} ref={droppableProvided.innerRef}>
          {instructions.map((item: InstructionPublicFields, index: number) => {
            if (!showSpecialInstructions && item.instructionType === "special")
              return null;
            
            return (
              <Draggable
                key={item.id}
                draggableId={item.id}
                index={index}
                isDragDisabled={draggingDisabled}
              >
                {(provided, snapshot) => {
                  const isLastItem = index === instructions.length - 1;
                  const instructionItemProps = {
                    isDragging: snapshot.isDragging,
                    inStructionId: item.instructionId,
                    instructionPrototype: item.prototype,
                    instructionType: item.instructionType,
                    description: item.description,
                    groupId: item._groupId,
                    groupDescription: item._groupDescription,
                    groupColor: item._groupColor,
                    isLastItem: isLastItem,
                    withEventExecutionProgressBar
                  };

                  return (
                    <>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        className={cn({[s.marginBottom]: !isLastItem})}
                      >
                        <InstructionItem 
                          onPressSend={onPressSendEvent ? () => {onPressSendEvent(item.instructionId)} : undefined}
                          {...instructionItemProps}
                        />
                      </div>
                      {snapshot.isDragging && (
                        <div className={cn(s.cloneContainer, {[s.marginBottom]: !isLastItem})}>
                          <InstructionItem {...instructionItemProps}/>
                        </div>
                      )}
                    </>
                  )
                }}
              </Draggable>);
            }
          )}
          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

DroppableInstructions.defaultProps={
  draggingDisabled: false,
  showSpecialInstructions: true,
  withEventExecutionProgressBar: false
}

export { DroppableInstructions };