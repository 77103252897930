import React, { ReactElement, createContext } from 'react';
import { DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import { notChosenNavBarTabsSelector, userSelector } from "~/logic/user/UserSelectors";
import { UserActions } from "~/logic/user/UserSlice";
import { reorder, remove, add } from '~/helpers/draggableHelpers';

export type DndOtherTabContextT = {
  onDragEnd: (result: DropResult) => void;
};

export const DndOtherTabContext = createContext<DndOtherTabContextT>(null as any);

export type DndOtherTabProviderProps = {
  children: ReactElement;
};

const DndOtherTabProvider: React.FC<DndOtherTabProviderProps> = ({children}) => {
  const dispatch = useDispatch();

  const {
    chosenNavBarTabs
  } = useSelector(userSelector);

  const notChosenNavBarTabs = useSelector(notChosenNavBarTabsSelector);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // console.log('==> result', result);

    // dropped outside the list
    if (!destination)
      return;

    switch (source.droppableId) {
      case destination.droppableId:
        if (destination.droppableId !== "dnd_navbar_tabs_other_pool") {
          dispatch(UserActions.setChosenNavBarTabs(
            reorder(
              chosenNavBarTabs,
              source.index,
              destination.index
            )
          ));
        }
        break;
      case "dnd_navbar_tabs_other_pool":
        dispatch(UserActions.setChosenNavBarTabs(
          add(
            chosenNavBarTabs,
            destination.index,
            notChosenNavBarTabs[source.index]
          )
        ))
        break;
      case "dnd_navbar_tabs_bar_itself":
        dispatch(UserActions.setChosenNavBarTabs(
          remove(
            chosenNavBarTabs,
            source.index
          )
        ));
        break;
      default:
        break;
    }
  };

  return (
    <DndOtherTabContext.Provider 
      value={{
        onDragEnd
      }}
    >
      {children}
    </DndOtherTabContext.Provider>
  );
};

DndOtherTabProvider.defaultProps={}

export { DndOtherTabProvider };