import React, { ReactNode, useState } from 'react';
import { useStyles } from './TimelineItemStyles';
import { Box } from '@material-ui/core';
import { useThemeContext } from '~/theme';
import ArrowDown from '~/components/ApiEventItem/assets/arrowDown';
import cn from 'classnames';

const MIN_HEIGHT = 70;

export type TimelineItemExternalProps = {
  showBorderBottom?: boolean;
  timeSincePrevEvent?: string;
};

type TimelineItemProps = TimelineItemExternalProps & {
  timeToShow: string;
  onChangeOpened?: (newValue: boolean) => void;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  openedChildren?: ReactNode;
};

// Kinda template component for wrapping customiseable timeline items components
const TimelineItem: React.FC<TimelineItemProps> = ({
  timeToShow,
  timeSincePrevEvent,
  onChangeOpened,
  showBorderBottom,
  leftChildren,
  rightChildren,
  openedChildren
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const [opened, setOpened] = useState(false);

  return (
    <Box 
      className={cn(s.container, {[s.borderBottom]: showBorderBottom})}
      style={{
        height: opened ? "auto": MIN_HEIGHT,
        backgroundColor: opened ? theme.colors.bgSeptenary : undefined
      }}
    >
      <Box 
        className={cn(s.innerCont)}
        style={{height: MIN_HEIGHT}}
        onClick={() => {
          onChangeOpened?.(!opened);
          setOpened((v) => !v);
        }}
      >
        <Box className={cn(s.left)}>
          {leftChildren}

          {!!timeSincePrevEvent &&
            <p className={cn(s.timeGapText)}>
              {timeSincePrevEvent}
            </p>
          }

          <p className={cn(s.timeText)}>
            {timeToShow}
          </p>
        </Box>
        <Box className={cn(s.right)}>
          {rightChildren}

          <Box className={cn(s.arrow, {[s.arrowHovered]: opened})}>
            <ArrowDown fill={'silver'}/>
          </Box>
        </Box>
      </Box>

      {opened &&
        (
          openedChildren
        )
      }
    </Box>
  );
};

TimelineItem.defaultProps={
  showBorderBottom: true,
  leftChildren: null,
  rightChildren: null,
  openedChildren: null
}

export { TimelineItem };