import CODEBUD_LOGO_LIGHT_THEME from "~/assets/images/codebudLogoLightTheme.png";
import CODEBUD_LOGO_LIGHT_THEME_SMALL from "~/assets/images/codebudLogoLightThemeSmall.png";
import CODEBUD_LOGO_DARK_THEME from "~/assets/images/codebudLogoDarkTheme.png";
import CODEBUD_LOGO_DARK_THEME_SMALL from "~/assets/images/codebudLogoDarkThemeSmall.png";

export const iconsLightTheme = {
  CODEBUD_LOGO: CODEBUD_LOGO_LIGHT_THEME,
  CODEBUD_LOGO_SMALL: CODEBUD_LOGO_LIGHT_THEME_SMALL
};

export const iconsDarkTheme = {
  CODEBUD_LOGO: CODEBUD_LOGO_DARK_THEME,
  CODEBUD_LOGO_SMALL: CODEBUD_LOGO_DARK_THEME_SMALL
};