import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  spaceContainer: {
    display: "flex",
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3
  },
  periodLabel: {
    textTransform: 'capitalize',
    color: theme.colors.black,
    fontFamily: theme.fonts.f600.fontFamily,
    pointerEvents: 'none',
    userSelect: 'none'
  },
  block: {
    display: "flex",
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    display: "flex",
    backgroundColor: theme.colors.red,
    borderRadius: theme.metrics.x,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 9,
  },
});