import { 
  createAction, 
  createSlice, 
  prepareAutoBatched,
  PayloadAction 
} from '@reduxjs/toolkit';
import {
  addAllRestReducers,
  createRestActions,
  getDefaultRestState,
} from "~/store/restHelper";
import { AuthRestActions, CheckMailBoxRestActions, ClearMailBoxRestActions, ClientsQueueRestActions, CreateTestAccountRestActions, DeleteTestAccountRestActions, EditProfileRestActions, EditTestAccountRestActions, ForgotPasswordRestActions, GenerateApiKeyRestActions, InitialSagaRestActions, LogoutRestActions, OtpRestActions, PersonalSettingRestActions, ProfileRestActions, ResendOtpRestActions, TestAccountsListRestActions } from "./UserActions";
import { v4 as uuid } from 'uuid';
import { DraggableInstuctionsState, Mail, SentEventsExecutionStatusTable, EventStateData, ExportableScenario } from '~/helpers/draggableHelpers';
import { InstructionPublicFields } from "~/types/api";
import { saveAppSettingsToLocalStorage, saveNetworkFiltersModalStateToLocalStorage, saveRndDefaultSizeSettingsToLocalStorage, saveScenariosCollectionToLocalStorage } from "~/helpers/localStorage";
import { socketService } from "~/services/socketService";
import { eventExecutionStatusHelpers } from "~/classes/EventExecutionStatus";
import { ClientsQueueTable, LogsTimelineItem, NavigationTab, ObjectT, PersonalProjectsSetting, RemoteEvent, StorageSnapshot, TestAccount, TimelineSorting } from "~/types/types";
import { NavigationBarTabT } from '~/components/NavigationBarTab';
import { DEFAULT_CHOSEN_NAVIGATION_BAR_TABS } from '~/components/NavigationBar';
import { NavigateFunction } from "react-router-dom";
import { getOS, UserOS } from "~/helpers/os";
import fp from "lodash/fp";
import { makeLogsTimelineCopy, sortAndSliceLogsTimeline } from '~/helpers/sort';

export type AppSettingObj = {
  key: string;
  value: boolean;
};

export type AppSettings = {
  autoLogoutAndLoginOnTestAccountChange: AppSettingObj;
  enableMsOnNetworkTimeline: AppSettingObj;
  twentyFourHoursTimeFormat: AppSettingObj;
  showSelectedTestAccountOnMainTab: AppSettingObj;
  showInstructionsSearch: AppSettingObj;
  showScrollToButtonsNetworkTimeline: AppSettingObj;
};

export type UserProfilePublicData = {
  projectsSetting: PersonalProjectsSetting;
};

export type UserProfile = {
  active: boolean;
  apiKey: string;
  email: string;
  _id: string;
} & UserProfilePublicData;

export type PersonalSettingResponse = UserProfilePublicData;

export type NetworkFiltersModalState = {
  InterceptedApiCallVisible: boolean;
  InterceptedReduxActionVisible: boolean;
  InterceptedStorageActionVisible: boolean;
  CapturedEventVisible: boolean;
  InterceptedTanStackQueryEventVisible: boolean;
  InterceptedMobxEventVisible: boolean;
  CapturedCrashReportVisible: boolean;
};

export const DEFAULT_NETWORK_FILTERS_MODAL_STATE: NetworkFiltersModalState = {
  InterceptedApiCallVisible: true,
  InterceptedReduxActionVisible: true,
  InterceptedStorageActionVisible: true,
  CapturedEventVisible: true,
  InterceptedTanStackQueryEventVisible: true,
  InterceptedMobxEventVisible: true,
  CapturedCrashReportVisible: true
};

export const DEFAULT_APP_SETTINGS: AppSettings = {
  autoLogoutAndLoginOnTestAccountChange: {
    key: "autoLogoutAndLoginOnTestAccountChange",
    value: false
  },
  enableMsOnNetworkTimeline: {
    key: "enableMsOnNetworkTimeline",
    value: false
  },
  twentyFourHoursTimeFormat: {
    key: "twentyFourHoursTimeFormat",
    value: false
  },
  showSelectedTestAccountOnMainTab: {
    key: "showSelectedTestAccountOnMainTab",
    value: true
  },
  showInstructionsSearch: {
    key: "showInstructionsSearch",
    value: true
  },
  showScrollToButtonsNetworkTimeline: {
    key: "showScrollToButtonsNetworkTimeline",
    value: true
  }
} as const;

type AllInstructionsSorting = "NO_SORTING" | "INSTRUCTION_ID";

export const ALL_INSTRUCTIONS_SORTING_DESCRIPTION: {[k in AllInstructionsSorting]: string} = {
  NO_SORTING: "Order by instructionId",
  INSTRUCTION_ID: "Disable sorting"
};

const MAX_LOGS_COUNT = 192;

export const MAX_TEST_ACCOUNTS_COUNT = 10;

const DRAGGABLE_INSTRUCTION_STATE_DEFAULT_VALUE = {
  [uuid()]: []
};

type InitialSagaPayload = {};

type AuthResponse = {
  access_token: string;
  shouldOTP: boolean;
};

type ForgotPasswordResponse = {
  message: string;
};

type OtpResponse = {
  message: string;
};

type ResendOtpResponse = {
  message: string;
}

type AuthPayload = {
  email: string;
  password: string;
  reCaptchaToken: string;
  navigate: NavigateFunction;
};

type ForgotPasswordPayload = {
  email: string;
  password: string;
  reCaptchaToken: string;
  code: string;
  navigate: NavigateFunction;
};

type OtpPayload = {
  code: string;
  navigate: NavigateFunction;
};

type ResendOtpPayload = {
  email: string;
  successCallback?: () => void;
};

type ProfilePayload = {
  navigate?: NavigateFunction;
  successCallback?: () => void;
};

type EditProfilePayload = {
  projectsSetting: PersonalProjectsSetting;
};

type PersonalSettingPayload = {
  apiKey: string;
};

type CreateTestAccountResponse = TestAccount;

type CheckMailBoxResponse = TestAccount;

type TestAccountsListResponse = TestAccount[];

type ClearMailBoxResponse = TestAccount;

type DeleteTestAccountResponse = {
  message: string;
};

type CreateTestAccountPayload = {
  login?: string;
  password?: string;
};

type CheckMailBoxPayload = {
  id: string;
};

type TestAccountsListPayload = {
  index: number;
};

type ClearMailBoxPayload = {
  id: string;
};

type DeleteTestAccountPayload = {
  id: string;
};

type EditTestAccountPayload = {
  id: string;
  login?: string;
  description?: string;
  isFavorite?: boolean;
  additionalData?: ObjectT<string>;
};

const initialSagaRestActions = createRestActions<
  void,
  InitialSagaPayload
>(InitialSagaRestActions);

const authRestActions = createRestActions<
  AuthResponse,
  AuthPayload
>(AuthRestActions);

const forgotPasswordRestActions = createRestActions<
  ForgotPasswordResponse,
  ForgotPasswordPayload
>(ForgotPasswordRestActions);

const otpRestActions = createRestActions<
  OtpResponse,
  OtpPayload
>(OtpRestActions);

const resendOtpRestActions = createRestActions<
  ResendOtpResponse,
  ResendOtpPayload
>(ResendOtpRestActions);

const profileRestActions = createRestActions<
  UserProfile,
  ProfilePayload
>(ProfileRestActions);

const editProfileRestActions = createRestActions<
  void,
  EditProfilePayload
>(EditProfileRestActions);

const personalSettingRestActions = createRestActions<
  PersonalSettingResponse,
  PersonalSettingPayload
>(PersonalSettingRestActions);

const generateApiKeyRestActions = createRestActions<
  UserProfile
>(GenerateApiKeyRestActions);

const logoutRestActions = createRestActions<
  void
>(LogoutRestActions);

const createTestAccountRestActions = createRestActions<
  CreateTestAccountResponse,
  CreateTestAccountPayload
>(CreateTestAccountRestActions);

const checkMailBoxRestActions = createRestActions<
  CheckMailBoxResponse,
  CheckMailBoxPayload
>(CheckMailBoxRestActions);

const testAccountsListRestActions = createRestActions<
  TestAccountsListResponse,
  TestAccountsListPayload
>(TestAccountsListRestActions);

const clearMailBoxRestActions = createRestActions<
  ClearMailBoxResponse,
  ClearMailBoxPayload
>(ClearMailBoxRestActions);

const deleteTestAccountRestActions = createRestActions<
  DeleteTestAccountResponse,
  DeleteTestAccountPayload
>(DeleteTestAccountRestActions);

const editTestAccountRestActions = createRestActions<
  void,
  EditTestAccountPayload
>(EditTestAccountRestActions);

const clientsQueueRestActions = createRestActions<
  void
>(ClientsQueueRestActions);

type UpdateDraggableInstuctionsStatePayload = {
  key: string;
  newValue: any[];
};

type AddExternalIdsToDraggableInstuctionsState = {
  key: string;
  remoteEvents: RemoteEvent[];
};

type RemoveExternalIdsFromDraggableInstuctionsStatePayload = {
  key: string;
};

type RemoveDraggableEventPayload = {
  list: string;
  id: string;
};

type UpdateNetworkFiltersModalStatePayload = {
  key: keyof NetworkFiltersModalState;
  value: boolean;
};

type SetParamValuePayload = {
  key: string;
  index: number;
  param: string;
  value: string;
};

type SetSingleEventConstructorParamValuePayload = {
  param: string;
  value: string;
};

type FillEventWithSelectedAccountDataPayload = {
  list: string;
  index: number;
};

type AddTestAccountPayload = {
  account: TestAccount;
  apiKey: string;
}

type SetAppSettingsPayload = {
  appSettings: AppSettings;
  updateLocalStorage: boolean;
}

type CreateSentEventsExecutionStatusTablePayload = {
  eventsSent: RemoteEvent[];
};

type SetRndDefaultSizeSettingsPayload = {key: string, value: number}[];

type UpdateSentEventsExecutionStatusTable = {
  eventId: number;
  executionStarted?: boolean;
  executionCompleted?: boolean;
  ok?: boolean;
};

type UpdateProjectsSettingPayload = {
  personalProjectsSetting: PersonalProjectsSetting;
};

export type RndDefaultSizeSettings = {[key: string]: number};

export type ConstructorMode = "EVENT" | "SCENARIO";

export type ToolsPanelMode = "LOGS" | "CONTROL";

export type ParamsModalState = {
  key: string;
  eventIndex: number;
} | null;

export type ActionModalState = {
  visible: boolean;
  title?: string;
  text?: string;
  yesBtnText?: string;
  noBtnText?: string;
  onPressYes: () => void;
  onPressNo?: () => void;
} | null;

type OtpCallback = null | ((...args: any[]) => void);
type OtpType = string | undefined;

const UserRestActions = {
  initialSaga: initialSagaRestActions,
  auth: authRestActions,
  forgotPassword: forgotPasswordRestActions,
  otp: otpRestActions,
  resendOtp: resendOtpRestActions,
  profile: profileRestActions,
  editProfile: editProfileRestActions,
  personalSetting: personalSettingRestActions,
  generateApiKey: generateApiKeyRestActions,
  logout: logoutRestActions,
  createTestAccount: createTestAccountRestActions,
  checkMailBox: checkMailBoxRestActions,
  testAccountsList: testAccountsListRestActions,
  clearMailBox: clearMailBoxRestActions,
  deleteTestAccount: deleteTestAccountRestActions,
  editTestAccount: editTestAccountRestActions,
  clientsQueue: clientsQueueRestActions
};

type UserState = {
  navigationTab: NavigationTab;
  apiKey: string;
  userToken: string | undefined;
  userEmail: string | undefined;
  userOS: UserOS;
  appSettings: AppSettings;
  testAccounts: TestAccount[];
  currentTestAccount: TestAccount | null;
  draggableInstuctionsState: DraggableInstuctionsState;
  sentEventsExecutionStatusTable: SentEventsExecutionStatusTable;
  constructorMode: ConstructorMode;
  allInstructions: InstructionPublicFields[];
  allInstructionsCopy: InstructionPublicFields[];
  bothSidesConnected: boolean;
  connectedClientProjectId: string | undefined;
  connectedClientEnvironmentInfo: ObjectT<any>;
  connectedClientSocketId: string | undefined;
  connectedClientSocketRouteId: string | undefined;
  allInstructionsSorting: AllInstructionsSorting;
  logs: LogsTimelineItem[];
  logsTimelineSorting: TimelineSorting;
  toolsPanelMode: ToolsPanelMode;
  paramsModalVisible: boolean;
  importScenarioModalVisible: boolean;
  exportScenarioModalVisible: boolean;
  scenarioCollectionModalVisible: boolean;
  networkFiltersModalVisible: boolean;
  actionModalState: ActionModalState;
  paramsModalState: ParamsModalState;
  networkFiltersModalState: NetworkFiltersModalState;
  testAccountsModalVisible: boolean;
  parseHtmlModalVisible: boolean;
  parseHtmlModalValue: string;
  viewingMail: Mail | null;
  scenarioIsExecuting: boolean;
  stopScenarioExecutionFetching: boolean;
  gotSomethingNewIndicator: boolean;
  emailTabGotSomethingNewIndicator: boolean;
  // For ParamsModal if tab is Network
  singleEventConstructor: EventStateData | null;
  lastSentEvent: EventStateData | null;
  otpCallback: OtpCallback;
  otpType: OtpType;
  rndDefaultSizeSettings: RndDefaultSizeSettings;
  connectedClientInfoModalVisible: boolean;
  scenariosCollection: ExportableScenario[];
  editingNavBarTabs: boolean;
  chosenNavBarTabs: NavigationBarTabT[];
  clientsQueueTable: ClientsQueueTable;
  storageSnapshotModalVisible: boolean;
  viewingStorageSnapshot: StorageSnapshot | null;
};

const initialUserState: UserState = {
  navigationTab: "MAIN_TAB",
  apiKey: "",
  userToken: undefined,
  userEmail: undefined,
  userOS: null,
  appSettings: DEFAULT_APP_SETTINGS,
  testAccounts: [],
  currentTestAccount: null,
  draggableInstuctionsState: DRAGGABLE_INSTRUCTION_STATE_DEFAULT_VALUE,
  sentEventsExecutionStatusTable: {},
  constructorMode: "EVENT",
  // allInstructions: INSTRUCTIONS as any,
  allInstructions: [],
  allInstructionsCopy: [],
  bothSidesConnected: false,
  connectedClientProjectId: undefined,
  connectedClientEnvironmentInfo: {},
  connectedClientSocketId: undefined,
  connectedClientSocketRouteId: undefined,
  allInstructionsSorting: "NO_SORTING",
  logs: [],
  logsTimelineSorting: "oldestOnTop",
  toolsPanelMode: "CONTROL",
  paramsModalVisible: false,
  importScenarioModalVisible: false,
  exportScenarioModalVisible: false,
  scenarioCollectionModalVisible: false,
  networkFiltersModalVisible: false,
  paramsModalState: null,
  actionModalState: null,
  networkFiltersModalState: DEFAULT_NETWORK_FILTERS_MODAL_STATE,
  testAccountsModalVisible: false,
  parseHtmlModalVisible: false,
  parseHtmlModalValue: "",
  viewingMail: null,
  scenarioIsExecuting: false,
  stopScenarioExecutionFetching: false,
  gotSomethingNewIndicator: false,
  emailTabGotSomethingNewIndicator: false,
  singleEventConstructor: null,
  lastSentEvent: null,
  otpCallback: null,
  otpType: undefined,
  rndDefaultSizeSettings: {},
  connectedClientInfoModalVisible: false,
  scenariosCollection: [],
  editingNavBarTabs: false,
  chosenNavBarTabs: DEFAULT_CHOSEN_NAVIGATION_BAR_TABS,
  clientsQueueTable: {},
  storageSnapshotModalVisible: false,
  viewingStorageSnapshot: null
};

const initialRestState = {
  initialSaga: getDefaultRestState(),
  auth: getDefaultRestState(),
  otp: getDefaultRestState(),
  resendOtp: getDefaultRestState(),
  profile: getDefaultRestState<UserProfile>(),
  editProfile: getDefaultRestState(),
  personalSetting: getDefaultRestState<PersonalSettingResponse>(),
  generateApiKey: getDefaultRestState(),
  logout: getDefaultRestState(),
  createTestAccount: getDefaultRestState(),
  checkMailBox: getDefaultRestState<CheckMailBoxResponse>(),
  testAccountsList: getDefaultRestState<TestAccountsListResponse>(),
  clearMailBox: getDefaultRestState(),
  deleteTestAccount: getDefaultRestState(),
  forgotPassword: getDefaultRestState(),
  editTestAccount: getDefaultRestState(),
  clientsQueue: getDefaultRestState()
};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialUserState, ...initialRestState },
  reducers: {
    setNavigationTab(state, action: PayloadAction<NavigationTab>) {
      state.navigationTab = action.payload;
    },
    updateDraggableInstuctionsState(state, action: PayloadAction<UpdateDraggableInstuctionsStatePayload>) {
      const {key} = action.payload;
      const newValue = action.payload.newValue.map((el) => ({...el, externalId: undefined}));

      state.constructorMode = newValue.length > 1 ? "SCENARIO" : "EVENT";
      state.draggableInstuctionsState[key] = newValue;
    },
    addExternalIdsToDraggableInstuctionsState(state, action: PayloadAction<AddExternalIdsToDraggableInstuctionsState>) {
      const { key, remoteEvents } = action.payload;

      const stateCopy = JSON.parse(JSON.stringify(state.draggableInstuctionsState[key]));

      for (let i = 0; i < stateCopy.length; i++) {
        stateCopy[i].externalId = remoteEvents[i].id;
      }
      
      state.draggableInstuctionsState[key] = stateCopy;
    },
    removeExternalIdsFromDraggableInstuctionsState(state, action: PayloadAction<RemoveExternalIdsFromDraggableInstuctionsStatePayload>) {
      const { key } = action.payload;

      const stateCopy = JSON.parse(JSON.stringify(state.draggableInstuctionsState[key]));
      
      for (let i = 0; i < stateCopy.length; i++) {
        stateCopy[i] = {...stateCopy[i], externalId: undefined};
      }
      
      state.draggableInstuctionsState[key] = stateCopy;
    },
    setDraggableInstuctionsState(state, action: PayloadAction<DraggableInstuctionsState>) {
      const newState = JSON.parse(JSON.stringify(action.payload));
      const lists = Object.keys(newState);
      for (let key of lists)
        for (let el of newState[key])
          el.externalId = undefined;

      state.constructorMode = newState[lists[0]].length > 1 ? "SCENARIO" : "EVENT";
      state.draggableInstuctionsState = newState;
    },
    setConstructorMode(state, action: PayloadAction<ConstructorMode>) {
      state.constructorMode = action.payload;

      if (action.payload === 'EVENT')
        state.draggableInstuctionsState = DRAGGABLE_INSTRUCTION_STATE_DEFAULT_VALUE;
    },
    setAllInstructions: {
      reducer(state, action: PayloadAction<InstructionPublicFields[]>) {
        state.allInstructions = action.payload;
        state.allInstructionsCopy = action.payload;
      },
      prepare: prepareAutoBatched<InstructionPublicFields[]>(),
    },
    clearAllInstructions(state) {
      state.allInstructions = [];
      state.allInstructionsCopy = [];
    },
    setBothSidesConnected: {
      reducer(state, action: PayloadAction<boolean>) {
        state.bothSidesConnected = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    removeDraggableEvent(state, action: PayloadAction<RemoveDraggableEventPayload>) {
      const {list, id} = action.payload;

      state.draggableInstuctionsState[list] = state.draggableInstuctionsState[list].filter((event) => event.id !== id);
      state.constructorMode = state.draggableInstuctionsState[list].length > 1 ? "SCENARIO" : "EVENT";
    },
    setAllInstructionsSorting(state, action: PayloadAction<AllInstructionsSorting>) {
      const sortType = action.payload;
      state.allInstructionsSorting = sortType;

      switch (sortType) {
        case "INSTRUCTION_ID":
          const instructionsToSort = [...state.allInstructionsCopy];
          state.allInstructions = instructionsToSort.sort((a, b) => a.instructionId.localeCompare(b.instructionId));
          break;
        case "NO_SORTING":
          state.allInstructions = state.allInstructionsCopy;
          break;
        default:
          break;
      }
    },
    clearLogs(state) {
      state.logs = [];
    },
    pushLog: {
      reducer(state, action: PayloadAction<LogsTimelineItem>) {
        let logsCopy = makeLogsTimelineCopy(state.logs);
        logsCopy.push(action.payload);

        state.logs = sortAndSliceLogsTimeline(logsCopy, state.logsTimelineSorting, MAX_LOGS_COUNT);

        if (state.navigationTab !== "MAIN_TAB")
          state.gotSomethingNewIndicator = true;
      },
      prepare: prepareAutoBatched<LogsTimelineItem>(),
    },
    setLogsTimelineSorting(state, action: PayloadAction<TimelineSorting>) {
      state.logsTimelineSorting = action.payload;
      let logsCopy = makeLogsTimelineCopy(state.logs);
      state.logs = sortAndSliceLogsTimeline(logsCopy, state.logsTimelineSorting, MAX_LOGS_COUNT);
    },
    setToolsPanelMode: {
      reducer(state, action: PayloadAction<ToolsPanelMode>) {
        state.toolsPanelMode = action.payload;
      },
      prepare: prepareAutoBatched<ToolsPanelMode>(),
    },
    setParamsModalVisible: {
      reducer(state, action: PayloadAction<boolean>) {
        state.paramsModalVisible = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setImportScenarioModalVisible(state, action: PayloadAction<boolean>) {
      state.importScenarioModalVisible = action.payload;
    },
    setExportScenarioModalVisible(state, action: PayloadAction<boolean>) {
      state.exportScenarioModalVisible = action.payload;
    },
    setScenarioCollectionModalVisible(state, action: PayloadAction<boolean>) {
      state.scenarioCollectionModalVisible = action.payload;
    },
    setNetworkFiltersModalVisible(state, action: PayloadAction<boolean>) {
      state.networkFiltersModalVisible = action.payload;
    },
    setParamsModalState: {
      reducer(state, action: PayloadAction<ParamsModalState>) {
        state.paramsModalState = action.payload;
      },
      prepare: prepareAutoBatched<ParamsModalState>(),
    },
    setParamValue(state, action: PayloadAction<SetParamValuePayload>) {
      const { key, index, param, value } = action.payload;

      if (state.draggableInstuctionsState[key][index]?.params) {
        state.draggableInstuctionsState[key][index].params[param] = value;
        state.draggableInstuctionsState[key][index].touched = true;
      }
    },
    fillEventWithSelectedAccountData(state, action: PayloadAction<FillEventWithSelectedAccountDataPayload>) {
      if (state.currentTestAccount) {
        const { list, index } = action.payload;

        if (state.draggableInstuctionsState[list][index].parametersDescription?.email)
          state.draggableInstuctionsState[list][index].params.email = state.currentTestAccount.email;
        if (state.draggableInstuctionsState[list][index].parametersDescription?.pass)
          state.draggableInstuctionsState[list][index].params.pass = state.currentTestAccount.password;
      }
    },
    fillEventWithLastParams(state, action: PayloadAction<FillEventWithSelectedAccountDataPayload>) {
      const { list, index } = action.payload;

      if (state.draggableInstuctionsState[list][index].lastParamsSaved) {
        state.draggableInstuctionsState[list][index].params = state.draggableInstuctionsState[list][index].lastParamsSaved as any;
        state.draggableInstuctionsState[list][index].touched = true;
      } else {
        console.warn("fillEventWithLastParams unexpected data");
      }
    },
    handleCurrentDraggableInstuctionsStateBeingSent(state) {
      const stateCopy: DraggableInstuctionsState = JSON.parse(JSON.stringify(state.draggableInstuctionsState));

      for (const key of Object.keys(stateCopy))
        for (let i = 0; i < stateCopy[key].length; i++)
          if (stateCopy[key][i].numOfArgs > 0)
            stateCopy[key][i].lastParamsSaved = stateCopy[key][i].params;

      state.draggableInstuctionsState = stateCopy;
    },
    setTestAccountsModalVisible: {
      reducer(state, action: PayloadAction<boolean>) {
        state.testAccountsModalVisible = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setParseHtmlModalVisible: {
      reducer(state, action: PayloadAction<boolean>) {
        state.parseHtmlModalVisible = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setParseHtmlModalValue: {
      reducer(state, action: PayloadAction<string>) {
        state.parseHtmlModalValue = action.payload;
      },
      prepare: prepareAutoBatched<string>(),
    },
    setTestAccounts: {
      reducer(state, action: PayloadAction<TestAccount[]>) {
        state.testAccounts = action.payload.sort((a, b) => a.isFavorite === b.isFavorite ? 0 : a.isFavorite ? -1 : 1);
      },
      prepare: prepareAutoBatched<TestAccount[]>(),
    },
    addTestAccount: {
      reducer(state, action: PayloadAction<AddTestAccountPayload>) {
        const { account } = action.payload;

        const favorites = state.testAccounts.filter((account) => account.isFavorite === true);
        let others = state.testAccounts.filter((account) => !account.isFavorite);
        others.push(account);
  
        if (favorites.length + others.length > MAX_TEST_ACCOUNTS_COUNT)
          others = others.slice(1, others.length);
  
        state.testAccounts = favorites.concat(others);
      },
      prepare: prepareAutoBatched<AddTestAccountPayload>(),
    },
    setApiKey(state, action: PayloadAction<string>) {
      state.apiKey = action.payload;
    },
    setCurrentTestAccount: {
      reducer(state, action: PayloadAction<TestAccount | null>) {
        const account = action.payload;
        state.currentTestAccount = account;
  
        if (account && state.appSettings.autoLogoutAndLoginOnTestAccountChange.value === true) {
          socketService.emitLogoutAndLogin(account);
        }
      },
      prepare: prepareAutoBatched<TestAccount | null>(),
    },
    setViewingMail(state, action: PayloadAction<Mail | null>) {
      state.viewingMail = action.payload;
    },
    handleNewEmailFromSocket(state, action: PayloadAction<Mail>) {
      const email = action.payload;

      if (state.checkMailBox.data && state.checkMailBox.data.email === email.to.text) {
        const mailsInBox = new Set();
        for (const m of state.checkMailBox.data.mailBox)
          mailsInBox.add(m.messageId);
        
        if (!mailsInBox.has(email.messageId)) {
          state.checkMailBox.data.mailBox.unshift(email);

          if (state.navigationTab !== "MAIL_TAB")
            state.emailTabGotSomethingNewIndicator = true;
        }
      }
    },
    setAppSettings(state, action: PayloadAction<SetAppSettingsPayload>) {
      const { appSettings, updateLocalStorage } = action.payload;

      state.appSettings = appSettings;

      if (updateLocalStorage)
        saveAppSettingsToLocalStorage(state.apiKey, appSettings);
    },
    createSentEventsExecutionStatusTable: {
      reducer(state, action: PayloadAction<CreateSentEventsExecutionStatusTablePayload>) {
        const { eventsSent } = action.payload;

        if (eventsSent.length > 0) {
          const table: SentEventsExecutionStatusTable = {};
  
          for (const event of eventsSent)
            table[event.id] = eventExecutionStatusHelpers.create(event);
  
          state.sentEventsExecutionStatusTable = table;
        } else {
          console.warn("Invalid numOfEventsSent!");
        }
      },
      prepare: prepareAutoBatched<CreateSentEventsExecutionStatusTablePayload>(),
    },
    updateSentEventsExecutionStatusTable: {
      reducer(state, action: PayloadAction<UpdateSentEventsExecutionStatusTable>) {
        const eventInExecutionTable = state.sentEventsExecutionStatusTable[action.payload.eventId];

        if (eventInExecutionTable) {
          if (action.payload.executionStarted)
            eventExecutionStatusHelpers.setExecutionStarted(eventInExecutionTable);
          if (action.payload.executionCompleted)
            eventExecutionStatusHelpers.setExecutionCompleted(eventInExecutionTable);
          if (action.payload.ok !== undefined)
            eventExecutionStatusHelpers.setOk(eventInExecutionTable, action.payload.ok);
  
          state.sentEventsExecutionStatusTable = {...state.sentEventsExecutionStatusTable, [action.payload.eventId]: eventInExecutionTable};
        }
      },
      prepare: prepareAutoBatched<UpdateSentEventsExecutionStatusTable>(),
    },
    clearSentEventsExecutionStatusTable(state) {
      state.sentEventsExecutionStatusTable = {};
    },
    setScenarioIsExecuting: {
      reducer(state, action: PayloadAction<boolean>) {
        state.scenarioIsExecuting = action.payload;
        state.stopScenarioExecutionFetching = false;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setStopScenarioExecutionFetching(state, action: PayloadAction<boolean>) {
      state.stopScenarioExecutionFetching = action.payload;
    },
    setGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.gotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setEmailTabGotSomethingNewIndicator: {
      reducer(state, action: PayloadAction<boolean>) {
        state.emailTabGotSomethingNewIndicator = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setSingleEventConstructor: {
      reducer(state, action: PayloadAction<EventStateData | null>) {
        state.singleEventConstructor = action.payload;
      },
      prepare: prepareAutoBatched<EventStateData | null>(),
    },
    setLastSentEvent: {
      reducer(state, action: PayloadAction<EventStateData | null>) {
        state.lastSentEvent = action.payload;
      },
      prepare: prepareAutoBatched<EventStateData | null>(),
    },
    setSingleEventConstructorParamValue(state, action: PayloadAction<SetSingleEventConstructorParamValuePayload>) {
      const { param, value } = action.payload;

      if (state.singleEventConstructor) {
        state.singleEventConstructor.params[param] = value;
        state.singleEventConstructor.touched = true;
      }
    },
    setActionModalState(state, action: PayloadAction<ActionModalState>) {
      state.actionModalState = action.payload;
    },
    setUserToken(state, action: PayloadAction<string | undefined>) {
      state.userToken = action.payload;
    },
    setUserEmail(state, action: PayloadAction<string | undefined>) {
      state.userEmail = action.payload;
    },
    detectUserOS(state) {
      state.userOS = getOS();
    },
    setOtpCallback(state, action: PayloadAction<OtpCallback>) {
      state.otpCallback = action.payload;
    },
    setOtpType(state, action: PayloadAction<OtpType>) {
      state.otpType = action.type;
    },
    setRndDefaultSizeSettings(state, action: PayloadAction<SetRndDefaultSizeSettingsPayload>) {
      const settingsCopy = JSON.parse(JSON.stringify(state.rndDefaultSizeSettings));

      for (const setting of action.payload)
        settingsCopy[setting.key] = setting.value;

      state.rndDefaultSizeSettings = settingsCopy;

      if (state.apiKey && state.userToken)
        saveRndDefaultSizeSettingsToLocalStorage(state.apiKey, state.userToken, settingsCopy);
    },
    setFullRndDefaultSizeSettings: {
      reducer(state, action: PayloadAction<RndDefaultSizeSettings>) {
        state.rndDefaultSizeSettings = action.payload;
      },
      prepare: prepareAutoBatched<RndDefaultSizeSettings>(),
    },
    setNetworkFiltersModalState(state, action: PayloadAction<NetworkFiltersModalState>) {
      state.networkFiltersModalState = action.payload;
    },
    updateNetworkFiltersModalState(state, action: PayloadAction<UpdateNetworkFiltersModalStatePayload>) {
      state.networkFiltersModalState[action.payload.key] = action.payload.value;
      saveNetworkFiltersModalStateToLocalStorage(state.networkFiltersModalState);
    },
    setConnectedClientProjectId: {
      reducer(state, action: PayloadAction<string | undefined>) {
        state.connectedClientProjectId = action.payload;
      },
      prepare: prepareAutoBatched<string | undefined>(),
    },
    setConnectedClientInfoModalVisible(state, action: PayloadAction<boolean>) {
      state.connectedClientInfoModalVisible = action.payload;
    },
    setConnectedClientEnvironmentInfo: {
      reducer(state, action: PayloadAction<ObjectT<any>>) {
        state.connectedClientEnvironmentInfo = action.payload;
      },
      prepare: prepareAutoBatched<ObjectT<any>>(),
    },
    setConnectedClientSocketId: {
      reducer(state, action: PayloadAction<string | undefined>) {
        state.connectedClientSocketId = action.payload;
      },
      prepare: prepareAutoBatched<string | undefined>(),
    },
    setConnectedClientSocketRouteId: {
      reducer(state, action: PayloadAction<string | undefined>) {
        state.connectedClientSocketRouteId = action.payload;
      },
      prepare: prepareAutoBatched<string | undefined>(),
    },
    clearAllState: {
      reducer() {
        return {
          ...initialUserState, 
          ...initialRestState
        }
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearConstructorState: {
      reducer(state) {
        state.draggableInstuctionsState = initialUserState.draggableInstuctionsState;
        state.sentEventsExecutionStatusTable = initialUserState.sentEventsExecutionStatusTable;
        state.constructorMode = initialUserState.constructorMode;
        state.allInstructions = initialUserState.allInstructions;
        state.allInstructionsCopy = initialUserState.allInstructionsCopy;
        state.logs = initialUserState.logs;
        state.toolsPanelMode = initialUserState.toolsPanelMode;
        state.paramsModalVisible = initialUserState.paramsModalVisible;
        state.paramsModalState = initialUserState.paramsModalState;
        state.scenarioIsExecuting = initialUserState.scenarioIsExecuting;
        state.stopScenarioExecutionFetching = initialUserState.stopScenarioExecutionFetching;
        state.singleEventConstructor = initialUserState.singleEventConstructor;
        state.scenariosCollection = initialUserState.scenariosCollection;
      },
      prepare: prepareAutoBatched<void>(),
    },
    clearConstructorStateFromInstructions(state) {
      state.draggableInstuctionsState = initialUserState.draggableInstuctionsState;
      state.sentEventsExecutionStatusTable = initialUserState.sentEventsExecutionStatusTable;
      state.constructorMode = initialUserState.constructorMode;
      state.paramsModalVisible = initialUserState.paramsModalVisible;
      state.paramsModalState = initialUserState.paramsModalState;
      state.scenarioIsExecuting = initialUserState.scenarioIsExecuting;
      state.stopScenarioExecutionFetching = initialUserState.stopScenarioExecutionFetching;
      state.singleEventConstructor = initialUserState.singleEventConstructor;
    },
    clearCheckMailBox(state) {
      state.checkMailBox = {...initialRestState.checkMailBox};
    },
    setScenariosCollection: {
      reducer(state, action: PayloadAction<ExportableScenario[]>) {
        state.scenariosCollection = action.payload;
      },
      prepare: prepareAutoBatched<ExportableScenario[]>(),
    },
    addScenarioToCollection(state, action: PayloadAction<ExportableScenario>) {
      if (state.apiKey && state.connectedClientProjectId) {
        const newScenariosCollection = [...state.scenariosCollection];
        newScenariosCollection.push(action.payload);
        state.scenariosCollection = newScenariosCollection;

        saveScenariosCollectionToLocalStorage(state.apiKey, state.connectedClientProjectId, newScenariosCollection);
      }
    },
    deleteScenarioFromCollection(state, action: PayloadAction<string>) {
      if (state.apiKey && state.connectedClientProjectId) {
        state.scenariosCollection = state.scenariosCollection.filter((s) => s.id !== action.payload);
        saveScenariosCollectionToLocalStorage(state.apiKey, state.connectedClientProjectId, state.scenariosCollection);
      }
    },
    setEditingNavBarTabs(state, action: PayloadAction<boolean>) {
      state.editingNavBarTabs = action.payload;
    },
    setChosenNavBarTabs(state, action: PayloadAction<NavigationBarTabT[]>) {
      state.chosenNavBarTabs = action.payload;
    },
    setClientsQueueTable(state, action: PayloadAction<ClientsQueueTable>) {
      state.clientsQueueTable = action.payload;
    },
    updateProjectsSetting(state, action: PayloadAction<UpdateProjectsSettingPayload>) {
      if (state.profile.data) {
        state.profile.data.projectsSetting = action.payload.personalProjectsSetting;
      }
    },
    setStorageSnapshotModalVisible: {
      reducer(state, action: PayloadAction<boolean>) {
        state.storageSnapshotModalVisible = action.payload;
      },
      prepare: prepareAutoBatched<boolean>(),
    },
    setViewingStorageSnapshot: {
      reducer(state, action: PayloadAction<StorageSnapshot | null>) {
        state.viewingStorageSnapshot = action.payload;
      },
      prepare: prepareAutoBatched<StorageSnapshot | null>()
    }
  },
  extraReducers: (builder) => fp.flow(addAllRestReducers<typeof UserRestActions>(UserRestActions))(builder)
});

const userReducer = userSlice.reducer;
const UserActions = { 
  ...UserRestActions, 
  ...userSlice.actions,
  setNavigationTabWithProcessing: createAction<NavigationTab>('user/setNavigationTabWithProcessing'),
  handleGotApiKey: createAction<string>('user/handleGotApiKey'),
  applyAndSetAppSettings: createAction<SetAppSettingsPayload>('user/applyAndSetAppSettings'),
};

export { userReducer, UserActions };