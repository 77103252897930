import { makeStyles } from '@material-ui/core/styles';
import { CONFIG } from '~/config';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: "flex",
    flexDirection: 'column',
    position: 'fixed',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: "100%",
    minHeight: 750,
    width: CONFIG.NAV_BAR_WIDTH,
    left: 0,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: theme.colors.bgTetriary
    // backgroundColor: 'red'
  },
  logoCont: {
    display: "flex",
    height: 64,
    width: 64,
    padding: theme.metrics.x3_5,
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: "none"
  },
  bottomCont: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 40,
    // backgroundColor: 'blue'
  },
  innerContainer: {
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    height: "73%",
    width: '100%',
    // backgroundColor: 'blue'
  },
});