import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  leftChildrenCont: {
    display: 'flex',
    alignItems: 'center'
  },
  orangeText: {
    margin: 0,
    color: theme.colors.mandy,
    fontFamily: theme.fonts.f700.fontFamily
  },
  row: {
    display: 'flex'
  },
  copyCURL: {
    width: 30,
    height: 30
  },
});