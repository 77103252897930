import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    width: '30vw',
    height: '40vh',
    minWidth: 200,
    minHeight: 300,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.sharkLight,
    borderRadius: 16,
    overflow: 'hidden',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16
  },
  title: {
    fontSize: 22,
    fontFamily: theme.fonts.f600.fontFamily,
    color: theme.colors.white,
    padding: 0,
    margin: 0,
    marginBottom: theme.metrics.x4
  },
  filterTitleText: {
    fontSize: 16,
    fontFamily: theme.fonts.f400.fontFamily,
    color: theme.colors.textBright,
    padding: 0,
    margin: 0,
    marginLeft: theme.metrics.x2
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.metrics.x2
  }
});