import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import { put, call, select, takeLatest } from "redux-saga/effects";
import { api, requestErrorFromResponse } from "~/api";
import { RemoteSettingsActions } from "./RemoteSettingsSlice";
import { AddProjectMemberResponse, CreateProjectResponse, EditProjectResponse, GetRemoteSettingsResponse, ProjectByIdResponse, ProjectListResponse, SetRemoteSettingsResponse } from "~/types/api";
import { showToast } from "~/helpers/alertService";
import { classicApiResponseValidator, retryApiCall } from "~/helpers/sagaHelpers";
import { projectListSelector } from "./RemoteSettingsSelectors";
import { Project } from "~/types/types";
import { connectedClientProjectIdSelector } from "../user/UserSelectors";
import i18next from "i18next";

function* getRemoteSettingsRequest(action: Action) {
  if (RemoteSettingsActions.getRemoteSettings.request.match(action)) {
    try {      
      const getRemoteSettingsResponse: ApiResponse<GetRemoteSettingsResponse> = yield call(retryApiCall, {
        apiRequest: api.getRemoteSettingsGet,
        args: [action.payload]
      });
      if (getRemoteSettingsResponse.ok && getRemoteSettingsResponse.data) {
        const responseData = getRemoteSettingsResponse.data;

        yield put(RemoteSettingsActions.updateRemoteSettingsByProjectIdTable({
          remoteSettings: responseData.remoteSettings,
          projectId: action.payload.projectId
        }));

        yield put(RemoteSettingsActions.getRemoteSettings.success(responseData.remoteSettings));
      } else {
        throw requestErrorFromResponse(getRemoteSettingsResponse);
      }
    } catch (error) {
      yield put(RemoteSettingsActions.getRemoteSettings.failure(error));
    }
  }
}

function* setRemoteSettingsRequest(action: Action) {
  if (RemoteSettingsActions.setRemoteSettings.request.match(action)) {
    try {
      const setRemoteSettingsResponse: ApiResponse<SetRemoteSettingsResponse> = yield call(retryApiCall, {
        apiRequest: api.setRemoteSettingsPost,
        args: [action.payload]
      });
      if (setRemoteSettingsResponse.ok && setRemoteSettingsResponse.data) {
        const responseData = setRemoteSettingsResponse.data;
        yield call(showToast, i18next.t("Toast.remoteSettingsSetSuccess"), "success");

        yield put(RemoteSettingsActions.updateRemoteSettingsByProjectIdTable({
          remoteSettings: responseData.remoteSettings,
          projectId: action.payload.projectId
        }));
        yield put(RemoteSettingsActions.getRemoteSettings.success(responseData.remoteSettings));

        yield put(RemoteSettingsActions.setRemoteSettings.success({message: "OK"}));
      } else {
        throw requestErrorFromResponse(setRemoteSettingsResponse);
      }
    } catch (error) {
      yield put(RemoteSettingsActions.setRemoteSettings.failure(error));
    }
  }
}

function* createProjectRequest(action: Action) {
  if (RemoteSettingsActions.createProject.request.match(action)) {
    try {
      const createProjectResponse: ApiResponse<CreateProjectResponse> = yield call(
        api.createProjectPost,
        action.payload
      );
      if (classicApiResponseValidator(createProjectResponse)) {
        const responseData = createProjectResponse.data!;

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield call(showToast, i18next.t("Toast.projectCreated"), "success");

        const projectList: Project[] = yield select(projectListSelector);
        projectList.push(responseData.project);
        yield put(RemoteSettingsActions.projectList.success(projectList));

        yield put(RemoteSettingsActions.createProject.success(responseData.project));
      } else {
        throw requestErrorFromResponse(createProjectResponse);
      }
    } catch (error: any) {
      yield call(showToast, error.message ?? error.description ?? "Server error", "error");
      yield put(RemoteSettingsActions.createProject.failure(error));
    }
  }
}

function* editProjectRequest(action: Action) {
  if (RemoteSettingsActions.editProject.request.match(action)) {
    try {
      const editProjectResponse: ApiResponse<EditProjectResponse> = yield call(
        api.editProjectPost,
        action.payload
      );
      if (classicApiResponseValidator(editProjectResponse)) {
        const responseData = editProjectResponse.data!;

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield call(showToast, i18next.t("Toast.projectEditedSuccess"), "success");

        const projectList: Project[] = yield select(projectListSelector);
        yield put(RemoteSettingsActions.projectList.success(projectList.map((p) => p._id === action.payload.id ? responseData.project : p)));

        yield put(RemoteSettingsActions.editProject.success(responseData.project));
      } else {
        throw requestErrorFromResponse(editProjectResponse);
      }
    } catch (error: any) {
      yield call(showToast, error.message ?? error.description ?? "Server error", "error");
      yield put(RemoteSettingsActions.editProject.failure(error));
    }
  }
}

function* deleteProjectRequest(action: Action) {
  if (RemoteSettingsActions.deleteProject.request.match(action)) {
    try {
      const deleteProjectResponse: ApiResponse<ResponseType> = yield call(
        api.deleteProjectDelete,
        action.payload
      );
      if (classicApiResponseValidator(deleteProjectResponse)) {
        const projectList: Project[] = yield select(projectListSelector);
        const newProjectList = projectList.filter((p) => p._id !== action.payload.id);
        yield put(RemoteSettingsActions.projectList.success(newProjectList));

        yield call(showToast, i18next.t("Toast.projectDeletedSuccess"), "success");

        yield put(RemoteSettingsActions.deleteProject.success());
      } else {
        throw requestErrorFromResponse(deleteProjectResponse);
      }
    } catch (error: any) {
      yield call(showToast, error.message ?? error.description ?? "Server error", "error");
      yield put(RemoteSettingsActions.deleteProject.failure(error));
    }
  }
}

function* projectListRequest(action: Action) {
  if (RemoteSettingsActions.projectList.request.match(action)) {
    try {
      const projectListResponse: ApiResponse<ProjectListResponse> = yield call(
        api.projectListGet,
        action.payload
      );
      if (classicApiResponseValidator(projectListResponse)) {
        const responseData = projectListResponse.data!;

        const connectedClientProjectId: string | undefined = yield select(connectedClientProjectIdSelector);
        let haveSetSelectedByDefaultProject = false;
        if (connectedClientProjectId) {
          const connectedProjectInfo = responseData.projects.find((p) => p._id === connectedClientProjectId);
          if (connectedProjectInfo) {
            yield put(RemoteSettingsActions.setSelectedProjectId(connectedProjectInfo._id));
            haveSetSelectedByDefaultProject = true;
          }
        }
        if (!haveSetSelectedByDefaultProject && responseData.projects.length > 0) {
          yield put(RemoteSettingsActions.setSelectedProjectId(responseData.projects[0]._id));
          haveSetSelectedByDefaultProject = true;
        }

        yield put(RemoteSettingsActions.projectList.success(responseData.projects));
      } else {
        throw requestErrorFromResponse(projectListResponse);
      }
    } catch (error) {
      yield put(RemoteSettingsActions.projectList.failure(error));
    }
  }
}

function* projectByIdRequest(action: Action) {
  if (RemoteSettingsActions.projectById.request.match(action)) {
    try {
      const projectByIdResponse: ApiResponse<ProjectByIdResponse> = yield call(
        api.projectByIdGet,
        action.payload
      );
      if (classicApiResponseValidator(projectByIdResponse)) {
        const responseData = projectByIdResponse.data!;
        yield put(RemoteSettingsActions.projectById.success(responseData.project));
      } else {
        throw requestErrorFromResponse(projectByIdResponse);
      }
    } catch (error) {
      yield put(RemoteSettingsActions.projectById.failure(error));
    }
  }
}

function* addProjectMemberRequest(action: Action) {
  if (RemoteSettingsActions.addProjectMember.request.match(action)) {
    try {
      const addProjectMemberResponse: ApiResponse<AddProjectMemberResponse> = yield call(
        api.addProjectMemberPost,
        action.payload
      );
      if (classicApiResponseValidator(addProjectMemberResponse)) {

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield call(showToast, i18next.t("Toast.newMemberAddedToProject"), "success");

        // Refetch projects
        yield put(RemoteSettingsActions.projectList.request({index: 0}));

        yield put(RemoteSettingsActions.addProjectMember.success());
      } else {
        throw requestErrorFromResponse(addProjectMemberResponse);
      }
    } catch (error: any) {
      yield call(showToast, error.message ?? error.description ?? "Server error", "error");
      yield put(RemoteSettingsActions.addProjectMember.failure(error));
    }
  }
}

function* removeProjectMemberRequest(action: Action) {
  if (RemoteSettingsActions.removeProjectMember.request.match(action)) {
    try {
      const removeProjectMemberResponse: ApiResponse<ResponseType> = yield call(
        api.removeProjectMemberDelete,
        action.payload
      );
      if (classicApiResponseValidator(removeProjectMemberResponse)) {

        if (action.payload.successCallback) {
          yield call(action.payload.successCallback);
        }

        yield call(showToast, i18next.t("Toast.memberWasRemovedFromProject"), "success");

        // Refetch projects
        yield put(RemoteSettingsActions.projectList.request({index: 0}));

        yield put(RemoteSettingsActions.removeProjectMember.success());
      } else {
        throw requestErrorFromResponse(removeProjectMemberResponse);
      }
    } catch (error: any) {
      yield call(showToast, error.message ?? error.description ?? "Server error", "error");
      yield put(RemoteSettingsActions.removeProjectMember.failure(error));
    }
  }
}

export function* RemoteSettingsSaga() {
  yield* [
    takeLatest(RemoteSettingsActions.getRemoteSettings.request.type, getRemoteSettingsRequest),
    takeLatest(RemoteSettingsActions.setRemoteSettings.request.type, setRemoteSettingsRequest),
    takeLatest(RemoteSettingsActions.createProject.request.type, createProjectRequest),
    takeLatest(RemoteSettingsActions.editProject.request.type, editProjectRequest),
    takeLatest(RemoteSettingsActions.deleteProject.request.type, deleteProjectRequest),
    takeLatest(RemoteSettingsActions.projectList.request.type, projectListRequest),
    takeLatest(RemoteSettingsActions.projectById.request.type, projectByIdRequest),
    takeLatest(RemoteSettingsActions.addProjectMember.request.type, addProjectMemberRequest),
    takeLatest(RemoteSettingsActions.removeProjectMember.request.type, removeProjectMemberRequest)
  ];
}