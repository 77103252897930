import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  select: {
    visibility: "hidden",
    width: 0,
    height: 0,
    position: 'absolute'
  },
  selectIcon: {
    visibility: "hidden",
    width: 0,
    height: 0,
  },
  paper: {
    backgroundColor: theme.colors.bgQuinary,
    color: theme.colors.textMain
  },
});