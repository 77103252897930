import * as React from "react"
const SvgComponent = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill={props.color}
  >
    <path d="M1.119 12.633v10.576h2.49V12.633h-2.49zm10.763-1.865c2.553 0 4.193 2.04 4.193 5.232 0 3.217-1.64 5.257-4.193 5.257-2.578 0-4.206-2.04-4.206-5.257 0-3.192 1.627-5.232 4.206-5.232zm13.568-2.19c-3.129 0-5.357 1.727-5.357 4.293 0 2.04 1.264 3.317 3.918 3.93l1.865.451c1.815.413 2.553 1.014 2.553 2.053 0 1.202-1.214 2.053-2.941 2.053-1.765 0-3.092-.864-3.229-2.19h-2.503c.1 2.654 2.278 4.281 5.582 4.281 3.492 0 5.683-1.715 5.683-4.443 0-2.14-1.252-3.354-4.155-4.018l-1.665-.376c-1.765-.426-2.491-.989-2.491-1.94 0-1.202 1.101-2.003 2.729-2.003 1.64 0 2.766.814 2.891 2.153h2.453c-.063-2.528-2.153-4.243-5.332-4.243zm-13.568 0c-4.205 0-6.834 2.866-6.834 7.422 0 4.594 2.628 7.447 6.834 7.447 4.181 0 6.821-2.854 6.821-7.447 0-4.556-2.641-7.422-6.822-7.422zm-9.525-.025h-.024a1.352 1.352 0 1 0 0 2.704h.026-.001l.037.001a1.352 1.352 0 1 0 0-2.704l-.039.001h.002z" />
  </svg>
)
export { SvgComponent as IosIcon }
