import React, { memo } from 'react';
import { ReduxActionItem } from '~/components/ReduxActionItem';
import { ApiEventItem } from '~/components/ApiEventItem';
import { StorageActionItem } from '~/components/StorageActionItem';
import { CapturedEventItem } from '~/components/CapturedEventItem';
import { TanStackQueryEventItem } from '~/components/TanStackQueryEventItem';
import { MobxEventItem } from '~/components/MobxEventItem';
import { CapturedCrashReportItem } from '~/components/CapturedCrashReportItem';
import { TimelineItem } from '~/types/types';

export type TimelineListItemProps = {
  item: TimelineItem;
  timeSincePrevEvent?: string;
};

const TimelineListItem: React.FC<TimelineListItemProps> = ({
  item,
  timeSincePrevEvent
}) => {
  if ("actionId" in item)
    return (
      <ReduxActionItem interceptedReduxAction={item} timeSincePrevEvent={timeSincePrevEvent}/>
    );
  if ("requestId" in item)
    return (
      <ApiEventItem interceptedApiCall={item} timeSincePrevEvent={timeSincePrevEvent}/>
    );
  if ("storageActionId" in item)
    return (
      <StorageActionItem interceptedStorageAction={item} timeSincePrevEvent={timeSincePrevEvent}/>
    );
  if ("capturedEventId" in item)
    return (
      <CapturedEventItem capturedEvent={item} timeSincePrevEvent={timeSincePrevEvent}/>
    );
  if ("tanStackQueryEventId" in item)
    return (
      <TanStackQueryEventItem interceptedTanStackQueryEvent={item} timeSincePrevEvent={timeSincePrevEvent} />
    );
  if ("mobxEventId" in item)
    return (
      <MobxEventItem interceptedMobxEvent={item} timeSincePrevEvent={timeSincePrevEvent} />
    );
  if ("crashReportId" in item)
    return (
      <CapturedCrashReportItem capturedCrashReport={item} timeSincePrevEvent={timeSincePrevEvent} />
    );
  
  return null;
};

TimelineListItem.defaultProps={}

const MemorizedComponent = memo(TimelineListItem);
export { MemorizedComponent as TimelineListItem };